import i18n from 'i18next';
import { cloneDeep } from 'lodash-es';

import { BatchInfo, CalculationVariable, UUID } from '@dametis/core';

import { apiQueryToBatch } from 'components/UI/BatchFilter/functions/batchConvert';
import flattenTree from 'functions/flattenTree';
import { sdk } from 'sdk';
import switchGroupSite from 'store/helpers/switchGroupSite';
import { clearQuery, setBatchFetching, setBatches, setCurrentBatch, setFilterOperator, setFilterQuery } from 'store/slices/batch';
import { ToastSeverity } from 'types';

import { TypedThunk } from '../index';
import { addToast } from '../slices/toast';

import { displaySdkErrorToast } from './toasts';

export const saveBatch = (): TypedThunk<Promise<boolean>> => async (dispatch, getState) => {
  const state = getState();
  if (!state.batch.currentBatch) throw new Error();
  const batch = cloneDeep(state.batch.currentBatch);
  dispatch(setBatchFetching(true));
  try {
    const { data } = await sdk.batch.Update(batch.uuid, {
      name: batch.name,
      children: batch.children,
      conditionMode: batch.conditionMode,
      conditionDelay: batch.conditionDelay,
      extendedCondition: batch.extendedCondition,
    });
    await dispatch(fetchBatches());
    dispatch(setCurrentBatch(data));
    dispatch(addToast({ message: i18n.t('toast:successSaveChanges'), severity: ToastSeverity.SUCCESS }));
    return true;
  } catch (err) {
    console.error(err);
    dispatch(displaySdkErrorToast(err));
    return false;
  } finally {
    dispatch(setBatchFetching(false));
  }
};

export const createNewBatch = (): TypedThunk<Promise<UUID | null>> => async (dispatch, getState) => {
  const state = getState();
  const siteId = state.auth.selectedSite?.uuid;
  if (!state.batch.currentBatch || !siteId) throw new Error();
  const currentBatch = cloneDeep(state.batch.currentBatch);
  dispatch(setBatchFetching(true));
  try {
    const { data } = await sdk.batch.Create(siteId, {
      name: currentBatch.name,
      children: currentBatch.children,
      conditionMode: currentBatch.conditionMode,
      conditionDelay: currentBatch.conditionDelay,
      extendedCondition: currentBatch.extendedCondition,
    });
    await dispatch(fetchBatches());
    dispatch(setCurrentBatch(data));
    dispatch(addToast({ message: i18n.t('toast:successCreateBatch'), severity: ToastSeverity.SUCCESS }));
    dispatch(setBatchFetching(false));
    return data.uuid;
  } catch (err) {
    console.error(err);
    dispatch(displaySdkErrorToast(err));
    dispatch(setBatchFetching(false));
    return null;
  }
};

export const getBatch =
  (batchId: UUID): TypedThunk<Promise<BatchInfo>> =>
  async dispatch => {
    dispatch(setBatchFetching(true));
    try {
      const { data } = await sdk.batch.Read(batchId);
      await dispatch(switchGroupSite(data));
      dispatch(setCurrentBatch(data));
      return data;
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
      dispatch(setCurrentBatch(null));
      throw err;
    } finally {
      dispatch(setBatchFetching(false));
    }
  };

export const fetchBatches = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  const { selectedSite } = getState().auth;
  if (!selectedSite) return;
  try {
    const { data } = await sdk.batch.List(selectedSite?.uuid);
    const flattenBatches = Object.values(flattenTree(data));
    dispatch(
      setBatches({
        allBatches: data,
        flattenBatches,
      }),
    );
  } catch (err) {
    console.error(err);
    dispatch(displaySdkErrorToast(err));
  }
};

export const clearStore = (): TypedThunk<void> => dispatch => {
  dispatch(clearQuery());
};

export const deleteBatch =
  (batchId: string): TypedThunk<Promise<void>> =>
  async dispatch => {
    await sdk.batch.Delete(batchId);
    await dispatch(fetchBatches());
    dispatch(addToast({ message: i18n.t('toast:successDeleteBatch'), severity: ToastSeverity.SUCCESS }));
  };

export const updateBatchFilters =
  (data: { type: string; content: any }[]): TypedThunk<void> =>
  dispatch => {
    try {
      dispatch(setFilterQuery(data));
    } catch (err) {
      console.error(err);
    }
  };

export const insertInQuery =
  (type: string, data: string | BatchInfo): TypedThunk<void> =>
  (dispatch, getState) => {
    const {
      batch: { filters },
    } = getState();
    const newQuery = cloneDeep(filters.query ?? []);
    if (newQuery.length >= 1) {
      newQuery.push({
        type: 'operator',
        content: 'and',
      });
    }
    newQuery.push({
      type,
      content: data,
    });
    dispatch(updateBatchFilters(newQuery));
  };

export const updateQuery =
  (type: string, data: string | BatchInfo, index: number): TypedThunk<void> =>
  (dispatch, getState) => {
    const {
      batch: { filters },
    } = getState();

    const newQuery = cloneDeep(filters.query ?? []);
    newQuery[index] = {
      type,
      content: data,
    };
    dispatch(updateBatchFilters(newQuery));
  };

export const setBatchFilters =
  (data?: CalculationVariable): TypedThunk<void> =>
  dispatch => {
    try {
      if (!data) {
        dispatch(setFilterQuery([]));
        return;
      }
      dispatch(setFilterQuery(apiQueryToBatch(data)));
      dispatch(setFilterOperator(data.operator));
    } catch (err) {
      dispatch(setFilterQuery([]));
    }
  };

import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FC, KeyboardEventHandler, PropsWithChildren, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface PromptBeforeDisablingProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onDisable: (...args: any[]) => any;
}

const PromptBeforeDisabling: FC<PropsWithChildren<PromptBeforeDisablingProps>> = ({ open, children = null, setOpen, onDisable }) => {
  const { t } = useTranslation('prompts');

  const [disabling, setDisabling] = useState<boolean>(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleDelete = useCallback(async () => {
    try {
      setDisabling(true);
      if (onDisable) {
        await onDisable();
      }
      setDisabling(false);
      setOpen(false);
    } catch (err) {
      console.error(err);
      setDisabling(false);
    }
  }, [setDisabling, onDisable, setOpen]);

  const handlePressEnter = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        void handleDelete();
      }
    },
    [handleDelete],
  );

  return (
    <Dialog open={open} onClose={handleCancel} onKeyDown={handlePressEnter}>
      <DialogTitle>{t('title.beforeDeleting')}</DialogTitle>
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions>
        <Button color="primary" onClick={handleCancel}>
          {t('button.noDisable')}
        </Button>
        <LoadingButton color="warning" loading={disabling} onClick={handleDelete}>
          {t('button.yesDisable')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PromptBeforeDisabling;

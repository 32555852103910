import { OrderBy } from '@dametis/core';

// import { BlockDiagramView } from 'components/Lego/BlockDiagram/BlockDiagramTopBar';
import { SortedBy as BlockSortedBy } from 'components/Lego/BlockDiagram/BlockList/BlockList';
import { SortedBy as FolderSortedBy } from 'components/Lego/BlockDiagram/FolderList/FolderList';
import { TabBlockFiltersElements } from 'types/lego';

export default {
  title: {
    lego: 'Diagrama de bloques',
    blockTypes: 'Tipos de bloque',
    createBlockTypeModal: 'Nuevo tipo de bloque',
    editBlockTypeModal: 'Modificar tipo de bloque',
    blockTypePanel: 'Tipo de bloque',
    blocks: 'Bloques',
    folders: 'Carpetas',
    createBlockModal: 'Nuevo bloque',
    createFolderModal: 'Nueva carpeta',
    updateBlockModal: 'Modificar bloque',
    updateFolderModal: 'Modificar carpeta',
    blockPanel: 'Bloque',
    folderPanel: 'Carpeta',
    blockFilters: 'Filtrar parámetros y métricas',
    nextAction: 'Acaba de crear un bloque. ¿Qué desea hacer ahora?',
    setBlockParameters: 'Definir los parámetros del bloque « {{blockName}} »',
    moveTo: 'Mover hacia...',
  },
  button: {
    add: 'Añadir',
    newBlockType: 'Tipo de bloque',
    newBlock: 'Bloque',
    newFolder: 'Carpeta',
    close: 'Cerrar',
    cancel: 'Cancelar',
    create: 'Crear',
    update: 'Modificar',
    duplicate: 'Duplicar',
    setParameters: 'Parámetros',
    edit: 'Modificar',
    delete: 'Vaciar',
    previous: 'Anterior',
    next: 'Siguiente',
    blocks: 'Bloques',
    blocks_zero: '0 bloque',
    blocks_one: '1 bloque',
    blocks_other: '{{count}} bloques',
    goToBlocks: 'Ir a los bloques',
    open: 'Abrir',
    openInPlayground: 'Abrir en un playground',
    createAnOtherBlock: 'Crear otro bloque',
    setBlockParameters: 'Definir parámetros',
    save: 'Guardar',
    sortedBy: 'Ordenar por: $t(lego:label.sortedBy.{{value}})',
    new: 'Nuevo',
    createBlock: 'Bloque',
    createFolder: 'Carpeta',
    moveTo: 'Mover hacia',
    move: 'Mover',
  },
  label: {
    name: 'Nombre',
    description: 'Descripción',
    parameters: 'Parámetros',
    metrics: 'Métricas',
    availableParameters: 'Parámetros disponibles',
    blockTypes: 'Tipos de bloque',
    owners: 'Propietarios',
    undefinedParameters_one: '{{count}} no asignado',
    undefinedParameters_other: '{{count}} no asignados',
    comment: 'Comentario',
    sortedBy: {
      [BlockSortedBy.BLOCK_NAME]: 'Nombre del bloque',
      [BlockSortedBy.OWNER]: 'Propietario',
      [BlockSortedBy.BLOCKTYPE_NAME]: 'Nombre del tipo de bloque',
      [BlockSortedBy.NB_PARAMETERS]: 'Número de parámetros',
      [BlockSortedBy.NB_METRICS]: 'Número de métricas',
      [BlockSortedBy.CREATED_AT]: 'Fecha de creación',
      [BlockSortedBy.UPDATED_AT]: 'Fecha de modificación',
      [FolderSortedBy.FOLDER_NAME]: 'Nombre',
      [FolderSortedBy.NB_BLOCKS]: 'Número de bloques',
      [FolderSortedBy.NB_FOLDERS]: 'Número de carpetas',
    },
    blocksLength_zero: 'Sin bloques',
    blocksLength_one: '1 bloque',
    blocksLength_other: '{{count}} bloques',
    availableBlocksLength_zero: 'Sin funcional',
    availableBlocksLength_one: '1 funcional',
    availableBlocksLength_other: '{{count}} funcionales',
    unavailableBlocksLength_zero: 'Sin incompleto',
    unavailableBlocksLength_one: '1 incompleto',
    unavailableBlocksLength_other: '{{count}} incompletos',
    blockListToolbar:
      '$t(lego:label.blocksLength, { "count": {{blocksLength}} }) ($t(lego:label.availableBlocksLength, { "count": {{availableBlocksLength}} }), $t(lego:label.unavailableBlocksLength, { "count": {{unavailableBlocksLength}} }))',
    foldersLength_zero: 'Sin carpeta',
    foldersLength_one: '1 carpeta',
    foldersLength_other: '{{count}} carpetas',
    filtersAndSort: 'Filtros y Orden',
    newBlock: 'Nuevo bloque',
    newFolder: 'Nueva carpeta',
    tags: 'Tags',
  },
  placeholder: {
    name: 'Nombre',
    calculation: 'Cálculo',
    blockKey: 'Identificador',
    unit: 'Unidad',
  },
  stepper: {
    general: 'General',
    metrics: 'Métricas',
    parameters: 'Parámetros',
    blockType: 'Tipo de bloque',
    informations: 'Información',
  },
  text: {
    noBlockType: 'Sin tipo de bloque',
    noBlock: 'Sin bloques',
    noParameter: 'Sin parámetro',
    noMetric: 'Sin métrica',
    availableParameter: '{{blockKey}} ({{unit}})',
    otherParametersLength: '+{{count}} parámetro(s)',
    otherMetricsLength: '+{{count}} métrica(s)',
    metricsCount_zero: 'Sin métrica',
    metricsCount_one: 'Una métrica',
    metricsCount_other: '{{count}} métricas',
    creationDate: 'Creado el {{date}}',
    updateDate: 'Actualizado el {{date}}',
    addFirstBlockType: 'Añadir un primer tipo de bloque',
    addFirstBlock: 'Añadir un primer bloque',
    canNotDeleteMetricReferredInAnOther: 'Imposible vaciar una métrica utilizada en otro lugar.',
    openInPlayground: 'Abrir en un playground.',
    editParameter: 'Modificar parámetro.',
    blockVariableWithPath: '{{block}} ▸ {{variable}}',
    // blockVariableWithPath: '{{standardBlock}} ▸ {{block}} ▸ {{variable}}'
    unusedParameters: 'Parámetros no utilizados:',
    usedParameters: 'Parámetros ya utilizados:',
    favorite: 'Favorito',
    allSelected: 'Seleccionar todo',
    chooseBlockType: 'Elija un tipo de bloque:',
    chooseRequiredParameters: 'Elija los parámetros requeridos:',
    nbRequiredParametersSelected_zero: 'Ningún parámetro seleccionado.',
    nbRequiredParametersSelected_one: 'Un parámetro seleccionado.',
    nbRequiredParametersSelected_other: '{{count}} parámetros seleccionados.',
    metricsAvailable: 'Disponibilidad de métricas:',
    nbMetricsAvailable_zero: 'Ninguna métrica disponible.',
    nbMetricsAvailable_one: 'Una métrica disponible.',
    nbMetricsAvailable_other: '{{count}} métricas disponibles.',
    noMetricWithHelper: 'No hay métricas disponibles para este bloque. Puede agregar parámetros para utilizar más métricas.',
    parameterInvalid_one: 'Este parámetro es requerido para el funcionamiento del bloque',
    parameterInvalid_other: 'Estos parámetros son requeridos para el funcionamiento del bloque',
    parameterUndefined_one: 'Este parámetro no es obligatorio pero no está definido',
    parameterUndefined_other: 'Estos parámetros no son obligatorios pero no están definidos',
    parameterUnsetVnc: `Ce paramètre n'a pas été défini`,
    orderBy: {
      [OrderBy.ASC]: 'Ascendente',
      [OrderBy.DESC]: 'Descendente',
    },
    home: 'Inicio',
    noBlockInFolder: 'No tiene ningún bloque en esta carpeta',
    noFolderInFolder: 'No tiene ninguna carpeta',
    noCorrespondingBlock: 'Ningún bloque coincide con su búsqueda',
    noCorrespondingFolder: 'Ninguna carpeta coincide con su búsqueda',
    noFolder: 'Sin carpeta',
    noParameterToDefine: 'Ningún parámetro por definir.',
    editToAddParameters: 'Debe modificar este bloque para añadirle parámetros.',
    copy: 'Copia',
  },
  toast: {
    // FOLDER
    createFolderSuccess: 'La carpeta fue creada correctamente',
    updateFolderSuccess: 'La carpeta se actualizó correctamente',
    deleteFolderSuccess: 'La carpeta se eliminó correctamente',
    duplicateFolderSuccess: 'La carpeta se duplicó correctamente',
    moveFolderSuccess: 'La carpeta se movió correctamente',
    // BLOCK
    createBlockSuccess: 'El bloque fue creado correctamente',
    updateBlockSuccess: 'El bloque se actualizó correctamente',
    deleteBlockSuccess: 'El bloque fue eliminado correctamente',
    duplicateBlockSuccess: 'El bloque se duplicó correctamente',
    moveBlockSuccess: 'El bloque se movió correctamente',
    // BLOCK TYPE
    createBlockTypeSuccess: 'El tipo de bloque se creó correctamente',
    updateBlockTypeSuccess: 'El tipo de bloque se actualizó correctamente',
    deleteBlockTypeSuccess: 'El tipo de bloque se eliminó correctamente',
    duplicateBlockTypeSuccess: 'El tipo de bloque se duplicó correctamente',
    moveBlockTypeSuccess: 'El tipo de bloque se movió correctamente',
    // STANDARD BLOCK
    createStandardBlockSuccess: 'El bloque estándar fue creado correctamente',
    updateStandardBlockSuccess: 'El bloque estándar se actualizó correctamente',
    deleteStandardBlockSuccess: 'El bloque estándar se eliminó correctamente',
    duplicateStandardBlockSuccess: 'El bloque estándar se duplicó correctamente',
    moveStandardBlockSuccess: 'El bloque estándar se movió correctamente',
  },
  blockFilters: {
    elements: {
      [TabBlockFiltersElements.ALL]: 'Todos',
      [TabBlockFiltersElements.FAVORITES]: 'Favoritos',
      [TabBlockFiltersElements.PARAMETERS]: 'Parámetros',
      [TabBlockFiltersElements.METRICS]: 'Métricas',
    },
  },
  tooltip: {
    // view: {
    //   [BlockDiagramView.FOLDERS]: 'Carpetas',
    //   [BlockDiagramView.ALL_BLOCKS]: 'Todos los bloques',
    // },
    createEntity: 'crear una entidad',
    seeCalculation: 'Ver la expresión',
    createBlock: 'crear un bloque',
    createFolder: 'créer una carpeta',
    createBlockType: 'crear un tipo de bloque',
  },
};

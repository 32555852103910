import { Alert, alertClasses } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MaintenanceMessage, MaintenanceMessageTypes } from '@dametis/core';

interface Props {
  message?: MaintenanceMessage;
}

const MaintenanceBanner: FC<Props> = ({
  message = {
    type: MaintenanceMessageTypes.MSG,
    content: undefined,
  },
}) => {
  const { t } = useTranslation('header');

  return (
    <Alert severity="warning" sx={{ maxHeight: 48, [`& .${alertClasses.message}`]: { p: 0, whiteSpace: 'pre-line' } }}>
      {message.type === MaintenanceMessageTypes.CUSTOM ? message.content : t(`maintenance.${message.type ?? MaintenanceMessageTypes.MSG}`)}
    </Alert>
  );
};

export default MaintenanceBanner;

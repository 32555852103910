import { DrawerActionName } from 'types/drawer';

export default {
  menu: {
    item: {
      mostRecent_zero: 'Aucun récemment visité',
      mostRecent_one: 'Récemment visité',
      mostRecent_other: 'Récemment visités',
      actions_zero: 'Aucune action rapide',
      actions_one: 'Action rapide',
      actions_other: 'Actions rapides',
    },
    actions: {
      [DrawerActionName.NEW_PLAYGROUND_WITH_TY_CHART]: 'Graphique temporel',
      [DrawerActionName.NEW_REPORT_CUSTOM_PERIOD]: 'Rapport personnalisé',
    },
  },
  toast: {
    responsiveDrawerDisabled: 'Menu adaptatif désactivé.',
    resetResponsiveDrawer: 'Rétablir',
  },
  tooltip: {
    removeEntity: 'Retirer de la liste',
  },
  home: {
    button: 'Accueil',
  },
  energies: {
    button: 'Énergies',
  },
  analysis: {
    button: 'Analyse',
  },
  synoptics: {
    button: 'Synoptiques',
  },
  lego: {
    button: 'Schéma-bloc',
    button_short: 'Blocs',
    blockTypes: 'Catalogue des types',
    blocks: 'Blocs',
  },
  alarms: {
    button: 'Alarmes',
    alarms: 'Journal',
    activeAlarms: 'Alarmes actives',
    alarmsHistory: 'Historique',
    settings: 'Paramètres',
  },
  reports: {
    button: 'Rapports',
  },
  models: {
    button: 'Modèles',
  },
  playgrounds: {
    button: 'Playgrounds',
  },
  projects: {
    button: 'Projets',
    projects: 'Liste des projets',
    template: 'Modèles de projets',
    tasks: 'Tâches',
  },
  acquisition: {
    button: 'Acquisition',
    box: 'Modules',
    settings: 'Configuration',
  },
  configuration: {
    button: 'Configuration',
    button_short: 'Config',
    variables: 'Variables',
    devices: 'Équipements',
    boxes: 'Boxes',
    group: 'Groupe',
    site: 'Site',
    batches: 'Batchs',
    models: 'Modélisation',
    tags: 'Tags',
  },
  data: {
    button: 'Données',
    button_short: 'Données',
    manualEntry: 'Saisie manuelle',
    advancedEntry: 'Correction',
    fileImport: 'Import',
    export: 'Export',
    history: 'Historique',
  },
  billing: {
    button: 'Facturation',
    bills: 'Factures',
    contracts: 'Contrats',
  },
  activities: {
    button: 'Activités',
  },
  debuggus: {
    button: 'Debuggus',
  },
  exploration: {
    button: 'Exploration',
  },
  dataHistory: {
    button: 'Historique',
    button_short: 'Historique',
  },
  misc: {
    button: 'Divers',
  },
  maintenance: {
    button: 'Maintenance',
    button_short: 'Maintenance',
  },
  monitoring: {
    button: 'Monitoring',
    beta: 'Beta',
  },
  boxes: {
    button: 'Boxes',
  },
  users: {
    button: 'Utilisateurs',
  },
  groups: {
    button: 'Groupes',
  },
  authentications: {
    button: 'Authentifications',
    button_short: 'Auth',
  },
  steamMixes: {
    button: 'Mix vapeur',
  },
};

import { Done } from '@mui/icons-material';
import { Chip, ChipProps, Palette, chipClasses, styled } from '@mui/material';

import { setColorLightness } from 'functions/color';

export interface ChipSelectItemProps extends Omit<ChipProps, 'variant' | 'className'> {
  selected?: boolean;
}

const IsPaletteKey = (color: string | undefined, palette: Palette): color is keyof Palette =>
  color !== undefined && palette[color as keyof Palette] !== undefined;

const ChipSelectItem = styled(({ selected, icon, ...props }: ChipSelectItemProps) => (
  <Chip icon={icon || (selected ? <Done fontSize="medium" /> : undefined)} variant={selected ? 'filled' : 'outlined'} {...props} />
))(({ theme, color }) => {
  const parsedColorKey: keyof Palette = IsPaletteKey(color, theme.palette) ? color : 'primary';

  return {
    cursor: 'pointer',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 12,
    width: 'fit-content',
    paddingInline: '1px',
    marginBlock: '1px',
    color: theme.palette[parsedColorKey].main,
    borderColor: theme.palette[parsedColorKey].main,
    [`& .${chipClasses.icon}`]: {
      fontSize: 18,
      color: `${theme.palette[parsedColorKey].main}`,
    },
    [`&.${chipClasses.filled}`]: {
      paddingInline: '0px',
      backgroundColor: setColorLightness(theme.palette[parsedColorKey].main, 95),
      color: theme.palette[parsedColorKey].dark,
      border: `2px solid ${theme.palette[parsedColorKey].main}`,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  };
});

export default ChipSelectItem;

import { Grid2, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef } from 'react';
import { useSlateStatic } from 'slate-react';
import { Key } from 'ts-key-enum';

import { Hotkeys } from '../../../../UI/Hotkeys/Hotkeys';
import { insertFunction } from '../../../slate/function';

import type { LocaleMathFunctionHelper } from './types';

interface Props {
  func: LocaleMathFunctionHelper;
  selected: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSearch: Dispatch<SetStateAction<string>>;
}

const FunctionListItem: FC<Props> = ({ func, selected, setOpen, setSearch }) => {
  const editor = useSlateStatic();

  const itemRef = useRef<HTMLLIElement>(null);

  const addFunction = useCallback(() => {
    setOpen(false);
    setSearch('');
    insertFunction(editor, func.name);
  }, [editor, func.name, setOpen, setSearch]);

  useEffect(() => {
    if (!selected) return;
    itemRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [selected]);

  return (
    <ListItem ref={itemRef} disablePadding sx={{ px: 0.75, position: 'relative' }}>
      <ListItemButton selected={selected} onClick={addFunction}>
        <ListItemText
          primary={
            <Grid2 container>
              <Grid2 size="grow">{func.localeName}()</Grid2>
              <Grid2 size={2}>
                <Typography align="right">{func.output.localeType}</Typography>
              </Grid2>
              <Grid2 size={2}>
                <Typography align="right">{func.output.unit}</Typography>
              </Grid2>
            </Grid2>
          }
          primaryTypographyProps={{ fontFamily: '"JuliaMono", monospace', color: 'primary.main' }}
          secondary={func.localeDescription}
          sx={{ m: 0 }}
        />
      </ListItemButton>
      {selected && (
        <>
          <Hotkeys
            hotkeys={Key.ArrowUp}
            size="small"
            sx={{ position: 'absolute', top: 0, left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2, pointerEvents: 'none' }}
          />
          <Hotkeys
            hotkeys={Key.ArrowDown}
            size="small"
            sx={{ position: 'absolute', bottom: 0, left: '50%', transform: 'translate(-50%, 50%)', zIndex: 2, pointerEvents: 'none' }}
          />
          <Hotkeys
            hotkeys={Key.Enter}
            size="small"
            sx={theme => ({
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: `translate(${theme.spacing(-2 * 0.75)}, -50%)`,
              zIndex: 2,
              pointerEvents: 'none',
            })}
          />
        </>
      )}
    </ListItem>
  );
};

export default FunctionListItem;

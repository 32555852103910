import { InputBaseComponentProps } from '@mui/material';
import { FC, useCallback } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

import AdvancedTextField, { AdvancedTextFieldProps } from '../Inputs/AdvancedTextField/AdvancedTextField';

export type AdvancedTextFieldWithMaskProps = AdvancedTextFieldProps & {
  maskedInputProps: MaskedInputProps;
};

const AdvancedTextFieldWithMask: FC<AdvancedTextFieldWithMaskProps> = ({ maskedInputProps, InputProps, ...advancedTextFieldProps }) => {
  const MaskedInputWithProps = useCallback(
    (props: InputBaseComponentProps) => <MaskedInput {...props} {...maskedInputProps} />,
    [maskedInputProps],
  );

  return (
    <AdvancedTextField
      InputProps={{
        inputComponent: MaskedInputWithProps,
        ...InputProps,
      }}
      {...advancedTextFieldProps}
    />
  );
};

export default AdvancedTextFieldWithMask;

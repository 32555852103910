import { ShortcutCategory } from '@dametis/core';

export default {
  title: 'Ajustes del sitio',
  siteMenu: {
    informations: {
      name: 'Información',
    },
    notifications: {
      name: 'Notificaciones',
    },
    usersManagement: {
      name: 'Gestión de usuarios',
    },
    users: {
      name: 'Usuarios',
    },
    invitations: {
      name: 'Invitaciones',
    },
    teams: {
      name: 'Equipos',
    },
    periods: {
      name: 'Períodos',
    },
    units: {
      name: 'Unidades',
    },
    styledRules: {
      name: 'Listas de reglas',
    },
  },
  informations: {
    form: {
      siteName: 'Nombre del sitio',
      groupName: 'Nombre del grupo',
      defaultReport: 'Informe de inicio predeterminado',
      selectReport: 'Seleccionar un informe',
      noReport: 'Ningún informe predeterminado',
      address: 'Dirección',
      language: 'Idioma del sitio',
      timeZone: 'Zona horaria',
      noTimeZoneFound: 'No hay ninguna zona horaria correspondiente',
    },
    presentation: {
      site: 'Sitio',
      group: 'Grupo',
      address: 'Dirección',
    },
  },
  users: {
    button: {
      invite: 'Invitar a un usuario',
      inviteLink: 'Invitar mediante enlace',
      create: 'Equipo',
    },
    searchNotFound: 'Ningún usuario coincide con esta búsqueda',
    userTile: {
      firstName: 'Nombre',
      lastName: 'Apellido',
      phone: 'Teléfono',
      email: 'Correo electrónico',
      statusInactive: 'Cuenta no activada',
      statusInvite: 'Invitación enviada',
      inviteButton: 'Invitar',
      clickToCopy: 'Hacer clic para copiar',
    },
  },
  periods: {
    button: {
      addPeriod: 'Periodo',
    },
  },
  toast: {
    successSaveChanges: 'Los cambios se han guardado correctamente.',
    errorSaveChanges: 'No se pudieron guardar los cambios.',
    infoUserInvitation: 'Se ha enviado un correo de invitación.',
    errorUserInvitation: 'No se pudo enviar la invitación.',
    userAlreadyExists: 'Esta dirección de correo electrónico ya está asociada a una cuenta.',
    successNewPeriod: 'El nuevo período ha sido guardado.',
    successRemovePeriod: 'El período ha sido eliminado correctamente.',
    updateNotificationsSuccess: 'La notificatión ha sido modificada correctamente.',
    updateNotificationsError: 'Error al editar las notificationes.',
  },
  tooltip: {
    editNotifications: 'Editar notificaciones',
    editSiteInfo: 'Editar la información del sitio',
    createTeam: 'Crear un equipo',
    createPeriod: 'Crear un período',
    createRuleList: 'Crear una lista de reglas',
    period: 'el período',
    inviteUser: 'Invitar a un nuevo usuario',
    createUserList: 'Crear un equipo de usuarios',
    editStyleConfiguration: 'Modificar',
    deleteStyleConfiguration: 'Vaciar',
    hasGroupAcl: 'No se pueden actualizar los derechos porque están configurados a nivel de grupo',
    mustChangeRole: 'Debe seleccionar otro rol para editar sitios',
    setMCO: 'Seleccionar para monitoring',
    mcoNotifications: 'Seleccionado para monitoring',
    selectReport: 'Seleccionar informe',
    removeReport: 'Eliminar informe',
  },
  text: {
    newList: 'Crear un equipo de usuarios',
    users: 'Usuarios',
    editList: 'Modificar el equipo: {{name}}',
    chosenUnit_zero: 'Ninguna unidad definida',
    chosenUnit_one: 'Una unidad definida',
    chosenUnit_other: '{{count}} unidades definidas',
    noStyledRules: 'Ninguna regla de estilo',
    entity: {
      [ShortcutCategory.PLAYGROUND]: 'Zona de pruebas: {{name}}',
    },
    csvModel: 'valor;texto\n1;Ejemplo de dispositivo 1\n',
  },
  input: {
    userListName: 'Nombre del equipo',
    search: 'Buscar...',
  },
  button: {
    add: 'Añadir',
    close: 'Cerrar',
    delete: 'Vaciar',
    cancel: 'Cancelar',
    create: 'Crear',
    update: 'Actualizar',
    edit: 'Modificar',
    save: 'Guardar',
    newStyle: 'Lista',
    newRule: 'Añadir una regla',
    clear: 'Vaciar',
    importFrom: 'Importar...',
    existingRules: 'Reglas existentes',
    matchingList: 'Lista de coincidencias',
    import: 'Importar',
    next: 'Siguiente',
    submit: 'Guardar',
  },
  modal: {
    title: 'Modificar el usuario',
    addTitle: 'Añadir un usuario',
    userSiteList: 'Sitios del usuario',
    warningEmptySites: 'El usuario debe tener al menos un sitio',
    createStyleConfigurationModal: 'Nueva lista de reglas',
    editStyleConfigurationModal: 'Modificar una lista de reglas',
    importStyledRulesFrom: 'Importar reglas desde...',
    saveStyleConfigurationModal: 'Guardar esta lista de reglas',
    personalInformation: 'Información personal',
    permissions: 'Permisos',
    corporate: 'Acceso al grupo',
  },
  label: {
    name: 'Apellido',
    rules: 'Reglas',
  },
  select: {
    role: 'Función',
    roleSITE_ADMINPrimary: 'Administrador',
    roleSITE_ADMINSecondary: 'Administración, lectura y escritura',
    roleCLIENTPrimary: 'Usuario estándar',
    roleCLIENTSecondary: 'Lectura y escritura',
    roleReadOnlyPrimary: 'Usuario limitado',
    roleReadOnlySecondary: 'Solo lectura',
    noGroupRole: 'Sin función de grupo',
  },
  pings: {
    title: 'Notificaciones',
    mails: 'Correos electrónicos',
    externalMails: 'Correos electrónicos externos',
    internalMails: 'Correos electrónicos internos',
    slack: 'Canal slack',
    addExternal: 'Añadir un correo electrónico interno',
    addInternal: 'Añadir un correo electrónico externo',
    changePeriod: 'Cambiar período',
    calendar: 'Calendario',
  },
};

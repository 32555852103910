import { CSVConfig, CSVDelimiter } from '@dametis/core';

export const createCSVConfig = ({
  delimiter = CSVDelimiter.AUTO,
  useHeader = false,
  startLine = null,
  stopLine = null,
  ignoreEmptyLines = false,
  charset = null,
}: Partial<CSVConfig> = {}): CSVConfig => ({
  delimiter,
  useHeader,
  startLine,
  stopLine,
  ignoreEmptyLines,
  charset,
});

import { ActiveAlarmInfo, UUID } from '@dametis/core';

import { activeAlarmsState, addActiveAlarmsListener, listenActiveAlarms, removeActiveAlarmsListener } from 'functions/activeAlarms';
import { RootState, useSelector } from 'store';

import { api } from './index';

const activeAlarmsApi = api.injectEndpoints({
  endpoints: build => ({
    readActiveAlarms: build.query<ActiveAlarmInfo[], { siteId?: UUID } | void>({
      providesTags: ['ActiveAlarms'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const siteId = (arg && arg.siteId) ?? state.auth.selectedSite!.uuid;
        listenActiveAlarms(siteId);
        return { data: await activeAlarmsState.record[siteId].asyncData };
      },
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, getState, updateCachedData }) {
        const state = getState() as RootState;
        const siteId = (arg && arg.siteId) ?? state.auth.selectedSite!.uuid;
        await cacheDataLoaded;
        const listener = (activeAlarms: ActiveAlarmInfo[]) => {
          updateCachedData(() => activeAlarms);
        };
        addActiveAlarmsListener({ listener, siteId });
        await cacheEntryRemoved;
        removeActiveAlarmsListener({ listener, siteId });
      },
    }),
  }),
});

export const { useReadActiveAlarmsQuery } = activeAlarmsApi;

export const useActiveAlarms: typeof useReadActiveAlarmsQuery = (arg, options) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return useReadActiveAlarmsQuery(arg ?? { siteId }, { skip: !siteId, ...options });
};

import {
  DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  DEFAULT_VARIABLE_DATA_DELAY,
  DEFAULT_VARIABLE_FREQUENCY,
  EmptyFileImportInfo,
  FileDeviceConfig,
  FileDeviceSourceType,
} from '@dametis/core';

export const createFileDeviceConfig = ({
  loopTime = 60000,
  deviceHeartbeatFrequency = DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  defaultVariableFrequency = DEFAULT_VARIABLE_FREQUENCY,
  defaultVariableDataDelay = DEFAULT_VARIABLE_DATA_DELAY,
  fileImport = null as unknown as EmptyFileImportInfo,
  source = {
    type: FileDeviceSourceType.SFTP_INTERNAL,
    config: {
      path: '',
    },
  },
}: Partial<FileDeviceConfig> = {}): FileDeviceConfig => ({
  loopTime,
  deviceHeartbeatFrequency,
  defaultVariableFrequency,
  defaultVariableDataDelay,
  fileImport,
  source,
});

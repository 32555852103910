import { DEFAULT_DEVICE_HEARTBEAT_FREQUENCY, DEFAULT_VARIABLE_DATA_DELAY, DEFAULT_VARIABLE_FREQUENCY, DeviceConfig } from '@dametis/core';

export const createDeviceConfig = ({
  deviceHeartbeatFrequency = DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  defaultVariableFrequency = DEFAULT_VARIABLE_FREQUENCY,
  defaultVariableDataDelay = DEFAULT_VARIABLE_DATA_DELAY,
}: Partial<DeviceConfig> = {}): DeviceConfig => ({
  deviceHeartbeatFrequency,
  defaultVariableFrequency,
  defaultVariableDataDelay,
});

import { ShortcutCategory } from '@dametis/core';

export default {
  title: 'Paramètres du site',
  siteMenu: {
    informations: {
      name: 'Informations',
    },
    notifications: {
      name: 'Notifications',
    },
    usersManagement: {
      name: 'Gestion des utilisateurs',
    },
    users: {
      name: 'Utilisateurs',
    },
    invitations: {
      name: 'Invitations',
    },
    teams: {
      name: 'Équipes',
    },
    periods: {
      name: 'Périodes',
    },
    units: {
      name: 'Unités',
    },
    styledRules: {
      name: 'Listes de règles',
    },
  },
  informations: {
    form: {
      siteName: 'Nom du site',
      groupName: 'Nom du groupe',
      defaultReport: "Rapport d'accueil par défaut",
      selectReport: 'Sélectionner un rapport',
      noReport: 'Aucun rapport par défaut',
      address: 'Adresse',
      language: 'Langue du site',
      timeZone: 'Fuseau horaire',
      noTimeZoneFound: 'Aucun fuseau horaire correspondant',
    },
    presentation: {
      site: 'Site',
      group: 'Groupe',
      address: 'Adresse',
    },
  },
  users: {
    button: {
      invite: 'Inviter un utilisateur',
      inviteLink: 'Inviter via un lien',
      create: 'Équipe',
    },
    searchNotFound: 'Aucun utilisateur ne correspond à cette recherche',
    userTile: {
      firstName: 'Prénom',
      lastName: 'Nom',
      phone: 'Téléphone',
      email: 'Email',
      statusInactive: 'Compte non activé',
      statusInvite: 'Invitation envoyée',
      inviteButton: 'Inviter',
      clickToCopy: 'Cliquer pour copier',
    },
  },
  periods: {
    button: {
      addPeriod: 'Période',
    },
  },
  toast: {
    successSaveChanges: 'Les modifications ont bien été enregistrées.',
    errorSaveChanges: "Impossible d'enregistrer les modifications.",
    infoUserInvitation: "Un émail d'invitation a été envoyé.",
    errorUserInvitation: "Impossible d'envoyer l'invitation.",
    userAlreadyExists: 'Cette adresse email est déjà associée à un compte.',
    successNewPeriod: 'La nouvelle période est enregistrée.',
    successRemovePeriod: 'La période a bien été supprimée.',
    updateNotificationsSuccess: 'Notifications modifiées avec succès',
    updateNotificationsError: 'Une erreur est survenue lors de la modification des notifications',
  },
  tooltip: {
    editNotifications: 'modifier les notications',
    editSiteInfo: 'modifier les informations du site',
    createTeam: 'créer une équipe',
    createPeriod: 'créer une période',
    createRuleList: 'créer une liste de règles',
    period: 'la période',
    inviteUser: 'inviter un nouvel utilisateur',
    createUserList: "créer une équipe d'utilisateurs",
    editStyleConfiguration: 'Modifier',
    deleteStyleConfiguration: 'Supprimer',
    hasGroupAcl: 'Vous ne pouvez pas modifier ces droits car ils sont paramétrés à un plus haut niveau',
    mustChangeRole: 'Vous devez changer de rôle pour changer les sites',
    setMCO: 'Sélectionner pour le monitoring',
    mcoNotifications: 'Sélectionné pour le monitoring',
    selectReport: 'Sélectionner un rapport',
    removeReport: 'Retirer le rapport',
  },
  text: {
    newList: "Créer une équipe d'utilisateurs",
    users: 'Utilisateurs',
    editList: "Modifier l'équipe: {{name}}",
    chosenUnit_zero: 'Aucune unité définie',
    chosenUnit_one: 'Une unité définie',
    chosenUnit_other: '{{count}} unités définies',
    noStyledRules: 'Aucune règle de style',
    entity: {
      [ShortcutCategory.PLAYGROUND]: 'Playground : {{name}}',
    },
    csvModel: "valeur;texte\n1;Exemple d'appareil 1\n",
  },
  input: {
    userListName: "Nom de l'équipe",
    search: 'Rechercher...',
  },
  button: {
    add: 'Ajouter',
    close: 'Fermer',
    delete: 'Supprimer',
    cancel: 'Annuler',
    create: 'Créer',
    update: 'Mettre à jour',
    edit: 'Modifier',
    save: 'Enregistrer',
    newStyle: 'Liste',
    newRule: 'Ajouter une règle',
    clear: 'Vider',
    importFrom: 'Importer...',
    existingRules: 'Règles existantes',
    matchingList: 'Liste de correspondances',
    import: 'Importer',
    next: 'Suivant',
    submit: 'Sauvegarder',
  },
  modal: {
    title: "Modifier l'utilisateur",
    addTitle: 'Ajouter un utilisateur',
    userSiteList: "Sites de l'utilisateur",
    warningEmptySites: "L'utilisateur doit avoir au moins un site",
    createStyleConfigurationModal: 'Nouvelle liste de règles',
    editStyleConfigurationModal: 'Modifier une liste de règles',
    importStyledRulesFrom: 'Importer des règles depuis...',
    saveStyleConfigurationModal: 'Sauvegarder cette liste de règles',
    personalInformation: 'Informations personnelles',
    permissions: 'Permissions',
    corporate: 'Accès au groupe',
  },
  label: {
    name: 'Nom',
    rules: 'Règles',
  },
  select: {
    role: 'Rôle',
    roleSITE_ADMINPrimary: 'Administrateur',
    roleSITE_ADMINSecondary: 'Administration, lecture et écriture',
    roleCLIENTPrimary: 'Utilisateur standard',
    roleCLIENTSecondary: 'Lecture et écriture',
    roleReadOnlyPrimary: 'Utilisateur limité',
    roleReadOnlySecondary: 'Lecture seule',
    noGroupRole: 'Sans rôle de groupe',
  },
  pings: {
    title: 'Notifications monitoring',
    mails: 'Emails',
    externalMails: 'Emails client',
    internalMails: 'Emails Dametis (cachés au client)',
    slack: 'Canal slack',
    addExternal: 'Ajouter un email client',
    addInternal: 'Ajouter un email Dametis',
    calendar: 'Période',
  },
};

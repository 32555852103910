import { ReportType, StyleFormat, TextAlign, WidgetType } from '@dametis/core';

import { GridView } from '../../types/grid';
import { WidgetCategories } from '../../types/reports';

export default {
  title: {
    reports: 'Rapports',
    myReports: 'Mes rapports',
    generatedReports: 'Rapports générés automatiquement',
    sharedReports: 'Partagés avec moi',
    privateReports: 'Privés',
    newReport: 'Nouveau rapport',
    editReport: 'Modifier le rapport',
    duplicateReport: 'Dupliquer le rapport',
    report: 'Rapport',
    report_withName: 'Rapport « {{name}} »',
    deleteReport: 'Supprimer le rapport « {{name}} » ?',
    home: 'Choisissez le type du rapport :',
    comments: 'Commentaires',
    flows: 'Flux',
    nodes: 'Noeuds',
    newFlow: 'Nouveau flux',
    styles: 'Styles',
    createReport: 'Créer un rapport',
  },
  button: {
    cancel: 'Annuler',
    create: 'Créer',
    createReport: 'Créer un rapport vierge',
    createReportFromModel: 'Créer un rapport depuis un autre',
    edit: 'Modifier',
    rename: 'Renommer',
    save: 'Enregistrer',
    saveAs: 'Dupliquer',
    close: 'Fermer',
    delete: 'Supprimer',
    duplicate: 'Dupliquer',
    newReport: 'Rapport',
    yes: 'Oui',
    no: 'Non',
    variable: 'Variable',
    print: 'Imprimer',
    cantPrint: 'Vous ne pouvez pas imprimer en mode édition',
    addWidget: 'Ajouter le widget',
    addSankeyFlow: 'Ajouter',
    createSankeyFlow: 'Créer',
    addVariable: 'Ajouter',
    addPlotBand: 'Ajouter',
    addBatch: 'Ajouter',
    addStyledRule: 'Ajouter',
    share: 'Partager',
    add: 'Ajouter',
    remove: 'Retirer',
    editTags: 'Éditer les tags',
    addComment: 'Ajouter un commentaire',
    duplicateWidget: 'Dupliquer le widget',
    deleteWidget: 'Supprimer le widget',
    insertColumn: 'Insérer une colonne',
    insertRow: 'Insérer une ligne',
    deleteColumn: 'Supprimer la colonne',
    deleteRow: 'Supprimer la ligne',
    saveStyleConfiguration: 'Liste',
    addStyleConfiguration: 'Liste',
    select: 'Sélectionner',
  },
  subtitle: {
    playground: 'Playground',
    playgroundTab: 'Onglet',
    noPlaygroundTabSelected: 'Aucun onglet sélectionné',
    minValue: 'min : {{min}}',
    maxValue: 'max : {{max}}',
    shownVariables: 'Variables affichées',
  },
  placeholder: {
    title: 'Titre',
    select: 'Sélectionner',
    reportName: 'Nom du rapport',
    text: 'Texte',
    label: 'Label (facultatif)',
    value: 'Valeur',
    searchWidget: 'Chercher un widget',
    selectBatch: 'Sélectionner...',
    placeholder: 'Écrivez quelque chose...',
  },
  allWidgets: {
    [WidgetCategories.CHARTS]: 'Graphiques',
    [WidgetCategories.TABLES]: 'Tableaux',
    [WidgetCategories.BASICS]: 'Élements de base',
    [WidgetCategories.PRETTY]: 'Graphiques minimalistes',
    [WidgetType.LINE]: {
      name: 'Courbe',
      description: "Affiche la courbe d'une variable.",
    },
    [WidgetType.BAR]: {
      name: 'Diagramme en bâtons',
      description: 'Affiche un diagramme en bâtons.',
    },
    [WidgetType.PRETTY_BAR]: {
      name: 'Diagramme en bâtons',
      description: 'Affiche un diagramme en bâtons.',
    },
    [WidgetType.PIE]: {
      name: 'Diagramme circulaire',
      description: 'Affiche un diagramme circulaire.',
    },
    [WidgetType.PRETTY_PIE]: {
      name: 'Diagramme circulaire',
      description: 'Affiche un diagramme circulaire.',
    },
    [WidgetType.CHRONO]: {
      name: 'Chronogramme',
      description: 'Affiche un chronogramme.',
    },
    [WidgetType.PLAYGROUND]: {
      name: 'Playground',
      description: "Affiche un onglet d'un playground.",
    },
    [WidgetType.AMM]: {
      name: 'Moyenne min max',
      description: "Affiche la moyenne, le minimum et le maximum d'une variable.",
    },
    [WidgetType.PMY]: {
      name: 'Période MTD YTD',
      description: "Affiche la valeur sur le mois en cours et l'année en cours.",
    },
    staticTable: {
      cell_average: 'Moyenne',
      cell_min: 'Min',
      cell_max: 'Max',
      cell_period: 'Période',
      cell_mtd: 'MTD',
      cell_ytd: 'YTD',
    },
    [WidgetType.TABLE]: {
      name: 'Tableau personnalisé',
      description: 'Affiche un tableau personnalisé.',
    },
    [WidgetType.SUBPERIODS]: {
      name: 'Tableau de sous-périodes',
      description: 'Affiche un tableau de sous-périodes.',
    },
    [WidgetType.TEXT]: {
      name: 'Texte',
      description: 'Affiche un texte personnalisé.',
    },
    [WidgetType.VARIABLE]: {
      name: 'Variable',
      description: "Affiche la valeur d'une variable.",
    },
    [WidgetType.DATE]: {
      name: 'Date',
      description: 'Affiche la date du rapport.',
    },
    [WidgetType.IMAGE]: {
      name: 'Image',
      description: 'Affiche une image de votre choix.',
    },
    [WidgetType.GAUGE]: {
      name: 'Graphique jauge',
      description: 'Affiche un graphique jauge.',
    },
    [WidgetType.SPLINE]: {
      name: 'Courbe lissée',
      description: 'Affiche une courbe lissée.',
    },
    count: {
      name: 'Compteur',
      description: 'Affiche un compteur.',
    },
    [WidgetType.SHORTCUTS]: {
      name: 'Raccourcis',
      description: 'Affiche une liste de raccourcis.',
    },
    [WidgetType.LINEAR_GAUGE]: {
      name: 'Jauge linéaire',
      description: 'Affiche une jauge linéaire.',
    },
    [WidgetType.PERIODIC_COMMENTS]: {
      name: 'Commentaires',
      description: 'Affiches les commentaires relatifs à la période en cours.',
    },
    [WidgetType.SANKEY]: {
      name: 'Sankey',
      description: 'Affiche un graphique sankey.',
    },
  },
  text: {
    widgets: 'Widgets',
    noReports: "Vous n'avez pas encore de rapport",
    noReportInSite: 'Aucun rapport accessible sur ce site',
    name: 'Nom',
    type: 'Type',
    tag: 'Tags',
    shortCreatedAt: 'Créé',
    shortUpdatedAt: 'Modifié',
    updatedAt: 'Modifié {{date}}',
    sharedBy: 'Partagé par {{name}}',
    ownedBy: 'Appartient à {{name}}',
    me: 'moi',
    emptyRow: 'Ligne vide',
    rows: 'Lignes',
    columns: 'Colonnes',
    displayLabel: 'Afficher les labels',
    headerFormat: "Format de l'en-tête",
    titleRow: 'Cellules de titre sur la 1ère ligne',
    titleCol: 'Cellules de titre sur la 1ère colonne',
    variable: 'Variable',
    batch: 'Batch',
    text: 'Texte',
    macrofield: 'Champ de macro',
    color: 'Couleur',
    picto: 'Picto',
    editCell: 'Modifier la cellule',
    errorName: 'Aucun nom de rapport',
    unit: 'Unité',
    autoUnit: 'Unité optimale',
    fontSize: 'Taille de police',
    small: 'Petite',
    medium: 'Moyenne',
    large: 'Grande',
    auto: 'Automatique',
    showSubPeriods: 'Afficher les données des sous-périodes',
    nothingToDisplay: 'Aucune variable sélectionnée',
    total: 'Total',
    subperiodTotal_week: 'Semaine',
    subperiodTotal_month: 'Mois',
    subperiodTotal_year: 'Année',
    data: 'Données',
    style: 'Style',
    plotBands: 'Zones',
    noSelectedData: 'Aucune donnée sélectionnée',
    noDefinedPlotBand: 'Aucune zone définie',
    needMinAndMax: 'Vous devez ajouter un MIN et un MAX',
    noAutoScale: "Impossible de générer automatiquement l'échelle avec un calcul.",
    noStyledRules: 'Pas de règles de style',
    rules: 'Règles',
    newReport: 'Nouveau rapport',
    noShortcut: 'Aucun raccourci',
    copy: 'Copie',
    search: 'Rechercher...',
    users: 'Utilisateurs',
    list: 'Listes',
    public: 'Public',
    private: 'Privé',
    shared: 'Partagé',
    view: {
      [GridView.TILE]: 'Vue tuile',
      [GridView.LIST]: 'Vue liste',
    },
    duplicated: ' - Copie',
    commentList: 'Liste des commentaires',
    noAssociatedProject: 'Pas de projet associé',
    noComment: 'Aucun commentaire',
    commentsPeriod: {
      from: 'Du {{from}}',
      to: 'au {{to}}',
    },
    commentsPagination: {
      nbComments_one: 'Un commentaire',
      nbComments_other: '{{count}} commentaires',
      linkedTo_one: 'lié à',
      linkedTo_other: 'liés à',
      nbProjects_one: 'un projet',
      nbProjects_other: '{{count}} projets',
      message_withProject:
        '$t(text.commentsPagination.nbComments, { "count": {{nbComments}} }) $t(text.commentsPagination.linkedTo, { "count": {{nbComments}} }) $t(text.commentsPagination.nbProjects, { "count": {{nbProjects}} })',
      message_withoutProject: '$t(text.commentsPagination.nbComments, { "count": {{nbComments}} })',
    },
    sortBy: 'trier par',
    types: {
      [ReportType.WEEK]: 'Rapports hebdomadaires',
      [ReportType.MONTH]: 'Rapports mensuels',
      [ReportType.YEAR]: 'Rapports annuels',
      [ReportType.CUSTOM]: 'Rapports personnalisés',
    },
    noSite: 'Aucun site',
    noSelectedSite: 'Aucun site sélectionné',
    editWidget: 'Modifier le widget',
    noImage: "Pas d'image pour l'instant",
    emptyFlows: 'Aucun flux',
    emptyNodes: 'Aucun noeud',
    flowDescription: 'Définissez ici vos noeuds et vos flux',
    nodeDescription: 'Le formatage d’un noeud s’applique aux flux dont il est la source',
    noSelectedPlayground: 'Aucun playground sélectionné',
    noVariables: 'Aucune variable',
    newGroup: 'Nouveau groupe',
  },
  image: {
    label: 'Image',
    button: 'Envoyer une image',
    fitOption: "Option d'ajustement",
    contain: 'Contenir',
    cover: 'Remplir',
  },
  input: {
    label: {
      newReport: 'Nom',
      yes: 'Oui',
      no: 'Non',
      format: {
        title: 'Format',
        [StyleFormat.BOLD]: 'Gras',
        [StyleFormat.ITALIC]: 'Italique',
        [StyleFormat.UNDERLINE]: 'Souligné',
      },
      align: {
        title: 'Alignement',
        [TextAlign.LEFT]: 'Gauche',
        [TextAlign.CENTER]: 'Centre',
        [TextAlign.RIGHT]: 'Droite',
      },
      fontSize: 'Taille',
      dataToDisplay: 'Affichage',
      reversed: 'Couleurs inversées',
      isLinkToPlayground: 'Ouvrir un playground',
      displayDuration: 'Afficher la durée',
      displayHours: "Afficher l'heure",
      displayPeriod: 'Afficher la période en cours',
      displayPagination: 'Afficher la pagination',
      displayByProjects: 'Grouper par projets',
      displayOnlyOnCurrentPeriod: 'Afficher les commentaires de la période en cours',
      type: 'Type',
      row: 'Label',
      data: 'Donnée',
      batch: 'Batch',
      source: 'Source',
      destination: 'Destination',
      unit: 'Unité',
      valueFormat: 'Format',
      name: 'Nom',
      newNode: 'Nouveau noeud',
      displayLegend: 'Afficher la légende',
      custom: 'Personnalisé',
    },
    helperText: {
      batch: 'Sélectionner un batch',
      variable: 'Sélectionner une variable',
      existingName: 'Déjà utilisé',
    },
    placeholder: {
      playgroundTab: 'Sélectionner un onglet...',
      noPlaygroundTabs: 'Aucun onglet disponible',
    },
  },
  tooltip: {
    createReport: 'créer un rapport',
    renameReport: 'renommer le rapport',
    editReportTags: 'modifier les tags du rapport',
    saveReport: 'sauvegarder le rapport',
    editReport: 'modifier le rapport',
    duplicateReport: 'dupliquer le rapport',
    printReport: 'imprimer le rapport',
    shareReport: 'partager le rapport',
    undoReportChanges: 'annuler les changements du rapport',
    deleteReport: 'supprimer le rapport',
    fontColor: 'Couleur texte',
    fillColor: 'Couleur fond',
    borderColor: 'Couleur bordure',
    picto: 'Pictogramme',
    generatedReport: 'Les rapports auto-générés ne sont pas modifiables ou supprimables',
    playgroundTabDisabled: 'Indisponible dans un rapport',
    editComment: 'Éditer le commentaire',
    deleteComment: 'Supprimer le commentaire',
    playground: 'Ouvrir un playground',
    openLink: 'Ouvrir le lien',
    addStyledRule: 'Ajouter une règle',
    saveStyleConfiguration: 'Enregistrer ces règles dans une liste',
    addStyleConfiguration: 'Utiliser une liste de règles',
    selectPlayground: 'Sélectionner un playground',
    removePlayground: 'Retirer le playground',
    editVariable: 'Modifier la variable',
    deleteVariable: 'Supprimer la variable',
  },
  form: {
    label: {
      shortcut: 'Raccourci',
      confidentiality: 'Confidentialité',
    },
    value: {
      confidentiality_private: 'Privé',
      confidentiality_shared: 'Partagé',
    },
    tooltip: {
      owner_shared: 'Si cette option est cochée, ce rapport sera visible par les autres utilisateurs',
      owner_private: 'Si cette option est cochée, ce rapport sera seulement visible par vous ',
      not_owner: "Vous ne pouvez pas changer la confidentialité de ce rapport car vous n'en êtes pas le propriétaire",
    },
  },
  types: {
    week: 'Rapport hebdomadaire',
    month: 'Rapport mensuel',
    year: 'Rapport annuel',
    custom: 'Rapport personnalisé',
  },
  helperText: {
    reloadPlayground1: 'Modifié ?',
    reloadPlayground2: 'Recharger.',
  },
  toast: {
    selectedReportCorrupted: 'Le rapport sélectionné est corrompu.',
    create: {
      success: 'Le rapport a bien été créé.',
      fail: 'Une erreur est survenue durant la création du rapport.',
    },
    save: {
      success: 'Le rapport a bien été enregistré.',
      fail: 'Une erreur est survenue durant la sauvegarde du rapport.',
    },
    delete: {
      success: 'Le rapport a bien été supprimé.',
      fail: 'Une erreur est survenue durant la suppression du rapport.',
    },
    duplicate: {
      success: 'Le rapport a bien été dupliqué.',
      fail: 'Une erreur est survenue durant la duplication du rapport.',
    },
    print: {
      failure: "Une erreur est survenue durant l'impression du rapport.",
    },
    subPeriodGroupByError: 'Le widget de sous-périodes est limité à 12 colonnes.',
  },
  error: {
    imageUpload_DEFAULT: "Il y a eu un problème lors de l'importation de votre image. Veuillez réessayer.",
    imageUpload_TOO_BIG: 'La taille de votre image est supérieure à 5 Mo. Veuillez réessayer avec une image plus petite.',
  },
  tab: {
    data: 'Paramètres',
    format: 'Format',
    style: 'Style',
    shortcut: 'Raccourci',
  },
  label: {
    title: 'Titre',
    subtitle: 'Sous-titre',
  },
};

import { IsFileParseError, IsPapaParseError, ParseError } from '@dametis/core';

export const getParseErrorsByRow = (errors?: ParseError[]): Record<number, ParseError[]> =>
  (errors ?? []).reduce<Record<number, ParseError[]>>((result, error) => {
    if (IsFileParseError(error) || (IsPapaParseError(error) && error.error.row)) {
      if (error.error.row !== undefined) {
        if (!result[error.error.row]) {
          result[error.error.row] = [];
        }
        result[error.error.row].push(error);
      }
    }
    return result;
  }, {});

import { differenceInSeconds } from 'date-fns';

import {
  CalculationVariable,
  DataVariable,
  GetCalculationVariableStats,
  IsCalculatedVariableInfo,
  IsRealVariableInfo,
  RawPoint,
  getMaxGroupBy,
} from '@dametis/core';

import store from '../../store';

export const shouldDisplayCalculationValue = (
  calculationVariable: CalculationVariable<DataVariable>,
  lastPoint?: RawPoint | undefined | null,
  fetchedAt?: Date | null,
): boolean => {
  const state = store.getState();
  if (lastPoint === undefined || lastPoint === null) return false;
  const selectedGroup = state.auth.selectedGroup!;
  const { selectedSite } = state.auth;
  const variables = state.variables.byId;
  let dataDelay = selectedSite !== null ? selectedSite.dataDelay : selectedGroup.sites[0].dataDelay;
  const stats = GetCalculationVariableStats(calculationVariable);
  const timeElapsed = differenceInSeconds(fetchedAt ?? new Date(), new Date(lastPoint.time));
  let maxGroupBy = getMaxGroupBy(calculationVariable);
  let hasCalculated = false;
  let hasReals = false;
  // eslint-disable-next-line no-restricted-syntax
  for (const dataVariable of stats.dataVariables) {
    const variable = variables[dataVariable.variableUuid];
    if (variable === undefined) return false;
    if (IsCalculatedVariableInfo(variable)) {
      const variableMaxGroupBy = getMaxGroupBy(variable.params);
      if (maxGroupBy === undefined || (variableMaxGroupBy !== undefined && variableMaxGroupBy.seconds > maxGroupBy.seconds)) {
        maxGroupBy = variableMaxGroupBy;
      }
      hasCalculated = true;
    }
    if (IsRealVariableInfo(variable)) {
      hasReals = true;
    }
  }
  if (maxGroupBy !== undefined) {
    dataDelay += maxGroupBy.seconds;
  }
  if (hasCalculated) {
    return dataDelay * 5 > timeElapsed;
  }
  if (hasReals) {
    return dataDelay > timeElapsed;
  }
  return true;
};

export const shouldDisplayDataVariable = (dataVariable: DataVariable, lastPoint?: RawPoint | undefined | null, fetchedAt?: Date): boolean =>
  shouldDisplayCalculationValue({ exp: 'var_0', vars: { var_0: dataVariable } }, lastPoint, fetchedAt);

import { StyleFormat, TextAlign } from '@dametis/core';

import { CommentFilter, CommentSortBy, CommentView, EntityType, LinkedProjectType } from 'types/comment';

export default {
  title: {
    comments: 'Comments',
    modal: 'Add a comment',
  },
  stepper: {
    comment: 'Comment',
    project: 'Project',
  },
  placeholder: {
    leaveAComment: 'Write a comment...',
  },
  text: {
    requiredComment: 'Leave a comment is required',
    sort: 'Sort',
    filter: 'Filter',
    noComment: 'No comments',
    owner: 'User',
    allSelected: 'Select all',
    from: 'from {{from}}',
    to: 'to {{to}}',
    noEntity: 'No entity',
    title: {
      variable: 'variable',
      report: 'report',
      alarm: 'alarm',
      project: 'project',
      task: 'task',
    },
    fontSize: {
      small: 'Small',
      medium: 'Medium',
      large: 'Large',
      auto: 'Auto',
    },
    periodFilterWarning:
      "After saving, your comment will disapear from the list be cause it's associated period is no longer included in the current period. Deactivate the filter on the current period to retrieve it.",
    noUserFound: 'No user found for "{{search}}"',
  },
  label: {
    withProject: 'Link a project to this comment',
    linkedProjectType: {
      [LinkedProjectType.NEW]: 'New project',
      [LinkedProjectType.EXISTING]: 'Existing project',
    },
    date: 'Date',
    displayOnlyOnCurrentPeriod: 'Display comments on current period',
    [StyleFormat.BOLD]: 'Bold',
    [StyleFormat.ITALIC]: 'Italic',
    [StyleFormat.UNDERLINE]: 'Underline',
    [TextAlign.LEFT]: 'Left alignment',
    [TextAlign.CENTER]: 'Center alignment',
    [TextAlign.RIGHT]: 'Right alignment',

    entity: {
      [EntityType.ALARM]: 'alarm',
      [EntityType.PLAYGROUND]: 'playground',
      [EntityType.REPORT]: 'report',
      [EntityType.PROJECT]: 'project',
      [EntityType.SYNOPTIC]: 'synoptic',
      [EntityType.VARIABLE]: 'variable',
      [EntityType.TASK]: 'task',
    },
  },
  button: {
    close: 'Close',
    next: 'Next',
    submit: 'Submit',
    previous: 'Previous',
    fullscreen: 'Full screen',
    exitFullscreen: 'Exit full screen',
    send: 'Send',
    joinProject: 'Link a project',
    cancel: 'Cancel',
    insertMention: 'Mention a collaborator',
  },
  sortBy: {
    [CommentSortBy.DATE]: 'Associated date',
    [CommentSortBy.DATE_REVERSE]: 'Associated date (reverse)',
    [CommentSortBy.CREATION_DATE]: "Comment's creation date",
    [CommentSortBy.CREATION_DATE_REVERSE]: "Comment's creation date (reverse)",
    [CommentSortBy.PERIOD_FROM]: 'Period start',
    [CommentSortBy.PERIOD_FROM_REVERSE]: 'Period start (reverse)',
    [CommentSortBy.PERIOD_TO]: 'Period end',
    [CommentSortBy.PERIOD_TO_REVERSE]: 'Period end (reverse)',
  },
  view: {
    [CommentView.LIST]: 'View as list',
    [CommentView.BY_PROJECT]: 'View by project',
  },
  filter: {
    [CommentFilter.ALL]: 'Display all comments',
    [CommentFilter.CURRENT_PERIOD]: 'Display comments on current period',
  },
  tooltip: {
    deleteComment: 'Delete this comment',
    editComment: 'Edit this comment',
    goToPeriod: 'Set period on {{entity}}',
    samePeriod: 'Period is already set on {{entity}}',
  },
};

import { Stack } from '@mui/material';
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
// import { useTranslation } from 'react-i18next';

import {
  BacnetDeviceConfig,
  Delay,
  DeviceConfig,
  FileDeviceConfig,
  HttpDeviceConfig,
  MQTTDeviceConfig,
  ModbusTcpDeviceConfig,
  OpcUaDeviceConfig,
  ProtocolKind,
  S7DeviceConfig,
  SQLDeviceConfig,
  SyncPoint,
} from '@dametis/core';

// import getProtocols from 'config/protocols';

import { FormLocation } from './FormPaper';
import MCOSettingsForm from './MCOSettingsForm';
import BacnetSettingsForm from './ProtocolSettingsForm/BacnetSettingsForm';
import FileSettingsForm from './ProtocolSettingsForm/FileSettingsForm/FileSettingsForm';
import HttpSettingsForm from './ProtocolSettingsForm/HttpSettingsForm/HttpSettingsForm';
import MQTTSettingsForm from './ProtocolSettingsForm/MQTTSettingsForm/MQTTSettingsForm';
import ModbusTcpSettingsForm from './ProtocolSettingsForm/ModbusTcpSettingsForm';
import OpcUaSettingsForm from './ProtocolSettingsForm/OpcUaSettingsForm/OpcUaSettingsForm';
import S7SettingsForm from './ProtocolSettingsForm/S7SettingsForm/S7SettingsForm';
import SQLSettingsForm from './ProtocolSettingsForm/SQLSettingsForm/SQLSettingsForm';

export enum SettingsMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export interface DeviceSettingsFormProps {
  protocol?: ProtocolKind;
  modbusTcp: ModbusTcpDeviceConfig;
  setModbusTcp: Dispatch<SetStateAction<ModbusTcpDeviceConfig>>;
  opcUa: OpcUaDeviceConfig;
  setOpcUa: Dispatch<SetStateAction<OpcUaDeviceConfig>>;
  sql: SQLDeviceConfig;
  setSQL: Dispatch<SetStateAction<SQLDeviceConfig>>;
  file: FileDeviceConfig;
  setFile: Dispatch<SetStateAction<FileDeviceConfig>>;
  s7: S7DeviceConfig;
  setS7: Dispatch<SetStateAction<S7DeviceConfig>>;
  http: HttpDeviceConfig;
  setHttp: Dispatch<SetStateAction<HttpDeviceConfig>>;
  bacnet: BacnetDeviceConfig;
  setBacnet: Dispatch<SetStateAction<BacnetDeviceConfig>>;
  mqtt: MQTTDeviceConfig;
  setMQTT: Dispatch<SetStateAction<MQTTDeviceConfig>>;
  isEditing?: boolean;
  syncPoint?: SyncPoint;
  onChangeSyncPoint?: (newSyncPoint: SyncPoint) => void;
  filterPastPoints?: boolean;
  onChangeFilterPastPoints?: (newFilterPastPoints: boolean) => void;
  location?: `${FormLocation}`;
  mode?: `${SettingsMode}`;
}

const DeviceSettingsForm: FC<DeviceSettingsFormProps> = ({
  protocol = undefined,
  modbusTcp,
  setModbusTcp,
  opcUa,
  setOpcUa,
  sql,
  setSQL,
  file,
  setFile,
  s7,
  setS7,
  http,
  setHttp,
  bacnet,
  setBacnet,
  mqtt,
  setMQTT,
  isEditing = true,
  syncPoint = undefined,
  onChangeSyncPoint = undefined,
  filterPastPoints = undefined,
  onChangeFilterPastPoints = undefined,
  location = 'modal',
  mode = 'create',
}) => {
  // const { t } = useTranslation('devices');

  // const protocols = useMemo(() => getProtocols(t), [t]);

  // const protocolConfig = useMemo(() => protocols[protocol] ?? null, [protocols, protocol]);

  const [deviceConfig, setDeviceConfig]: [DeviceConfig | undefined, Dispatch<SetStateAction<DeviceConfig>> | undefined] = useMemo(() => {
    if (protocol === ProtocolKind.MODBUS_TCP) {
      return [modbusTcp, setModbusTcp as Dispatch<SetStateAction<DeviceConfig>>];
    }
    if (protocol === ProtocolKind.OPC_UA) {
      return [opcUa, setOpcUa as Dispatch<SetStateAction<DeviceConfig>>];
    }
    if (protocol === ProtocolKind.SQL) {
      return [sql, setSQL as Dispatch<SetStateAction<DeviceConfig>>];
    }
    if (protocol === ProtocolKind.FILE) {
      return [file, setFile as Dispatch<SetStateAction<DeviceConfig>>];
    }
    if (protocol === ProtocolKind.S7) {
      return [s7, setS7 as Dispatch<SetStateAction<DeviceConfig>>];
    }
    if (protocol === ProtocolKind.HTTP) {
      return [http, setHttp as Dispatch<SetStateAction<DeviceConfig>>];
    }
    if (protocol === ProtocolKind.BACNET) {
      return [bacnet, setBacnet as Dispatch<SetStateAction<DeviceConfig>>];
    }
    if (protocol === ProtocolKind.MQTT) {
      return [mqtt, setMQTT as Dispatch<SetStateAction<DeviceConfig>>];
    }
    return [undefined, undefined];
  }, [
    protocol,
    modbusTcp,
    setModbusTcp,
    opcUa,
    setOpcUa,
    sql,
    setSQL,
    file,
    setFile,
    s7,
    setS7,
    http,
    setHttp,
    bacnet,
    setBacnet,
    mqtt,
    setMQTT,
  ]);

  const handleChangeDefaultVariableDataDelay = useCallback(
    (newDelay: Delay) => {
      if (!setDeviceConfig) {
        return;
      }
      setDeviceConfig(state => ({ ...state, defaultVariableDataDelay: newDelay }));
    },
    [setDeviceConfig],
  );

  const handleChangeDeviceHeartbeatFrequency = useCallback(
    (newDelay: Delay) => {
      if (!setDeviceConfig) {
        return;
      }
      setDeviceConfig(state => ({ ...state, deviceHeartbeatFrequency: newDelay }));
    },
    [setDeviceConfig],
  );

  const handleChangeDefaultVariableFrequency = useCallback(
    (newDelay: Delay) => {
      if (!setDeviceConfig) {
        return;
      }
      setDeviceConfig(state => ({ ...state, defaultVariableFrequency: newDelay }));
    },
    [setDeviceConfig],
  );

  return (
    <Stack gap={1.5}>
      {/* <Typography variant="h6">{protocolConfig.name}</Typography> */}
      {protocol === ProtocolKind.MODBUS_TCP && (
        <ModbusTcpSettingsForm isEditing={isEditing} location={location} modbusTcp={modbusTcp} setModbusTcp={setModbusTcp} />
      )}
      {protocol === ProtocolKind.OPC_UA && (
        <OpcUaSettingsForm isEditing={isEditing} location={location} opcUa={opcUa} setOpcUa={setOpcUa} />
      )}
      {protocol === ProtocolKind.SQL && <SQLSettingsForm isEditing={isEditing} location={location} setSQL={setSQL} sql={sql} />}
      {protocol === ProtocolKind.FILE && (
        <FileSettingsForm
          file={file}
          filterPastPoints={filterPastPoints}
          isEditing={isEditing}
          location={location}
          mode={mode}
          setFile={setFile}
          syncPoint={syncPoint}
          onChangeFilterPastPoints={onChangeFilterPastPoints}
          onChangeSyncPoint={onChangeSyncPoint}
        />
      )}
      {protocol === ProtocolKind.S7 && <S7SettingsForm isEditing={isEditing} location={location} s7={s7} setS7={setS7} />}
      {protocol === ProtocolKind.HTTP && (
        <HttpSettingsForm
          filterPastPoints={filterPastPoints}
          http={http}
          isEditing={isEditing}
          location={location}
          setHttp={setHttp}
          syncPoint={syncPoint}
          onChangeFilterPastPoints={onChangeFilterPastPoints}
          onChangeSyncPoint={onChangeSyncPoint}
        />
      )}
      {protocol === ProtocolKind.BACNET && (
        <BacnetSettingsForm bacnet={bacnet} isEditing={isEditing} location={location} setBacnet={setBacnet} />
      )}
      {protocol === ProtocolKind.MQTT && <MQTTSettingsForm isEditing={isEditing} location={location} mqtt={mqtt} setMQTT={setMQTT} />}

      {deviceConfig && (
        <MCOSettingsForm
          defaultVariableDataDelay={deviceConfig.defaultVariableDataDelay}
          defaultVariableFrequency={deviceConfig.defaultVariableFrequency}
          deviceHeartbeatFrequency={deviceConfig.deviceHeartbeatFrequency}
          isEditing={isEditing}
          location={location}
          onChangeDefaultVariableDataDelay={handleChangeDefaultVariableDataDelay}
          onChangeDefaultVariableFrequency={handleChangeDefaultVariableFrequency}
          onChangeDeviceHeartbeatFrequency={handleChangeDeviceHeartbeatFrequency}
        />
      )}
    </Stack>
  );
};

export default DeviceSettingsForm;

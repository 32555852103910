import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { ChangeEventHandler, Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModbusTcpDeviceConfig } from '@dametis/core';

import AdvancedTextFieldWithMask from 'components/UI/AdvancedTextFieldWithMask/AdvancedTextFieldWithMask';
import MaskFormat from 'components/UI/InputMask/MaskFormat';
import AdvancedTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';

import FormPaper, { FormLocation } from '../FormPaper';

export interface ModbusTcpSettingsFormProps {
  modbusTcp: ModbusTcpDeviceConfig;
  setModbusTcp: Dispatch<SetStateAction<ModbusTcpDeviceConfig>>;
  isEditing?: boolean;
  location?: `${FormLocation}`;
}

const ModbusTcpSettingsForm: FC<ModbusTcpSettingsFormProps> = ({ modbusTcp, setModbusTcp, isEditing = true, location = 'modal' }) => {
  const { t } = useTranslation('devices');

  const [portInputValue, setPortInputValue] = useState<string>('');
  const [uidInputValue, setUidInputValue] = useState<string>('');
  const [loopTimeInputValue, setLoopTimeInputValue] = useState<string>('');
  const [pauseAfterRequestInputValue, setPauseAfterRequestInputValue] = useState<string>('');
  const [maxRegisterToReadByRequestInputValue, setMaxRegisterToReadByRequestInputValue] = useState<string>('');
  const [maxUnusedValueInputValue, setMaxUnusedValueInputValue] = useState<string>('');

  const handleChangeHost: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setModbusTcp) {
        return;
      }
      setModbusTcp(state => ({ ...state, host: event.target.value }));
    },
    [setModbusTcp],
  );

  const handleChangePort: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setModbusTcp) {
        return;
      }
      setPortInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setModbusTcp(state => ({ ...state, port: parsedValue }));
      }
    },
    [setModbusTcp],
  );

  const handleChangeUid: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setModbusTcp) {
        return;
      }
      setUidInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setModbusTcp(state => ({ ...state, uid: parsedValue }));
      }
    },
    [setModbusTcp],
  );

  const handleChangeLoopTime: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setModbusTcp) {
        return;
      }
      setLoopTimeInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setModbusTcp(state => ({ ...state, loopTime: parsedValue }));
      }
    },
    [setModbusTcp],
  );

  const handleChangePauseAfterRequest: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setModbusTcp) {
        return;
      }
      setPauseAfterRequestInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setModbusTcp(state => ({ ...state, pauseAfterRequest: parsedValue }));
      }
    },
    [setModbusTcp],
  );

  const handleChangeMaxRegisterToReadByRequest: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setModbusTcp) {
        return;
      }
      setMaxRegisterToReadByRequestInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setModbusTcp(state => ({ ...state, maxRegisterToReadByRequest: parsedValue }));
      }
    },
    [setModbusTcp],
  );

  const handleChangeMaxUnusedValue: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setModbusTcp) {
        return;
      }
      setMaxUnusedValueInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setModbusTcp(state => ({ ...state, maxUnusedValue: parsedValue }));
      }
    },
    [setModbusTcp],
  );

  const handleChangeInvertBytes: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setModbusTcp) {
        return;
      }
      setModbusTcp(state => ({ ...state, invertBytes: event.target.checked }));
    },
    [setModbusTcp],
  );

  const handleChangeInvertWords: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setModbusTcp) {
        return;
      }
      setModbusTcp(state => ({ ...state, invertWords: event.target.checked }));
    },
    [setModbusTcp],
  );

  useEffect(() => {
    setPortInputValue(`${modbusTcp.port ?? ''}`);
  }, [modbusTcp.port]);

  useEffect(() => {
    setUidInputValue(`${modbusTcp.uid ?? ''}`);
  }, [modbusTcp.uid]);

  useEffect(() => {
    setLoopTimeInputValue(`${modbusTcp.loopTime ?? ''}`);
  }, [modbusTcp.loopTime]);

  useEffect(() => {
    setPauseAfterRequestInputValue(`${modbusTcp.pauseAfterRequest ?? ''}`);
  }, [modbusTcp.pauseAfterRequest]);

  useEffect(() => {
    setMaxRegisterToReadByRequestInputValue(`${modbusTcp.maxRegisterToReadByRequest ?? ''}`);
  }, [modbusTcp.maxRegisterToReadByRequest]);

  useEffect(() => {
    setMaxUnusedValueInputValue(`${modbusTcp.maxUnusedValue ?? ''}`);
  }, [modbusTcp.maxUnusedValue]);

  return (
    <>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.acquisition')}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <AdvancedTextFieldWithMask
              fullWidth
              required
              editing={isEditing}
              label={t('label.ip')}
              maskedInputProps={MaskFormat.ip}
              value={modbusTcp.host ?? ''}
              onChange={handleChangeHost}
            />
          </Grid>
          <Grid item xs={3}>
            <AdvancedTextField
              fullWidth
              required
              editing={isEditing}
              InputProps={{ inputProps: { min: 0 } }} // SLE: check that
              label={t('label.port')}
              type="number"
              value={portInputValue}
              onChange={handleChangePort}
            />
          </Grid>
          <Grid item xs={3}>
            <AdvancedTextField
              fullWidth
              required
              editing={isEditing}
              InputProps={{ inputProps: { min: 0, max: 255 } }} // SLE: check that
              label={t('label.uid')}
              type="number"
              value={uidInputValue}
              onChange={handleChangeUid}
            />
          </Grid>
        </Grid>
      </FormPaper>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.queries')}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <AdvancedTextField
              fullWidth
              required
              description={t('description.loopTime')}
              editing={isEditing}
              InputProps={{ inputProps: { step: 100, min: 1000 } }} // SLE: check that // SLE: this step does not count in validator
              label={t('label.loopTime')}
              type="number"
              value={loopTimeInputValue}
              onChange={handleChangeLoopTime}
            />
          </Grid>
          <Grid item xs={6}>
            <AdvancedTextField
              fullWidth
              required
              description={t('description.pauseAfterRequest')}
              editing={isEditing}
              InputProps={{ inputProps: { step: 100, min: 0, max: 5000 } }} // SLE: check that
              label={t('label.pauseAfterRequest')}
              type="number"
              value={pauseAfterRequestInputValue}
              onChange={handleChangePauseAfterRequest}
            />
          </Grid>
          <Grid item xs={6}>
            <AdvancedTextField
              fullWidth
              required
              description={t('description.maxRegisterToReadByRequest')}
              editing={isEditing}
              InputProps={{ inputProps: { step: 1, min: 1, max: 255 } }} // SLE: check that
              label={t('label.maxRegisterToReadByRequest')}
              type="number"
              value={maxRegisterToReadByRequestInputValue}
              onChange={handleChangeMaxRegisterToReadByRequest}
            />
          </Grid>
          <Grid item xs={6}>
            <AdvancedTextField
              fullWidth
              required
              description={t('description.maxUnusedValue')}
              editing={isEditing}
              InputProps={{ inputProps: { step: 1, min: 0, max: 255 } }} // SLE: check that
              label={t('label.maxUnusedValue')}
              type="number"
              value={maxUnusedValueInputValue}
              onChange={handleChangeMaxUnusedValue}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={modbusTcp.invertBytes}
                  sx={!isEditing ? { pointerEvents: 'none' } : undefined}
                  onChange={isEditing ? handleChangeInvertBytes : undefined}
                />
              }
              label={t('label.invertBytes')}
              labelPlacement="end"
              sx={{ mr: 0, cursor: isEditing ? 'pointer' : 'default' }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={modbusTcp.invertWords}
                  sx={!isEditing ? { pointerEvents: 'none' } : undefined}
                  onChange={isEditing ? handleChangeInvertWords : undefined}
                />
              }
              label={t('label.invertWords')}
              labelPlacement="end"
              sx={{ mr: 0, cursor: isEditing ? 'pointer' : 'default' }}
            />
          </Grid>
        </Grid>
      </FormPaper>
    </>
  );
};

export default ModbusTcpSettingsForm;

import { LocationOnOutlined } from '@mui/icons-material';
import {
  Checkbox,
  CheckboxProps,
  Chip,
  Grid2,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getPhysicalQuantities } from 'config';
import { getVariableKind } from 'functions/getVariableKind';
import { useSelector } from 'store';
import { useVncStore } from 'zustand/stores/vnc';

import { AreSameVarCalc } from '../../../../../functions/tada/helpers.ts';
import CovarianceChip from '../../../../UI/CovarianceChip/CovarianceChip';
import PhysicalQuantityAvatar from '../../../../UI/PhysicalQuantity/PhysicalQuantityAvatar';
import PhysicalQuantityChip from '../../../../UI/PhysicalQuantity/PhysicalQuantityChip';
import VariableKindIcon from '../../../../UI/VariableKindIcon/VariableKindIcon';
import { PropsContext } from '../../../context';
import { useInsertVariable } from '../../../hooks';
import { VariableResult } from '../../../types';
import { TypographyNoBreak } from '../TypographyNoBreak';

export interface ListItemVariableProps {
  index: number;
  isLast?: boolean;
  item: VariableResult;
}

const ListItemVariable: FC<ListItemVariableProps> = ({ index, isLast = false, item: variable }) => {
  const { t } = useTranslation('vnc');

  const { multiple, defaultVariableOperator, disableMaths, covarianceVariable, calculatedVariableMode, defaultGlobalOperator } =
    useContext(PropsContext);

  const vncHighlight = useSelector(state => state.auth.user!.preferences.vncHighlight);
  const corporate = useSelector(state => !state.auth.selectedSite);

  const color = useMemo(() => getPhysicalQuantities()[variable.physicalQuantity]?.color, [variable.physicalQuantity]);

  const insertVariable = useInsertVariable();
  const selection = useVncStore(state => state.selection);
  const someSelected = useVncStore(state => Boolean(state.selection.length));
  const addToSelection = useVncStore(state => state.addToSelection);
  const removeByUuidFromSelection = useVncStore(state => state.removeByUuidFromSelection);

  const lessThanMd = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  const lessThanLg = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  const selected = useMemo(
    () => selection.some(varCalc => AreSameVarCalc(varCalc, { variableUuid: variable.uuid })),
    [selection, variable],
  );

  const firstTags = useMemo(() => variable.tags.slice(0, lessThanLg && !lessThanMd ? 1 : 2), [lessThanLg, lessThanMd, variable.tags]);
  const lastTags = useMemo(() => variable.tags.slice(lessThanLg && !lessThanMd ? 1 : 2), [lessThanLg, lessThanMd, variable.tags]);

  const variableName = useMemo(() => {
    if (!vncHighlight || variable.highlight === undefined || variable.highlight.name === undefined) {
      return <>{variable.name}</>;
    }
    return variable.highlight.name[0].split('<|>').map((text, i) => <>{i % 2 === 0 ? text : <mark>{text}</mark>}</>);
  }, [vncHighlight, variable.name, variable.highlight]);

  const variableReference = useMemo(() => {
    if (!vncHighlight || variable.highlight === undefined || variable.highlight.reference === undefined) {
      return <>{variable.reference}</>;
    }
    return variable.highlight.reference[0].split('<|>').map((text, i) => <>{i % 2 === 0 ? text : <mark>{text}</mark>}</>);
  }, [vncHighlight, variable.reference, variable.highlight]);

  const variableDescription = useMemo(() => {
    if (!vncHighlight || variable.highlight === undefined || variable.highlight.description === undefined) {
      return <>{variable.description}</>;
    }
    return variable.highlight.description[0].split('<|>').map((text, i) => <>{i % 2 === 0 ? text : <mark>{text}</mark>}</>);
  }, [vncHighlight, variable.description, variable.highlight]);

  const addVariable = useCallback(() => {
    insertVariable({ variableUuid: variable.uuid }, disableMaths, defaultVariableOperator ?? undefined, calculatedVariableMode);
  }, [insertVariable, variable.uuid, defaultVariableOperator, disableMaths, calculatedVariableMode]);

  const toggleVariableFromCheckbox = useCallback<NonNullable<CheckboxProps['onChange']>>(
    (_, checked) => {
      (checked ? addToSelection : removeByUuidFromSelection)({
        variableUuid: variable.uuid,
        operator: defaultVariableOperator ?? defaultGlobalOperator ?? undefined,
      });
    },
    [addToSelection, removeByUuidFromSelection, variable.uuid, defaultVariableOperator, defaultGlobalOperator],
  );

  const toggleVariableFromButton = useCallback(() => {
    (selected ? removeByUuidFromSelection : addToSelection)({
      variableUuid: variable.uuid,
      operator: defaultVariableOperator ?? defaultGlobalOperator ?? undefined,
    });
  }, [selected, removeByUuidFromSelection, addToSelection, variable.uuid, defaultVariableOperator, defaultGlobalOperator]);

  return (
    <ListItem
      divider={!isLast}
      secondaryAction={
        multiple ? (
          <Tooltip placement="right" title={t(selected ? 'tooltip.removeFromSelection' : 'tooltip.addToSelection')}>
            <Checkbox
              checked={selected}
              edge="end"
              sx={[Boolean(color) && { color: `${color} !important` }]}
              onChange={toggleVariableFromCheckbox}
            />
          </Tooltip>
        ) : undefined
      }
      sx={{ px: 0, py: 0.5 }}
    >
      <ListItemButton
        disableGutters
        selected={selected}
        tabIndex={2 + index}
        onClick={someSelected ? toggleVariableFromButton : addVariable}
      >
        <ListItemAvatar>
          <Stack alignItems="center" spacing={0.5} sx={{ width: 40 }}>
            <PhysicalQuantityAvatar physicalQuantity={variable.physicalQuantity} size="default" />
            <TypographyNoBreak variant="caption">{t('list.text.variable')}</TypographyNoBreak>
          </Stack>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Grid2 container alignItems="center" spacing={0.5}>
              <Grid2
                size={{
                  lg: 4,
                  md: 12,
                  sm: 12,
                  xs: 12,
                }}
              >
                <Typography noWrap variant="h6">
                  {variableName}
                </Typography>
                <Typography noWrap fontFamily="'Ubuntu Mono', monospace" fontSize={12} variant="subtitle2">
                  {variableReference}
                </Typography>
              </Grid2>
              <Grid2
                size={{
                  lg: 2,
                  md: 3,
                  sm: 4,
                  xs: 4,
                }}
              >
                <PhysicalQuantityChip
                  disableAvatar
                  physicalQuantity={variable.physicalQuantity}
                  sx={{ fontSize: 12 }}
                  unit={variable.unit}
                />
              </Grid2>
              {!lessThanMd && (
                <Grid2
                  size={{
                    lg: 3,
                    md: 4,
                  }}
                >
                  <Stack direction="row" spacing={0.5} sx={{ overflowX: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}>
                    {firstTags.map(tag => (
                      <Chip key={tag.uuid} label={tag.name} size="small" sx={{ fontSize: 12 }} />
                    ))}
                    {Boolean(lastTags.length) && (
                      <Tooltip placement="right" title={lastTags.map(tag => tag.name).join(', ')}>
                        <Chip label={`+${lastTags.length}`} size="small" sx={{ fontSize: 12 }} />
                      </Tooltip>
                    )}
                  </Stack>
                </Grid2>
              )}
              <Grid2
                size={{
                  lg: 2,
                  md: 3,
                  sm: 4,
                  xs: 4,
                }}
              >
                {corporate ? (
                  variable.site?.name && (
                    <Chip icon={<LocationOnOutlined />} label={variable.site.name} size="small" sx={{ fontSize: 12 }} variant="outlined" />
                  )
                ) : (
                  <Stack alignItems="center" direction="row" spacing={0.5}>
                    <VariableKindIcon fontSize="small" kind={getVariableKind(variable)} />
                    <Typography fontSize={12} lineHeight="12px">
                      {t(`data:variableKind.${getVariableKind(variable)}`)}
                    </Typography>
                  </Stack>
                )}
              </Grid2>
              {Boolean(covarianceVariable) && variable.score !== null && variable.score !== undefined && (
                <Grid2
                  size={{
                    lg: 1,
                    md: 2,
                    sm: 4,
                    xs: 4,
                  }}
                >
                  <CovarianceChip covariance={variable.score} disabled={variable.dismissed} size="small" />
                </Grid2>
              )}
              {lessThanMd && (
                <Grid2
                  size={{
                    sm: 12,
                    xs: 12,
                  }}
                >
                  <Stack direction="row" spacing={0.5} sx={{ overflowX: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}>
                    {firstTags.map(tag => (
                      <Chip key={tag.uuid} label={tag.name} size="small" sx={{ fontSize: 12 }} />
                    ))}
                    {Boolean(lastTags.length) && (
                      <Tooltip placement="right" title={lastTags.map(tag => tag.name).join(', ')}>
                        <Chip label={`+${lastTags.length}`} size="small" sx={{ fontSize: 12 }} />
                      </Tooltip>
                    )}
                  </Stack>
                </Grid2>
              )}
            </Grid2>
          }
          primaryTypographyProps={{ component: 'div' }}
          secondary={
            <Grid2 container mt={0.5} spacing={0.5}>
              <Grid2 sx={{ minWidth: '40%', maxWidth: '100%' }}>
                <Typography
                  noWrap
                  component="blockquote"
                  sx={{ backgroundColor: variable.description.length ? undefined : 'transparent' }}
                  variant="body2"
                >
                  {variableDescription}&nbsp;
                </Typography>
              </Grid2>
            </Grid2>
          }
          secondaryTypographyProps={{ component: 'div' }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default ListItemVariable;

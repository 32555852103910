import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { FC, useMemo } from 'react';

import { IsFileParseError, SourceColumn } from '@dametis/core';

import { areSameSourceColumns } from 'components/Data/FileImport/helpers/areSameSourceColumns';
import { isValid, localizedFormat } from 'localization/localizedDateFns';

import { useFilePreviewContext } from './FilePreviewContext';
import { ImportGridData } from './FilePreviewGridColDef';

export interface ValueCellProps {
  source: SourceColumn;
  params: GridRenderCellParams<ImportGridData>;
}

const ValueCell: FC<ValueCellProps> = ({ source, params }) => {
  const { dateTimeSource, dataInfo, parseErrorsByRow } = useFilePreviewContext();

  const isDateTimeCell = useMemo(() => dateTimeSource !== null && areSameSourceColumns(dateTimeSource, source), [dateTimeSource, source]);
  const rowIndex = useMemo(() => params.row.index, [params.row.index]);
  const columnIndex = useMemo(() => Number(params.field), [params.field]);
  const originalValue = useMemo(() => dataInfo?.[rowIndex]?.[columnIndex]?.originalValue, [dataInfo, rowIndex, columnIndex]);

  const dateTimeHasError = useMemo(
    () =>
      (parseErrorsByRow[params.row.index] ?? []).some(
        parseError => IsFileParseError(parseError) && parseError.error.column === dateTimeSource?.value,
      ),
    [parseErrorsByRow, params.row.index, dateTimeSource?.value],
  );

  const parsedValue: string = useMemo(() => {
    if (isDateTimeCell && !dateTimeHasError) {
      const parsedDate = new Date(params.value);
      if (isValid(parsedDate)) {
        return localizedFormat(parsedDate, 'Ppp');
      }
      return params.value;
    }
    return params.value;
  }, [params.value, isDateTimeCell, dateTimeHasError]);

  const displayOriginalValue = useMemo(
    () => originalValue && originalValue !== parsedValue && originalValue !== params.value,
    [originalValue, parsedValue, params.value],
  );

  return (
    <Stack>
      {displayOriginalValue && <Typography variant="caption">{originalValue}</Typography>}
      <Typography>{parsedValue}</Typography>
    </Stack>
  );
};

export default ValueCell;

import { ActivityVerb, ProjectTemplateCategoryType, ProjectType, StandardProjectStatus, StandardTaskStatus } from '@dametis/core';

export default {
  title: {
    projects: 'Mis proyectos',
    createForm: 'Nuevo proyecto',
    editForm: 'Modificar proyecto',
    task: 'Tarea',
    editTask: 'Modificar tarea',
    newProject: 'Nuevo proyecto',
    newSaving: 'Añadir ganancia',
    newForecastedSaving: 'Añadir ganancia prevista',
    editSaving: 'Modificar ganancia',
    editForecastedSaving: 'Modificar ganancia prevista',
    newConsumption: 'Introducir consumo de referencia',
    editConsumption: 'Modificar consumo de referencia',
    editInformation: 'Modificar información',
    newExpense: 'Añadir gasto',
    newSubsidy: 'Añadir subvención',
    duplicateProject: 'Duplicar proyecto',
    relativeSavings: 'Ganancias en relación con el período de referencia',
    createdAtBy: 'Creado el {{date}} por {{name}}',
    deletedUser: 'Usuario suprimido',
    editValues: 'Modificar valores',
    editRating: 'Modificar criterios de puntuación',
    caroussel: 'Imagen: {{imageName}}',
  },
  button: {
    newProject: 'Proyecto',
    addCost: 'Añadir costo',
    create: 'Crear',
    save: 'Guardar',
    share: 'Compartir',
    edit: 'Modificar...',
    cancel: 'Cancelar',
    continue: 'Continuar',
    delete: 'Suprimir',
    no: 'No',
    yes: 'Sí',
    addSubpart: 'Añadir categoría',
    addTask: 'Añadir tarea',
    removeTask: 'Suprimir tarea',
    deleteTask: 'Suprimir tarea',
    editTask: 'Modificar tarea',
    add: 'Añadir',
    upload: 'Importar',
    newAsset: 'Añadir documento',
    newTask: 'Añadir tarea',
    newSaving: 'Añadir ganancia',
    newForecastedSaving: 'Añadir ganancia prevista',
    newConsumption: 'Introducir consumo',
    changeStatus: 'Cambiar estado',
    newExpense: 'Añadir',
    newSubsidy: 'Añadir',
    editShortcuts: 'Modificar accesos directos',
    editCustom: 'Modificar',
    duplicate: 'Duplicar',
    order: {
      newest: 'De más reciente a más antiguo',
      oldest: 'De más antiguo a más reciente',
    },
    newImage: 'Añadir imagen',
    close: 'Cerrar',
    editImage: 'Modificar',
    deleteImage: 'Suprimir',
  },
  tooltip: {
    createProject: 'Crear proyecto',
    duplicateProject: 'Duplicar proyecto',
    shareProject: 'Compartir proyecto',
    editProject: 'Modificar proyecto',
    deleteProject: 'Suprimir',
    addDate: 'Añadir fecha',
    deleteDate: 'Suprimir fecha',
    editDates: 'Modificar período de referencia',
    savingByYear: 'Ganancias por año',
    consumptionByPeriod: 'Consumo en el período',
    delete: 'Suprimir',
    send: 'Enviar',
    edit: 'Modificar',
    taskAfterDeadline: 'Se ha superado la vencimiento',
    previousImage: 'Anterior',
    nextImage: 'Siguiente',
    noAccessUser: 'Este usuario no tiene acceso al proyecto',
  },
  toggle: {
    byYear: 'Por año',
    byPeriod: 'Por período',
  },
  input: {
    label: {
      name: 'Nombre',
      description: 'Descripción',
      currency: 'Moneda',
      costLabel: 'Partida de gasto',
      costValue: 'Monto',
      budget: 'Presupuesto',
      orderBy: 'Ordenar por',
      search: 'Buscar...',
      subpart: 'Categoría',
      task: 'Tarea',
      in: 'En la categoría',
      dependency: 'Dependencias',
      noDependency: 'Sin dependencia',
      from: 'Fecha de inicio',
      auto: 'Automático',
      duration: 'Duración',
      milestone: 'Hitos',
      co2: 'CO2',
      finance: 'Ahorros',
      electricity: 'Electricidad',
      water: 'Agua',
      gaz: 'Gas',
      assignee: 'Usuario asignado',
      deadline: 'Vencimiento',
      type: 'Tipo',
      priority: 'Prioridad',
      assets: 'Documentos',
      carbon: 'Carbono',
      financial: 'Financiero',
      energyType: 'Naturaleza',
      energy: 'Cantidad',
      // trackingMethod: 'Método de seguimiento',
      // trackingFrequency: 'Frecuencia de seguimiento',
      referencePeriod: 'Período de referencia',
      enableReferencePeriod: 'Activar período de referencia',
      label: 'Nombre del gasto',
      value: 'Valor',
      note: 'Nota',
      chief: 'Responsable',
      tags: 'Tags',
      tasksTemplate: 'Modelo de tareas',
      keepTasks: 'Duplicar tareas existentes',
      startDate: 'Inicio',
      endDate: 'Fin',
      currentStatus: 'Estado actual',
      statusDates: 'Fechas de estado',
      score: 'Calificación global',
      subsidyLabel: 'Nombre de la subvención',
      template: 'Modelo de proyectos',
      energiesUnit: 'Unidad',
      impact: 'Impacto',
    },
    placeholder: {
      emptyType: 'Ningún tipo seleccionado',
      emptyPriority: 'Ninguna prioridad seleccionada',
      emptyStatus: 'Ningún estado seleccionado',
      emptyTag: 'Ninguna tag seleccionada',
      emptySite: 'Todos los sitios',
      noSelect: 'Ninguna selección',
      renameFile: 'Debe seleccionar primero un documento',
    },
  },
  timeUnit: {
    hours: 'hora(s)',
    days: 'día(s)',
    weeks: 'semana(s)',
    months: 'mes',
  },
  text: {
    name: 'Nombre',
    chief: 'Responsable',
    type: 'Tipo',
    priority: 'Prioridad',
    state: 'Estado',
    currency: 'Moneda',
    cost: 'Costo',
    cost_plural: 'Costos',
    ongoingProjects: 'Proyectos en curso',
    finishedProjects: 'Proyectos cerrados',
    carbon: 'de emisiones de CO2',
    energy: 'de gasto energético',
    electricity: 'de ganancia en electricidad',
    fuel: 'de ganancia en fuel',
    gas: 'de ganancia en gas',
    water: 'de ganancia en agua',
    product: 'de ganancia en producto',
    finance: 'de ahorros',
    deleteProject: '¿Suprimir proyecto {{name}}?',
    ganttChart: 'Planificación',
    site: 'Sitio',
    noExpense: 'Sin gasto',
    noSubsidy: 'Sin subvención',
    noAsset: 'Sin documento',
    noImages: 'Sin imagen',
    noCommentLinked: 'Sin comentario asociado',
    noShortcut: 'Sin accesos directos definidos',
    noPlayground: 'Sin playground asociado.',
    noSynoptic: 'Sin sinopsis asociada.',
    noReport: 'Sin informe asociado.',
    reports: 'Informes',
    synoptics: 'Sinopsis',
    playgrounds: 'Playgrounds',
    unassigned: 'Sin asignar',
    noUser: 'Usuario desconocido',
    collaborators: 'Colaboradores',
    noTemplate: 'Sin modelo',
    commentNumber_zero: 'Sin comentario',
    commentNumber_one: '{{count}} comentario',
    commentNumber_other: '{{count}} comentarios',
    unknownSite: 'Sitio desconocido',
    allSelected: 'Seleccionar todo',
    byYear: '/año',
    byPeriod: '/período',
    years_one: 'año',
    years_other: 'años',
    paybackPeriod: {
      short: 'TIR real',
      full: 'Tiempo de retorno de la inversión real',
      value: 'con subvenciones',
      valueWithoutSubsidies: 'sin subvenciones',
      noData: 'Imposible calcular TIR',
    },
    forecastedPaybackPeriod: {
      short: 'TIR previsto',
      full: 'Tiempo de retorno de la inversión previsto',
      value: 'con subvenciones',
      valueWithoutSubsidies: 'sin subvenciones',
      noData: 'Imposible calcular TIR previsto',
    },
    categorySelectItemDisabled: 'Debe seleccionar una categoría de Ganancias y una categoría de Gastos',
    display: 'Vista',
    period: 'Período',
    total: 'Total',
    noConsumption: {
      title: 'Sin consumo de referencia',
      description: 'Agregue un consumo de referencia desde la sección "Período de referencia" para ver sus ganancias relativas',
    },
    comment: '“{{comment}}”',
    noComment: 'Sin comentario',
    alarm: 'alarma',
    playground: 'playground',
    report: 'informe',
    variable: 'variable',
    noValue: '-',
    template: 'Modelo',
    withoutTemplate: 'Sin modelo',
    customStatus: 'Otros estados',
    completionRate: 'Progreso',
    [ProjectTemplateCategoryType.STATUS]: 'Estado',
    [ProjectTemplateCategoryType.RATING]: 'Calificación',
    [ProjectTemplateCategoryType.REFPERIOD]: 'Período de referencia',
    [ProjectTemplateCategoryType.FORECASTED_SAVINGS]: 'Ganancias previstas',
    [ProjectTemplateCategoryType.SAVINGS]: 'Ganancias reales',
    [ProjectTemplateCategoryType.EXPENSES]: 'Gastos',
    [ProjectTemplateCategoryType.SUBSIDIES]: 'Subvenciones',
    [ProjectTemplateCategoryType.PAYBACK]: 'TIR',
    [ProjectTemplateCategoryType.TASKS]: 'Tareas',
    [ProjectTemplateCategoryType.ASSETS]: 'Documentos',
    [ProjectTemplateCategoryType.SHORTCUTS]: 'Accesos directos',
    [ProjectTemplateCategoryType.LINKS]: 'Enlaces',
    [ProjectTemplateCategoryType.COMMENTS]: 'Menciones',
    [ProjectTemplateCategoryType.ACTIVITIES]: 'Actividades',
    [ProjectTemplateCategoryType.CUSTOM]: 'Personalizado',
    [ProjectTemplateCategoryType.IMAGES]: 'Imágenes',
    noTask: 'Sin tarea',
  },
  priority: {
    variant_improvement: 'Mejora',
    variant_necessary: 'Necesario',
    variant_critical: 'Crítico',
  },
  type: {
    [`variant_${ProjectType.ANOMALY}`]: 'Anomalía',
    [`variant_${ProjectType.OPTIMISATION}`]: 'Optimización',
    [`variant_${ProjectType.GLOBALPROJECT}`]: 'Proyecto global',
    [`variant_${ProjectType.PROJECT}`]: 'Proyecto',
  },
  state: {
    variant_PENDING_VALIDATION_CAPEX: 'En validación CAPEX',
    variant_DEFINITION: 'Definición de proyecto',
    variant_PENDING_SIGNATURE: 'En espera de firma',
    variant_VALIDATED: 'Proyecto validado',
    variant_FINISH: 'Proyecto cerrado',
  },
  status: {
    [`variant_${StandardProjectStatus.PENDING_VALIDATION}`]: 'En validación',
    [`variant_${StandardProjectStatus.VALIDATED}`]: 'Validado',
    [`variant_${StandardProjectStatus.IN_PROGRESS}`]: 'En proceso',
    [`variant_${StandardProjectStatus.COMPLETED}`]: 'Finalizado',
    [`variant_${StandardProjectStatus.PERFORMANCE_VALIDATION}`]: 'Validación de rendimiento',
    [`variant_${StandardProjectStatus.CLOSED}`]: 'Terminado',
  },
  tasks: {
    status: {
      [`variant_${StandardTaskStatus.TODO}`]: 'Pendiente',
      [`variant_${StandardTaskStatus.ONGOING}`]: 'En curso',
      [`variant_${StandardTaskStatus.TESTING}`]: 'Por probar',
      [`variant_${StandardTaskStatus.DONE}`]: 'Terminada',
    },
  },
  activities: {
    type: {
      [`variant_${ActivityVerb.CREATE}`]: 'Creación',
      [`variant_${ActivityVerb.UPDATE}`]: 'Modificación',
      [`variant_${ActivityVerb.DELETE}`]: 'Borrado',
    },
    data: {
      variant_projects: 'de un proyecto',
      variant_sites: 'de un sitio',
      variant_assets: 'de un documento',
      variant_synoptics: 'de una sinopsis',
      variant_alarms: 'de una alarma',
      variant_reports: 'de un informe',
      variant_playgrounds: 'de un playground',
      variant_tasks: 'de una tarea',
    },
    label: `$t(projects:activities.type.variant, { "context": "{{type}}" }) $t(projects:activities.data.variant, { "context": "{{data}}" })`,
  },
  template: {
    name: {
      optimisationCip: 'Optimización de tiempos de NEP',
      optimisationEnvironment: 'Optimización ambiental',
    },
    tasks: {
      traceCleanProfile: '1. Trazar perfiles de lavado',
      analyse: '2. Analizar',
      defineNewTime: '3. Definir nuevos tiempos',
      preValidationQuality: '4. Prevalidación de Calidad',
      editAutomatism: '5. Ajuste de tiempos: acción de automatización',
      postValidationQuality: '6. Post-validación de Calidad',
      technicalValidation: 'Validación técnica',
      financialValidation: 'Validación financiera',
      environmentalValidation: 'Validación ambiental',
    },
  },
  grid: {
    noConsumption: 'Sin consumo ingresado',
    noSavings: 'Sin ganancias',
    noForecastedSavings: 'Sin ganancia prevista',
    savings: 'Ganancias',
    carbon: 'Carbono',
    electricity: 'Electricidad',
    gas: 'Gas',
    fuel: 'Fuel',
    water: 'Agua',
    product: 'Producto',
    financial: 'Ahorro',
  },
  unit: {
    PCS: 'pcs',
  },
  toast: {
    delete: {
      success: 'Proyecto suprimido con éxito.',
      error: 'Error al suprimir el proyecto',
    },
  },
};

import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const availableBufferEncoding: NodeJS.BufferEncoding[] = [
  'ascii',
  'utf8',
  'utf-8',
  'utf16le',
  'utf-16le',
  'ucs2',
  'ucs-2',
  'base64',
  'base64url',
  'latin1',
  'binary',
  'hex',
];

export const AUTO_BUFFER_ENCODING = 'auto';

export interface CharsetPickerProps {
  value: NodeJS.BufferEncoding | null;
  onChange: (newValue: NodeJS.BufferEncoding | null) => void;
}

const CharsetPicker: FC<CharsetPickerProps> = ({ value, onChange }) => {
  const { t } = useTranslation('fileImport');

  const [selectValue, setSelectValue] = useState<string>(AUTO_BUFFER_ENCODING);

  const handleChangeValue = useCallback(
    (event: SelectChangeEvent<string>) => {
      const newValue = event.target.value === AUTO_BUFFER_ENCODING ? null : (event.target.value as NodeJS.BufferEncoding);
      onChange(newValue);
    },
    [onChange],
  );

  useEffect(() => {
    if (value === null) {
      setSelectValue(AUTO_BUFFER_ENCODING);
    } else {
      setSelectValue(value);
    }
  }, [value]);

  return (
    <FormControl fullWidth required>
      <InputLabel>{t('label.charset')}</InputLabel>
      <Select<string>
        renderValue={inputValue => (
          <Box sx={{ ...(inputValue === AUTO_BUFFER_ENCODING && { fontStyle: 'italic', textTransform: 'capitalize' }) }}>{inputValue}</Box>
        )}
        value={selectValue}
        onChange={handleChangeValue}
      >
        <MenuItem sx={{ fontStyle: 'italic', textTransform: 'capitalize' }} value={AUTO_BUFFER_ENCODING}>
          {AUTO_BUFFER_ENCODING}
        </MenuItem>
        {availableBufferEncoding.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CharsetPicker;

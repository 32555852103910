import { FC, useCallback } from 'react';

import { SourceColumn, SourceColumnRealByHeader, SourceColumnRealByIndex, VirtualColumnColumn } from '@dametis/core';

import SourceColumnPicker from '../../SourceColumnPicker/SourceColumnPicker';

export const REAL_VALUE_PREFIX = 'real_';
export const VIRTUAL_VALUE_PREFIX = 'virtual_';

export interface VirtualColumnColumnTileProps {
  element: VirtualColumnColumn;
  onChange: (newElement: VirtualColumnColumn) => void;
  availableRealByIndexColumns: SourceColumnRealByIndex[];
  availableRealByHeaderColumns: SourceColumnRealByHeader[];
  availableVirtualColumnNames: string[];
}

const VirtualColumnColumnTile: FC<VirtualColumnColumnTileProps> = ({
  element,
  onChange,
  availableRealByIndexColumns,
  availableRealByHeaderColumns,
  availableVirtualColumnNames,
}) => {
  const handleChangeContentSource = useCallback(
    (newSource: SourceColumn) => {
      const newElement: VirtualColumnColumn = { ...element, content: { source: newSource } };
      onChange(newElement);
    },
    [element, onChange],
  );

  return (
    <>
      <SourceColumnPicker
        availableRealByHeaderColumns={availableRealByHeaderColumns}
        availableRealByIndexColumns={availableRealByIndexColumns}
        availableVirtualColumnNames={availableVirtualColumnNames}
        value={element.content.source}
        onChange={handleChangeContentSource}
      />
    </>
  );
};

export default VirtualColumnColumnTile;

import { InputLabel, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IsFileParseError, IsJqParseError, ParseError } from '@dametis/core';

import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';

import { getParseErrorLabel } from '../../helpers/getParseErrorLabel';

import {
  ERROR_HEIGHT,
  GAP_HEIGHT,
  IsVirtualListItemGlobalParseErrors,
  IsVirtualListItemRowParseErrors,
  LABEL_HEIGHT,
  VirtualListItem,
} from './types';

export type ErrorsListRowProps = {
  item: VirtualListItem;
};

const ErrorsListRow: FC<ErrorsListRowProps> = ({ item }) => {
  const { t } = useTranslation('fileImport');

  return (
    <Stack gap={`${GAP_HEIGHT}px`}>
      <InputLabel sx={{ height: LABEL_HEIGHT }}>
        {IsVirtualListItemGlobalParseErrors(item)
          ? t('label.globalErrors', { count: item.content.length })
          : t('label.rowWithNumber', { number: (item.content[0].error.row ?? 0) + 1, count: item.content.length })}
      </InputLabel>
      {item.content.map((parseError: ParseError, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Stack key={index} alignItems="center" direction="row" gap={0.5} height={ERROR_HEIGHT}>
          {((IsVirtualListItemRowParseErrors(item) && IsFileParseError(parseError)) || IsJqParseError(parseError)) &&
            parseError.error.metadata && (
              <Typography sx={{ background: theme => theme.palette.background.default, borderRadius: 0.25, p: 0.25 }} variant="caption">
                {parseError.error.metadata}
              </Typography>
            )}
          <TypographyEllipse lineHeight={1}>{getParseErrorLabel(parseError, t)}</TypographyEllipse>
        </Stack>
      ))}
    </Stack>
  );
};

ErrorsListRow.displayName = 'ErrorsListRow';

export default ErrorsListRow;

import {
  AnonymousIdentity,
  DEFAULT_DEVICE_LOOPTIME,
  MessageSecurityMode,
  OpcUaConnectionMode,
  OpcUaConnectionUriEndpoint,
  OpcUaConnectionUriHostAndPort,
  OpcUaDeviceConfig,
  OpcUaDeviceConnection,
  SecurityPolicy,
  UserIdentityInfoUserName,
  UserIdentityInfoX509,
  UserTokenType,
} from '@dametis/core';

import { createDeviceConfig } from './createDeviceConfig';

export const createOpcUaConnectionUriEndpoint = ({
  mode = OpcUaConnectionMode.ENDPOINT,
  endpoint = '',
}: Partial<OpcUaConnectionUriEndpoint> = {}): OpcUaConnectionUriEndpoint => ({
  mode,
  endpoint,
});

export const createOpcUaConnectionUriHostAndPort = ({
  mode = OpcUaConnectionMode.HOST_AND_PORT,
  host = '',
  port = 4841,
}: Partial<OpcUaConnectionUriHostAndPort> = {}): OpcUaConnectionUriHostAndPort => ({
  mode,
  host,
  port,
});

export const createUserIdentityInfoUserName = ({
  type = UserTokenType.UserName,
  userName = '',
  password = '',
}: Partial<UserIdentityInfoUserName> = {}): UserIdentityInfoUserName => ({
  type,
  userName,
  password,
});

export const createAnonymousIdentity = ({ type = UserTokenType.Anonymous }: Partial<AnonymousIdentity> = {}): AnonymousIdentity => ({
  type,
});

export const createUserIdentityInfoX509 = ({
  type = UserTokenType.Certificate,
  policyId = null,
  certificateData = Buffer.from(''),
  privateKey = '',
}: Partial<UserIdentityInfoX509> = {}): UserIdentityInfoX509 => ({
  type,
  policyId,
  certificateData,
  privateKey,
});

export const createOpcUaDeviceConnection = ({
  connectionUri = createOpcUaConnectionUriEndpoint(),
  securityPolicy = SecurityPolicy.None,
  securityMode = MessageSecurityMode.None,
  userIdentityInfo = createUserIdentityInfoUserName(),
}: Partial<OpcUaDeviceConnection> = {}): OpcUaDeviceConnection => ({
  connectionUri,
  securityPolicy,
  securityMode,
  userIdentityInfo,
});

export const createOpcUaDeviceConfig = ({
  connections = [createOpcUaDeviceConnection()],
  loopTime = DEFAULT_DEVICE_LOOPTIME,
}: Partial<OpcUaDeviceConfig> = {}): OpcUaDeviceConfig => ({
  connections,
  loopTime,
  // SLE: do that in all device but HTTP is null for some damco values
  ...createDeviceConfig(),
});

import { sdk } from 'sdk';
import { TypedThunk } from 'store';
import { setMaintenanceStatus } from 'store/slices/maintenance';

export const fetchMaintenanceStatus = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const groupId = state.auth.selectedGroup?.uuid;
  try {
    if (groupId !== undefined) {
      const { data } = await sdk.group.ReadMaintenance(groupId);
      dispatch(setMaintenanceStatus(data));
    }
  } catch (err) {
    console.error(err);
  }
};

import { AlarmPriority } from '@dametis/core';

export default {
  title: {
    alarm: 'Alarm "{{name}}"',
    newAlarm: 'New alarm',
    when: 'Conditions',
    do: 'Actions',
    logs: 'Logs',
    leave: 'Leave without saving',
  },
  button: {
    createAlarm: 'Create',
    addRule: 'Add',
    addAction: 'Add',
    cancel: 'Cancel',
    add: 'Add',
    validate: 'Validate',
  },
  input: {
    label: {
      name: 'Name',
      criticality: 'Criticality',
      durationValue: 'Minimum duration before activation',
      calendar: 'Activation period',
      condition: 'Activation rule',
      email: 'Email address',
      phone: 'Phone number',
      autoAck: 'AutoAck',
      tamatPoste: 'Poste Tamat',
      ackCommentRequired: 'Comment on acknowledgement',
    },
    helperText: {
      advanced: 'Compare to an expression',
      simple: 'Compare to a value',
    },
    adornment: {
      durationValue: 'during',
    },
    value: {
      joinWith_and: 'AND',
      joinWith_or: 'OR',
      required: 'Required',
      optional: 'Optional',
    },
  },
  actions: {
    app: {
      showInActiveAlarms: 'Be notified in Active alarms',
    },
    emails: {
      title: 'Mail',
      sendEmail: 'Send an email to...',
    },
    phone: {
      title: 'Phone',
      sendText: 'Send a text message to...',
    },
    whatsapp: {
      title: 'WhatsApp',
      sendMessage: 'Send a WhatsApp message to...',
    },
    tamat: {
      title: 'Tamat',
      sendAlarm: 'Send an alarm to Tamat...',
    },
  },
  criticality: {
    [`variant_${AlarmPriority.NOTIFICATION}`]: 'Notification',
    [`variant_${AlarmPriority.WARNING}`]: 'Warning',
    [`variant_${AlarmPriority.CRITICAL}`]: 'Critical',
  },
  toast: {
    createAlarmSuccess: 'Alarm created successfully.',
    createAlarmError: 'An error occured while creating this alarm.',
    saveAlarmSuccess: 'Changes saved.',
    saveAlarmError: 'An error occured while saving this alarm changes.',
    deleteAlarmSuccess: 'Alarm deleted successfully.',
    deleteAlarmError: 'An error occurred while deleting this alarm.',
  },
  tooltip: {
    saveAlarm: 'save this alarm',
    acknowledgeAlarm: 'acknowledge this alarm',
    shareAlarm: 'share this alarm',
    cancelAlarmChanges: 'cancel changes',
    deleteAlarm: 'Delete this alarm',
    muteAlarm: 'mute this alarm',
    unmuteAlarm: 'unmute this alarm',
    addCondition: 'add a condition',
    deleteCondition: 'delete a condition',
    addAction: 'add an action',
    noChangeYet: 'No change yet',
    previousChart: 'Previous chart',
    nextChart: 'Next chart',
    autoAck:
      'When AutoAck is enabled, the alarm is automatically acknowledged when the incident is resolved. You will always receive a notification when the alarm is triggered.',
  },
};

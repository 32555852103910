import { Box, Chip } from '@mui/material';
import { FC } from 'react';
import { RenderElementProps, useReadOnly, useSelected } from 'slate-react';

import { OperatorElement } from '@dametis/core';

import { setColorLightness } from 'functions/color';

interface Props extends RenderElementProps {
  element: OperatorElement;
}

const SlateOperator: FC<Props> = ({ element, attributes, children = undefined }) => {
  const readOnly = useReadOnly();
  const selected = useSelected() && !readOnly;

  return (
    <Box component="span" contentEditable={false} {...attributes}>
      <Chip
        color="secondary"
        label={element.operator}
        size="small"
        sx={{
          color: theme => theme.palette.text.primary,
          fontSize: 12,
          fontWeight: 600,
          backgroundColor: theme => setColorLightness(theme.palette.picker.cyan, selected ? 75 : 85),
        }}
      />
      {children}
    </Box>
  );
};

export default SlateOperator;

import { Grid, InputLabel, InputLabelProps } from '@mui/material';
import { GridProps } from '@mui/material/Grid';
import { FC, ReactNode } from 'react';

const gridPropsDefaultProp: GridProps = {};

export interface RightPanelSectionSubtitleProps extends InputLabelProps {
  noMargin?: boolean;
  noPadding?: boolean;
  action?: ReactNode;
  gridProps?: GridProps;
}

const RightPanelSectionSubtitle: FC<RightPanelSectionSubtitleProps> = ({
  noMargin = false,
  noPadding = false,
  action = undefined,
  children = undefined,
  gridProps: { sx: gridSx, ...gridProps } = gridPropsDefaultProp,
  sx,
  ...props
}) => (
  <Grid
    container
    alignItems="center"
    justifyContent="space-between"
    sx={{ py: 0, px: noPadding ? 0 : 2, mb: noMargin ? 0 : 1, ...gridSx }}
    {...gridProps}
  >
    <Grid item>
      <InputLabel sx={{ position: 'unset', ...sx }} {...props}>
        {children}
      </InputLabel>
    </Grid>
    <Grid item>{action}</Grid>
  </Grid>
);

export default RightPanelSectionSubtitle;

import { RelativeToNowPeriod } from 'components/UI/RelativeToNowPeriodPicker/RelativeToNowPeriodPicker';
import { CustomRangeType } from 'types/date';

const relativeToNowPeriods: Record<RelativeToNowPeriod, string> = {
  [RelativeToNowPeriod.ONE_DAY]: '1 jour',
  [RelativeToNowPeriod.THREE_DAYS]: '3 jours',
  [RelativeToNowPeriod.ONE_WEEK]: '1 semaine',
  [RelativeToNowPeriod.TWO_WEEKS]: '2 semaines',
  [RelativeToNowPeriod.ONE_MONTH]: '1 mois',
};

export default {
  title: {
    absoluteTimeRange: 'Absolu',
    relativeTimeRanges: 'Relatif',
    refreshRate: 'Taux de rafraîchissement',
    liveMode: 'live',
    history: 'Historique',
  },
  input: {
    from: {
      label: 'Début',
    },
    to: {
      label: 'Fin',
    },
  },
  button: {
    [`range_${CustomRangeType.YESTERDAY}`]: 'Hier',
    [`range_${CustomRangeType.TODAY}`]: "Aujourd'hui",
    [`range_${CustomRangeType.THIS_WEEK}`]: 'Cette semaine-ci',
    [`range_${CustomRangeType.THIS_MONTH}`]: 'Ce mois-ci',
    lastXUnitOfTime: 'Depuis {{value}} $t(global:unit.time_{{unit}}, { "count": {{value}} })',
    refreshRate: '{{count}} $t(global:unit.time_{{unit}}, { "count": {{count}} })',
    refreshRate_off: 'Désactivé',
    submit: 'Valider',
    nextQuarterPeriod: "Avancer d'un quart de période",
    previousQuarterPeriod: "Reculer d'un quart de période",
    nextPeriod: "Avancer d'une période",
    previousPeriod: "Reculer d'une période",
    clear: 'Effacer',
  },
  tabs: {
    calendar: 'Calendrier',
    batches: 'Batchs',
    label: 'Onglets',
  },
  text: {
    empty: 'Aucun élément',
    noTimeRange: "Aucune période dans l'historique",
    fixedPeriodDuration: 'Chaque période personnalisée a la même durée que la première, soit {{duration}}.',
    duration: 'Durée : ',
  },
  tooltip: {
    previousPeriod: 'Période précédente',
    nextPeriod: 'Période suivante',
  },
  periods: {
    year: 'Année',
    month: 'Mois',
  },
  relativeToNowPeriods,
  sinceRelative: 'Depuis $t(dateTimePicker:relativeToNowPeriods.{{value}})',
  now: 'Maintenant',
};

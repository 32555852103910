import { CreateDeviceBody, DeviceInfo, ShortDeviceInfo, UUID, UpdateDeviceBody } from '@dametis/core';

import { sdk } from 'sdk';
import { RootState, useSelector } from 'store';

import { api } from './index';

export const devicesApi = api.injectEndpoints({
  endpoints: build => ({
    readDevices: build.query<ShortDeviceInfo[], { siteId?: UUID } | void>({
      providesTags: ['Boxes', 'Devices'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const { siteId } = arg || { siteId: state.auth.selectedSite?.uuid };
        if (!siteId) {
          return { data: [] };
        }
        try {
          const { data } = await sdk.device.List(siteId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    createDevice: build.mutation<DeviceInfo, { boxId: UUID; body: CreateDeviceBody }>({
      invalidatesTags: ['Boxes', 'Devices'],
      queryFn: async ({ boxId, body }) => {
        try {
          const { data } = await sdk.device.Create(boxId, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateDevice: build.mutation<DeviceInfo, { uuid: UUID; body: UpdateDeviceBody }>({
      invalidatesTags: ['Boxes', 'Devices'],
      queryFn: async ({ uuid, body }) => {
        try {
          const { data } = await sdk.device.Update(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteDevice: build.mutation<void, UUID>({
      invalidatesTags: ['Boxes', 'Devices'],
      queryFn: async uuid => {
        try {
          const { data } = await sdk.device.Delete(uuid);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    restoreDevice: build.mutation<UUID, { activityId: UUID; deviceId: UUID }>({
      invalidatesTags: ['Boxes', 'Devices'],
      queryFn: async ({ activityId, deviceId }) => {
        try {
          await sdk.device.Restore(deviceId, activityId);
          return { data: deviceId };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useReadDevicesQuery,
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
  useRestoreDeviceMutation,
  endpoints: devicesEndpoints,
} = devicesApi;

export const useDevices: typeof useReadDevicesQuery = (arg, options) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return useReadDevicesQuery(arg ?? { siteId }, { skip: !siteId, ...options });
};

import { OpenInNewOutlined } from '@mui/icons-material';
import { Box, Link, Stack, TextField, Typography } from '@mui/material';
import { ChangeEventHandler, Dispatch, FC, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CSVConfig, ReferencedBy } from '@dametis/core';

import Helper from 'components/UI/Helper/Helper';
import { UserHelperName } from 'types/userHelpers';

import { SectionAccordion, SectionAccordionDetails, SectionAccordionSummary } from './SectionAccordion';

export interface JQTransformerProps {
  jqValue: string;
  setJqValue: Dispatch<SetStateAction<string>>;
  setReferencedBy: Dispatch<SetStateAction<ReferencedBy>>;
  setCSVConfig: Dispatch<SetStateAction<CSVConfig>>;
}

const JQTransformer: FC<JQTransformerProps> = ({ jqValue, setJqValue, setReferencedBy, setCSVConfig }) => {
  const { t } = useTranslation('fileImport');

  const handleJqChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setJqValue(event.target.value);
      if (event.target.value.length > 0) {
        setReferencedBy(ReferencedBy.ROWS);
        setCSVConfig(state => ({
          ...state,
          useHeader: true,
        }));
      }
    },
    [setJqValue, setReferencedBy, setCSVConfig],
  );

  return (
    <SectionAccordion defaultExpanded={false}>
      <SectionAccordionSummary>
        <Typography sx={{ marginRight: '5px' }} variant="h6">
          {t('label.jq')}
        </Typography>
      </SectionAccordionSummary>
      <SectionAccordionDetails>
        <Stack gap={1.5}>
          <Helper name={UserHelperName.FILE_IMPORT_JQ_TRANSFORMER}>
            <Box>{t('tooltip.jqDesc')}</Box>
            <Link href="https://jqlang.github.io/jq/tutorial/" rel="noopener" target="_blank">
              {t('tooltip.docLink')}
              <OpenInNewOutlined sx={{ verticalAlign: 'text-top', ml: 0.25, fontSize: 12 }} />
            </Link>
          </Helper>
          <TextField fullWidth multiline name="title.jqTransformer" value={jqValue} variant="outlined" onChange={handleJqChange} />
        </Stack>
      </SectionAccordionDetails>
    </SectionAccordion>
  );
};

export default JQTransformer;

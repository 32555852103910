import {
  BaseModelInfo,
  EquationConfig,
  EquationModelInfo,
  ExplanatoryVariable,
  ExplanatoryVariableStats,
  LinearRegressionCoeffResult,
  ModelKind,
  Period,
  ResponseVariable,
  RlmConfig,
  RlmModelInfo,
  RlmModelMetrics,
} from '@dametis/core';

export type StoreExplanatoryVariable = Omit<ExplanatoryVariable, 'stats' | 'unit'>;
export type StoreResponseVariable = Omit<ResponseVariable, 'unit'>;

export type StoreRlmConfig = RlmConfig;
export type StoreEquationConfig = EquationConfig;

export type StoreBaseModel = Omit<BaseModelInfo, 'kind' | 'period' | 'xVars' | 'metrics'> & {
  kind: ModelKind | null;
  period: Period;
  yVar: StoreResponseVariable | null;
  xVars: StoreExplanatoryVariable[];
};
export type StoreRlmModel = Omit<RlmModelInfo, 'period' | 'yVar' | 'xVars' | 'metrics' | 'config'> & {
  period: Period;
  yVar: StoreResponseVariable | null;
  xVars: StoreExplanatoryVariable[];
  config: StoreRlmConfig;
};
export type StoreEquationModel = Omit<EquationModelInfo, 'period' | 'yVar' | 'xVars' | 'metrics' | 'config'> & {
  period: Period;
  yVar: StoreResponseVariable | null;
  xVars: StoreExplanatoryVariable[];
  config: StoreEquationConfig;
};

export type StoreModel = StoreBaseModel | StoreRlmModel | StoreEquationModel;

export const IsStoreRlmModel = (model: any): model is StoreRlmModel => model?.kind === ModelKind.RLM;
export const IsStoreEquationModel = (model: any): model is StoreEquationModel => model?.kind === ModelKind.EQUATION;

export interface StoreModelXData {
  unit: string | undefined;
  points: [number, number][];
  stats: ExplanatoryVariableStats;
}

export interface LinearRegressionCoeffResultWithXVar extends LinearRegressionCoeffResult {
  xVarIndex: number;
  xVarUuid: 'none' | ExplanatoryVariable['uuid'];
}

type StoreModelDataMetrics = Omit<RlmModelMetrics, 'coefficients'> & {
  coefficients: LinearRegressionCoeffResultWithXVar[] | null; // ⬅️ Useful to check if the coefficient really belongs to the xVariable later
};

interface StoreModelYData {
  unit: string | undefined;
  points: [number, number][];
}

export interface StoreModelData {
  x: Record<StoreExplanatoryVariable['uuid'], StoreModelXData>;
  y: StoreModelYData;
  model: [number, number][] | null;
  metrics: StoreModelDataMetrics;
}

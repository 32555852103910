import { MathFunctionInputName, MathFunctionName, MathFunctionType, Operator } from '@dametis/core';

import { InfluentVariablesState } from 'components/VNC/components/Filters/InfluentVariablesFilters';

import { UserHelperName } from '../../types/userHelpers';
import { Mode } from '../en/vnc';

const name: Record<MathFunctionName, string> = {
  AC_Debit: 'Débit',
  AC_PointDeRoseeSousPression: 'PointDeRoséeSousPression',
  AC_ConsommationSpecifique: 'ConsommationSpécifique',
  EAU_Debit: 'Débit',
  EAU_MasseVolumique: 'MasseVolumique',
  FROID_TemperatureSaturation: 'TempératureSaturation',
  FROID_TemperatureHumide: 'TempératureHumide',
  FROID_PuissanceFluideThermique: 'PuissanceFluideThermique', // ce calcul devrai être masqué
  THERMIQUE_DeltaT: 'DeltaT',
  THERMIQUE_DebitToutLiquide: 'DébitToutLiquide',
  THERMIQUE_DebitToutGaz: 'DébitToutGaz',
  VAP_Debit: 'Débit',
  VAP_DebitSurchauffee: 'DébitSurchauffée',
  VAP_Enthalpie: 'Enthalpie',
  VAP_RendementCombustion: 'RendementCombustion',
  BORNESUP: 'BorneSup',
  BORNEINF: 'BorneInf',
  ONOFF: 'OnOff',
  BORNES: 'Bornes',
  INFO_Bit: 'Bit',
  IF: 'If',
  getDate: 'ExtractDate',
  time: 'Time',
  now: 'Now',
};

const description: Record<MathFunctionName, string> = {
  AC_Debit: "Calcule le débit d'air comprimé",
  AC_PointDeRoseeSousPression: 'Calcule le point de rosée sous pression',
  AC_ConsommationSpecifique: "Calcule la consommation spécifique d'air comprimé",
  EAU_Debit: "Calcule le débit d'eau",
  EAU_MasseVolumique: "Calcule la masse volumique de l'eau",
  FROID_TemperatureSaturation: "Calcule la température de saturation d'un fluide frigorigène",
  FROID_TemperatureHumide: "Calcule la température humide en fonction de la température sèche et du taux d'humidité relative",
  FROID_PuissanceFluideThermique: "Calcule la puissance thermique d'un fluide frigorigène", // ce calcul devrai être masqué
  THERMIQUE_DeltaT: 'Calcule la différence de température entre deux fluides',
  THERMIQUE_DebitToutLiquide: 'Calcule le débit de liquide pour un diaphragme',
  THERMIQUE_DebitToutGaz: 'Calcule le débit de gaz pour un diaphragme',
  VAP_Debit: 'Calcule le débit de vapeur saturée pour un diaphragme',
  VAP_DebitSurchauffee: 'Calcule le débit de vapeur surchauffée pour un diaphragme',
  VAP_Enthalpie: "Calcule l'enthalpie de la vapeur saturée",
  VAP_RendementCombustion: 'Calcul du rendement de combustion par les pertes sur les fumées',
  BORNESUP: 'Retourne la valeur limitée au seuil inférieur donné',
  BORNEINF: 'Retourne la valeur limitée au seuil supérieur donné',
  ONOFF: 'Retourne 0 si la valeur est inférieure au seuil, sinon retourne 1',
  BORNES: 'Retourne la valeur limitée entre les seuils inférieur et supérieur donnés',
  INFO_Bit: "Retourne l'état du bit spécifié dans la valeur donnée (0 ou 1)",
  IF: "En fonction de la valeur de la condition, retourne le résultat de l'argument correspondant",
  getDate: "Extrait une partie d'une date",
  time: "Retourne la date des points d'une variable (unix timestamp)",
  now: 'Retourne la date actuelle (unix timestamp)',
};

const inputsName: Record<MathFunctionInputName, string> = {
  temperature: 'température',
  pressure: 'pression',
  power: 'puissance',
  flow: 'débit',
  delta_pressure: 'pression différentielle',
  threshold_delta_pressure: 'seuil pression différentielle',
  diameter_in: "diamètre de l'orifice",
  diameter_out: 'diamètre de la tuyauterie',
  reynolds: 'nombre de Reynolds',
  altitude: 'altitude',
  atmospheric_pressure: 'pression atmosphérique',
  quantity: 'quantité',
  fluid: 'fluid',
  intake_air_temperature: "température d'air d’aspiration",
  flue_gas_temperature: 'température des fumées',
  dry_temperature: 'température sèche',
  relative_humidity: 'humidité relative',
  volume_flow_rate: 'débit volumique',
  delta_temperature: 'température différentielle',
  mass_density: 'masse volumique',
  hot_temperature: 'température chaude',
  cold_temperature: 'température froide',
  viscosity: 'viscosité cinématique',
  value: 'valeur',
  value_true: 'valeur si vrai',
  value_false: 'valeur si faux',
  threshold: 'seuil',
  threshold_min: 'seuil haut',
  threshold_max: 'seuil bas',
  condition: 'condition',
  bit: 'bit',
  heat_capacity: 'capacité thermique massique',
  fuel_type: 'type de combustible',
  oxygen_levels: "taux d'oxygène",
  datetime: 'date',
  datetimeKey: 'partie de la date',
  variable: 'variable',
};

const inputsDescription: Partial<Record<`${MathFunctionName}_${MathFunctionInputName}`, string>> = {
  AC_Debit_temperature: "Température de l'air ",
  AC_Debit_pressure: "Pression de l'air",
  AC_Debit_delta_pressure: 'Mesure de pression différentielle',
  AC_Debit_threshold_delta_pressure: 'Seuil de différence de pression',
  AC_Debit_diameter_in: "Diamètre de l'orifice du diaphragme",
  AC_Debit_diameter_out: 'Diamètre intérieur de la tuyauterie',
  AC_Debit_reynolds: '0 renvoie le débit, 1 renvoie le nombre de Reynolds',
  AC_Debit_altitude: 'Altitude en mètres, par défaut 0',
  AC_PointDeRoseeSousPression_temperature: 'Point de rosée',
  AC_PointDeRoseeSousPression_pressure: "Pression de l'air",
  AC_PointDeRoseeSousPression_atmospheric_pressure: 'Pression atmosphérique',
  AC_ConsommationSpecifique_pressure: 'Puissance de la centrale ou du compresseur',
  AC_ConsommationSpecifique_quantity: 'Débit Air comprimé',
  EAU_Debit_temperature: "Température de l'eau",
  EAU_Debit_delta_pressure: 'Mesure de pression différentielle',
  EAU_Debit_threshold_delta_pressure: 'Seuil de différence de pression',
  EAU_Debit_diameter_in: "Diamètre de l'orifice du diaphragme",
  EAU_Debit_diameter_out: 'Diamètre intérieur de la tuyauterie',
  EAU_Debit_reynolds: '0 renvoie le débit, 1 renvoie le nombre de Reynolds',
  EAU_MasseVolumique_temperature: "Température de l'eau",
  FROID_TemperatureSaturation_fluid: 'Type de fluide frigorigène (0: Ammoniac, 1: R22, 2: R12, 3: R502, 4: R134A)',
  FROID_TemperatureSaturation_pressure: 'Pression du fluide frigorigène',
  FROID_TemperatureHumide_dry_temperature: 'Température sèche',
  FROID_TemperatureHumide_relative_humidity: "Taux d'humidité relative",
  FROID_TemperatureHumide_atmospheric_pressure: 'Pression atmosphérique',
  FROID_PuissanceFluideThermique_fluid: 'Type de fluide frigorigène (0: Ammoniac, 1: R22, 2: R12, 3: R502, 4: R134A)',
  FROID_PuissanceFluideThermique_volume_flow_rate: 'Débit volumique',
  FROID_PuissanceFluideThermique_delta_temperature: 'Différence de température',
  FROID_PuissanceFluideThermique_mass_density: 'Masse volumique du fluide frigorigène',
  FROID_PuissanceFluideThermique_heat_capacity: 'Capacité thermique massique du fluide frigorigène',
  THERMIQUE_DeltaT_hot_temperature: 'Température du fluide chaud',
  THERMIQUE_DeltaT_cold_temperature: 'Température du fluide froid',
  THERMIQUE_DebitToutLiquide_temperature: 'Température du fluide',
  THERMIQUE_DebitToutLiquide_pressure: 'Pression du fluide',
  THERMIQUE_DebitToutLiquide_delta_pressure: 'Mesure de pression différentielle',
  THERMIQUE_DebitToutLiquide_threshold_delta_pressure: 'Seuil de différence de pression',
  THERMIQUE_DebitToutLiquide_diameter_in: "Diamètre de l'orifice du diaphragme",
  THERMIQUE_DebitToutLiquide_diameter_out: 'Diamètre intérieur de la tuyauterie',
  THERMIQUE_DebitToutLiquide_mass_density: 'Masse volumique du fluide',
  THERMIQUE_DebitToutLiquide_viscosity: 'Viscosité du fluide',
  THERMIQUE_DebitToutLiquide_reynolds: '0 renvoie le débit, 1 renvoie le nombre de Reynolds',
  THERMIQUE_DebitToutGaz_temperature: 'Température du fluide',
  THERMIQUE_DebitToutGaz_pressure: 'Pression du fluide',
  THERMIQUE_DebitToutGaz_delta_pressure: 'Mesure de pression différentielle',
  THERMIQUE_DebitToutGaz_threshold_delta_pressure: 'Seuil de différence de pression',
  THERMIQUE_DebitToutGaz_diameter_in: "Diamètre de l'orifice du diaphragme",
  THERMIQUE_DebitToutGaz_diameter_out: 'Diamètre intérieur de la tuyauterie',
  THERMIQUE_DebitToutGaz_mass_density: 'Mass volumique du fluide',
  THERMIQUE_DebitToutGaz_viscosity: 'Viscosité du fluide',
  THERMIQUE_DebitToutGaz_reynolds: '0 renvoie le débit, 1 renvoie le nombre de Reynolds',
  VAP_Debit_pressure: 'Pression de la vapeur',
  VAP_Debit_delta_pressure: 'Mesure de pression différentielle',
  VAP_Debit_threshold_delta_pressure: 'Seuil de différence de pression',
  VAP_Debit_diameter_in: "Diamètre de l'orifice du diaphragme",
  VAP_Debit_diameter_out: 'Diamètre intérieur de la tuyauterie',
  VAP_Debit_reynolds: '0 renvoie le débit, 1 renvoie le nombre de Reynolds',
  VAP_DebitSurchauffee_pressure: 'Pression de la vapeur',
  VAP_DebitSurchauffee_temperature: 'Température de la vapeur',
  VAP_DebitSurchauffee_delta_pressure: 'Mesure de pression différentielle',
  VAP_DebitSurchauffee_threshold_delta_pressure: 'Seuil de différence de pression',
  VAP_DebitSurchauffee_diameter_in: "Diamètre de l'orifice du diaphragme",
  VAP_DebitSurchauffee_diameter_out: 'Diamètre intérieur de la tuyauterie',
  VAP_DebitSurchauffee_reynolds: '0 renvoie le débit, 1 renvoie le nombre de Reynolds',
  VAP_Enthalpie_pressure: 'Pression de la vapeur',
  VAP_RendementCombustion_fuel_type: 'Type de combustible',
  VAP_RendementCombustion_intake_air_temperature: "Température d'air d’aspiration",
  VAP_RendementCombustion_flue_gas_temperature: 'Température des fumées',
  VAP_RendementCombustion_oxygen_levels: "Taux d'oxygène",
  BORNESUP_value: 'Valeur à limiter',
  BORNESUP_threshold: 'Seuil supérieur',
  BORNEINF_value: 'Valeur à limiter',
  BORNEINF_threshold: 'Seuil inférieur',
  ONOFF_value: 'Valeur à comparer au seuil',
  ONOFF_threshold: 'Seuil de comparaison',
  BORNES_value: 'Valeur à limiter',
  BORNES_threshold_min: 'Seuil inférieur',
  BORNES_threshold_max: 'Seuil supérieur',
  INFO_Bit_value: 'Valeur contenant les bits',
  INFO_Bit_bit: 'Position du bit à vérifier (0 à 15)',
  IF_condition: 'Condition à vérifier',
  IF_value_true: 'Valeur à retourner si la condition est vraie.',
  IF_value_false: 'Valeur à retourner si la condition est fausse',
  getDate_datetime: 'Date en millisecondes',
  getDate_datetimeKey:
    "Valeurs possibles : 'second' / 'minute' / 'hour' / 'day' / 'week' / 'weekDay' / 'daysInMonth' / 'month' / 'dayOfYear' / 'year'",
};

const operatorDescription: Record<`OP_${Operator}_${Mode}`, string> = {
  OP_MIN_GLOBAL: 'Renvoie le point ayant la valeur minimale. Donc une seule valeur.',
  OP_MIN_GROUP_BY: 'Renvoie, pour chaque sous-période, le point ayant la valeur minimale. Donc une valeur pour chaque sous-période.',
  OP_MIN_PTP: '--',
  OP_MAX_GLOBAL: 'Renvoie le point ayant la valeur maximale. Donc une seule valeur.',
  OP_MAX_GROUP_BY: 'Renvoie, pour chaque sous-période, le point ayant la valeur maximale. Donc une valeur pour chaque sous-période.',
  OP_MAX_PTP: '--',
  OP_GAP_GLOBAL: 'Renvoie la différence entre la valeur maximale et la valeur minimale. Donc une seule valeur.',
  OP_GAP_GROUP_BY:
    'Renvoie, pour chaque sous-période, la différence entre la valeur maximale et la valeur minimale. Donc une valeur pour chaque sous-période.',
  OP_GAP_PTP: '',
  OP_FIRST_GLOBAL: 'Renvoie le premier point. Donc une seule valeur.',
  OP_FIRST_GROUP_BY: 'Renvoie, pour chaque sous-période, le premier point. Donc une valeur pour chaque sous-période.',
  OP_FIRST_PTP: '--',
  OP_LAST_GLOBAL: 'Renvoie le dernier point. Donc une seule valeur.',
  OP_LAST_GROUP_BY: 'Renvoie, pour chaque sous-période, le dernier point. Donc une valeur pour chaque sous-période.',
  OP_LAST_PTP: '--',
  OP_INDEX_GLOBAL: "Renvoie le point à l'index donné. Donc une seule valeur.",
  OP_INDEX_GROUP_BY: "Renvoie, pour chaque sous-période, le point à l'index donné. Donc une valeur pour chaque sous-période.",
  OP_INDEX_PTP: '--',
  OP_HISTORY_GLOBAL: 'Renvoie tous les points.',
  OP_HISTORY_GROUP_BY: '--',
  OP_HISTORY_PTP: '--',
  OP_MEAN_GLOBAL: 'Renvoie la moyenne de la valeur des points. Donc une seule valeur.',
  OP_MEAN_GROUP_BY: 'Renvoie, pour chaque sous-période, la moyenne de la valeur des points. Donc une valeur pour chaque sous-période.',
  OP_MEAN_PTP: '--',
  OP_ARITHMETIC_MEAN_GLOBAL: 'Renvoie la moyenne arithmétique de la valeur des points. Donc une seule valeur.',
  OP_ARITHMETIC_MEAN_GROUP_BY:
    'Renvoie, pour chaque sous-période, la moyenne arithmétique de la valeur des points. Donc une valeur pour chaque sous-période.',
  OP_ARITHMETIC_MEAN_PTP: '--',
  OP_TIME_WEIGHTED_MEAN_GLOBAL: '',
  OP_TIME_WEIGHTED_MEAN_GROUP_BY: '',
  OP_TIME_WEIGHTED_MEAN_PTP: '',
  OP_DELTA_GLOBAL: 'Renvoie la différence entre la valeur du premier et du dernier. Donc une seule valeur.',
  OP_DELTA_GROUP_BY:
    'Renvoie, pour chaque sous-période, la différence entre la valeur du premier et du dernier. Donc une valeur pour chaque sous-période.',
  OP_DELTA_PTP:
    'Renvoie, pour chaque point de la variable, la différence entre le point courant et son précédent. Donc une valeur pour chaque point de la variable.',
  OP_COUNT_GLOBAL: 'Renvoie le nombre de points présents. Donc une seule valeur.',
  OP_COUNT_GROUP_BY: 'Renvoie, pour chaque sous-période, le nombre de points présents. Donc une valeur pour chaque sous-période.',
  OP_COUNT_PTP: '',
  OP_INTEGRAL_GLOBAL: "Renvoie l'intégrale (base de temps 1h). Donc une seule valeur.",
  OP_INTEGRAL_GROUP_BY: "Renvoie, pour chaque sous-période, l'intégrale (base de temps 1h). Donc une valeur pour chaque sous-période.",
  OP_INTEGRAL_PTP:
    "Renvoie, pour chaque point de la variable, l'intégrale calculée à partir du point courant et de son précédent. Donc une valeur pour chaque point de la variable.",
  OP_TRAPEZOIDAL_INTEGRAL_GLOBAL: '--',
  OP_TRAPEZOIDAL_INTEGRAL_GROUP_BY: '--',
  OP_TRAPEZOIDAL_INTEGRAL_PTP: '--',
  OP_LEFT_RECTANGLE_INTEGRAL_GLOBAL: '--',
  OP_LEFT_RECTANGLE_INTEGRAL_GROUP_BY: '--',
  OP_LEFT_RECTANGLE_INTEGRAL_PTP: '--',
  OP_DERIVATIVE_GLOBAL:
    "Renvoie la dérivée, c'est-à-dire le delta divisé par la base de temps. Donc 1h). Donc voir aussi opérateur delta). Donc une seule valeur.",
  OP_DERIVATIVE_GROUP_BY:
    "Renvoie, pour chaque sous-période, la dérivée, c'est-à-dire le delta divisé par la base de temps (1h). Donc une valeur pour chaque sous-période.",
  OP_DERIVATIVE_PTP:
    "Renvoie, pour chaque point de la variable, la dérivée de chaque point, c'est-à-dire le delta entre le point courant et le précédent divisé par la base de temps (1h). Donc une valeur pour chaque point de la variable.",
  OP_STDDEV_GLOBAL: "Renvoie l'écart type de la valeur des points. Donc une seule valeur.",
  OP_STDDEV_GROUP_BY: "Renvoie, pour chaque sous-période, l'écart type de la valeur des points. Donc une valeur pour chaque sous-période.",
  OP_STDDEV_PTP: '--',
  OP_MOVING_AVERAGE_GLOBAL: '--',
  OP_MOVING_AVERAGE_GROUP_BY:
    'Renvoie, pour chaque sous-période, la moyenne de la valeur des points sur la durée spécifiée (période la moyenne mobile). Donc une valeur pour chaque sous-période.',
  OP_MOVING_AVERAGE_PTP: '--',
  OP_SUM_GLOBAL: 'Renvoie la somme de la valeur des points. Donc une seule valeur.',
  OP_SUM_GROUP_BY: 'Renvoie, pour chaque sous-période, la somme de la valeur des points. Donc une valeur pour chaque sous-période.',
  OP_SUM_PTP: '--',
  OP_DISTINCT_GLOBAL: 'Renvoie l’ensemble des points en éliminant les valeurs doublons. Donc une valeur pour chaque point.',
  OP_DISTINCT_GROUP_BY: '--',
  OP_DISTINCT_PTP: '--',
  OP_TIME_GLOBAL: 'Renvoie la valeur de la date du début de la période (format Unix timestamp en millisecondes). Donc une seule valeur.',
  OP_TIME_GROUP_BY:
    'Renvoie la valeur de la date de chaque début de sous-période (format Unix timestamp en millisecondes). Donc une valeur pour chaque sous-période.',
  OP_TIME_PTP: 'Renvoie, pour chaque point de la variable, la valeur de sa date. Donc une valeur pour chaque point de la variable.',
  OP_GROUP_BY_DURATION_GLOBAL: 'Renvoie la durée de la période (en millisecondes). Donc une seule valeur.',
  OP_GROUP_BY_DURATION_GROUP_BY: 'Renvoie la durée de chaque sous-période (en millisecondes). Donc une valeur pour chaque sous-période.',
  OP_GROUP_BY_DURATION_PTP:
    'Renvoie, pour chaque point de la variable, la durée entre le point courant et son précédent (en millisecondes). Donc une valeur pour chaque point de la variable.',
  OP_LINEAR_CADENCE_GLOBAL:
    'Renvoie la valeur linéarisée entre le point précédant la période et le premier de la période. Donc une seule valeur.',
  OP_LINEAR_CADENCE_GROUP_BY:
    'Linéarise la courbe en créant un point interpolé pour chaque sous-période. Donc une valeur pour chaque sous-période.',
  OP_LINEAR_CADENCE_PTP: '--',
};

export default {
  alert: {
    ignoreSymbols1: 'Tapez',
    ignoreSymbols2: 'pour ignorer les symboles mathématiques.',
    disableMaths: 'Dans ce contexte, vous ne pouvez que choisir une variable.',
    tadaError: 'Une erreur est survenue.',
    tadaOk: 'Ok !',
    tadaWarningSingleValue: "Ce calcul renvoie {{counter}} résultats sur cette période, au lieu d'un dans ce contexte.",
    tadaWarningMultipleValues: "Ce calcul ne renvoie qu'un résultat sur cette période, au lieu de plusieurs dans ce contexte.",
    forbiddenOperators_one: "Vous ne pouvez pas utiliser l'opérateur {{operators, list}} dans ce contexte.",
    forbiddenOperators_other: 'Vous ne pouvez pas utiliser les opérateurs {{operators, list}} dans ce contexte.',
  },
  accordion: {
    physicalQuantities: 'Grandeurs physiques',
    utilities: 'Utilités',
    sites: 'Sites et corporate',
    kinds: 'Types',
    blockTypes: 'Types de bloc',
    standardBlocks: 'Bloc standards',
    tags: 'Tags',
    influentVariables: 'Variables influentes',
    advanced: 'Paramètres avancés',
  },
  button: {
    cancel: 'Annuler',
    ok: 'Ok',
    clearAllSelection: 'Vider',
    deleteHistoryItem: "Effacer de l'historique",
    function: 'Fonction',
    closeVariableMenu: 'Fermer',
    saveHistoryItemAsAlias: 'Créer un alias depuis ce calcul',
    clearUnit: "Retirer l'unité",
    deleteAliasItem: "Supprimer l'alias",
    createAlias: 'Créer un alias...',
    updateAliasItem: "Modifier l'alias",
    slate: 'Zone de texte',
    json: 'JSON',
  },
  list: {
    text: {
      variable: 'Variable',
      history: 'Calcul',
      model: 'Calcul',
      isCorporate: 'Corporate',
      physicalQuantitiesAll: 'Toutes les grandeurs physiques',
      kindsAll: 'Tous les types de variable',
      influentVariablesAll: 'Toutes les variables',
      byDefault: '(par défaut)',
    },
    subheader: {
      operator: 'Opérateur',
      sameEntity_one: 'Créé depuis $t(shortcut:name.{{category}}_this, { "count": 1 })',
      sameEntity_other: 'Créés depuis $t(shortcut:name.{{category}}_this, { "count": 1 })',
      sameCategory_one: 'Créé depuis $t(shortcut:name.{{category}}_a, { "count": 1 })',
      sameCategory_other: 'Créés depuis $t(shortcut:name.{{category}}_a, { "count": 1 })',
      others_one: 'Autre',
      others_other: 'Autres',
      otherModels: 'Autres variables expliquées',
    },
  },
  input: {
    label: {
      data: 'Données',
      opArgsPeriod: 'Période de la moyenne mobile',
      aliasName: 'Nom',
      aliasSlate: 'Calcul',
      aliasSource: 'Créé depuis',
      aliasOwner: 'Par',
      fill: 'Remplir les valeurs manquantes par',
      flags: 'Options',
      accumulate: 'Accumuler les valeurs',
      timestamp: 'Horodatage des valeurs',
      varCalcKind: 'Type',
      openIn: 'Ouvrir dans',
    },
    adornment: {
      nickname: 'alias',
    },
    helperText: {
      addNickname: 'Trop long ?',
      removeNickname: 'Retirer le surnom',
    },
    placeholder: {
      input: 'Variable ou calcul',
      input_disableMaths: 'Variable',
      searchTag: 'Rechercher...',
    },
    value: {
      fillPrevious: 'Valeur précédente (par défaut)',
      fillNull: 'Aucune valeur',
      fillNull2: 'Attention ! Ceci peut casser votre calcul.',
      fillLinear: 'Interpolation linéaire',
      fillNone: 'Ne pas remplir',
      fillZero: 'Zéros',
      timestampUndefined: 'Automatique (par défaut)',
      timestampUndefined2: 'Dépend du contexte',
      timestampStart: 'Début de la sous-période',
      timestampEnd: 'Fin de la sous-période',
      timestampMiddle: 'Milieu de la sous-période',
      timestampKeep: "Conserver l'horodatage du point",
      timestampEx: 'ex :',
    },
  },
  subtitle: {
    parameters: 'Paramètres',
    metrics: 'Métriques',
    folders: 'Dossiers',
    blocks: 'Blocs',
    noVariables: 'Pas de variables correspondantes à cette recherche',
    noHistory: 'Historique vide',
    resultsFor: 'Résultats pour « {{search}} »',
    allVariablesByUpdateDate: 'Toutes les variables récemment modifiées',
    allInfluentVariables: 'Variables influentes de {{name}}',
    multipleSelection: 'Sélection multiple',
    explanatoryVariables: 'Variables explicatives',
    noAliases: "Vous pouvez créer votre premier alias depuis votre calcul ou depuis l'historique",
    noAliasesForSearch: "Pas d'alias pour « {{search}} »",
  },
  text: {
    noBlockType: 'Aucun type de bloc',
    noBlock: 'Aucun bloc',
    noFolder: 'Aucun dossier',
    selectedVariables_one: '{{count}} variable sélectionnée',
    selectedVariables_other: '{{count}} variables sélectionnées',
    unNamedMetric: '(Anonyme)',
    unknownId: 'Variable inconnue',
    unknownBlock: 'Bloc inconnu',
    noParameter: 'Aucun paramètre',
    noMetric: 'Aucun métrique',
    noDisplayedUtilities: 'Aucune sélectionnée',
    noUtility: 'Aucun résultat',
    noDisplayedTagFilters: 'Aucun sélectionné',
    noTagFilter: 'Aucun résultat',
    noExplainedVariable: 'Aucune variable expliquée',
    noModel: 'Aucun modèle',
    modelVariable: 'Modèle',
    modelCalculation: 'Équation',
    yVar: 'Variable expliquée',
    noExplanatoryVariable: 'Aucune variable explicative',
    modelAbsoluteGap: 'Écart absolu',
    modelAbsoluteGapFormula: 'modèle - variable expliquée',
    modelRelativeGap: 'Écart relatif',
    modelRelativeGapFormula: '(modèle - variable expliquée) / variable expliquée',
    begin: 'Début : {{date}}',
    end: 'Fin : {{date}}',
    groupBy: 'Sous-périodes : {{value}}',
    linkedModels_one: '{{count}} simulation associée',
    linkedModels_other: '{{count}} simulations associées',
    selectAll: 'Tout sélectionner',
    availableParameter: '{{blockKey}} ({{unit}})',
    opOrGroupBy: 'Opérateur ? Sous-périodes ?',
    unitNa: 'n/a',
    noUnit: "Pas d'unité",
    home: 'Accueil',
    filteredWith: 'Filtrer avec :',
    fromSource: ', depuis',
    createdBy: ', par',
    createdIn: ' dans',
  },
  title: {
    selection: 'Sélection',
    aliases: 'Alias',
    history: 'Historique',
    calculationMenu: 'Paramètres du calcul',
    variableMenu: 'Paramètres de la variable « {{name}} »',
    variableDetails: 'Informations',
  },
  tooltip: {
    createAlias: 'créer un alias',
    addToSelection: 'Ajouter à la sélection',
    removeFromSelection: 'Retirer',
    calculationMenu: 'Paramètres du calcul',
    variableMenu: 'Paramètres de la variable',
    rightClickToOpenVariableMenu1: 'Clic droit',
    rightClickToOpenVariableMenu2: 'sur la variable pour ouvrir ses paramètres',
    calcVarHasHistoryOp: 'Votre calcul ayant un opérateur Valeur, la période a été réduite à une semaine.',
    periodIsFuture: 'La période étant partiellement dans le futur, elle a été réduite aux 7 derniers jours.',
    clear: 'Effacer',
    metricsCalcOn_one: 'Afficher les détails de la métrique',
    metricsCalcOn_other: 'Afficher les détails des métriques',
    metricsCalcOff_one: 'Masquer les détails de la métrique',
    metricsCalcOff_other: 'Masquer les détails des métriques',
    openPopper: 'Agrandir ce champ',
    closePopper: 'Refermer',
  },
  tab: {
    history: 'Historique & alias',
    variables: 'Variables',
    lego: 'Blocs',
    models: 'Modèles',
  },
  functions: {
    name,
    description,
    type: {
      number: 'nombre',
      string: 'chaîne de caractères',
      boolean: 'booléen',
    } as Record<MathFunctionType, string>,
    inputs: {
      name: inputsName,
      description: inputsDescription,
    },
  },
  operators: {
    title: '{{groupBy}} + {{operator}} =',
    context: "Dans ce contexte, l'opérateur {{ op }} ",
    operatorDescription,
  },
  helper: {
    [UserHelperName.VNC_VARIABLE_MENU_OPERATOR]: 'Les opérateurs disponibles dépendent du type de sous-périodes que vous choisissez.',
  },
  influentVariablesState: {
    [InfluentVariablesState.HIDDEN]: 'Variables cachées',
    [InfluentVariablesState.VISIBLE]: 'Variables pertinentes',
  },
  dialog: {
    title: {
      updateAlias: "Modifier l'alias",
      createAlias: 'Créer un alias',
    },
    button: {
      cancel: 'Annuler',
      updateAlias: 'Modifier',
      createAlias: 'Créer',
    },
  },
  monaco: {
    loading: 'Chargement...',
  },
};

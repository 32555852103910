import { Box, chipClasses } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import ChipEllipse, { ChipEllipseProps } from 'components/UI/ChipEllipse/ChipEllipse';
import ShortcutIcon from 'components/UI/Shortcut/ShortcutIcon';
import { EntityType } from 'types/entity';
import { entityToShortcutTypes } from 'types/shortcut';

export type EntityChipProps = Omit<ChipEllipseProps, 'label'> & { entity: { name: string }; type: EntityType } & {
  clickable?: boolean;
  label?: string;
  to?: string;
  willOpenTab?: boolean;
};

const EntityChip: FC<EntityChipProps> = ({
  clickable = false,
  entity,
  label = undefined,
  onClick,
  type,
  to = undefined,
  willOpenTab = false,
  ...props
}) => {
  const content = (
    <ChipEllipse
      icon={
        <ShortcutIcon
          withTooltip
          category={entityToShortcutTypes[type]}
          sx={{ [`&.${chipClasses.icon}`]: { ...(!clickable && { color: 'black' }) } }}
        />
      }
      label={label ?? entity.name}
      sx={{ ...(!clickable && { height: '25px' }) }}
      variant={clickable ? 'link' : undefined}
      onClick={onClick}
      {...props}
    />
  );

  return to ? (
    <Box
      component={Link}
      overflow="hidden"
      sx={{ borderRadius: theme => theme.others.chipBorderRadius, ...(!clickable && { pointerEvents: 'none' }) }}
      to={to}
      {...(willOpenTab && { target: '_blank', rel: 'noopener noreferrer' })}
    >
      {content}
    </Box>
  ) : (
    content
  );
};

export default EntityChip;

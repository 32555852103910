import { DeleteOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { MouseEventHandler } from 'react';

export interface EndpointRow {
  id: number;
  endpoint: string;
}

export const getColumnsDefObj = (
  isEditing: boolean,
  onDelete: (endpointRowId: number) => MouseEventHandler<HTMLButtonElement>,
): Record<string, GridColDef<EndpointRow>> => ({
  endpoint: {
    field: 'endpoint',
    headerName: '',
    flex: 1,
    align: 'left',
    resizable: false,
    disableColumnMenu: true,
    sortable: false,
    editable: isEditing,
    // renderCell: ({ value }) => <Typography color="primary">{value}</Typography>,
  },
  ...(isEditing && {
    edit: {
      field: 'edit',
      width: 30,
      headerName: '',
      resizable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton size="small" onClick={onDelete(row.id)}>
          <DeleteOutlined fontSize="small" />
        </IconButton>
      ),
    },
  }),
});

import { Box, chipClasses } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Shortcut } from '@dametis/core';

import ChipEllipse, { ChipEllipseProps } from 'components/UI/ChipEllipse/ChipEllipse';
import getShortcutTo from 'functions/getShortcutTo';

import ShortcutIcon from './ShortcutIcon';

export interface ShortcutUnknownChipProps extends Omit<ChipEllipseProps, 'label'> {
  shortcut: Shortcut;
  willOpenTab?: boolean;
}

const ShortcutUnknownChip: FC<ShortcutUnknownChipProps> = ({ shortcut, willOpenTab = false, ...props }) => {
  const { t } = useTranslation('shortcut');

  const to = useMemo(() => getShortcutTo(shortcut), [shortcut]);

  const content = (
    <ChipEllipse
      clickable
      icon={<ShortcutIcon withTooltip category={shortcut.category} sx={{ color: 'black' }} />}
      label={t('text.notFound')}
      sx={{
        bgcolor: 'daRed.100',
        [`.${chipClasses.icon}`]: {
          color: 'black',
        },
      }}
      {...props}
    />
  );

  return to ? (
    <Box
      component={Link}
      sx={{ borderRadius: theme => theme.others.chipBorderRadius }}
      to={to}
      {...(willOpenTab && { target: '_blank', rel: 'noopener noreferrer' })}
    >
      {content}
    </Box>
  ) : (
    content
  );
};

export default ShortcutUnknownChip;

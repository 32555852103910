import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, createSearchParams, useLocation, useSearchParams } from 'react-router-dom';

import { RouteConfig } from '../../routes';
import { useSelector } from '../../store';
import DocumentTitle from '../UI/DocumentTitle/DocumentTitle';

interface Props {
  route: RouteConfig;
}

const RouteElement: FC<Props> = ({ route }) => {
  const { t, i18n } = useTranslation('routes');
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const isAuth = useSelector(state => Boolean(state.auth.user));

  if (route.auth !== null && isAuth !== route.auth) {
    return (
      <Navigate
        to={
          isAuth
            ? '/'
            : {
                pathname: '/login',
                search: createSearchParams({
                  redirectUri: searchParams.get('redirectUri') ?? `${location.pathname}${location.search}`,
                }).toString(),
              }
        }
      />
    );
  }

  return <DocumentTitle title={`${i18n.exists(route.name) ? t(route.name) : ''} - Dametis`}>{route.component}</DocumentTitle>;
};

export default RouteElement;

import { FC } from 'react';

import { IsFileDevice, ShortDeviceInfo } from '@dametis/core';

import { RightPanelBody } from 'components/UI/RightPanel';

import FileDeviceCredentials from './FileDeviceCredentials/FileDeviceCredentials';

export interface CredentialsPanelProps {
  device: ShortDeviceInfo;
}

const CredentialsPanel: FC<CredentialsPanelProps> = ({ device }) => (
  <RightPanelBody>{IsFileDevice(device) && <FileDeviceCredentials config={device.file} />}</RightPanelBody>
);

export default CredentialsPanel;

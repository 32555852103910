import { Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FolderInfo, ShortStandardBlockInfo } from '@dametis/core';

import { DeployedCodeOutlined } from 'assets/icons/DeployedCodeOutlined';
import { getStandardBlockPath } from 'components/Lego/helpers/getStandardBlockPath';
import { getStandardBlockUrl } from 'components/Lego/helpers/getStandardBlockUrl';
import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import { useReadGlobalRootFolderQuery } from 'store/api/globalFolders';

import LegoPreviewPaper from '../../LegoPreviewPaper';

export interface StandardBlockPreviewProps {
  standardBlock: ShortStandardBlockInfo;
  redirect?: boolean;
}

const StandardBlockPreview: FC<StandardBlockPreviewProps> = ({ standardBlock, redirect = false }) => {
  const { t } = useTranslation('lego');

  const { data: globalRootFolder = null } = useReadGlobalRootFolderQuery();

  const parsedGlobalRootFolder: FolderInfo | null = useMemo(
    () => (globalRootFolder ? { ...globalRootFolder, name: t('lego:text.factory') } : null),
    [globalRootFolder, t],
  );

  const url = useMemo(() => getStandardBlockUrl(globalRootFolder, standardBlock.uuid) ?? '#', [globalRootFolder, standardBlock.uuid]);

  const foldersPath = useMemo(
    () => (parsedGlobalRootFolder ? getStandardBlockPath(parsedGlobalRootFolder, standardBlock.uuid) : []),
    [parsedGlobalRootFolder, standardBlock.uuid],
  );

  const path = useMemo(() => foldersPath.map(folder => folder.name).join(' › '), [foldersPath]);

  return (
    <>
      <LegoPreviewPaper
        redirect={redirect}
        sx={{ textDecoration: 'inherit', background: theme => theme.palette.background.default, p: 1 }}
        url={url}
      >
        <Stack alignItems="center" direction="row" gap={1}>
          <DeployedCodeOutlined sx={{ color: standardBlock.ui.color }} />
          <Stack>
            <TypographyEllipse variant="caption">{path}</TypographyEllipse>
            <Typography>{standardBlock.name}</Typography>
          </Stack>
        </Stack>
      </LegoPreviewPaper>
    </>
  );
};

export default StandardBlockPreview;

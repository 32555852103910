import { DateRangeOutlined, DeleteOutline } from '@mui/icons-material';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DateRange,
  DateRangePicker,
  DateRangeValidationError,
  LocalizationProvider,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import clsx from 'clsx';
import { endOfDay, isValid, set, startOfDay, startOfHour, startOfMonth, subDays, subHours, subMonths, subYears } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getLocale } from 'localization';
import { localizedFormat } from 'localization/localizedDateFns';
import { IsCustomRange, Range } from 'types/date';

import { addTimeRange, deleteTimeRange, getTimeRanges } from '../../../functions/timeRangeHistory';
import { TimeRange } from '../../../types/timeRange';

import useDateTimePickerStyles from './DateTimeRangePanel.styles';

interface Props {
  from: Date;
  to: Date;
  format: string;
  displayFormat: string;
  onChange: ([from, to]: Array<Date>) => void;
  setOpenPanel: (open: boolean) => void;
  setBatchDate: (date: DateRange<any>) => void;
}

const CalendarTab: FC<Props> = ({ from, to, format, displayFormat, onChange, setOpenPanel, setBatchDate }) => {
  const { t } = useTranslation('dateTimePicker');

  const [relative, setRelative] = useState<string>('');
  const [pickerOpen, setPickerOpen] = useState<boolean>(false);
  const [timeRange, setTimeRange] = useState<DateRange<any>>([null, null]);
  const [timeRangeHistory, setTimeRangeHistory] = useState<TimeRange[]>([]);

  const classes = useDateTimePickerStyles();

  const isFocused = useRef(false);

  const getRanges = useCallback((): Range[] => {
    const date = Date();
    const startSecond = set(date, { milliseconds: 0 });
    const startHour = startOfHour(date);
    const startDay = startOfDay(date);
    // const startWeek = localizedStartOfWeek(date);
    const startMonth = startOfMonth(date);
    return [
      /*
      { type: CustomRangeType.TODAY, from: startDay, to: addDays(startDay, 1) },
      { type: CustomRangeType.YESTERDAY, from: subDays(startDay, 1), to: startDay },
      { type: CustomRangeType.THIS_WEEK, from: startWeek, to: addWeeks(startWeek, 1) },
      { type: CustomRangeType.THIS_MONTH, from: startMonth, to: addMonths(startMonth, 1) },
      */
      { count: 5, unit: 'hour', from: subHours(startSecond, 5), to: startSecond },
      { count: 24, unit: 'hour', from: subHours(startSecond, 24), to: startSecond },
      { count: 2, unit: 'day', from: subDays(startHour, 2), to: startHour },
      { count: 7, unit: 'day', from: subDays(startHour, 7), to: startHour },
      { count: 1, unit: 'month', from: subMonths(startDay, 1), to: startDay },
      { count: 3, unit: 'month', from: subMonths(startDay, 3), to: startDay },
      { count: 6, unit: 'month', from: subMonths(startDay, 6), to: startDay },
      { count: 1, unit: 'year', from: subYears(startMonth, 1), to: startMonth },
      { count: 2, unit: 'year', from: subYears(startMonth, 2), to: startMonth },
      { count: 5, unit: 'year', from: subYears(startMonth, 5), to: startMonth },
    ];
  }, []);

  const ranges = useMemo(getRanges, [getRanges]);

  const changeTimeRange = useCallback(
    (newFrom: Date, newTo: Date) => {
      setTimeRangeHistory(addTimeRange({ from: newFrom, to: newTo }));
      onChange([newFrom, newTo]);
      setOpenPanel(false);
    },
    [setOpenPanel, onChange],
  );

  const handleRelative = useCallback(
    (index: number) => () => {
      const range = getRanges()[index];
      setRelative(IsCustomRange(range) ? range.type : `${range.count}${range.unit}`);
      setTimeRange([range.from, range.to]);
      onChange([range.from, range.to]);
      setOpenPanel(false);
    },
    [getRanges, setOpenPanel, onChange],
  );

  const handleTimeRange = useCallback(
    (newDates: DateRange<any>, context: PickerChangeHandlerContext<DateRangeValidationError>) => {
      setRelative('');
      if (!context.validationError[0] && !context.validationError[1]) {
        const extremes: [Date, Date] = [startOfDay(newDates[0]), endOfDay(newDates[1])];
        setBatchDate(extremes);
        setTimeRange(isFocused.current ? newDates : extremes);
      }
    },
    [setBatchDate],
  );

  const handleSubmit = useCallback(() => {
    changeTimeRange(timeRange[0], timeRange[1]);
  }, [timeRange, changeTimeRange]);

  const handleOpenPicker = useCallback(
    (state: boolean) => () => {
      setPickerOpen(state);
    },
    [],
  );

  const handleSelectTimeRange = useCallback(
    (uuid: string) => () => {
      const newTimeRange = timeRangeHistory.find(e => e.uuid === uuid);
      if (newTimeRange) {
        onChange([newTimeRange.from, newTimeRange.to]);
        setOpenPanel(false);
      }
    },
    [timeRangeHistory, onChange, setOpenPanel],
  );

  const handleDeleteTimeRange = useCallback(
    (uuid: string) => () => {
      setTimeRangeHistory(deleteTimeRange(uuid));
    },
    [],
  );

  useEffect(() => {
    setTimeRangeHistory(getTimeRanges());
  }, []);

  useEffect(() => {
    setTimeRange([from, to]);
  }, [from, to]);

  const setIsFocused = useCallback(
    (newIsFocused: boolean) => () => {
      isFocused.current = newIsFocused;
    },
    [],
  );

  return (
    <Grid container sx={{ width: '480px' }}>
      <Grid item xs className={clsx(classes.popper__element, classes.popper__element__top)}>
        <LocalizationProvider adapterLocale={getLocale()} dateAdapter={AdapterDateFns}>
          <DateRangePicker
            disableAutoMonthSwitching
            format={format}
            localeText={{
              start: t('input.from.label'),
              end: t('input.to.label'),
            }}
            minDate={new Date(+0)}
            open={pickerOpen}
            slotProps={{
              fieldRoot: {
                sx: { display: 'flex', flexDirection: 'column', gap: 2 },
              },
              fieldSeparator: { sx: { display: 'none' } },
              textField: {
                sx: { ml: '0 !important' },
                onFocus: setIsFocused(true),
                onBlur: setIsFocused(false),
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton className={classes.dateRange__iconButton} size="large" onClick={handleOpenPicker(!pickerOpen)}>
                        <DateRangeOutlined />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
            }}
            value={timeRange}
            onChange={handleTimeRange}
            onClose={handleOpenPicker(false)}
          />
          <Button
            color="secondary"
            disabled={!(timeRange[0] && isValid(timeRange[0]) && timeRange[1] && isValid(timeRange[1]))}
            size="small"
            sx={{ mt: 3 }}
            variant="contained"
            onClick={handleSubmit}
          >
            {t('button.submit')}
          </Button>
        </LocalizationProvider>
      </Grid>
      <Divider flexItem orientation="vertical" />
      <Grid item xs className={clsx(classes.popper__element, classes.popper__element__top)}>
        <List>
          {ranges.map((r, i) =>
            IsCustomRange(r) ? (
              <ListItemButton key={r.type} disableGutters selected={r.type === relative} onClick={handleRelative(i)}>
                {t('button.range', { context: r.type })}
              </ListItemButton>
            ) : (
              <ListItemButton
                key={`${r.count}${r.unit}`}
                disableGutters
                selected={`${r.count}${r.unit}` === relative}
                onClick={handleRelative(i)}
              >
                {t('button.lastXUnitOfTime', { value: r.count, unit: r.unit })}
              </ListItemButton>
            ),
          )}
        </List>
      </Grid>
      {timeRangeHistory.length > 0 && (
        <Grid item className={clsx(classes.popper__element, classes.popper__element__bottom)} xs={12}>
          <Typography className={classes.popper__element__title} variant="h6">
            {t('title.history')}
          </Typography>
          <List component="nav">
            {timeRangeHistory.slice(0, 5).map(item => (
              <ListItemButton key={item.uuid} dense disableGutters onClick={handleSelectTimeRange(item.uuid)}>
                <ListItemText primary={`${localizedFormat(item.from, displayFormat)} - ${localizedFormat(item.to, displayFormat)}`} />
                <ListItemSecondaryAction onClick={handleDeleteTimeRange(item.uuid)}>
                  <Tooltip placement="right" title={t('global:tooltip.delete')}>
                    <IconButton edge="end" size="small">
                      <DeleteOutline fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItemButton>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default CalendarTab;

import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import { FC, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { VirtualColumn } from '@dametis/core';

import VirtualColumnElementValue from './VirtualColumnElementValue';

export interface VirtualColumnTileProps {
  virtualColumn: VirtualColumn;
  onDelete: () => void;
}

const VirtualColumnTile: FC<VirtualColumnTileProps> = ({ virtualColumn, onDelete }) => {
  const { t } = useTranslation('fileImport');

  const handleDeleteElement = useCallback(() => {
    onDelete();
  }, [onDelete]);

  return (
    <Paper
      component={Stack}
      gap={0.5}
      sx={{ p: 1, '& .deleteElementButton': { opacity: 0 }, '&:hover .deleteElementButton': { opacity: 1 } }}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography>{virtualColumn.name}</Typography>
        <IconButton className="deleteElementButton" sx={{ p: 0 }} onClick={handleDeleteElement}>
          <DeleteOutlined color="icon" sx={{ fontSize: 16 }} />
        </IconButton>
      </Stack>
      {virtualColumn.elements.length > 0 && (
        <Stack alignItems="stretch" direction="row" flexWrap="wrap" gap={0.5} justifyContent="flex-start">
          {virtualColumn.elements.map((element, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              <VirtualColumnElementValue element={element} />
              {index !== virtualColumn.elements.length - 1 && (
                <Stack justifyContent="center">
                  <AddOutlined color="icon" fontSize="small" sx={{ fontSize: 13 }} />
                </Stack>
              )}
            </Fragment>
          ))}
        </Stack>
      )}
      {virtualColumn.elements.length === 0 && (
        <Typography align="center" p={1} variant="subtitle2">
          {t('text.noVirtualColumnElement')}
        </Typography>
      )}
    </Paper>
  );
};

export default VirtualColumnTile;

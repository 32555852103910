import { GridLocaleText } from '@mui/x-data-grid-premium';

import { EntityKey } from '../../components/Configuration/Variables/VariablesRightPanel/DependenciesPanel/DependenciesPanel.tsx';
import { PanelTab } from '../../types/variables.ts';

const batchUsesEntity: Record<EntityKey, string> = {
  alarms: 'Alarmes',
  batches: 'Batchs',
  blockTypes: 'Types de bloc',
  calculatedVariables: 'Variables',
  models: 'Modèles',
  playgrounds: 'Playgrounds',
  reports: 'Rapports',
  synoptics: 'Synoptiques',
  variableLists: 'Listes de variables',
  blocks: 'Blocks',
  aliases: 'Alias',
};

export default {
  button: {
    acknowledge: 'Acquitter',
    advancedSearch: 'Recherche avancée',
    addAlarm: 'Batch',
    deleteAlarm: 'Supprimer cette alarme',
    editAlarm: 'Éditer cette alarme',
    deleteAlarmConfirmation_yes: 'Oui, supprimer',
    deleteAlarmConfirmation_cancel: 'Annuler',
    muteAlarm: 'Désactiver cette alarme',
    unmuteAlarm: 'Réactiver cette alarme',
  },
  title: {
    alarms: 'Alarmes',
    // activeAlarms: 'Alarmes actives',
    // alarmsHistory: 'Historique',
    tagsDetails: 'Détails des batchs',
    batchesList: 'Liste des batchs',
  },
  batchUsesEntity,
  subtitle: {
    updatedAt: 'Modifié {{date}}',
  },
  text: {
    when: 'Quand',
    do: 'Faire',
    deleteAlarmConfirmation: 'Confirmez-vous la suppression de cette alarme ?',
    events: 'Historique des évènements',
    for: 'pendant',
    day: 'j',
    noResults: 'Aucun résultat.',
    noActiveAlarms: 'Aucune alarme active',
    noHistoryIncidents: 'Aucun incident',
    noDependency: 'Aucune dépendance',
    updatedAt: 'Modifiée {{date}}',
    noBatches: 'Aucun batch',
  },
  panelTab: {
    [PanelTab.DEPENDENCIES]: 'Dépendances',
  },
  condition: {
    'equation_>': '{{variable}} est supérieur à {{value}}',
    'equation_>=': '{{variable}} est supérieur ou égal à {{value}}',
    'equation_<': '{{variable}} est inférieur à {{value}}',
    'equation_<=': '{{variable}} est inférieur ou égal à {{value}}',
    'equation_==': '{{variable}} est égal à {{value}}',
    'equation_!=': '{{variable}} est différent de {{value}}',
    forXUnitOfTime: 'pendant {{count}} $t(global:unit.time_{{unit}}, { "count": {{count}} })',
  },
  action: {
    sendEmail: 'Envoyer un email à {{email}}',
    sendText: 'Envoyer un message au {{phone}}',
    sendEmail_notSet: '$t(action.sendNotification)',
    sendNotification: "Notifier dans l'app",
  },
  snackbar: {
    message: {
      acknowledge: {
        success: "L'alerte a bien été marquée comme vue",
      },
    },
  },
  table: {
    cells: {
      state: 'État',
      name: 'Nom',
      criticality: 'Criticité',
      extremeValue: 'Extrême',
      triggerDate: 'Déclenchée le',
      activeDuration: 'Active pendant',
      actions: 'Action',
      acknowledgementDate: 'Acquittée le',
      acknowledgementActor: 'Acquittée par',
      resolveDate: 'Résolue le',
      date: 'Date',
      event: 'Évènement',
      comment: 'Commentaire',
      res: 'Acquittée le ... par',
    },
    rows: {
      acknowledgedOn: '{{date, dd/MM/yyyy HH:mm:ss}}',
      acknowledgedBy: '{{name}}',
      event_resolved: 'Résolue',
      event_triggered: 'Déclenchée',
      event_notify: 'Notification envoyée',
      event_ack: 'Acquittée',
      event_ackBy: 'Acquittée par {{name}}',
    },
  },
  criticality: {
    text: 'Criticité',
    variant_notification: 'Notification',
    variant_warning: 'Attention',
    variant_critical: 'Critique',
  },
  filters: {
    variablesFilter: 'Variables',
    superBatchFilter: 'Super batch',
  },
  input: {
    timeRange: {
      label: 'Date',
      value: {
        lastXUnitOfTime: 'Depuis {{count}} $t(global:unit.time_{{unit}}, { "count": {{count}} })',
        value_all: 'Toutes',
      },
    },
    criticality: {
      label: 'Criticité',
      value: {
        variant: '$t(criticality.variant)',
      },
    },
    alarms: {
      label: 'Nom',
    },
    variable: {
      label: 'Variable',
      error: {
        empty: 'Requis',
      },
    },
    name: {
      label: "Nom de l'alarme",
      error: {
        empty: 'Requis',
        over60characters: 'Trop long',
      },
    },
    operand: {
      error: {
        empty: 'Requis',
      },
    },
    notificationDelay: {
      error: {
        empty: 'Requis',
        notPositive: 'Ne peut-être négatif',
      },
    },
    email: {
      placeholder: 'Envoyer un email à',
      error: {
        invalid: 'Invalide',
      },
    },
    phone: {
      placeholder: 'Envoyer un message au',
      error: {
        invalid: 'Invalide',
      },
    },
    operator: {
      label: 'Opérateur',
    },
    chip: {
      backgroundColor: 'red',
    },
  },
  xgrid: {
    noBatchesLabel: 'Aucun batch',
    footerTotalAlarms: 'Total alarmes :',
    footerPaginationAlarmsPerPage: 'Alarmes par page :',
    noIncidentsLabel: 'Aucun incident',
    footerTotalIncidents: 'Total incidents :',
    footerPaginationIncidentsPerPage: 'Incidents par page :',
  } as Partial<GridLocaleText>,
  tooltip: {
    createBatch: 'créer un batch',
  },
};

import { Box, FormLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, inputBaseClasses } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ReferencedBy } from '@dametis/core';

import ExampleTable from 'components/Data/FileImport/UI/ExampleTable';

import ReferencedByHelper from './ReferencedByHelper';

export interface ReferencedByPickerProps {
  value: ReferencedBy;
  onChange: (newValue: ReferencedBy) => void;
  disabled?: boolean;
}

const ReferencedByPicker: FC<ReferencedByPickerProps> = ({ value, onChange, disabled = false }) => {
  const { t } = useTranslation('fileImport');

  const handleChangeValue = useCallback(
    (event: SelectChangeEvent<ReferencedBy>) => {
      const newValue = event.target.value as ReferencedBy;
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <>
      <Stack sx={{ width: 1, [`& > .${inputBaseClasses.root}`]: { mt: 0.5 } }}>
        <FormLabel>{t('label.referencedBy')}</FormLabel>
        <Select<ReferencedBy>
          fullWidth
          disabled={disabled}
          MenuProps={{ sx: { maxWidth: 500 } }}
          renderValue={inputValue => (
            <Stack direction="row" justifyContent="space-between">
              <Box>{t(`referencedBy.${inputValue}`)}</Box>
              <ReferencedByHelper value={inputValue} />
            </Stack>
          )}
          value={value}
          onChange={handleChangeValue}
        >
          <MenuItem value={ReferencedBy.COLUMNS}>
            <ListItemText
              primary={t(`referencedBy.${ReferencedBy.COLUMNS}`)}
              secondary={
                <Stack gap={1}>
                  <Box>{t(`helper.referencedBy.${ReferencedBy.COLUMNS}`)}</Box>
                  <ExampleTable
                    header={['date', 'variable 1', 'variable 2']}
                    rows={[
                      ['01/01/24', '123', '456'],
                      ['02/01/24', '234', '567'],
                    ]}
                  />
                </Stack>
              }
              secondaryTypographyProps={{ whiteSpace: 'normal' }}
            />
            {/* <ReferencedByHelper value={ReferencedBy.COLUMNS} /> */}
          </MenuItem>
          <MenuItem value={ReferencedBy.ROWS}>
            <ListItemText
              primary={t(`referencedBy.${ReferencedBy.ROWS}`)}
              secondary={
                <Stack gap={1}>
                  <Box>{t(`helper.referencedBy.${ReferencedBy.ROWS}`)}</Box>
                  <ExampleTable
                    header={['date', 'référence', 'valeur']}
                    rows={[
                      ['01/01/24', 'variable 1', '123'],
                      ['01/01/24', 'variable 2', '456'],
                    ]}
                  />
                </Stack>
              }
              secondaryTypographyProps={{ whiteSpace: 'normal' }}
            />
            {/* <ReferencedByHelper value={ReferencedBy.ROWS} /> */}
          </MenuItem>
        </Select>
      </Stack>
    </>
  );
};

export default ReferencedByPicker;

import { ReportProblemOutlined, SignalCellularAltOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import clsx from 'clsx';
import { subSeconds } from 'date-fns';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BoxInfo } from '@dametis/core';

import { useDispatch, useSelector } from 'store';
import { getBoxesStatus, getCurrentBox } from 'store/actions/currentBox';
import { useBoxes } from 'store/api/boxes';

import { BoxStatusLabel } from '../../../types/box';

import BigButton from './BigButton/BigButton';
import BigButtonDescription from './BigButton/BigButtonDescription';
import BigButtonIcon from './BigButton/BigButtonIcon';
import BigButtonTitle from './BigButton/BigButtonTitle';
import useStatusMenuStyles from './StatusMenu.styles';

const boxesEmptyArray: BoxInfo[] = [];

interface Props {
  className?: string;
}

const StatusMenu: FC<Props> = ({ className = '' }) => {
  const { t } = useTranslation('header');
  const statusClasses = useStatusMenuStyles();
  const dispatch = useDispatch();

  const siteId = useSelector(state => state.auth.selectedSite?.uuid);
  const dataDelay = useSelector(state => state.auth.selectedSite?.dataDelay);

  const { data: boxes = boxesEmptyArray } = useBoxes();

  const [status, setStatus] = useState<BoxStatusLabel | null>(null);

  const boxStatus = useSelector(state => {
    const boxId = state.currentBox?.infos?.uuid;
    return boxId ? state.configuration.boxesStatus[boxId] : undefined;
  });

  const menuAnchorEl = useRef<HTMLButtonElement>(null);
  const interval = useRef<ReturnType<typeof setTimeout>>();
  const axiosSource = useRef<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    if (!boxStatus || dataDelay === undefined) return;

    const timeLimit = subSeconds(new Date(), dataDelay);
    if (new Date(boxStatus.time) > timeLimit && new Date(boxStatus.time) > timeLimit) {
      setStatus(BoxStatusLabel.NORMAL);
    } else {
      setStatus(BoxStatusLabel.CRITICAL);
    }
  }, [boxStatus, dataDelay]);

  useEffect(() => {
    if (boxes && boxes.length > 0) {
      // SLE: wtf is that ? linked to a ticket, but don't understand what is it https://dametis.atlassian.net/browse/DAMAPP-932
      // let targetBoxUuid = null;
      // if (currentBoxInfos && currentBoxInfos.siteId === boxes[0]?.siteId) {
      //   targetBoxUuid = currentBoxInfos?.uuid;
      // } else {
      //   targetBoxUuid = boxes[0]?.uuid;
      // }
      void dispatch(getCurrentBox(boxes[0]?.uuid, boxes));
      interval.current = setInterval(() => dispatch(getBoxesStatus(boxes)), 60000);
    }
    return () => clearInterval(interval.current);
  }, [boxes, dispatch]);

  useEffect(() => () => axiosSource.current.cancel(), []);

  if (!siteId) return null;
  return (
    <BigButton ref={menuAnchorEl} className={className} to="/configuration/devices">
      <BigButtonIcon
        className={clsx(
          (!status || status === BoxStatusLabel.NORMAL) && statusClasses.button__icon__normal,
          status === BoxStatusLabel.WARNING && statusClasses.button__icon__warning,
          status === BoxStatusLabel.CRITICAL && statusClasses.button__icon__critical,
        )}
      >
        {!status && <CircularProgress size={24} />}
        {status === BoxStatusLabel.NORMAL && <SignalCellularAltOutlined />}
        {(status === BoxStatusLabel.CRITICAL || status === BoxStatusLabel.WARNING) && <ReportProblemOutlined />}
      </BigButtonIcon>
      <BigButtonTitle>{t('statusMenu.status')}</BigButtonTitle>
      <BigButtonDescription>{status ? t('statusMenu.msg', { context: status }) : t('global:text.loadingValue')}</BigButtonDescription>
    </BigButton>
  );
};

export default StatusMenu;

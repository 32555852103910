import { ReportType, StyleFormat, TextAlign, WidgetType } from '@dametis/core';

import { GridView } from '../../types/grid';
import { WidgetCategories } from '../../types/reports';

export default {
  title: {
    reports: 'Informes',
    myReports: 'Mis informes',
    generatedReports: 'Informes generados automáticamente',
    sharedReports: 'Compartidos conmigo',
    privateReports: 'Privados',
    newReport: 'Nuevo informe',
    editReport: 'Editar el informe',
    duplicateReport: 'Duplicar el informe',
    report: 'Informe',
    report_withName: 'Informe "{{name}}"',
    deleteReport: '¿Vaciar el informe "{{name}}"?',
    home: 'Elija el tipo de informe:',
    comments: 'Comentarios',
    flows: 'Flujo',
    nodes: 'Nudos',
    newFlow: 'Nuevo flujo',
    styles: 'Estilos',
    createReport: 'Crear un informe',
  },
  button: {
    cancel: 'Cancelar',
    create: 'Crear',
    createReport: 'Crear un informe',
    createReportFromModel: 'Crear un informe desde una plantilla',
    edit: 'Modificar',
    rename: 'Renombrar',
    save: 'Guardar',
    saveAs: 'Duplicar',
    close: 'Cerrar',
    delete: 'Vaciar',
    duplicate: 'Duplicar',
    newReport: 'Informe',
    yes: 'Sí',
    no: 'No',
    variable: 'Variable',
    print: 'Imprimir',
    cantPrint: 'No se puede imprimir en modo de edición',
    addWidget: 'Añadir widget',
    addSankeyFlow: 'Añadir',
    createSankeyFlow: 'Crear',
    addVariable: 'Añadir',
    addPlotBand: 'Añadir',
    addBatch: 'Añadir',
    addStyledRule: 'Añadir',
    share: 'Compartir',
    add: 'Añadir',
    remove: 'Quitar',
    editTags: 'Editar tags',
    addComment: 'Añadir un comentario',
    duplicateWidget: 'Duplicar widget',
    deleteWidget: 'Vaciar widget',
    insertColumn: 'Insertar columna',
    insertRow: 'Insertar fila',
    deleteColumn: 'Vaciar columna',
    deleteRow: 'Vaciar fila',
    saveStyleConfiguration: 'Lista',
    addStyleConfiguration: 'Lista',
    select: 'Seleccionar',
  },
  subtitle: {
    playground: 'Playground',
    playgroundTab: 'Pestaña',
    noPlaygroundTabSelected: 'Ninguna pestaña seleccionada',
    shownVariables: 'Variables mostradas',
  },
  placeholder: {
    title: 'Título',
    select: 'Seleccionar',
    reportName: 'Nombre del informe',
    text: 'Texto',
    label: 'Tag (opcional)',
    value: 'Valor',
    searchWidget: 'Buscar un widget',
    selectBatch: 'Seleccionar...',
    placeholder: 'Escriba algo...',
  },
  allWidgets: {
    [WidgetCategories.CHARTS]: 'Gráficos',
    [WidgetCategories.TABLES]: 'Tablas',
    [WidgetCategories.BASICS]: 'Elementos básicos',
    [WidgetCategories.PRETTY]: 'Gráficos minimalistas',
    [WidgetType.LINE]: {
      name: 'Curva',
      description: 'Muestra la curva de una variable.',
    },
    [WidgetType.BAR]: {
      name: 'Diagrama de barras',
      description: 'Muestra un diagrama de barras.',
    },
    [WidgetType.PRETTY_BAR]: {
      name: 'Diagrama de barras',
      description: 'Muestra un diagrama de barras.',
    },
    [WidgetType.PIE]: {
      name: 'Diagrama circular',
      description: 'Muestra un diagrama circular.',
    },
    [WidgetType.PRETTY_PIE]: {
      name: 'Diagrama circular',
      description: 'Muestra un diagrama circular.',
    },
    [WidgetType.CHRONO]: {
      name: 'Cronograma',
      description: 'Muestra un cronograma.',
    },
    [WidgetType.PLAYGROUND]: {
      name: 'Playground',
      description: 'Muestra una pestaña de un playground.',
    },
    [WidgetType.AMM]: {
      name: 'Promedio min max',
      description: 'Muestra el promedio, el mínimo y el máximo de una variable.',
    },
    [WidgetType.PMY]: {
      name: 'Período MTD YTD',
      description: 'Muestra el valor del mes actual y del año actual.',
    },
    staticTable: {
      cell_average: 'Promedio',
      cell_min: 'Mínimo',
      cell_max: 'Máximo',
      cell_period: 'Período',
      cell_mtd: 'MTD',
      cell_ytd: 'YTD',
    },
    [WidgetType.TABLE]: {
      name: 'Tabla personalizada',
      description: 'Muestra una tabla personalizada.',
    },
    [WidgetType.SUBPERIODS]: {
      name: 'Tabla de subperíodos',
      description: 'Muestra una tabla de subperíodos.',
    },
    [WidgetType.TEXT]: {
      name: 'Texto',
      description: 'Muestra un texto personalizado.',
    },
    [WidgetType.VARIABLE]: {
      name: 'Variable',
      description: 'Muestra el valor de una variable.',
    },
    [WidgetType.DATE]: {
      name: 'Fecha',
      description: 'Muestra la fecha del informe.',
    },
    [WidgetType.IMAGE]: {
      name: 'Imagen',
      description: 'Muestra una imagen de su elección.',
    },
    [WidgetType.GAUGE]: {
      name: 'Gráfico de medidor',
      description: 'Muestra un gráfico de medidor.',
    },
    [WidgetType.SPLINE]: {
      name: 'Curva suavizada',
      description: 'Muestra una curva suavizada.',
    },
    count: {
      name: 'Contador',
      description: 'Muestra un contador.',
    },
    [WidgetType.SHORTCUTS]: {
      name: 'Atajos',
      description: 'Muestra una lista de atajos.',
    },
    [WidgetType.LINEAR_GAUGE]: {
      name: 'Indicador lineal',
      description: 'Muestra un indicador lineal.',
    },
    [WidgetType.PERIODIC_COMMENTS]: {
      name: 'Comentarios',
      description: 'Muestra los comentarios relacionados con el período actual.',
    },
    [WidgetType.SANKEY]: {
      name: 'Sankey',
      description: 'Muestra un gráfico sankey.',
    },
  },
  text: {
    widgets: 'Widgets',
    noReports: 'Todavía no tienes ningún informe',
    noReportInSite: 'Ningún informe accesible en este sitio',
    name: 'Nombre',
    type: 'Tipo',
    tag: 'Tags',
    shortCreatedAt: 'Creado',
    shortUpdatedAt: 'Modificado',
    updatedAt: 'Modificado {{date}}',
    sharedBy: 'Compartido por {{name}}',
    ownedBy: 'Pertenece a {{name}}',
    me: 'yo',
    emptyRow: 'Línea vacía',
    rows: 'Líneas',
    columns: 'Columnas',
    displayLabel: 'Mostrar nombre de la variable',
    headerFormat: 'Formato del encabezado',
    titleRow: 'Celdas de título en la 1ª línea',
    titleCol: 'Celdas de título en la 1ª columna',
    variable: 'Variable',
    batch: 'Batch',
    text: 'Texto',
    macrofield: 'Campo de macro',
    color: 'Color',
    picto: 'Pictograma',
    editCell: 'Modificar celda',
    errorName: 'Ningún nombre de informe',
    unit: 'Unidad',
    autoUnit: 'Unidad óptima',
    fontSize: 'Tamaño de fuente',
    small: 'Pequeño',
    medium: 'Promedio',
    large: 'Grande',
    auto: 'Automático',
    showSubPeriods: 'Mostrar datos de subperíodos',
    nothingToDisplay: 'Ninguna variable seleccionada',
    total: 'Total',
    subperiodTotal_week: 'Semana',
    subperiodTotal_month: 'Mes',
    subperiodTotal_year: 'Año',
    data: 'Datos',
    style: 'Estilo',
    plotBands: 'Zonas',
    noSelectedData: 'Ningún dato seleccionado',
    noDefinedPlotBand: 'Ninguna zona definida',
    needMinAndMax: 'Debe agregar un MIN y un MAX',
    noAutoScale: 'No se puede generar automáticamente la escala con un cálculo.',
    noStyledRules: 'Sin reglas de estilo',
    rules: 'Reglas',
    newReport: 'Nuevo informe',
    noShortcut: 'Ningún acceso directo',
    copy: 'Copia',
    search: 'Buscar...',
    users: 'Usuarios',
    list: 'Listas',
    public: 'Público',
    private: 'Privado',
    shared: 'Compartido',
    view: {
      [GridView.TILE]: 'Vista en mosaico',
      [GridView.LIST]: 'Vista de lista',
    },
    commentList: 'Lista de comentarios',
    noAssociatedProject: 'Sin proyecto asociado',
    noComment: 'Ningún comentario',
    commentsPeriod: {
      from: 'Desde el {{from}}',
      to: 'hasta el {{to}}',
    },
    commentsPagination: {
      nbComments_one: 'Un comentario',
      nbComments_other: '{{count}} comentarios',
      linkedTo_one: 'vinculado a',
      linkedTo_other: 'vinculados a',
      nbProjects_one: 'un proyecto',
      nbProjects_other: '{{count}} proyectos',
      message_withProject:
        '$t(text.commentsPagination.nbComments, { "count": {{nbComments}} }) $t(text.commentsPagination.linkedTo, { "count": {{nbComments}} }) $t(text.commentsPagination.nbProjects, { "count": {{nbProjects}} })',
      message_withoutProject: '$t(text.commentsPagination.nbComments, { "count": {{nbComments}} })',
    },
    sortBy: 'ordenar por',
    types: {
      [ReportType.WEEK]: 'Informes semanales',
      [ReportType.MONTH]: 'Informes mensuales',
      [ReportType.YEAR]: 'Informes anuales',
      [ReportType.CUSTOM]: 'Informes personalizados',
    },
    noSite: 'Ningún sitio',
    noSelectedSite: 'Ningún sitio seleccionado',
    editWidget: 'Modificar el widget',
    noImage: 'Por el momento no hay imagen',
    emptyFlows: 'Ningún flujo',
    emptyNodes: 'Ningún nudo',
    flowDescription: 'Defina aquí sus nudos y flujos',
    nodeDescription: 'El formato de un nudo se aplica a los flujos de los que es la fuente',
    noSelectedPlayground: 'Ningún playground seleccionado :',
    noVariables: 'Ninguna variable',
    newGroup: 'Nuevo grupo',
  },
  image: {
    label: 'Imagen',
    button: 'Subir una imagen',
    fitOption: 'Opción de ajuste',
    contain: 'Contener',
    cover: 'Llenar',
  },
  input: {
    label: {
      newReport: 'Nombre',
      yes: 'Sí',
      no: 'No',
      format: {
        title: 'Formato',
        [StyleFormat.BOLD]: 'Negrita',
        [StyleFormat.ITALIC]: 'Cursiva',
        [StyleFormat.UNDERLINE]: 'Subrayado',
      },
      align: {
        title: 'Alineación',
        [TextAlign.LEFT]: 'Izquierda',
        [TextAlign.CENTER]: 'Centro',
        [TextAlign.RIGHT]: 'Derecha',
      },
      fontSize: 'Tamaño',
      dataToDisplay: 'Visualización',
      reversed: 'Colores invertidos',
      isLinkToPlayground: 'Abrir un playground',
      displayDuration: 'Mostrar duración',
      displayHours: 'Mostrar hora',
      displayPeriod: 'Mostrar período actual',
      displayPagination: 'Mostrar paginación',
      displayByProjects: 'Agrupar por proyectos',
      displayOnlyOnCurrentPeriod: 'Mostrar comentarios del período actual',
      type: 'Tipo',
      row: 'Tag',
      data: 'Dato',
      batch: 'Batch',
      source: 'Origen',
      destination: 'Destino',
      unit: 'Unidad',
      valueFormat: 'Formato',
      name: 'Nombre',
      newNode: 'Nuevo nudo',
      displayLegend: 'Mostrar leyenda',
      custom: 'Personalizado',
    },
    helperText: {
      batch: 'Seleccionar un batch',
      variable: 'Seleccionar una variable',
      existingName: 'Ya utilizado',
    },
    placeholder: {
      playgroundTab: 'Seleccionar una pestaña...',
      noPlaygroundTabs: 'Ninguna pestaña disponible',
    },
  },
  tooltip: {
    createReport: 'crear un informe',
    renameReport: 'renombrar el informe',
    editReportTags: 'modificar las tags del informe',
    saveReport: 'guardar el informe',
    editReport: 'modificar el informe',
    duplicateReport: 'duplicar el informe',
    printReport: 'imprimir el informe',
    shareReport: 'compartir el informe',
    undoReportChanges: 'cancelar los cambios del informe',
    deleteReport: 'vaciar el informe',
    fontColor: 'Color del texto',
    fillColor: 'Color de fondo',
    borderColor: 'Color del borde',
    picto: 'Pictograma',
    generatedReport: 'Los informes generados automáticamente no son modificables ni eliminables',
    playgroundTabDisabled: 'No disponible en un informe',
    editComment: 'Editar comentario',
    deleteComment: 'Vaciar comentario',
    playground: 'Abrir un playground',
    openLink: 'Abrir el enlace',
    addStyledRule: 'Añadir una regla',
    saveStyleConfiguration: 'Guardar estas reglas en una lista',
    addStyleConfiguration: 'Usar una lista de reglas',
    selectPlayground: 'Seleccionar playground',
    removePlayground: 'Eliminar playground',
    editVariable: 'Modificar la variable',
    deleteVariable: 'Eliminar la variable',
  },
  form: {
    label: {
      shortcut: 'Atajo',
      confidentiality: 'Confidencialidad',
    },
    value: {
      confidentiality_private: 'Privado',
      confidentiality_shared: 'Compartido',
    },
    tooltip: {
      owner_shared: 'Si esta opción está marcada, este informe será visible para otros usuarios',
      owner_private: 'Si esta opción está marcada, este informe será visible solo para usted',
      not_owner: 'No puedes cambiar la confidencialidad de este informe porque no eres el propietario',
    },
  },
  types: {
    week: 'Informe semanal',
    month: 'Informe mensual',
    year: 'Informe anual',
    custom: 'Informe personalizado',
  },
  helperText: {
    reloadPlayground1: '¿Modificado?',
    reloadPlayground2: 'Recargar.',
  },
  toast: {
    selectedReportCorrupted: 'El informe seleccionado está dañado.',
    create: {
      success: 'El informe ha sido creado correctamente.',
      fail: 'Se produjo un error durante la creación del informe.',
    },
    save: {
      success: 'El informe ha sido guardado correctamente.',
      fail: 'Se produjo un error durante el guardado del informe.',
    },
    delete: {
      success: 'El informe ha sido eliminado correctamente.',
      fail: 'Se produjo un error durante la eliminación del informe.',
    },
    duplicate: {
      success: 'El informe ha sido duplicado correctamente.',
      fail: 'Se produjo un error durante la duplicación del informe.',
    },
    print: {
      failure: 'Se produjo un error durante la impresión del informe.',
    },
    subPeriodGroupByError: 'El widget de subperíodos está limitado a 12 columnas.',
  },
  error: {
    imageUpload_DEFAULT: 'Hubo un problema al importar su imagen. Por favor, inténtelo de nuevo.',
    imageUpload_TOO_BIG: 'El tamaño de su imagen es superior a 5 MB. Por favor, inténtelo con una imagen más pequeña.',
  },
  tab: {
    data: 'Parámetros',
    format: 'Formato',
    style: 'Estilo',
    shortcut: 'Atajo',
  },
  label: {
    title: 'Título',
    subtitle: 'Subtítulo',
  },
};

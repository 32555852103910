import { Stack, StackProps } from '@mui/material';
import { FC } from 'react';

export type RightPanelActionsProps = StackProps;

const RightPanelActions: FC<RightPanelActionsProps> = ({ ...props }) => (
  <Stack
    alignItems="center"
    columnGap={0.5}
    direction="row"
    flexWrap="wrap"
    justifyContent="flex-end"
    mb={4}
    px={2}
    py={0}
    rowGap={1}
    {...props}
  />
);

export default RightPanelActions;

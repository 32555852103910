export interface StandardRange {
  from: Date;
  to: Date;
  count: number;
  unit: string;
}

export enum CustomRangeType {
  YESTERDAY = 'YESTERDAY',
  TODAY = 'TODAY',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
}

export interface CustomRange {
  from: Date;
  to: Date;
  type: 'YESTERDAY' | 'TODAY' | 'THIS_WEEK' | 'THIS_MONTH';
}

export type Range = StandardRange | CustomRange;

export const IsCustomRange = (range: Range): range is CustomRange => 'type' in range;

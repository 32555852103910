import {
  DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  DEFAULT_DEVICE_LOOPTIME,
  DEFAULT_VARIABLE_DATA_DELAY,
  DEFAULT_VARIABLE_FREQUENCY,
  S7DeviceConfig,
} from '@dametis/core';

export const createS7DeviceConfig = ({
  host = '',
  port = 102,
  rack = 0,
  slot = 0,
  timeout = undefined,
  localTSAP = undefined,
  remoteTSAP = undefined,
  loopTime = DEFAULT_DEVICE_LOOPTIME,
  deviceHeartbeatFrequency = DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  defaultVariableFrequency = DEFAULT_VARIABLE_FREQUENCY,
  defaultVariableDataDelay = DEFAULT_VARIABLE_DATA_DELAY,
}: Partial<S7DeviceConfig> = {}): S7DeviceConfig => ({
  host,
  port,
  rack,
  slot,
  timeout,
  localTSAP,
  remoteTSAP,
  loopTime,
  deviceHeartbeatFrequency,
  defaultVariableFrequency,
  defaultVariableDataDelay,
});

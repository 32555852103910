import { TextField } from '@mui/material';
import { ChangeEventHandler, FC, useCallback } from 'react';

import { VirtualColumnText } from '@dametis/core';

export interface VirtualColumnTextTileProps {
  element: VirtualColumnText;
  onChange: (newElement: VirtualColumnText) => void;
}

const VirtualColumnTextTile: FC<VirtualColumnTextTileProps> = ({ element, onChange }) => {
  const handleChangeContentIndex: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const newElement = { ...element, content: { value: event.target.value } };
      onChange(newElement);
    },
    [element, onChange],
  );

  return (
    <>
      <TextField size="small" value={element.content.value} onChange={handleChangeContentIndex} />
    </>
  );
};

export default VirtualColumnTextTile;

import { EntityType } from 'types/entity';

export default {
  title: {
    entitySelector: 'Selección',
    [`tab_${EntityType.ALARM}`]: 'Alarmas',
    [`tab_${EntityType.PLAYGROUND}`]: 'Playgrounds',
    [`tab_${EntityType.PROJECT}`]: 'Proyectos',
    [`tab_${EntityType.REPORT}`]: 'Informes',
    [`tab_${EntityType.SYNOPTIC}`]: 'Sinópticos',
  },
  button: {
    reset: 'Reiniciar',
    cancel: 'Cancelar',
    select: 'Seleccionar',
  },
  text: {
    noSelection: 'Sin selección',
    selected: 'Seleccionado : {{context}}',
    selectedPrivate: 'La entidad que has seleccionado es privada. Hazlo pública para que pueda ser vista por todos tus colaboradores.',
    [`no_${EntityType.ALARM}`]: 'Ningún alarms',
    [`no_${EntityType.PLAYGROUND}`]: 'Ningún playgrounds',
    [`no_${EntityType.PROJECT}`]: 'Ningún projects',
    [`no_${EntityType.REPORT}`]: 'Ningún reports',
    [`no_${EntityType.SYNOPTIC}`]: 'Ningún synoptics',
  },
};

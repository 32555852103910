import { BuildRounded, BusinessRounded, RouterOutlined } from '@mui/icons-material';
import { Box, Paper, PaperProps, Stack, styled } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BrandDeviceInfo } from '@dametis/core';

import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import getProtocols from 'config/protocols';

export type BrandDevicePaperProps = PaperProps & { selected?: boolean };

export const BrandDevicePaper = styled(Paper, {
  shouldForwardProp: propName => propName !== 'selected',
})<BrandDevicePaperProps>(({ theme, selected }) => ({
  textAlign: 'left',
  padding: theme.spacing(1),
  width: '100%',
  boxShadow: `0 0 0 0px ${theme.palette.secondary.light}`,
  transition: theme.transitions.create(['box-shadow']),
  ...(selected && {
    boxShadow: `0 0 0 3px ${theme.palette.secondary.light}`,
  }),
}));

export interface BrandDevicePreviewProps extends BrandDevicePaperProps {
  brandDevice: BrandDeviceInfo;
}

const BrandDevicePreview: FC<BrandDevicePreviewProps> = ({ brandDevice, ...props }) => {
  const { t } = useTranslation('devices');

  const protocols = useMemo(() => getProtocols(t), [t]);

  return (
    <>
      <BrandDevicePaper {...props}>
        <Stack direction="row" gap={1} width={1}>
          <Box
            alt={brandDevice.name}
            component="img"
            src={brandDevice.previewPath || protocols[brandDevice.protocol].preview}
            sx={{
              height: 70,
              aspectRatio: '1/1',
              borderRadius: theme => `${theme.shape.borderRadius}px`,
              objectFit: 'contain',
            }}
          />
          <Stack justifyContent="space-between" sx={{ overflow: 'hidden' }}>
            <TypographyEllipse variant="h6">{brandDevice.name}</TypographyEllipse>
            <Box>
              <Stack alignItems="center" direction="row" gap={0.5}>
                <BuildRounded color="icon" sx={{ fontSize: 14 }} />
                <TypographyEllipse fontStyle={brandDevice.type?.trim().length > 0 ? undefined : 'italic'} variant="caption">
                  {brandDevice.type?.trim().length > 0 ? brandDevice.type : t('text.unknownType')}
                </TypographyEllipse>
              </Stack>
              <Stack alignItems="center" direction="row" gap={0.5}>
                <BusinessRounded color="icon" sx={{ fontSize: 14 }} />
                <TypographyEllipse fontStyle={brandDevice.brand?.trim().length > 0 ? undefined : 'italic'} variant="caption">
                  {brandDevice.brand?.trim().length > 0 ? brandDevice.brand : t('text.unknownBrand')}
                </TypographyEllipse>
              </Stack>
              <Stack alignItems="center" direction="row" gap={0.5}>
                <RouterOutlined color="icon" sx={{ fontSize: 14 }} />
                <TypographyEllipse variant="caption">
                  {t('text.brandDeviceVariablesLength', { count: brandDevice.variables.length })}
                </TypographyEllipse>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </BrandDevicePaper>
    </>
  );
};

export default BrandDevicePreview;

import { Grid2, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack, Typography } from '@mui/material';
import { FC } from 'react';

export interface ListItemSkeletonProps {
  isLast?: boolean;
}

const ListItemSkeleton: FC<ListItemSkeletonProps> = ({ isLast = false }) => (
  <ListItem divider={!isLast} sx={[{ px: 0, py: 0.5 }]}>
    <ListItemAvatar>
      <Stack alignItems="center" spacing={0.5} sx={{ width: 40 }}>
        <Skeleton height={40} variant="circular" width={40} />
      </Stack>
    </ListItemAvatar>
    <ListItemText
      primary={
        <Grid2 container alignItems="center" spacing={0.5}>
          <Grid2 size={6}>
            <Typography variant="h6">
              <Skeleton width="60%" />
            </Typography>
            <Typography variant="body2">
              <Skeleton width="40%" />
            </Typography>
          </Grid2>
          <Grid2 size={2}>
            <Skeleton variant="rounded" width="80%" />
          </Grid2>
          <Grid2 size={3}>
            <Skeleton variant="rounded" width="60%" />
          </Grid2>
          <Grid2 size={1}>
            <Skeleton />
          </Grid2>
        </Grid2>
      }
      primaryTypographyProps={{ component: 'div' }}
      secondary={
        <Grid2 container spacing={0.5}>
          <Grid2 sx={{ minWidth: '40%', maxWidth: '100%' }}>
            <Skeleton />
          </Grid2>
        </Grid2>
      }
      secondaryTypographyProps={{ component: 'div' }}
    />
  </ListItem>
);

export default ListItemSkeleton;

import { AddOutlined } from '@mui/icons-material';
import { AccordionProps, Stack, Typography, accordionClasses } from '@mui/material';
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SourceColumnRealByHeader, SourceColumnRealByIndex, VirtualColumn } from '@dametis/core';

import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import Helper from 'components/UI/Helper/Helper';
import { UserHelperName } from 'types/userHelpers';

import CreateVirtualColumnModal from '../../UI/CreateVirtualColumnModal/CreateVirtualColumnModal';
import VirtualColumnTile from '../../UI/VirtualColumnTile/VirtualColumnTile';

import { SectionAccordion, SectionAccordionDetails, SectionAccordionSummary } from './SectionAccordion';

export interface VirtualColumnsSettingsProps {
  virtualColumns: VirtualColumn[];
  setVirtualColumns: Dispatch<SetStateAction<VirtualColumn[]>>;
  availableRealByIndexColumns: SourceColumnRealByIndex[];
  availableRealByHeaderColumns: SourceColumnRealByHeader[];
  availableVirtualColumnNames: string[];
  disabled?: boolean;
}

const VirtualColumnsSettings: FC<VirtualColumnsSettingsProps> = ({
  virtualColumns,
  setVirtualColumns,
  availableRealByIndexColumns,
  availableRealByHeaderColumns,
  availableVirtualColumnNames,
  disabled = false,
}) => {
  const { t } = useTranslation('fileImport');

  const [isCreateVirtualColumnModalOpen, setIsCreateVirtualColumnModalOpen] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const parsedIsExpanded = useMemo(() => !disabled && isExpanded, [disabled, isExpanded]);

  const handleChangeIsExpanded = useCallback<NonNullable<AccordionProps['onChange']>>(
    (_event, expanded) => {
      setIsExpanded(expanded);
    },
    [setIsExpanded],
  );

  const handleOpenCreateVirtualColumnModal = useCallback(() => {
    setIsCreateVirtualColumnModalOpen(true);
  }, []);

  const handleCreateVirtualColumn = useCallback(
    (newVirtualColumn: VirtualColumn) => {
      setVirtualColumns(state => [...state, newVirtualColumn]);
    },
    [setVirtualColumns],
  );

  const handleDeleteVirtualColumn = useCallback(
    (deletedElementIndex: number) => () => {
      setVirtualColumns(state => state.filter((_virtualColumn, index) => index !== deletedElementIndex));
    },
    [setVirtualColumns],
  );

  return (
    <>
      <SectionAccordion
        disabled={disabled}
        expanded={parsedIsExpanded}
        sx={{ [`&.${accordionClasses.disabled}`]: { background: 'unset' } }}
        onChange={handleChangeIsExpanded}
      >
        <SectionAccordionSummary>
          <Typography variant="h6">{t('title.virtualColumns')}</Typography>
        </SectionAccordionSummary>
        <SectionAccordionDetails>
          <Stack gap={1}>
            <Stack alignItems="flex-end">
              <ActionButton disabled={disabled} startIcon={<AddOutlined />} onClick={handleOpenCreateVirtualColumnModal}>
                {t('button.add')}
              </ActionButton>
            </Stack>
            <Helper name={UserHelperName.FILE_IMPORT_VIRTUAL_COLUMNS}>{t('helper.virtualColumns')}</Helper>
            {virtualColumns.length > 0 &&
              virtualColumns.map((virtualColumn, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <VirtualColumnTile key={index} virtualColumn={virtualColumn} onDelete={handleDeleteVirtualColumn(index)} />
              ))}
            {virtualColumns.length === 0 && (
              <Typography align="center" p={1} variant="subtitle2">
                {t('text.noVirtualColumn')}
              </Typography>
            )}
          </Stack>
        </SectionAccordionDetails>
      </SectionAccordion>
      <CreateVirtualColumnModal
        availableRealByHeaderColumns={availableRealByHeaderColumns}
        availableRealByIndexColumns={availableRealByIndexColumns}
        availableVirtualColumnNames={availableVirtualColumnNames}
        isOpen={isCreateVirtualColumnModalOpen}
        setIsOpen={setIsCreateVirtualColumnModalOpen}
        onCreate={handleCreateVirtualColumn}
      />
    </>
  );
};

export default VirtualColumnsSettings;

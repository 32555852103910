import { Alert, Grid, Typography } from '@mui/material';
import { ChangeEventHandler, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeObserver } from 'usehooks-ts';

import { S7DeviceConfig } from '@dametis/core';

import ExampleTable from 'components/Data/FileImport/UI/ExampleTable';
import AdvancedTextFieldWithMask from 'components/UI/AdvancedTextFieldWithMask/AdvancedTextFieldWithMask';
import MaskFormat from 'components/UI/InputMask/MaskFormat';
import AdvancedTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';

import FormPaper, { FormLocation } from '../../FormPaper';

import { getRackInfos } from './getRackInfos';
import { getSlotInfos } from './getSlotInfos';

export interface S7SettingsFormProps {
  s7: S7DeviceConfig;
  setS7: Dispatch<SetStateAction<S7DeviceConfig>>;
  isEditing?: boolean;
  location?: `${FormLocation}`;
}

const S7SettingsForm: FC<S7SettingsFormProps> = ({ s7, setS7, isEditing = true, location = 'modal' }) => {
  const { t } = useTranslation('devices');

  const [portInputValue, setPortInputValue] = useState<string>('');
  const [loopTimeInputValue, setLoopTimeInputValue] = useState<string>('');
  const [rackInputValue, setRackInputValue] = useState<string>('');
  const [slotInputValue, setSlotInputValue] = useState<string>('');
  const [timeoutInputValue, setTimeoutInputValue] = useState<string>('');
  const [localTSAPInputValue, setLocalTSAPInputValue] = useState<string>('');
  const [remoteTSAPInputValue, setRemoteTSAPInputValue] = useState<string>('');

  const slotInfos = useMemo(() => getSlotInfos(t), [t]);
  const rackInfos = useMemo(() => getRackInfos(), []);

  const paperRef = useRef<HTMLDivElement | null>(null);

  const { width: paperWidth = 0 } = useResizeObserver({
    ref: paperRef,
  });

  const handleChangeHost: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setS7) {
        return;
      }
      setS7(state => ({ ...state, host: event.target.value }));
    },
    [setS7],
  );

  const handleChangePort: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setS7) {
        return;
      }
      setPortInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setS7(state => ({ ...state, port: parsedValue }));
      }
    },
    [setS7],
  );

  const handleChangeLoopTime: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setS7) {
        return;
      }
      setLoopTimeInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setS7(state => ({ ...state, loopTime: parsedValue }));
      }
    },
    [setS7],
  );

  const handleChangeRack: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setS7) {
        return;
      }
      setRackInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setS7(state => ({ ...state, rack: parsedValue }));
      }
    },
    [setS7],
  );

  const handleChangeSlot: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setS7) {
        return;
      }
      setSlotInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setS7(state => ({ ...state, slot: parsedValue }));
      }
    },
    [setS7],
  );

  const handleChangeTimeout: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setS7) {
        return;
      }
      setTimeoutInputValue(event.target.value);
      if (event.target.value.trim().length === 0) {
        setS7(state => ({ ...state, timeout: undefined }));
      }
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setS7(state => ({ ...state, timeout: parsedValue }));
      }
    },
    [setS7],
  );

  const handleChangeLocalTSAP: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setS7) {
        return;
      }
      setLocalTSAPInputValue(event.target.value);
      if (event.target.value.trim().length === 0) {
        setS7(state => ({ ...state, localTSAP: undefined }));
      }
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setS7(state => ({ ...state, localTSAP: parsedValue }));
      }
    },
    [setS7],
  );

  const handleChangeRemoteTSAP: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setS7) {
        return;
      }
      setRemoteTSAPInputValue(event.target.value);
      if (event.target.value.trim().length === 0) {
        setS7(state => ({ ...state, remoteTSAP: undefined }));
      }
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setS7(state => ({ ...state, remoteTSAP: parsedValue }));
      }
    },
    [setS7],
  );

  useEffect(() => {
    setPortInputValue(`${s7.port ?? ''}`);
  }, [s7.port]);

  useEffect(() => {
    setLoopTimeInputValue(`${s7.loopTime ?? ''}`);
  }, [s7.loopTime]);

  useEffect(() => {
    setRackInputValue(`${s7.rack ?? ''}`);
  }, [s7.rack]);

  useEffect(() => {
    setSlotInputValue(`${s7.slot ?? ''}`);
  }, [s7.slot]);

  useEffect(() => {
    setTimeoutInputValue(`${s7.timeout ?? ''}`);
  }, [s7.timeout]);

  useEffect(() => {
    setLocalTSAPInputValue(`${s7.localTSAP ?? ''}`);
  }, [s7.localTSAP]);

  useEffect(() => {
    setRemoteTSAPInputValue(`${s7.remoteTSAP ?? ''}`);
  }, [s7.remoteTSAP]);

  return (
    <FormPaper ref={paperRef} location={location} sx={{ p: 1.5 }}>
      <Typography mb={1.5} variant="h6">
        {t('title.acquisition')}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={paperWidth > 400 ? 6 : 8}>
          <AdvancedTextFieldWithMask
            fullWidth
            required
            editing={isEditing}
            label={t('label.ip')}
            maskedInputProps={MaskFormat.ip}
            value={s7.host ?? ''}
            onChange={handleChangeHost}
          />
        </Grid>
        <Grid item xs={paperWidth > 400 ? 3 : 4}>
          <AdvancedTextField
            fullWidth
            required
            editing={isEditing}
            InputProps={{ inputProps: { min: 0 } }} // SLE: check that
            label={t('label.port')}
            type="number"
            value={portInputValue}
            onChange={handleChangePort}
          />
        </Grid>
        <Grid item xs={paperWidth > 400 ? 3 : 12}>
          <AdvancedTextField
            fullWidth
            required
            description={t('description.loopTime')}
            editing={isEditing}
            InputProps={{ inputProps: { step: 100, min: 1000 } }} // SLE: check that // SLE: this step does not count in validator
            label={t('label.loopTime')}
            type="number"
            value={loopTimeInputValue}
            onChange={handleChangeLoopTime}
          />
        </Grid>
        <Grid item xs={6}>
          <AdvancedTextField
            fullWidth
            required
            description={
              <Alert elevation={8} icon={false} severity="info">
                <Typography align="center" mb={0.5} sx={{ color: theme => theme.palette.primary.dark }} variant="h6">
                  {t('label.indicativeValues')}
                </Typography>
                <ExampleTable header={[]} rows={rackInfos.map(rackInfo => [rackInfo.key, rackInfo.value])} />
              </Alert>
            }
            editing={isEditing}
            label={t('label.rack')}
            labelWithDescriptionProps={{
              tooltipProps: { componentsProps: { tooltip: { sx: { backgroundColor: 'transparent', p: 0 } } } },
            }}
            type="number"
            value={rackInputValue}
            onChange={handleChangeRack}
          />
        </Grid>
        <Grid item xs={6}>
          <AdvancedTextField
            fullWidth
            required
            description={
              <Alert elevation={8} icon={false} severity="info">
                <Typography align="center" mb={0.5} sx={{ color: theme => theme.palette.primary.dark }} variant="h6">
                  {t('label.indicativeValues')}
                </Typography>
                <ExampleTable header={[]} rows={slotInfos.map(slotInfo => [slotInfo.key, slotInfo.value])} />
              </Alert>
            }
            editing={isEditing}
            label={t('label.slot')}
            labelWithDescriptionProps={{
              tooltipProps: { componentsProps: { tooltip: { sx: { backgroundColor: 'transparent', p: 0 } } } },
            }}
            type="number"
            value={slotInputValue}
            onChange={handleChangeSlot}
          />
        </Grid>
        <Grid item xs={paperWidth > 400 ? 4 : 6}>
          <AdvancedTextField
            fullWidth
            editing={isEditing}
            label={t('label.timeout')}
            placeholder={t('placeholder.defaultValue')}
            type="number"
            value={timeoutInputValue}
            onChange={handleChangeTimeout}
          />
        </Grid>
        <Grid item xs={paperWidth > 400 ? 4 : 6}>
          <AdvancedTextField
            fullWidth
            editing={isEditing}
            label={t('label.localTSAP')}
            placeholder={t('placeholder.defaultValue')}
            type="number"
            value={localTSAPInputValue}
            onChange={handleChangeLocalTSAP}
          />
        </Grid>
        <Grid item xs={paperWidth > 400 ? 4 : 6}>
          <AdvancedTextField
            fullWidth
            editing={isEditing}
            label={t('label.remoteTSAP')}
            placeholder={t('placeholder.defaultValue')}
            type="number"
            value={remoteTSAPInputValue}
            onChange={handleChangeRemoteTSAP}
          />
        </Grid>
      </Grid>
    </FormPaper>
  );
};

export default S7SettingsForm;

import { Chip, Skeleton, chipClasses } from '@mui/material';
import { FC, useMemo } from 'react';

import { UUID } from '@dametis/core';

import { ChipEllipseProps } from 'components/UI/ChipEllipse/ChipEllipse';
import EntityChip from 'components/UI/EntityTile/EntityChip';
import getShortcutTo from 'functions/getShortcutTo';
import useEntity from 'hooks/useEntity';
import { SelectableEntityShortcut, shortcutToEntityTypes } from 'types/shortcut';

import ShortcutIcon from './ShortcutIcon';
import ShortcutUnknownChip from './ShortcutUnknownChip';

export type ShortcutEntityChipProps = Omit<ChipEllipseProps, 'label'> & {
  clickable?: boolean;
  groupId?: UUID;
  shortcut: SelectableEntityShortcut;
  siteId?: UUID;
  willOpenTab?: boolean;
};

const ShortcutEntityChip: FC<ShortcutEntityChipProps> = ({
  clickable = true,
  groupId = undefined,
  shortcut,
  siteId = undefined,
  willOpenTab = false,
  ...props
}) => {
  const to = getShortcutTo(shortcut);

  const category = useMemo(() => shortcutToEntityTypes[shortcut.category], [shortcut.category]);

  const [entity, isLoading] = useEntity({ entityId: shortcut.uuid, groupId, siteId, type: category });

  if (isLoading) {
    return (
      <Chip
        clickable={clickable}
        icon={<ShortcutIcon withTooltip category={shortcut.category} />}
        label={<Skeleton height={32} variant="text" width={100} />}
        sx={{
          [`.${chipClasses.label}`]: {
            display: 'flex',
          },
        }}
        variant="link"
        {...props}
      />
    );
  }

  if (!entity || !to) {
    return <ShortcutUnknownChip shortcut={shortcut} {...props} />;
  }

  return (
    <EntityChip clickable={clickable} entity={entity} label={shortcut.label} to={to} type={category} willOpenTab={willOpenTab} {...props} />
  );
};
export default ShortcutEntityChip;

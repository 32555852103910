import { SerializedElementNode } from 'lexical';

import { NotifyType, SendNotificationBody, SerializedEditorState, TinyUserInfo, UUID } from '@dametis/core';

import { isSerializedMentionNode } from '../MentionNode';
import {
  AtSignMentionsRegex,
  AtSignMentionsRegexAliasRegex,
  CapitalizedNameMentionsRegex,
  QueryMatch,
} from '../config/MentionPluginConfig';

export const checkForCapitalizedNameMentions = (text: string, minMatchLength: number): QueryMatch | null => {
  const match = CapitalizedNameMentionsRegex.exec(text);
  if (match !== null) {
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[2];
    if (matchingString != null && matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: matchingString,
      };
    }
  }
  return null;
};

export const checkForAtSignMentions = (text: string, minMatchLength: number): QueryMatch | null => {
  let match = AtSignMentionsRegex.exec(text);
  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  } else {
    const maybeLeadingWhitespace = match[1];
    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
};

export const getPossibleQueryMatch = (text: string): QueryMatch | null => {
  const match = checkForAtSignMentions(text, 0);
  return match === null ? checkForCapitalizedNameMentions(text, 3) : match;
};

export const generateNotifications = (message: SerializedEditorState<SerializedElementNode>): SendNotificationBody | undefined => {
  const mentionedUsers = findMentionedUsers(message.root);
  const userIds = Object.keys(mentionedUsers);
  return userIds.length > 0 ? { userIds, emails: [], comment: '', type: NotifyType.MENTION } : undefined;
};

// SLE: better typing when we'll understand how lexical works
export const findMentionedUsers = (node: SerializedElementNode, usersById: Record<UUID, TinyUserInfo> = {}): Record<UUID, TinyUserInfo> => {
  node.children?.forEach(child => {
    if (isSerializedMentionNode(child)) {
      if (!usersById[child.user.uuid]) {
        usersById[child.user.uuid] = child.user;
      }
    } else if (Array.isArray((child as any).children)) {
      findMentionedUsers(child as SerializedElementNode, usersById);
    }
  });
  return usersById;
};

import { InfoOutlined, OpenInNewOutlined } from '@mui/icons-material';
import { Link, Tooltip, TooltipProps, styled } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const InfoIcon = styled(InfoOutlined)({
  fontSize: '1.15em',
  verticalAlign: 'text-top',
  color: 'inherit',
});

const OpenInNewIcon = styled(OpenInNewOutlined)({
  fontSize: '1.15em',
  verticalAlign: 'text-top',
});

interface Props {
  title: TooltipProps['title'];
  learnMoreLink?: string;
}

const Info: FC<Props> = ({ title, learnMoreLink = undefined }) => {
  const { t } = useTranslation('global');

  return (
    <Tooltip
      disableInteractive={false}
      title={
        <>
          {title}{' '}
          {Boolean(learnMoreLink?.length) && (
            <Link href={learnMoreLink} target="_blank">
              {t('tooltip.learnMore')} <OpenInNewIcon />
            </Link>
          )}
        </>
      }
    >
      <InfoIcon />
    </Tooltip>
  );
};

export default Info;

import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ParseError } from '@dametis/core';

import { getParseErrorLabel } from 'components/Data/FileImport/helpers/getParseErrorLabel';

// import { ImportGridData } from './FilePreviewGridColDef';

export interface RowErrorsDetailPanelProps {
  // row: ImportGridData;
  errors: ParseError[];
}

const RowErrorsDetailPanel: FC<RowErrorsDetailPanelProps> = ({ errors }) => {
  const { t } = useTranslation('fileImport');

  return (
    <Box sx={{ p: 2, background: theme => theme.palette.background.default }}>
      {errors.map((error, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Typography key={index}>{getParseErrorLabel(error, t)}</Typography>
      ))}
    </Box>
  );
};

export default RowErrorsDetailPanel;

import i18next from 'i18next';

import {
  AssetInfo,
  CreateImageBody,
  CreateProjectBody,
  CreateTaskBody,
  ProjectInfo,
  ProjectReferencePeriod,
  ProjectSaving,
  ProjectStatus,
  TaskInfo,
  UUID,
  UpdateImageBody,
  UpdateProjectBody,
} from '@dametis/core';

import { ProjectTasksTemplate } from 'config/projectTasksTemplates';
import downloadFile from 'functions/downloadFile';
import { sdk } from 'sdk';
import { projectEndpoints } from 'store/api/projects';
import {
  getDataByYear,
  getSavingPercentage,
  getStatsByEnergies,
  getStatsByEnergy,
  getTotal,
  getTotalSavingPercentage,
} from 'store/helpers/projectStats';
import switchGroupSite from 'store/helpers/switchGroupSite';

import { ToastSeverity } from '../../types/toast';
import { TypedThunk } from '../index';
import {
  setProject,
  setProjectAssets,
  setProjectFetching,
  setProjectForecastedSavings,
  setProjectImages,
  setProjectReferencePeriod,
  setProjectSavings,
  setProjectStats,
  setProjectTasks,
  setTaskLoading,
} from '../slices/project';
import { addToast } from '../slices/toast';

import { displaySdkErrorToast } from './toasts';

export const buildStatusDates = (statusDates: Record<ProjectStatus, Date | null>): Record<ProjectStatus, Date | null> =>
  Object.fromEntries(Object.entries(statusDates).map(([key, value]) => [key, value === null ? null : new Date(value)])) as Record<
    ProjectStatus,
    Date | null
  >;

export const buildReferencePeriod = (referencePeriod: ProjectReferencePeriod): ProjectReferencePeriod => ({
  ...referencePeriod,
  from: new Date(referencePeriod.from),
  to: new Date(referencePeriod.to),
});

const buildProjectInfo = (project: ProjectInfo): ProjectInfo => ({
  ...project,
  // forecastedSavings: project?.savings ?? [],
  statusDates: buildStatusDates(project.statusDates),
  referencePeriod: buildReferencePeriod(project.referencePeriod),
});

export const getProject =
  (projectId: string): TypedThunk<Promise<void>> =>
  async dispatch => {
    dispatch(setProjectFetching(true));
    try {
      const { data } = await sdk.project.Read(projectId);
      await dispatch(switchGroupSite(data));
      dispatch(setProject(buildProjectInfo(data)));
      dispatch(updateProjectStats());
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
      dispatch(setProject(null));
    } finally {
      dispatch(setProjectFetching(false));
    }
  };

export const createProject =
  (project: CreateProjectBody, template?: ProjectTasksTemplate): TypedThunk<Promise<ProjectInfo | null>> =>
  async dispatch => {
    const body = template ? { ...template.project, ...project } : project;
    dispatch(setProjectFetching(true));
    try {
      const data = await dispatch(projectEndpoints.createProject.initiate(body)).unwrap();
      dispatch(setProject(buildProjectInfo(data)));
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successCreateProject'),
        }),
      );
      dispatch(setProjectFetching(false));
      dispatch(updateProjectStats());
      return data;
    } catch (err) {
      console.error(err);
      dispatch(setProjectFetching(false));
      return null;
    }
  };

export const patchProject =
  (project: UpdateProjectBody): TypedThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    const projectId = getState().project.project?.uuid;
    if (!projectId) throw new Error();
    dispatch(setProjectFetching(true));
    try {
      const data = await dispatch(projectEndpoints.updateProject.initiate({ uuid: projectId, body: project })).unwrap();
      if (!data) throw new Error();
      dispatch(setProject(buildProjectInfo(data)));
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successSaveChanges'),
        }),
      );
      dispatch(setProjectFetching(false));
      dispatch(updateProjectStats());
      return true;
    } catch (err) {
      console.error(err);
      dispatch(setProjectFetching(false));
      return false;
    }
  };

export const uploadAsset =
  (formData: FormData): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const { project } = state.project;
    if (!project) throw new Error();
    const { assets, uuid: projectId } = project;
    const { user } = getState().auth;
    try {
      const { data } = await sdk.project.UploadAsset(projectId, formData);
      dispatch(setProjectAssets([...assets, { ...data, owner: user ?? undefined }]));
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successUploadAsset'),
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const deleteAsset =
  (assetId: UUID): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const { project } = state.project;
    if (!project) throw new Error();
    const { assets, uuid: projectId } = project;
    try {
      await sdk.project.DeleteAsset(projectId, assetId);
      dispatch(setProjectAssets(assets.filter(asset => asset.uuid !== assetId)));
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successSaveChanges'),
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const downloadAssets =
  (asset: AssetInfo, uuid: UUID, scope: 'task' | 'project'): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      const { data, headers } = await sdk[scope].DownloadAsset(uuid, asset.uuid, asset.contentType);
      if (data) {
        downloadFile(data, headers['content-type'] as string, asset.name);
      }
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successDownloadAsset'),
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(
        addToast({
          severity: ToastSeverity.ERROR,
          message: i18next.t('toast:error'),
        }),
      );
    }
  };

export const postImage =
  (createImageBody: CreateImageBody): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const { project } = state.project;
    if (!project) throw new Error();
    const { images = [], uuid: projectId } = project;
    try {
      const { data } = await sdk.project.CreateImage(projectId, createImageBody);
      dispatch(setProjectImages([...images, data]));
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successUploadImage'),
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const patchImage =
  (updateImageBody: UpdateImageBody, imageId: UUID): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const { project } = state.project;
    if (!project) throw new Error();
    const { images = [], uuid: projectId } = project;
    try {
      const { data } = await sdk.project.UpdateImage(projectId, imageId, updateImageBody);
      const index = images.findIndex(img => img.uuid === imageId);
      const newImages = [...images];
      newImages.splice(index, 1, data);
      dispatch(setProjectImages(newImages));
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successSaveChanges'),
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const deleteImage =
  (imageId: UUID): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const { project } = state.project;
    if (!project) throw new Error();
    const { images, uuid: projectId } = project;
    try {
      await sdk.project.DeleteImage(projectId, imageId);
      dispatch(setProjectImages(images?.filter(img => img.uuid !== imageId) ?? []));
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successSaveChanges'),
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const createTask =
  (task: CreateTaskBody): TypedThunk<Promise<TaskInfo | null>> =>
  async (dispatch, getState) => {
    const state = getState();
    const { project } = state.project;
    if (!project) throw new Error();
    const { uuid: projectId, tasks } = project;
    dispatch(setTaskLoading(true));
    try {
      const { data } = await sdk.task.Create(projectId, task);
      dispatch(setProjectTasks([...tasks, data]));
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successCreateTask'),
        }),
      );
      return data;
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
      return null;
    } finally {
      dispatch(setTaskLoading(false));
    }
  };

export const updateForecastedSavings =
  (forecastedSavings: ProjectSaving[]): TypedThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    const state = getState();
    const { project } = state.project;
    if (!project) throw new Error();
    const { uuid: projectId } = project;
    const body: UpdateProjectBody = {
      forecastedSavings,
    };
    try {
      await dispatch(projectEndpoints.updateProject.initiate({ uuid: projectId, body })).unwrap();
      dispatch(setProjectForecastedSavings(forecastedSavings));
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successSaveChanges'),
        }),
      );
      dispatch(updateProjectStats());
      return true;
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
      return false;
    }
  };

export const updateSavings =
  (savings: ProjectSaving[]): TypedThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    const state = getState();
    const { project } = state.project;
    if (!project) throw new Error();
    const { uuid: projectId } = project;
    const body: UpdateProjectBody = {
      savings,
    };
    try {
      await dispatch(projectEndpoints.updateProject.initiate({ uuid: projectId, body })).unwrap();
      dispatch(setProjectSavings(savings));
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successSaveChanges'),
        }),
      );
      dispatch(updateProjectStats());
      return true;
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
      return false;
    }
  };

export const updateReferencePeriod =
  (referencePeriod: ProjectReferencePeriod): TypedThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    const state = getState();
    const { project } = state.project;
    if (!project) throw new Error();
    const { uuid: projectId } = project;
    const body: UpdateProjectBody = {
      referencePeriod,
    };
    try {
      await dispatch(projectEndpoints.updateProject.initiate({ uuid: projectId, body })).unwrap();
      dispatch(setProjectReferencePeriod(referencePeriod));
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successSaveChanges'),
        }),
      );
      dispatch(updateProjectStats());
      return true;
    } catch (err) {
      console.error(err);
      dispatch(
        addToast({
          severity: ToastSeverity.ERROR,
          message: i18next.t('toast:error'),
        }),
      );
      return false;
    }
  };

export const updateProjectStats = (): TypedThunk<void> => (dispatch, getState) => {
  // const { savings, referencePeriod, costs, subsidies } = getState().project.project;
  const state = getState();
  const { project } = state.project;
  if (!project) throw new Error();
  const { forecastedSavings, savings, referencePeriod, costs, subsidies } = project;

  // const totalForecastedSavings = { carbon: 0, financial: 0 };
  const totalForecastedSavings = getTotal(forecastedSavings);
  const totalSavings = getTotal(savings);
  const totalConsumption = getTotal(referencePeriod.consumptions);
  const consumptionByYear = getDataByYear({ from: referencePeriod.from, to: referencePeriod.to }, referencePeriod.consumptions);
  const totalConsumptionByYear = getTotal(consumptionByYear);

  const totalForecastedImpact = (totalForecastedSavings.financial / totalConsumptionByYear.financial) * 100;
  const totalImpact = (totalSavings.financial / totalConsumptionByYear.financial) * 100;

  const savingsByEnergyByYear = getStatsByEnergies(savings); // savings already by year
  const consumptionByEnergyByYear = getStatsByEnergy(consumptionByYear);
  const savingPercentage = getSavingPercentage(savingsByEnergyByYear, consumptionByEnergyByYear);
  const totalSavingPercentage = getTotalSavingPercentage(totalSavings, totalConsumptionByYear);

  const totalCosts = costs.reduce((acc, current) => acc + current.value, 0);
  const totalSubsidies = subsidies.reduce((acc, current) => acc + current.value, 0);
  const forecastedPaybackPeriod = {
    value: (totalCosts - totalSubsidies) / totalForecastedSavings.financial,
    valueWithoutSubsidies: totalCosts / totalForecastedSavings.financial,
  };
  const paybackPeriod = {
    value: (totalCosts - totalSubsidies) / totalSavings.financial,
    valueWithoutSubsidies: totalCosts / totalSavings.financial,
  };

  dispatch(
    setProjectStats({
      consumptionByYear,
      totalConsumption,
      totalConsumptionByYear,
      totalForecastedImpact,
      totalForecastedSavings,
      totalImpact,
      totalSavings,
      savingPercentage,
      totalSavingPercentage,
      forecastedPaybackPeriod,
      paybackPeriod,
      totalCosts,
      totalSubsidies,
    }),
  );
};

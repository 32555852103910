import {
  BulkQueryStrategy,
  DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  DEFAULT_DEVICE_LOOPTIME,
  DEFAULT_VARIABLE_DATA_DELAY,
  DEFAULT_VARIABLE_FREQUENCY,
  DefaultQueryStrategy,
  QueryStrategy,
  SQLBigQDeviceConfig,
  SQLDefaultDeviceConfig,
  SQLDialect,
  TemplateQueryStrategy,
} from '@dametis/core';

export const createSQLDefaultDeviceConfig = ({
  host = '',
  port = 1433,
  database = '',
  credentials = null,
  dialect = SQLDialect.MSSQL,
  dateFormat = 'X',
  timeZone = 'Europe/Paris',
  queryStrategy = createDefaultQueryStrategy(),
  loopTime = DEFAULT_DEVICE_LOOPTIME,
  deviceHeartbeatFrequency = DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  defaultVariableFrequency = DEFAULT_VARIABLE_FREQUENCY,
  defaultVariableDataDelay = DEFAULT_VARIABLE_DATA_DELAY,
}: Partial<SQLDefaultDeviceConfig> = {}): SQLDefaultDeviceConfig => ({
  host,
  port,
  database,
  credentials,
  dialect,
  dateFormat,
  timeZone,
  queryStrategy,
  loopTime,
  deviceHeartbeatFrequency,
  defaultVariableFrequency,
  defaultVariableDataDelay,
});

export const createSQLBigQDeviceConfig = ({
  dialect = SQLDialect.BIGQUERY,
  dateFormat = 'X',
  timeZone = 'Europe/Paris',
  queryStrategy = createDefaultQueryStrategy(),
  loopTime = DEFAULT_DEVICE_LOOPTIME,
  deviceHeartbeatFrequency = DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  defaultVariableFrequency = DEFAULT_VARIABLE_FREQUENCY,
  defaultVariableDataDelay = DEFAULT_VARIABLE_DATA_DELAY,
}: Partial<SQLBigQDeviceConfig> = {}): SQLBigQDeviceConfig => ({
  dialect,
  dateFormat,
  timeZone,
  queryStrategy,
  loopTime,
  deviceHeartbeatFrequency,
  defaultVariableFrequency,
  defaultVariableDataDelay,
});

export const createDefaultQueryStrategy = ({
  mode = QueryStrategy.DEFAULT,
  propertyMatcher = {
    date: '',
    value: '',
  },
  variables = {},
}: Partial<DefaultQueryStrategy> = {}): DefaultQueryStrategy => ({
  mode,
  propertyMatcher,
  variables,
});

export const createTemplateQueryStrategy = ({
  mode = QueryStrategy.TEMPLATE,
  propertyMatcher = {
    date: '',
    value: '',
  },
  query = '',
  variables = [],
}: Partial<TemplateQueryStrategy> = {}): TemplateQueryStrategy => ({
  mode,
  propertyMatcher,
  query,
  variables,
});

export const createBulkQueryStrategy = ({
  mode = QueryStrategy.BULK,
  propertyMatcher = {
    date: '',
    value: '',
    reference: '',
  },
  query = '',
}: Partial<BulkQueryStrategy> = {}): BulkQueryStrategy => ({
  mode,
  propertyMatcher,
  query,
});

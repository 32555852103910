import { VirtualColumnColumn, VirtualColumnElementType, VirtualColumnSeparator, VirtualColumnText } from '@dametis/core';

import { createSourceColumnRealByIndex } from './createSourceColumn';

export const createVirtualColumnColumn = ({
  type = VirtualColumnElementType.COLUMN,
  content = {
    source: createSourceColumnRealByIndex(),
  },
}: Partial<VirtualColumnColumn> = {}): VirtualColumnColumn => ({
  type,
  content,
});

export const createVirtualColumnText = ({
  type = VirtualColumnElementType.TEXT,
  content = {
    value: '',
  },
}: Partial<VirtualColumnText> = {}): VirtualColumnText => ({
  type,
  content,
});

export const createVirtualColumnSeparator = ({
  type = VirtualColumnElementType.SEPARATOR,
  content = {
    value: '',
  },
}: Partial<VirtualColumnSeparator> = {}): VirtualColumnSeparator => ({
  type,
  content,
});

import { Stack, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Delay, IsTimeGroupBy, MUTED_GROUPBY, TimeUnit, VariableDelay } from '@dametis/core';

import DelayPicker from 'components/UI/DelayPicker/DelayPicker';

import FormPaper, { FormLocation } from './FormPaper';

export interface MCOSettingsFormProps {
  defaultVariableDataDelay: Delay;
  onChangeDefaultVariableDataDelay: (newDelay: Delay) => void;
  deviceHeartbeatFrequency: Delay;
  onChangeDeviceHeartbeatFrequency: (newDelay: Delay) => void;
  defaultVariableFrequency: Delay;
  onChangeDefaultVariableFrequency: (newDelay: Delay) => void;
  isEditing?: boolean;
  location?: `${FormLocation}`;
}

const IsDelay = (delay: VariableDelay | `${TimeUnit}` | ''): delay is Delay => delay === MUTED_GROUPBY || IsTimeGroupBy(delay);

const MCOSettingsForm: FC<MCOSettingsFormProps> = ({
  isEditing = true,
  defaultVariableDataDelay,
  onChangeDefaultVariableDataDelay,
  deviceHeartbeatFrequency,
  onChangeDeviceHeartbeatFrequency,
  defaultVariableFrequency,
  onChangeDefaultVariableFrequency,
  location = 'modal',
}) => {
  const { t } = useTranslation('devices');

  const handleChangeDelay = useCallback(
    (callback: (value: Delay) => void) => (newDelay: VariableDelay | `${TimeUnit}` | '') => {
      if (IsDelay(newDelay)) {
        callback(newDelay);
      }
    },
    [],
  );

  return (
    <FormPaper location={location} sx={{ p: 1.5 }}>
      <Typography mb={1.5} variant="h6">
        {t('title.mcoSettings')}
      </Typography>
      <Stack gap={1}>
        <DelayPicker
          canBeLive
          required
          editing={isEditing}
          label={t('label.defaultVariableDataDelay')}
          name="defaultVariableDataDelay"
          value={defaultVariableDataDelay}
          onChange={handleChangeDelay(onChangeDefaultVariableDataDelay)}
        />
        <DelayPicker
          canBeMuted
          required
          editing={isEditing}
          label={t('label.deviceHeartbeatFrequency')}
          name="deviceHeartbeatFrequency"
          value={deviceHeartbeatFrequency}
          onChange={handleChangeDelay(onChangeDeviceHeartbeatFrequency)}
        />
        <DelayPicker
          canBeMuted
          required
          editing={isEditing}
          label={t('label.defaultVariableFrequency')}
          name="defaultVariableFrequency"
          value={defaultVariableFrequency}
          onChange={handleChangeDelay(onChangeDefaultVariableFrequency)}
        />
      </Stack>
    </FormPaper>
  );
};

export default MCOSettingsForm;

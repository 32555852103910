import { ArrowDropDown } from '@mui/icons-material';
import { Popover, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FC, MouseEvent, MouseEventHandler, useCallback, useMemo, useState } from 'react';
// import { useTranslation } from 'react-i18next';

import { VirtualColumnSeparator } from '@dametis/core';

import { getParsedSeparator } from 'components/Data/FileImport/helpers/getParsedSeparator';

const displayedSeparators = ['/', '-', ':'];
const otherSeparators = ['_', ',', '.', '&', ' '];

export interface VirtualColumnSeparatorTileProps {
  element: VirtualColumnSeparator;
  onChange: (newElement: VirtualColumnSeparator) => void;
}

const VirtualColumnSeparatorTile: FC<VirtualColumnSeparatorTileProps> = ({ element, onChange }) => {
  // const { t } = useTranslation('fileImport');

  const [otherPickerAnchor, setOtherPickerAnchor] = useState<HTMLElement | null>(null);

  const isOtherPickerOpen = useMemo(() => Boolean(otherPickerAnchor), [otherPickerAnchor]);
  const isOtherSelected = useMemo(() => otherSeparators.includes(element.content.value), [element.content.value]);

  const handleOpenOtherPicker: MouseEventHandler<HTMLElement> = useCallback(event => {
    setOtherPickerAnchor(event.currentTarget);
  }, []);

  const handleCloseOtherPicker = useCallback(() => {
    setOtherPickerAnchor(null);
  }, []);

  const handleChangeContentSeparatorFromDisplayed = useCallback(
    (_event: MouseEvent<HTMLElement>, newSeparator: string) => {
      const newElement: VirtualColumnSeparator = { ...element, content: { ...element.content, value: newSeparator } };
      onChange(newElement);
      setOtherPickerAnchor(null);
    },
    [element, onChange],
  );

  return (
    <>
      <Stack direction="row" gap={1}>
        <ToggleButtonGroup exclusive size="small" value={element.content.value} onChange={handleChangeContentSeparatorFromDisplayed}>
          {displayedSeparators.map(separator => (
            <ToggleButton key={separator} sx={{ p: 0, width: 30, fontSize: 18 }} value={separator}>
              {getParsedSeparator(separator)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <ToggleButtonGroup exclusive size="small">
          <ToggleButton selected={isOtherSelected} sx={{ p: 0, width: 30, fontSize: 18 }} value="other" onClick={handleOpenOtherPicker}>
            {isOtherSelected ? getParsedSeparator(element.content.value) : <ArrowDropDown fontSize="small" />}
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Popover
        anchorEl={otherPickerAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isOtherPickerOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseOtherPicker}
      >
        <ToggleButtonGroup
          exclusive
          size="small"
          sx={{ background: 'unset' }}
          value={element.content.value}
          onChange={handleChangeContentSeparatorFromDisplayed}
        >
          {otherSeparators.map(separator => (
            <ToggleButton key={separator} sx={{ p: 0, width: 30, fontSize: 18 }} value={separator}>
              {getParsedSeparator(separator)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Popover>
    </>
  );
};

export default VirtualColumnSeparatorTile;

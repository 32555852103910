import { HelpOutlineOutlined } from '@mui/icons-material';
import { FC } from 'react';

import { ReferencedBy } from '@dametis/core';

import ExampleTable from 'components/Data/FileImport/UI/ExampleTable';
import AlertTooltip from 'components/UI/AlertTooltip/AlertTooltip';

export interface ReferencedByHelperProps {
  value: ReferencedBy;
}

const ReferencedByHelper: FC<ReferencedByHelperProps> = ({ value }) => (
  <AlertTooltip
    AlertProps={{ severity: 'info', icon: false }}
    color="icon"
    placement="right"
    title={
      <>
        {value === ReferencedBy.COLUMNS ? (
          <ExampleTable
            header={['date', 'variable 1', 'variable 2']}
            rows={[
              ['01/01/24', '123', '456'],
              ['02/01/24', '234', '567'],
            ]}
          />
        ) : (
          <ExampleTable
            header={['date', 'référence', 'valeur']}
            rows={[
              ['01/01/24', 'variable 1', '123'],
              ['01/01/24', 'variable 2', '456'],
            ]}
          />
        )}
      </>
    }
  >
    <HelpOutlineOutlined fontSize="small" />
  </AlertTooltip>
);

export default ReferencedByHelper;

import {
  DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  DEFAULT_VARIABLE_DATA_DELAY,
  DEFAULT_VARIABLE_FREQUENCY,
  MQTTAuthenticationType,
  MQTTDeviceConfig,
  MQTTPasswordAuthentication,
  MQTTPayloadPropertyMatcher,
  MQTTPropertyMatcherType,
  MQTTTopicPropertyMatcher,
} from '@dametis/core';

export const createMQTTDeviceConfig = ({
  host = '',
  port = 1883,
  topic = '',
  propertyMatcher = createMQTTPropertyMatcher(MQTTPropertyMatcherType.MAP_ON_TOPIC),
  jqTransformer = null,
  timeZone = '',
  dateFormat = '',
  authentication = createMQTTPasswordAuthentication(),
  deviceHeartbeatFrequency = DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  defaultVariableFrequency = DEFAULT_VARIABLE_FREQUENCY,
  defaultVariableDataDelay = DEFAULT_VARIABLE_DATA_DELAY,
}: Partial<MQTTDeviceConfig> = {}): MQTTDeviceConfig => ({
  host,
  port,
  topic,
  propertyMatcher,
  jqTransformer,
  timeZone,
  dateFormat,
  authentication,
  deviceHeartbeatFrequency,
  defaultVariableFrequency,
  defaultVariableDataDelay,
});

export const createMQTTPasswordAuthentication = ({
  type = MQTTAuthenticationType.PASSWORD,
  username = '',
  password = '',
}: Partial<MQTTPasswordAuthentication> = {}): MQTTPasswordAuthentication => ({
  type,
  username,
  password,
});

export const createMQTTPropertyMatcher = <T extends MQTTPropertyMatcherType = MQTTPropertyMatcherType>(
  type: T,
  payload: Partial<MQTTPayloadPropertyMatcher | MQTTTopicPropertyMatcher> = {},
): MQTTPayloadPropertyMatcher | MQTTTopicPropertyMatcher => {
  if (type === MQTTPropertyMatcherType.MAP_ON_PAYLOAD) {
    return createMQTTPayloadPropertyMatcher(payload as Partial<MQTTPayloadPropertyMatcher>);
  }
  return createMQTTTopicPropertyMatcher(payload as Partial<MQTTTopicPropertyMatcher>);
};

export const createMQTTPayloadPropertyMatcher = ({
  type = MQTTPropertyMatcherType.MAP_ON_PAYLOAD,
  reference = '',
  date = '',
  value = '',
}: Partial<MQTTPayloadPropertyMatcher> = {}): MQTTPayloadPropertyMatcher => ({
  type,
  reference,
  date,
  value,
});

export const createMQTTTopicPropertyMatcher = ({
  type = MQTTPropertyMatcherType.MAP_ON_TOPIC,
  date = '',
  value = '',
}: Partial<MQTTTopicPropertyMatcher> = {}): MQTTTopicPropertyMatcher => ({
  type,
  date,
  value,
});

import { Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IsVirtualColumnColumn, IsVirtualColumnSeparator, IsVirtualColumnText, VirtualColumnElement } from '@dametis/core';

import { getSourceColumnName } from 'components/Data/FileImport/helpers/getSourceColumnName';

export interface VirtualColumnElementValueProps {
  element: VirtualColumnElement;
}

const VirtualColumnElementValue: FC<VirtualColumnElementValueProps> = ({ element }) => {
  const { t } = useTranslation('fileImport');

  const label: string = useMemo(() => {
    if (IsVirtualColumnColumn(element)) {
      return getSourceColumnName(element.content.source, t);
    }
    if (IsVirtualColumnText(element)) {
      return element.content.value;
    }
    if (IsVirtualColumnSeparator(element)) {
      return t('text.separator', { value: element.content.value });
    }
    return '';
  }, [t, element]);

  return (
    <Stack fontSize={10} gap={0.5} sx={{ backgroundColor: theme => theme.palette.background.default, borderRadius: 0.5, p: 0.5 }}>
      <Typography lineHeight={1} variant="overline">
        {t(`virtualColumnElementType.${element.type}`)}
      </Typography>
      <Typography lineHeight={1}>{label}</Typography>
    </Stack>
  );
};

export default VirtualColumnElementValue;

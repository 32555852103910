import { ArrowDropDown, CachedOutlined } from '@mui/icons-material';
import { Button, CircularProgress, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridColDef, GridRowParams, useGridApiRef } from '@mui/x-data-grid-premium';
import { FC, MouseEventHandler, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { FileInfo, PreviewFileParsingResult, SourceColumn } from '@dametis/core';

import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';

import ErrorsAlert from './ErrorsAlert';
import { useFilePreviewContext } from './FilePreviewContext';
import { ImportGridData, getColumnDef, indexColDef } from './FilePreviewGridColDef';
import RowErrorsDetailPanel from './RowErrorsDetailPanel';

export interface FilePreviewProps {
  selectedFile: FileInfo;
  onChangeSelectedFile: (newSelectedFile: FileInfo) => void;
  files: FileInfo[];
  preview: PreviewFileParsingResult | null;
  isFetching: boolean;
}

const FilePreview: FC<FilePreviewProps> = ({ selectedFile, onChangeSelectedFile, files, preview, isFetching }) => {
  const { t } = useTranslation('fileImport');

  const apiRef = useGridApiRef();

  const { globalParseErrors, parseErrorsByRow } = useFilePreviewContext();

  const [fileMenuAnchorEl, setFileMenuAnchorEl] = useState<HTMLButtonElement | undefined>(undefined);
  const [containerHeight, setContainerHeight] = useState<number | undefined>(undefined);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const isFileMenuOpen = useMemo(() => Boolean(fileMenuAnchorEl), [fileMenuAnchorEl]);

  const gridData: ImportGridData[] = useMemo(
    () =>
      (preview?.data ?? []).map((cells, rowIndex) =>
        cells.reduce((row, cell, columnIndex) => ({ ...row, [columnIndex]: cell }), { uuid: uuidv4(), index: rowIndex }),
      ),
    [preview],
  );

  const gridColDef: GridColDef[] = useMemo(
    () => ((preview?.columns as SourceColumn[]) ?? []).map<GridColDef<ImportGridData>>((source, index) => getColumnDef(source, index)),
    [preview],
  );

  const gridColDefWithIndex = useMemo(() => [indexColDef, ...gridColDef], [gridColDef]);

  const getDetailPanelContent = useCallback(
    ({ row }: GridRowParams<ImportGridData>): ReactNode | undefined =>
      parseErrorsByRow[row.index] ? <RowErrorsDetailPanel errors={parseErrorsByRow[row.index]} /> : undefined,
    [parseErrorsByRow],
  );

  const handleResize = useCallback(() => {
    if (!containerRef.current) {
      return;
    }
    const { clientHeight } = containerRef.current;
    setContainerHeight(clientHeight);
  }, []);

  const handleOpenFileMenu: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    setFileMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseFileMenu = useCallback(() => {
    setFileMenuAnchorEl(undefined);
  }, []);

  const handleChangeSelectedFile = useCallback(
    (newSelectedFile: FileInfo) => () => {
      onChangeSelectedFile(newSelectedFile);
      setFileMenuAnchorEl(undefined);
    },
    [onChangeSelectedFile],
  );

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver(handleResize);
    if (containerRef.current) {
      resizeObserverRef.current.observe(containerRef.current);
    }
    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [handleResize]);

  return (
    <>
      <Stack ref={containerRef} flexGrow={1} gap={1} overflow="hidden" width={1}>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack alignItems="center" direction="row" gap={2} overflow="hidden">
            <Typography variant="h6">{t('title.preview')}</Typography>
            <Button
              color="inherit"
              size="small"
              sx={{ textTransform: 'inherit', fontWeight: 'inherit', gap: 0.5, pointerEvents: files.length > 1 ? 'auto' : 'none' }}
              onClick={handleOpenFileMenu}
            >
              <TypographyEllipse>{selectedFile.name}</TypographyEllipse>
              {files.length > 1 && <ArrowDropDown />}
            </Button>
          </Stack>
          <ActionButton
            disabled={isFetching}
            startIcon={<CachedOutlined />}
            sx={{ flexShrink: 0 }}
            onClick={handleChangeSelectedFile(selectedFile)}
          >
            {t('button.refresh')}
          </ActionButton>
        </Stack>
        {preview && (
          <>
            <DataGridPremium
              disableRowSelectionOnClick
              apiRef={apiRef}
              columns={gridColDefWithIndex}
              density="compact"
              getDetailPanelContent={getDetailPanelContent}
              getDetailPanelHeight={() => 'auto'}
              getRowId={row => row.uuid}
              loading={isFetching}
              rows={gridData}
              sx={{ flexGrow: 1 }}
            />
            {(globalParseErrors.length > 0 || Object.keys(parseErrorsByRow).length > 0) && (
              <ErrorsAlert containerHeight={containerHeight} globalParseErrors={globalParseErrors} parseErrorsByRow={parseErrorsByRow} />
            )}
          </>
        )}
        {!preview && isFetching && (
          <Stack alignItems="center" flexGrow={1} justifyContent="center">
            <CircularProgress color="secondary" />
          </Stack>
        )}
      </Stack>
      <Menu
        anchorEl={fileMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isFileMenuOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseFileMenu}
      >
        {files.map(file => (
          <MenuItem key={file.name} selected={file.name === selectedFile.name} onClick={handleChangeSelectedFile(file)}>
            {file.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default FilePreview;

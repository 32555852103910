import { Paper, PaperProps, styled } from '@mui/material';

export enum FormLocation {
  MODAL = 'modal',
  PANEL = 'panel',
}

const FormPaper = styled(Paper, {
  shouldForwardProp: propName => propName !== 'location' && propName !== 'reverse',
})<PaperProps & { location?: `${FormLocation}`; reverse?: boolean }>(({ theme, location, reverse }) => ({
  backgroundColor:
    (location === FormLocation.PANEL && !reverse) || (location === FormLocation.MODAL && reverse)
      ? theme.palette.background.default
      : theme.palette.background.paper,
}));

export default FormPaper;

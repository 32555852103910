// import { ButtonBase, Menu, MenuItem, Stack } from '@mui/material';
import { GridColumnHeaderParams } from '@mui/x-data-grid-premium';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SourceColumn } from '@dametis/core';

import CustomGridColumnHeader from 'components/Configuration/Variables/VariablesGrid/CustomGridColumnHeader';
import { areSameSourceColumns } from 'components/Data/FileImport/helpers/areSameSourceColumns';
import { getColumnSourceValue } from 'components/Data/FileImport/helpers/getColumnSourceValue';
import { getSourceColumnName } from 'components/Data/FileImport/helpers/getSourceColumnName';
import { getSourceValueLabel } from 'components/Data/FileImport/helpers/getSourceValueLabel';
import { useVariableName } from 'hooks/useVariableProp';

import { useFilePreviewContext } from './FilePreviewContext';
import { ImportGridData } from './FilePreviewGridColDef';

export interface ColumnPickerHeaderCellProps {
  source: SourceColumn;
  params: GridColumnHeaderParams<ImportGridData>;
}

const ColumnPickerHeaderCell: FC<ColumnPickerHeaderCellProps> = ({ source, params }) => {
  const { t } = useTranslation('fileImport');

  const {
    referencedBy,
    usedSources,
    dateTimeSource,
    referenceSource,
    valueSource,
    // setDateTimeSource,
    // setReferenceSource,
    // setValueSource,
    skippedColumns,
    fixedVariables,
    csvConfig: { useHeader },
  } = useFilePreviewContext();

  // const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

  // const isMenuOpen = useMemo(() => Boolean(menuAnchor), [menuAnchor]);
  const isUsedColumn = useMemo(() => usedSources.some(usedSource => areSameSourceColumns(usedSource, source)), [usedSources, source]);
  const foundFixedVariable = useMemo(
    () => fixedVariables.find(fixedVariable => areSameSourceColumns(fixedVariable.source, source)),
    [fixedVariables, source],
  );

  const sourceValue = useMemo(
    () => getColumnSourceValue(source, dateTimeSource, referenceSource, valueSource, skippedColumns, fixedVariables),
    [source, dateTimeSource, referenceSource, valueSource, skippedColumns, fixedVariables],
  );

  const label: string = useMemo(
    () => getSourceValueLabel(sourceValue, referencedBy, useHeader, t),
    [t, sourceValue, useHeader, referencedBy],
  );

  const sourceName = useMemo(() => getSourceColumnName(source, t), [t, source]);

  const variableName = useVariableName({ variableUuid: foundFixedVariable?.variableId ?? '' });

  // const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
  //   event.stopPropagation();
  //   setMenuAnchor(event.currentTarget);
  // }, []);

  // const handleCloseMenu = useCallback(() => {
  //   setMenuAnchor(null);
  // }, []);

  // const handleSetDateTimeSource = useCallback(() => {
  //   setDateTimeSource(source);
  //   setMenuAnchor(null);
  // }, [source, setDateTimeSource]);

  // const handleSetReferenceSource = useCallback(() => {
  //   setReferenceSource(source);
  //   setMenuAnchor(null);
  // }, [source, setReferenceSource]);

  // const handleSetValueSource = useCallback(() => {
  //   setValueSource(source);
  //   setMenuAnchor(null);
  // }, [source, setValueSource]);

  return (
    <>
      {/* <Stack> */}
      {/* <ButtonBase sx={{ p: 0.5 }} onClick={handleOpenMenu}> */}
      <CustomGridColumnHeader
        columnWidth={params.colDef.width}
        label={foundFixedVariable && foundFixedVariable.variableId ? variableName : label}
        labelProps={!isUsedColumn ? { fontStyle: 'italic' } : undefined}
        params={params}
        subtitle={sourceName}
      />
      {/* </ButtonBase> */}
      {/* </Stack> */}
      {/* SLE: one day be back here with more options like skipped and fixed */}
      {/* <Menu
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={isMenuOpen}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleSetDateTimeSource}>{t('column.dateTimeSource')}</MenuItem>
        {referencedBy === ReferencedBy.ROWS && <MenuItem onClick={handleSetReferenceSource}>{t('column.referenceSource')}</MenuItem>}
        {referencedBy === ReferencedBy.ROWS && <MenuItem onClick={handleSetValueSource}>{t('column.valueSource')}</MenuItem>}
      </Menu> */}
    </>
  );
};

export default ColumnPickerHeaderCell;

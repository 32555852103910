import { ActiveAlarmInfo, StreamingBodyKind } from '@dametis/core';

import store from 'store';
import { ActiveAlarmsParameters } from 'types';

export interface ActiveAlarmsState {
  listener: ((arg: ActiveAlarmsParameters) => void) | null;
  record: Record<
    string,
    {
      asyncData: Promise<ActiveAlarmInfo[]>;
      data: ActiveAlarmInfo[];
      isLoading: boolean;
      listeners: ((arg: ActiveAlarmInfo[]) => void)[];
      receivedAt: number;
    }
  >;
}

export const activeAlarmsState: ActiveAlarmsState = {
  listener: null,
  record: {},
};

export const listenActiveAlarms = (siteId: string) => {
  const state = store.getState();
  const userId = state.auth.user!.uuid;
  const socket = state.auth.socket!;
  if (siteId in activeAlarmsState.record) {
    return;
  }
  if (activeAlarmsState.record[siteId] === undefined) {
    activeAlarmsState.record[siteId] = {
      asyncData: new Promise<ActiveAlarmInfo[]>(resolve => {
        const onceListener = ({ activeAlarms }: ActiveAlarmsParameters) => {
          resolve(activeAlarms);
        };
        socket.once(`users:${userId}`, onceListener);
      }),
      data: [],
      isLoading: true,
      listeners: [],
      receivedAt: Date.now(),
    };
    socket.emit(StreamingBodyKind.REFRESH_ACTIVE_ALARMS, { kind: StreamingBodyKind.REFRESH_ACTIVE_ALARMS, data: { siteId } });
  }
  if (activeAlarmsState.listener === null) {
    const listener = ({ activeAlarms, siteId: thisSiteId }: ActiveAlarmsParameters) => {
      if (activeAlarmsState.record[thisSiteId] === undefined) {
        return;
      }
      activeAlarmsState.record[thisSiteId].data = activeAlarms;
      activeAlarmsState.record[thisSiteId].isLoading = false;
      activeAlarmsState.record[thisSiteId].listeners.forEach(thisListener => {
        thisListener(activeAlarms);
      });
      activeAlarmsState.record[thisSiteId].receivedAt = Date.now();
    };
    socket.on(`users:${userId}`, listener);
    activeAlarmsState.listener = listener;
  }
};

export const stopListeningActiveAlarms = () => {
  const state = store.getState();
  const socket = state.auth.socket!;
  const userId = state.auth.user!.uuid;
  if (activeAlarmsState.listener === null) {
    return;
  }
  socket.off(`users:${userId}`, activeAlarmsState.listener);
  activeAlarmsState.listener = null;
  activeAlarmsState.record = {};
};

export const addActiveAlarmsListener = ({ siteId, listener }: { siteId: string; listener: (arg: ActiveAlarmInfo[]) => void }) => {
  activeAlarmsState.record[siteId].listeners.push(listener);
};

export const removeActiveAlarmsListener = ({ siteId, listener }: { siteId: string; listener: (arg: ActiveAlarmInfo[]) => void }) => {
  activeAlarmsState.record[siteId].listeners = activeAlarmsState.record[siteId].listeners.filter(thisListener => thisListener !== listener);
};

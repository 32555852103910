export default {
  title: {
    batch: 'Batch "{{name}}"',
    newBatch: 'New batch',
    when: 'Conditions',
    do: 'Actions',
    logs: 'Run logs',
    newElem: 'New item',
    batchFilters: 'Filters by batch',
  },
  button: {
    editBatch: 'Edit',
    deleteBatch: 'Delete',
    saveBatch: 'Save',
    cantSaveBatch: 'Add an item to save',
    createBatch: 'Create',
    addRule: 'Add a rule',
    addAction: 'Add an action',
    ok: 'Ok',
    cancel: 'Cancel',
    add: 'Save',
    addBatch: 'Add a batch',
    addElem: 'Add an item',
    addFirstElem: 'Add the first item',
  },
  input: {
    label: {
      name: 'Name',
      criticality: 'Criticality',
      durationValue: 'Minimum duration before activation',
      calendar: 'Activation period',
      condition: 'Activation rule',
      conditionMode: 'The state of this element depends on:',
      operator: 'Operator',
    },
    helperText: {
      advanced: 'Compare to an expression',
      simple: 'Compare to a value',
    },
    adornment: {
      durationValue: 'during',
    },
    value: {
      joinWith_and: 'AND',
      joinWith_or: 'OR',
      own: 'one or more conditions',
      children: 'its children',
      tooltipToogleStateError: 'Cannot change state because this batch still has children',
    },
    placeholder: 'Select...',
    noBatchSelected: 'No batch selected',
    target: 'Target duration',
  },
  results: {
    count: 'Occurrences',
    cumulatedDuration: 'Total duration',
    meanDuration: 'Mean duration',
  },
  tooltip: {
    createBatch: 'Create a batch',
    editBatch: 'Edit this batch',
    deleteBatch: 'Delete this batch',
    addBatch: 'Add a batch',
    addFirstElem: 'Add the first item',
    deleteBatchChild: 'Delete child',
    deleteRule: 'Delete rule',
    duplicateBatchChild: 'Duplicate',
    recomputeBatchVariable: 'Recalculate batch variable',
    dependencies: 'Dependencies',
  },
  text: {
    copy: 'Copy',
  },
  helper: {
    howToUse: 'Insert operators and parentheses to create complex conditions',
  },
  toast: {
    successRecompute: 'Your request has been received, recalculation in progress...',
  },
};

import { Box, InputLabel, Link, Stack, alertClasses } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Helper from 'components/UI/Helper/Helper';
import AdvancedTextField, { AdvancedTextFieldProps } from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';
import { UserHelperName } from 'types/userHelpers';

import { getAvailableFormats } from './availableFormats';

export const AVAILABLE_FORMATS = 'availableFormats';

export type DateTimeFormatPickerProps = AdvancedTextFieldProps;

const DateTimeFormatPicker: FC<DateTimeFormatPickerProps> = ({ inputProps, label, editing, required, ...props }) => {
  const { t } = useTranslation('fileImport');

  const availableFormats = useMemo(() => getAvailableFormats(), []);

  return (
    <Stack gap={editing ? 0.5 : 0}>
      <InputLabel required={required}>{label ?? t('label.dateTimeFormat')}</InputLabel>
      {editing && (
        <Helper name={UserHelperName.FILE_IMPORT_DATE_TIME_FORMAT} sx={{ [`& .${alertClasses.action}`]: { pl: 0 } }}>
          <Box component="span">{t('helper.dateTimeFormatDescription')}</Box>
          <Link href="https://moment.github.io/luxon/#/formatting?id=table-of-tokens" rel="noopener noreferrer" target="_blank">
            {t('helper.dateTimeFormatRedirection')}
          </Link>
        </Helper>
      )}
      <AdvancedTextField {...props} editing={editing} inputProps={{ list: AVAILABLE_FORMATS, ...inputProps }} />
      <Box component="datalist" id={AVAILABLE_FORMATS}>
        {availableFormats.map(availableFormat => (
          <Box key={availableFormat.value} component="option" value={availableFormat.value} />
        ))}
      </Box>
    </Stack>
  );
};

export default DateTimeFormatPicker;

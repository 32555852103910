import i18next from 'i18next';

import { BoxInfo } from '@dametis/core';

import { boxStatus } from 'functions/socketIo';
import { boxesEndpoints } from 'store/api/boxes';
import { setBoxesStatuses } from 'store/slices/configuration';

import { ToastSeverity } from '../../types/toast';
import { TypedThunk } from '../index';
import { setInfos } from '../slices/currentBox';
import { addToast } from '../slices/toast';

export const getCurrentBox =
  (boxId: string, boxes: BoxInfo[]): TypedThunk<Promise<void>> =>
  async dispatch => {
    const box = boxes.find(x => x.uuid === boxId);
    if (!box) {
      return;
    }
    try {
      dispatch(setInfos(box));
      await dispatch(getBoxesStatus(boxes));
    } catch (err) {
      console.error(err);
      dispatch(addToast({ severity: ToastSeverity.WARNING, message: i18next.t('toast:errorBoxVersion') }));
    }
  };

export const syncBox =
  (boxes: BoxInfo[], boxId?: string): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const uuid = boxId ?? getState().currentBox.infos?.uuid;

    if (!uuid) throw new Error();

    try {
      dispatch(addToast({ severity: ToastSeverity.INFO, message: i18next.t('toast:waitingSyncConfigBox') }));
      await dispatch(boxesEndpoints.synchronizeBox.initiate({ uuid })).unwrap();
      await dispatch(getCurrentBox(uuid, boxes));
      dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: i18next.t('toast:successSyncConfigBox') }));
    } catch (err) {
      console.error(err);
    }
  };

export const updateBoxData =
  (boxId: string, data: BoxInfo): TypedThunk<void> =>
  (dispatch, getState) => {
    const { infos: currentBox } = getState().currentBox;
    if (currentBox?.uuid === boxId) {
      dispatch(setInfos(data));
    }
  };

export const getBoxesStatus =
  (boxes: BoxInfo[]): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      // eslint-disable-next-line @dametis/no-promise-all-map
      const statuses = await Promise.allSettled(boxes.map(box => boxStatus(box.uuid)));
      dispatch(setBoxesStatuses(statuses.reduce((a, v, i) => ({ ...a, [boxes[i].uuid]: v.status === 'fulfilled' ? v.value : null }), {})));
    } catch (err) {
      console.error(err);
      dispatch(
        addToast({
          severity: ToastSeverity.WARNING,
          message: i18next.t('toast:errorBoxVersion'),
        }),
      );
    }
  };

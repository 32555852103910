import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface PromptBeforeCancelingProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirmCanceling: () => Promise<void>;
}

const PromptBeforeCanceling: FC<PromptBeforeCancelingProps> = ({ isOpen, setIsOpen, onConfirmCanceling }) => {
  const { t } = useTranslation('fileImport');

  const [isCanceling, setIsCanceling] = useState<boolean>(false);

  const handleCloseCanceling = useCallback(() => {
    if (!isCanceling) {
      setIsOpen(false);
    }
  }, [isCanceling, setIsOpen]);

  const handleConfirmCanceling = useCallback(async () => {
    setIsCanceling(true);
    await onConfirmCanceling();
    setIsCanceling(false);
  }, [onConfirmCanceling]);

  return (
    <Dialog open={isOpen} onClose={handleCloseCanceling}>
      <DialogTitle>{t('title.promptBeforeCanceling')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('text.promptBeforeCanceling')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={isCanceling} onClick={handleCloseCanceling}>
          {t('button.closeCanceling')}
        </Button>
        <LoadingButton color="error" loading={isCanceling} onClick={handleConfirmCanceling}>
          {t('button.confirmCanceling')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PromptBeforeCanceling;

export interface S7RackInfo {
  key: string;
  value: string;
}

export const getRackInfos = (): S7RackInfo[] => [
  { key: 'S7 300 CPU', value: '0' },
  { key: 'S7 1200 CPU', value: '0' },
  { key: 'S7 1500 CPU', value: '0' },
  { key: 'CP 343', value: '0' },
];

import { FC } from 'react';

import { FileDeviceConfig, IsFileDeviceSftpInternalSource } from '@dametis/core';

import SftpInternalSourceCredentials from './SftpInternalSourceCredentials';

export interface FileDeviceCredentialsProps {
  config: FileDeviceConfig;
}

const FileDeviceCredentials: FC<FileDeviceCredentialsProps> = ({ config }) => (
  <>{IsFileDeviceSftpInternalSource(config.source) && <SftpInternalSourceCredentials source={config.source} />}</>
);

export default FileDeviceCredentials;

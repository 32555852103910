import { Box } from '@mui/material';
import { FC } from 'react';

export interface ExampleTableProps {
  header: string[];
  rows: string[][];
}

const ExampleTable: FC<ExampleTableProps> = ({ header, rows }) => (
  <Box
    component="table"
    sx={{
      tableLayout: 'fixed',
      width: 1,
      color: theme => theme.palette.grey.A200,
      fontSize: 11,
      borderCollapse: 'collapse',
      '& td, th': { p: 0.5 },
      '& td:not(:first-child), th:not(:first-child)': { borderLeft: theme => `1px solid ${theme.palette.divider}` },
      '& tbody tr': { borderTop: theme => `1px solid ${theme.palette.divider}` },
    }}
  >
    <Box component="thead">
      <Box component="tr">
        {header.map((cell, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index} align="left" component="th" whiteSpace="nowrap">
            {cell}
          </Box>
        ))}
      </Box>
    </Box>
    <Box component="tbody">
      {rows.map((row, rowIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={rowIndex} component="tr">
          {row.map((cell, cellIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={cellIndex} align="left" component="td" whiteSpace="nowrap">
              {cell}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  </Box>
);

export default ExampleTable;

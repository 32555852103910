import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { Button, IconButton, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { ChangeEventHandler, FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import AdvancedTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';

export interface TemplateVariablesPickerProps {
  value: string[];
  onChange: (newValue: string[]) => void;
  isEditing?: boolean;
}

const TemplateVariablesPicker: FC<TemplateVariablesPickerProps> = ({ value, onChange, isEditing = true }) => {
  const { t } = useTranslation('devices');

  const [newVariableMenuAnchorEl, setNewVariableMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [newVariableName, setNewVariableName] = useState<string>('');

  const isNewVariableMenuOpen = useMemo(() => Boolean(newVariableMenuAnchorEl), [newVariableMenuAnchorEl]);

  const isNewVariableValid = useMemo(() => newVariableName.trim().length > 0 && !value.includes(newVariableName), [newVariableName, value]);

  const handleDeleteVariable = useCallback(
    (variableIndex: number) => () => {
      const newValue = value.filter((_variable, index) => index !== variableIndex);
      onChange(newValue);
    },
    [value, onChange],
  );

  const handleChangeNewVariableName: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setNewVariableName(event.target.value);
  }, []);

  const handleOpenNewVariableMenu: MouseEventHandler<HTMLElement> = useCallback(event => {
    setNewVariableName('');
    setNewVariableMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseNewVariableMenu = useCallback(() => {
    setNewVariableMenuAnchorEl(null);
  }, []);

  const handleAddNewVariable = useCallback(() => {
    const newValue = [...value, newVariableName.trim()];
    onChange(newValue);
    setNewVariableMenuAnchorEl(null);
  }, [value, onChange, newVariableName]);

  return (
    <>
      <Stack gap={1}>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography variant="h6">{t('label.variables')}</Typography>
          {isEditing && (
            <ActionButton startIcon={<AddOutlined />} onClick={handleOpenNewVariableMenu}>
              {t('button.add')}
            </ActionButton>
          )}
        </Stack>
        {value.length > 0 && (
          <Stack gap={0.5}>
            {value.map((variableName, index) => (
              <Stack
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                alignItems="center"
                direction="row"
                gap={1}
              >
                <Typography flexGrow={1}>{variableName}</Typography>
                {isEditing && (
                  <Tooltip placement="top" title={t('tooltip.delete')}>
                    <IconButton size="small" onClick={handleDeleteVariable(index)}>
                      <DeleteOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            ))}
          </Stack>
        )}
        {value.length === 0 && (
          <Typography align="center" variant="subtitle2">
            {t('text.noVariable')}
          </Typography>
        )}
      </Stack>
      <Popover
        anchorEl={newVariableMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isNewVariableMenuOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseNewVariableMenu}
      >
        <Stack direction="row" p={2} spacing={1}>
          <AdvancedTextField
            autoFocus
            fullWidth
            placeholder={t('placeholder.name')}
            size="small"
            value={newVariableName}
            onChange={handleChangeNewVariableName}
          />
          <Button
            color="secondary"
            disabled={!isNewVariableValid}
            sx={{ minWidth: 'unset', padding: '6px' }}
            variant="contained"
            onClick={handleAddNewVariable}
          >
            <AddOutlined />
          </Button>
        </Stack>
      </Popover>
    </>
  );
};

export default TemplateVariablesPicker;

import { RssFeedRounded } from '@mui/icons-material';
import { Chip, ChipProps } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProtocolKind } from '@dametis/core';

import getProtocols from '../../../config/protocols';

import useProtocolChipStyles from './ProtocolChip.styles';

export interface ProtocolChipProps extends ChipProps {
  protocol?: ProtocolKind;
}

const ProtocolChip: FC<ProtocolChipProps> = ({ protocol = undefined, ...props }) => {
  const classes = useProtocolChipStyles();
  const { t } = useTranslation('variables');

  const protocols = useMemo(() => getProtocols(t), [t]);
  const label = useMemo(
    () => (protocol !== undefined && protocols[protocol] !== undefined ? protocols[protocol].name : ''),
    [protocols, protocol],
  );

  return (
    <Chip
      classes={{
        root: classes.protocolChip,
        icon: classes.protocolChip__icon,
      }}
      color="primary"
      icon={<RssFeedRounded />}
      label={label}
      size="small"
      variant="outlined"
      {...props}
    />
  );
};

export default ProtocolChip;

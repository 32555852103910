import { AddOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormLabel,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  ChangeEventHandler,
  Dispatch,
  FC,
  Fragment,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  SourceColumn,
  SourceColumnRealByHeader,
  SourceColumnRealByIndex,
  VirtualColumn,
  VirtualColumnElement,
  VirtualColumnElementType,
} from '@dametis/core';

import { createVirtualColumn } from 'components/Data/FileImport/helpers/createVirtualColumn';
import {
  createVirtualColumnColumn,
  createVirtualColumnSeparator,
  createVirtualColumnText,
} from 'components/Data/FileImport/helpers/createVirtualColumnElement';
import AnimatedHeightDialogContent from 'components/UI/AnimatedHeightDialogContent/AnimatedHeightDialogContent';
import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';

import VirtualColumnElementTile from './VirtualColumnElementTile/VirtualColumnElementTile';

export interface CreateVirtualColumnModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCreate: (newVirtualColumn: VirtualColumn) => void;
  availableRealByIndexColumns: SourceColumnRealByIndex[];
  availableRealByHeaderColumns: SourceColumnRealByHeader[];
  availableVirtualColumnNames: string[];
}

const CreateVirtualColumnModal: FC<CreateVirtualColumnModalProps> = ({
  isOpen,
  setIsOpen,
  onCreate,
  availableRealByIndexColumns,
  availableRealByHeaderColumns,
  availableVirtualColumnNames,
}) => {
  const { t } = useTranslation('fileImport');

  const [virtualColumn, setVirtualColumn] = useState<VirtualColumn>(createVirtualColumn);
  const [elementPickerAnchor, setElementPickerAnchor] = useState<HTMLButtonElement | undefined>(undefined);

  const isNameAlreadyUsed = useMemo(
    () => availableVirtualColumnNames.includes(virtualColumn.name),
    [virtualColumn.name, availableVirtualColumnNames],
  );
  const isNameValid = useMemo(() => virtualColumn.name.length > 0 && !isNameAlreadyUsed, [virtualColumn.name, isNameAlreadyUsed]);

  const isElementPickerOpen = useMemo(() => Boolean(elementPickerAnchor), [elementPickerAnchor]);
  const lastElement = useMemo(() => virtualColumn.elements[virtualColumn.elements.length - 1], [virtualColumn.elements]);
  const isSeparatorDisabled = useMemo(
    () => !(lastElement?.type === VirtualColumnElementType.COLUMN || lastElement?.type === VirtualColumnElementType.TEXT),
    [lastElement?.type],
  );

  const defaultSourceColumn: SourceColumn | undefined = useMemo(() => {
    if (availableRealByIndexColumns.length > 0) {
      return availableRealByIndexColumns[0];
    }
    if (availableRealByHeaderColumns.length > 0) {
      return availableRealByHeaderColumns[0];
    }
    return undefined;
  }, [availableRealByIndexColumns, availableRealByHeaderColumns]);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleCreateVirtualColumn = useCallback(() => {
    onCreate(virtualColumn);
    setIsOpen(false);
  }, [onCreate, virtualColumn, setIsOpen]);

  const handleChangeVirtualColumnName: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setVirtualColumn(state => ({ ...state, name: event.target.value }));
  }, []);

  const handleChangeVirtualColumnElement = useCallback(
    (newElementIndex: number) => (newElement: VirtualColumnElement) => {
      setVirtualColumn(state => ({
        ...state,
        elements: state.elements.map((element, index) => (index === newElementIndex ? newElement : element)),
      }));
    },
    [],
  );

  const handleAddVirtualColumnColumn = useCallback(() => {
    setElementPickerAnchor(undefined);
    setVirtualColumn(state => ({
      ...state,
      elements: [
        ...state.elements,
        createVirtualColumnColumn(defaultSourceColumn ? { content: { source: defaultSourceColumn } } : undefined),
      ],
    }));
  }, [defaultSourceColumn]);

  const handleAddVirtualColumnSeparator = useCallback(() => {
    setElementPickerAnchor(undefined);
    setVirtualColumn(state => ({ ...state, elements: [...state.elements, createVirtualColumnSeparator({ content: { value: '/' } })] }));
  }, []);

  const handleAddVirtualColumnText = useCallback(() => {
    setElementPickerAnchor(undefined);
    setVirtualColumn(state => ({ ...state, elements: [...state.elements, createVirtualColumnText({ content: { value: '' } })] }));
  }, []);

  const handleOpenElementPicker: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    setElementPickerAnchor(event.currentTarget);
  }, []);

  const handleCloseElementPicker = useCallback(() => {
    setElementPickerAnchor(undefined);
  }, []);

  const handleDeleteVirtualColumnElement = useCallback(
    (deletedElementIndex: number) => () => {
      setVirtualColumn(state => ({
        ...state,
        elements: state.elements.filter((_element, index) => index !== deletedElementIndex),
      }));
    },
    [],
  );

  useEffect(() => {
    if (isOpen) {
      setVirtualColumn(createVirtualColumn());
    }
  }, [isOpen]);

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleCloseModal}>
        <DialogTitle>{t('title.createVirtualColumnModal')}</DialogTitle>
        <AnimatedHeightDialogContent>
          <Stack gap={2}>
            <TextField
              autoFocus
              fullWidth
              error={isNameAlreadyUsed}
              helperText={isNameAlreadyUsed ? t('text.virtualColumnNameIsAlreayUsed') : undefined}
              label={t('label.name')}
              value={virtualColumn.name}
              onChange={handleChangeVirtualColumnName}
            />
            <Stack gap={1}>
              <Stack alignItems="center" direction="row" justifyContent="space-between">
                <FormLabel>{t('label.elements')}</FormLabel>
                <ActionButton startIcon={<AddOutlined />} onClick={handleOpenElementPicker}>
                  {t('button.add')}
                </ActionButton>
              </Stack>
              <Stack alignItems="stretch" direction="row" flexWrap="wrap" gap={0.5} justifyContent="flex-start">
                {virtualColumn.elements.map((element, index) => (
                  <Fragment
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    <VirtualColumnElementTile
                      availableRealByHeaderColumns={availableRealByHeaderColumns}
                      availableRealByIndexColumns={availableRealByIndexColumns}
                      availableVirtualColumnNames={availableVirtualColumnNames}
                      element={element}
                      onChange={handleChangeVirtualColumnElement(index)}
                      onDelete={handleDeleteVirtualColumnElement(index)}
                    />
                    {index !== virtualColumn.elements.length - 1 && (
                      <Stack justifyContent="center">
                        <AddOutlined color="icon" fontSize="small" sx={{ fontSize: undefined }} />
                      </Stack>
                    )}
                  </Fragment>
                ))}
                {virtualColumn.elements.length === 0 && (
                  <Typography align="center" p={1} variant="subtitle2" width={1}>
                    {t('text.noVirtualColumnElement')}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </AnimatedHeightDialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>{t('button.close')}</Button>
          <Button color="secondary" disabled={!isNameValid} variant="contained" onClick={handleCreateVirtualColumn}>
            {t('button.add')}
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        anchorEl={elementPickerAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isElementPickerOpen}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={handleCloseElementPicker}
      >
        <MenuItem onClick={handleAddVirtualColumnColumn}>{t(`virtualColumnElementType.${VirtualColumnElementType.COLUMN}`)}</MenuItem>
        <Tooltip title={isSeparatorDisabled ? t('text.separatorMustFollowColumnOrText') : undefined}>
          <span>
            <MenuItem disabled={isSeparatorDisabled} onClick={handleAddVirtualColumnSeparator}>
              {t(`virtualColumnElementType.${VirtualColumnElementType.SEPARATOR}`)}
            </MenuItem>
          </span>
        </Tooltip>
        <MenuItem onClick={handleAddVirtualColumnText}>{t(`virtualColumnElementType.${VirtualColumnElementType.TEXT}`)}</MenuItem>
      </Menu>
    </>
  );
};

export default CreateVirtualColumnModal;

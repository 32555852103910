import { UserInfo } from '@dametis/core';

const getUserName = (user?: Pick<UserInfo, 'firstName' | 'lastName'> | null) => {
  if (user === undefined || user === null) {
    return '';
  }

  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  if (user.firstName) {
    return user.firstName;
  }

  if (user.lastName) {
    return user.lastName;
  }

  return '';
};

export default getUserName;

import { PrimaryEnergies } from '@dametis/core';

export default {
  contract: 'Contract "{{name}}"',
  contracts: 'Contracts',
  bill: 'Bill "{{name}}"',
  bills: 'Bills',
  button: {
    undo: 'Cancel',
    redo: 'Redo',
    close: 'Close',
    save: 'Save',
    addFirstElem: 'Add item',
    addPeriod: 'Add period',
    addValidationFormula: 'Add validation formula',
    addContract: 'Contract',
    refresh: 'Refresh',
    no: 'No',
    yes: 'Yes',
    deselectAll: 'Unselect all',
    cancel: 'Cancel',
    addBill: 'Bill',
    add: 'Add',
    uploadBill: 'Upload bill',
    uploadContract: 'Upload contract',
    edit: 'Edit',
    seeRelatedContract: 'See related contract',
    seeRelatedBills: 'See related bills',
    confirm: 'Confirm',
    addPricing: 'Add pricing',
  },
  energy: {
    [PrimaryEnergies.ELECTRICITY]: 'Electricity',
    [PrimaryEnergies.GAS]: 'Gas',
    [PrimaryEnergies.WATER]: 'Water',
  },
  text: {
    billingStrategy: 'Billing strategy',
    informations: 'Information',
    general: 'General',
    contract: 'Contract',
    validation: 'Validation',
    absolutTime: 'Absolute',
    averageTime: 'Average',
    count: 'Count',
    integral: 'Integral',
    maximum: 'Maximum',
    VALID: 'Valid',
    INVALID: 'Invalid',
    TO_VALIDATE: 'To validate',
    unableToDeleteContract: 'You cannot delete this contract because it has associated bills.',
    deleteContract: 'Delete contract',
    pricing: 'Pricing',
    noSelectedPricingMethod: 'No pricing selected',
    noPricing: 'No pricing',
    noAsset: 'No document',
  },
  title: {
    newContract: 'New contract',
    deleteContract: 'Delete contract',
    contracts: 'Contracts',
    newBill: 'New bill',
    bills: 'Bills',
    bill: 'Document',
    general: 'General',
    period: 'Periods',
    validation: 'Validation',
    description: 'Description',
    home: 'Choose the type of energy :',
    warning: 'Warning',
  },
  input: {
    label: {
      name: 'Name',
      periodName: 'Period name',
      startDate: 'Start date',
      endDate: 'End date',
      energy: 'Energy',
      provider: 'Provider',
      search: 'Search',
      color: 'Color',
      period: 'Period',
      pricings: 'Pricing',
      overload: 'Overload',
      overloadTime: 'Overload time',
      billedConsumption: 'Billed consumption',
      billedPrice: 'Billed price',
      description: 'Description',
      billAsset: 'Bill',
      contractName: "Contract's name ",
      validated: 'validated',
      billId: 'Bill Id',
      totalPrice: 'Invoice amount',
      validation: 'Validation',
      total: 'Total',
      clientId: 'Client ID',
      deliveryPoint: 'Delivery point',
      mainFormula: 'Main formula ',
      formula: 'Formula',
      unit: 'Unit',
      price: 'Price',
      contractPower: {
        value: 'Contract power',
      },
      validationState: 'State',
      optional: 'Optional',
    },
    placeholderEnergies: 'No energy selected',
    search: 'Search...',
    energyType: 'Energy Type',
    contract: 'Contract',
  },
  prompt: {
    deleteBill: 'Delete',
  },
  table: {
    cell: {
      name: 'Name',
      startDate: 'Start date',
      endDate: 'End date',
      delete: 'Delete',
      energy: 'Energy',
      dates: 'Dates',
      provider: 'Provider',
      billingPeriod: 'Billed period',
      measuredConsumption: 'Measured consumption',
      billedConsumption: 'Billed consumption',
      contractName: "Contract's name",
      totalBilledConsumption: 'Billed consumption',
      totalMeasuredConsumption: 'Measured consumption',
      validationState: 'State',
      id: 'Bill number',
      contractNameUnknown: 'Unknown contract name',
      duplicatePeriod: 'Duplicate period',
      totalPrice: 'Amount',
    },
  },
  tooltip: {
    createBill: 'create a bill',
    editBill: 'edit this bill',
    deleteBill: 'delete this bill',
    createContract: 'create a contract',
    editContract: 'edit this contract',
    deleteContract: 'delete this contract',
    deletePeriod: 'Delete period',
    duplicatePeriod: 'Duplicate period',
    validationDelta: 'Delta with main validation formula (n°1)',
    deleteRule: 'Delete formula',
    editPricing: 'Edit pricing',
    deletePricing: 'Delete pricing',
  },
  xGrid: {
    period: { noData: 'No period' },
    noContract: 'No contract',
    totalContracts: 'Number of contracts',
    noBill: 'No bill',
    totalBills: 'Number of bills',
  },
  alert: {
    mismatchedTimeZonesTitle: 'Your time zones mismatch!',
    mismatchedTimeZones:
      'You appear to be in "{{currTZ}}" while this site is in "{{siteTZ}}". Be aware that timestamps bellow are "{{currTZ}}".',
    weekNotCovered: 'You must enter a pricing for each hour of the week in order to be able to create a period',
    weekNotCoveredTitle: 'Week not covered',
    incompletePeriodsTitle: 'Incomplete periods',
    incompletePeriod: 'The periods must cover the entire duration of the contract',
  },
  error: {
    dateOrder: 'Invalid dates',
    datesNotInContractDateRange: "Dates not included in contract's date range",
    datesOverlap: 'Dates overlap',
    colorAlreadyExists: 'Color already used',
    required: 'Required',
    nameAlreadyExists: 'Name already used',
  },
  helper: {
    PCS: 'HHV',
    cannotEditDates: 'You cannot edit the dates because there are bills associated with this contract.',
    cannotEditDatesBecausePeriods: 'You cannot edit the dates because there are periods associated with this contract',
    cannotEditPeriods: 'You cannot edit the periods because there are bills associated with this contract',
    cannotEditValidation: 'You cannot edit the formulas because there are bills associated with this contract',
  },
  warning: {
    editBill:
      'The measured consumption data will be updated and may be different from those recorded in the event that the configuration has been modified.',
  },
  pricingTemplates: {
    PT: 'PT', // peak time
    OPT: 'OPT', // off-peak time
    ST: 'ST', // shoulder time
    WPT: 'WPT', // winter peak time
    WOPT: 'WOPT', // winter off-peak time
    SPT: 'SPT', // summer peak time
    SOPT: 'SOPT', // summer off-peak time
  },
};

import { Stack, StackProps } from '@mui/material';
import { FC } from 'react';

interface StandardPageProps extends StackProps {
  size?: 'normal' | 'large';
}

const StandardPage: FC<StandardPageProps> = ({ children = undefined, size = 'normal', ...props }) => (
  <Stack
    maxWidth={size === 'normal' ? 1300 : 1600}
    minHeight={theme => `calc(100vh - ${theme.others.headerHeight})`}
    mx="auto"
    p={theme => theme.others.pagePadding}
    {...props}
  >
    {children}
  </Stack>
);

export default StandardPage;

import { Box, BoxProps } from '@mui/material';
import { ElementType, MouseEventHandler, ReactNode, forwardRef, useCallback, useMemo } from 'react';

import { CalculationVariable } from '@dametis/core';

import { createCalculationVariable } from 'functions/createCalculationVariable';
import { useDispatch } from 'store';
import { openCalculationMenu } from 'store/actions/calculationMenu';

const itemsDefaultProp: ReactNode[] = [];

export interface CalculationMenuContainerProps extends Omit<BoxProps, 'onContextMenu'> {
  calculation: CalculationVariable | null;
  items?: ReactNode[];
  chartDisabled?: boolean;
  component?: ElementType;
}

const CalculationMenuContainer = forwardRef<HTMLDivElement, CalculationMenuContainerProps>(
  ({ calculation: userCalculation, items: userItems, chartDisabled, component, sx, children, ...props }, ref) => {
    const dispatch = useDispatch();

    const sxProp = useMemo(() => ({ cursor: userCalculation ? 'context-menu' : undefined, ...sx }), [sx, userCalculation]);

    const handleClick: MouseEventHandler<HTMLElement> = useCallback(
      event => {
        event.preventDefault();
        const position = { mouseX: event.clientX, mouseY: event.clientY };
        dispatch(
          openCalculationMenu({ position, calculation: userCalculation ?? createCalculationVariable(), items: userItems, chartDisabled }),
        );
      },
      [userCalculation, dispatch, userItems, chartDisabled],
    );

    return (
      <Box ref={ref} sx={sxProp} onContextMenu={userCalculation ? handleClick : undefined} {...props} {...{ component }}>
        {children}
      </Box>
    );
  },
);

CalculationMenuContainer.defaultProps = {
  items: itemsDefaultProp,
  chartDisabled: false,
  component: 'div',
};

CalculationMenuContainer.displayName = 'CalculationMenuContainer';

export default CalculationMenuContainer;

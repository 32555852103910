import { Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockInfo, BlockTypeInfo, ShortStandardBlockInfo } from '@dametis/core';

import BlockPreview from 'components/Lego/UI/Block/BlockPreview/BlockPreview';
import { RightPanelBody, RightPanelSection, RightPanelSectionContent } from 'components/UI/RightPanel';
import { useBlocks } from 'store/api/blocks';

const blocksEmptyArray: BlockInfo[] = [];

export interface StandardBlocksTabProps {
  standardBlock: ShortStandardBlockInfo;
  blockType?: BlockTypeInfo;
}

const StandardBlocksTab: FC<StandardBlocksTabProps> = ({ blockType = undefined, standardBlock }) => {
  const { t } = useTranslation('lego');

  const { data: blocks = blocksEmptyArray } = useBlocks();

  const filteredBlocks = useMemo(() => blocks.filter(block => block.standardBlockId === standardBlock.uuid), [blocks, standardBlock]);

  return (
    <RightPanelBody>
      <RightPanelSection>
        <RightPanelSectionContent>
          {filteredBlocks.length > 0 && (
            <Stack gap={1}>
              {filteredBlocks.map(block => (
                <BlockPreview key={block.uuid} redirect block={block} blockType={blockType} />
              ))}
            </Stack>
          )}
          {filteredBlocks.length === 0 && (
            <Typography align="center" p={2} variant="subtitle2">
              {t('text.noBlock')}
            </Typography>
          )}
        </RightPanelSectionContent>
      </RightPanelSection>
    </RightPanelBody>
  );
};

export default StandardBlocksTab;

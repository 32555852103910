import { makeStyles } from '@mui/styles';

const useProtocolChipStyles = makeStyles(() => ({
  protocolChip: {
    borderRadius: '4px',
    borderStyle: 'dashed',
    height: '20px',
  },
  protocolChip__icon: {
    transform: 'scale(0.7)',
  },
}));

export default useProtocolChipStyles;

export default {
  text: {
    logIntoDametis: 'Log into :',
    logRecoverDametis: 'Forgot you password?',
    logChangePasswordDametis: 'Change your password :',
    logInvalidLostPwdToken: 'Invalid change password token',
    logInvalidAccountActToken: 'Invalid Account Activation Token',
    logDeadApi: 'An error occured, please try again later',
    invalid: 'Invalid email and password',
    or: 'or',
    setCookie: 'Setting cookie...',
    connection: 'Login',
    invitation: 'Invitation',
    email: 'Email',
    passkey: 'Passkey',
  },
  input: {
    accountNotActive: 'Your account is not active, please contact support@dametis.com',
    email: {
      label: 'Email',
      error: {
        userNotFound: 'User not found',
        emailNotFound: 'Email not found',
        token: 'Invalid Token',
        tokenPassword: 'Invalid Token, try to generate another one.',
      },
    },
    password: {
      label: 'Password',
      error: {
        wrongPassword: 'Wrong password',
        wrongEmailOrPassword: 'Wrong email or password',
        invalidStrength: 'Please choose a stronger password. Use a mix of letters, numbers and symbols.',
      },
    },
    newPassword: {
      label: 'New Password',
      success: 'Password change successful, login!',
      error: {
        mismatch: 'The passwords do not match',
        invalidToken: 'Invalid password recovery link',
        noTokenFound: 'Invalid token',
      },
    },
    confirmPassword: {
      label: 'Confirm your password',
    },
  },
  button: {
    logIn: 'Log in',
    recover: 'Forgot your password?',
    reset: 'Reset my password',
    update: 'Update my password',
    loginWith: 'Login with {{ auth }}',
    next: 'Next',
    login: 'Login',
    retry: 'Try again',
  },
  link: {
    alwaysShowGoogle: 'Always show',
  },
  alertMessage: {
    invalidEmail: 'Invalid email',
    wrongEmail: 'Email not found',
    successRecoveryEmail: 'If an account exists for this email address, you will receive a password recovery link shortly',
    emptyField: 'Please, fill all required fields',
    successChangePassword: 'You can now log in!',
  },
  reconnect: {
    error: 'There has been an error while logging you in.',
  },
  tooltip: {
    showPassword: 'Show password',
    hidePassword: 'Hide password',
    refresh: 'Refresh',
  },
  twoFA: {
    email: 'Receive a code by email',
    phone: 'Receive a code by text message',
    totp: 'Use authenticator app',
    passkey: 'Use passkey',
    selectMethod: 'Select your authentication method',
    chooseOtherMethod: 'Previous',
    changeAccount: 'Previous',
    errorCode: 'Invalid code',
    enterTOTPCode: 'Enter the code generated by your authenticator app.',
    enrollment: {
      explanation: 'Your organisation requires you to configure a second authentication method',
      selectMethod: 'Select your authentication method',
      email: 'Configure with email',
      phone: 'Configure with text message',
      otp: 'Configure with authenticator app',
      later: 'Configure later',
      previous: 'Previous',
      emailTitle: 'Enter your email',
      phoneTitle: 'Enter your phone number',
      codeTitle: 'Enter the verification code',
      emailError: 'Invalid email',
      phoneError: 'Invalid phone number',
      code: 'Verification code',
      disconnect: 'Disconnect',
    },
  },
  sso: {
    notFound: 'You have not been invited to create an account. Please contact {{ admin }} to receive an invitation link.',
    expired: 'The link you have is expired, please contact {{ admin }} to get a new one.',
    invalidId: 'We could not verify your identity. Please try again or contact {{ admin }}.',
    invitation: 'You have been invited to join the site {{ site }} - {{ group }} by {{ username }}.',
    askMail: 'Please enter your professional email address.',
    yourAdmin: 'your administrator',
  },
  webauthn: {
    error: {
      authenticator: 'Your authenticator could not provide the passkey',
    },
  },
};

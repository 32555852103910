import { Avatar, AvatarProps } from '@mui/material';
import { forwardRef } from 'react';

import { TinyUserInfo } from '@dametis/core';

import { getUserColor } from 'functions/color';

import { UserAvatarBorder } from './UserAvatarBorder';

export interface UserAvatarProps extends AvatarProps {
  user?: TinyUserInfo;
  size?: number;
  checked?: boolean;
  onClick?: () => void;
}

const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(
  ({ checked = false, onClick = undefined, size = 25, user = undefined, sx, ...props }, ref) => (
    <Avatar
      {...props}
      ref={ref}
      sx={{
        backgroundColor: getUserColor(user),
        fontSize: Math.round(size / 2),
        height: size,
        width: size,
        ...(!!onClick && { overflow: 'visible' }),
        ...sx,
      }}
      onClick={onClick}
    >
      {user?.firstName?.[0]?.toUpperCase()}
      {user?.lastName?.[0]?.toUpperCase()}
      <UserAvatarBorder checked={checked} clickable={!!onClick} />
    </Avatar>
  ),
);

UserAvatar.defaultProps = {
  size: 25,
  onClick: undefined,
  checked: false,
  user: undefined,
};

UserAvatar.displayName = 'UserAvatar';

export default UserAvatar;

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Action, Blocker, Location } from 'history';
import { FC, KeyboardEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useBlocker } from '../../../functions/react-router-dom-blocker';

interface Props {
  when: boolean;
  onLeave?: (nextLocation: HistoryLocation) => void;
}

export type HistoryLocationState = {
  goToGroupAndSite: boolean;
  drawerAction: string;
};

export interface HistoryLocation extends Location {
  // don't withdraw Partial from here as long as we use react-router (state is typed as unknown so every state keys should be optional)
  state: Partial<HistoryLocationState>;
}

const PromptBeforeLeaving: FC<Props> = ({ when, onLeave = undefined }) => {
  const { t } = useTranslation('prompts');
  const navigate = useNavigate();
  const location: HistoryLocation = useLocation();

  const [nextLocation, setNextLocation] = useState<HistoryLocation>(location);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);

  const handleUnsavedChanges = useCallback<Blocker>(
    transition => {
      setNextLocation(transition.location as HistoryLocation);
      if (transition.location.pathname === location.pathname || transition.action === Action.Replace) {
        transition.retry();
        return;
      }
      if ((transition.location as HistoryLocation).state?.goToGroupAndSite) {
        transition.retry();
        return;
      }
      if (when) {
        if (showPrompt) {
          setShowPrompt(false);
          transition.retry();
        }
        setShowPrompt(true);
      }
    },
    [location.pathname, showPrompt, when],
  );

  useBlocker(handleUnsavedChanges, when && !nextLocation?.state?.goToGroupAndSite);

  const closePrompt = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const leavePage = useCallback(() => {
    if (onLeave) {
      onLeave(nextLocation);
    }
    navigate(`${nextLocation.pathname}${nextLocation.search ?? ''}`, { state: nextLocation.state });
    setShowPrompt(false);
  }, [onLeave, nextLocation, navigate, setShowPrompt]);

  const handlePressEnter = useCallback<KeyboardEventHandler>(
    event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        leavePage();
      }
    },
    [leavePage],
  );

  return (
    <Dialog open={showPrompt} onClose={closePrompt} onKeyDown={handlePressEnter}>
      <DialogTitle>{t('title.beforeLeaving')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('text.beforeLeaving')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closePrompt}>
          {t('button.noLeave')}
        </Button>
        <Button color="error" onClick={leavePage}>
          {t('button.yesLeave')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromptBeforeLeaving;

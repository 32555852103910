import { EditorProps, OnMount, loader, useMonaco } from '@monaco-editor/react';
import monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockTypeInfo, ModelInfo, REGEX_UUID, ShortAliasInfo, ShortBlockInfo } from '@dametis/core';

import { useSelector } from 'store';
import { useAliases } from 'store/api/aliases';
import { useBlockTypes } from 'store/api/blockTypes';
import { useBlocks } from 'store/api/blocks';
import { useModels } from 'store/api/models';

import { StyledMonaco } from '../styled';

loader.config({ monaco: monacoEditor });

const aliasEmptyArray: ShortAliasInfo[] = [];
const blocksEmptyArray: ShortBlockInfo[] = [];
const blockTypesEmptyArray: BlockTypeInfo[] = [];
const emptyModelsArray: ModelInfo[] = [];

interface Props extends Omit<EditorProps, 'theme'> {}

const MonacoInput: FC<Props> = ({ onMount, ...props }) => {
  const { t } = useTranslation('vnc');
  const monaco = useMonaco();

  const variables = useSelector(state => state.variables.byId);
  const batches = useSelector(state => state.batch.flattenBatches);
  const sites = useSelector(state => state.auth.selectedGroup!.sites);

  const { data: aliases = aliasEmptyArray } = useAliases();
  const { data: blocks = blocksEmptyArray } = useBlocks();
  const { data: blockTypes = blockTypesEmptyArray } = useBlockTypes();
  const { data: models = emptyModelsArray } = useModels();

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (!monaco) return undefined;
    const disposable = monaco.languages.registerInlayHintsProvider('json', {
      displayName: 'uuid',
      provideInlayHints: model => {
        const matches = model.findMatches(REGEX_UUID, true, true, false, '', true);
        return {
          hints: matches.map(match => {
            const entityUuid = match.matches?.at(-1);
            const entityName =
              entityUuid === undefined
                ? t('text.unknownId')
                : (variables[entityUuid]?.name ??
                  [...sites, ...batches, ...blocks, ...blockTypes, ...models, ...aliases].find(e => e.uuid === entityUuid)?.name ??
                  t('text.unknownId'));
            return {
              label: `${entityName}:`,
              position: {
                column: match.range.startColumn,
                lineNumber: match.range.startLineNumber,
              },
            };
          }),
          dispose: () => null,
        };
      },
    });
    return () => {
      disposable.dispose();
    };
  }, [aliases, batches, blockTypes, blocks, models, monaco, sites, t, variables]);

  const handleDidMount = useCallback<OnMount>(
    (editor, monaco2) => {
      editor.onDidFocusEditorWidget(() => {
        setFocused(true);
      });
      editor.onDidBlurEditorWidget(() => {
        setFocused(false);
      });
      onMount?.(editor, monaco2);
    },
    [onMount],
  );

  return (
    <StyledMonaco
      focused={focused}
      language="json"
      loading={t('monaco.loading')}
      options={{ fixedOverflowWidgets: true, ...props.options }}
      onMount={handleDidMount}
      {...props}
    />
  );
};

export default MonacoInput;

import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CSVDelimiter } from '@dametis/core';

export interface DelimiterPickerProps {
  value: CSVDelimiter;
  onChange: (newValue: CSVDelimiter) => void;
}

const DelimiterPicker: FC<DelimiterPickerProps> = ({ value, onChange }) => {
  const { t } = useTranslation('fileImport');

  const options = useMemo(() => Object.values(CSVDelimiter), []);

  const handleChangeValue = useCallback(
    (event: SelectChangeEvent<CSVDelimiter>) => {
      const newValue = event.target.value as CSVDelimiter;
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <FormControl fullWidth required>
      <InputLabel>{t('label.delimiter')}</InputLabel>
      <Select<CSVDelimiter>
        renderValue={inputValue => (
          <Box fontStyle={inputValue === CSVDelimiter.AUTO ? 'italic' : undefined}>{t(`csvDelimiter.${inputValue}`)}</Box>
        )}
        value={value}
        onChange={handleChangeValue}
      >
        {options.map(option => (
          <MenuItem key={option} sx={{ fontStyle: 'italic' }} value={option}>
            {t(`csvDelimiter.${option}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DelimiterPicker;

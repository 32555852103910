import { GridLocaleText } from '@mui/x-data-grid-premium';

import { AlarmPriority } from '@dametis/core';

import { SortOption } from 'types';

export default {
  button: {
    acknowledge: 'Acquitter',
    createAlarm: 'Alarme',
    advancedSearch: 'Recherche avancée',
    deleteAlarmConfirmation_yes: 'Oui, supprimer',
    deleteAlarmConfirmation_cancel: 'Annuler',
    muteAlarm: 'Désactiver cette alarme',
    unmuteAlarm: 'Réactiver cette alarme',
    close: 'fermer',
    create: 'créer',
    previous: 'Précédent',
    next: 'Suivant',
    submit: 'Valider',
    seeAllAlarms: 'Voir toutes les alarmes',
  },
  title: {
    alarms: 'Alarmes',
    activeAlarms: 'Alarmes actives',
    alarmsHistory: 'Historique',
    alarmsSettings: 'Paramètres des alarmes',
    acknowledgeAlarmModal: 'Acquittement',
  },
  subtitle: {
    updatedAt: 'Modifiée {{date}}',
    updatedAtBy: 'Modifiée {{date}} par {{name}}',
    createdAtBy: 'Créée {{date}} par {{name}}',
  },
  text: {
    when: 'Quand',
    do: 'Faire',
    deleteAlarmConfirmation: 'Confirmez-vous la suppression de cette alarme ?',
    events: 'Historique des évènements',
    for: 'pendant',
    day: 'j',
    noResults: 'Aucun résultat',
    noActiveAlarms: 'Aucune alarme active',
    noHistoryIncidents: 'Aucun incident',
    noAlarms: 'Aucune alarme',
    allSelected: 'Tout sélectionner',
    requiredComment: 'Laisser un commentaire est requis sur cette alarme',
    projectFromAlarmName: 'Projet depuis "{{name}}"',
    commentDateIsCurrentDate: "La date du commentaire sera celle de l'acquittement",
    alarmAck: 'Acquittée',
    alarmNotAck: 'Non acquittée',
  },
  condition: {
    'equation_>': '{{variable}} est supérieur à {{value}}',
    'equation_>=': '{{variable}} est supérieur ou égal à {{value}}',
    'equation_<': '{{variable}} est inférieur à {{value}}',
    'equation_<=': '{{variable}} est inférieur ou égal à {{value}}',
    'equation_==': '{{variable}} est égal à {{value}}',
    'equation_!=': '{{variable}} est différent de {{value}}',
    forXUnitOfTime: 'pendant {{count}} $t(global:unit.time_{{unit}}, { "count": {{count}} })',
  },
  action: {
    sendEmail: 'Envoyer un email à {{email}}',
    sendText: 'Envoyer un message au {{phone}}',
    sendEmail_notSet: '$t(action.sendNotification)',
    sendNotification: "Notifier dans l'app",
  },
  snackbar: {
    message: {
      acknowledge: {
        success: "L'alerte a bien été marquée comme vue",
      },
      duplicate: 'Alarme dupliquée',
    },
  },
  table: {
    cells: {
      state: 'État',
      name: 'Nom',
      criticality: 'Criticité',
      extremeValue: 'Extrême',
      triggerDate: 'Déclenchée le',
      activeDuration: 'Active pendant',
      actions: 'Action',
      acknowledgementDate: 'Acquittée le',
      acknowledgementActor: 'Acquittée par',
      resolveDate: 'Résolue le',
      date: 'Date',
      event: 'Évènement',
      comment: 'Commentaire',
      commentLinkedProject: 'projet associé',
      resOn: 'Acquittée le ',
      resBy: 'par ',
    },
    rows: {
      acknowledgedBy: '{{name}}',
      event_resolved: 'Résolue',
      event_triggered: 'Déclenchée',
      event_notify: 'Notification envoyée',
      event_ack: 'Acquittée',
      event_ackBy: 'Acquittée par {{name}}',
    },
  },
  criticality: {
    text: 'Criticité',
    [`variant_${AlarmPriority.NOTIFICATION}`]: 'Notification',
    [`variant_${AlarmPriority.WARNING}`]: 'Attention',
    [`variant_${AlarmPriority.CRITICAL}`]: 'Critique',
  },
  priority: {
    [AlarmPriority.NOTIFICATION]: 'Notification',
    [AlarmPriority.WARNING]: 'Attention',
    [AlarmPriority.CRITICAL]: 'Critique',
  },
  sortBy: {
    [SortOption.ALPHA_ASC]: 'Ordre alphabétique croissant',
    [SortOption.ALPHA_DESC]: 'Ordre alphabétique décroissant',
    [SortOption.DATE_ASC]: 'Date croissante',
    [SortOption.DATE_DESC]: 'Date décroissante',
  },
  filters: {
    variablesFilter: 'Variables',
    criticalityFilter: 'Criticité',
  },
  input: {
    timeRange: {
      label: 'Date',
      value: {
        lastXUnitOfTime: 'Depuis {{count}} $t(global:unit.time_{{unit}}, { "count": {{count}} })',
        value_all: 'Toutes',
      },
    },
    criticality: {
      label: 'Criticité',
      value: {
        variant: '$t(criticality.variant)',
      },
    },
    alarms: {
      label: 'Nom',
    },
    variable: {
      label: 'Variable',
      error: {
        empty: 'Requis',
      },
    },
    name: {
      label: "Nom de l'alarme",
      error: {
        empty: 'Requis',
        over60characters: 'Trop long',
      },
    },
    operand: {
      error: {
        empty: 'Requis',
      },
    },
    notificationDelay: {
      error: {
        empty: 'Requis',
        notPositive: 'Ne peut-être négatif',
      },
    },
    email: {
      placeholder: 'Envoyer un email à',
      error: {
        invalid: 'Invalide',
      },
    },
    phone: {
      placeholder: 'Envoyer un message au',
      error: {
        invalid: 'Invalide',
      },
    },
    operator: {
      label: 'Opérateur',
    },
    chip: {
      backgroundColor: 'red',
    },
    emptyAlarmFilter: 'Aucun filtre par alarme',
  },
  xgrid: {
    noAlarmsLabel: 'Aucune alarme',
    footerTotalAlarms: 'Total alarmes :',
    footerPaginationAlarmsPerPage: 'Alarmes par page :',
    noIncidentsLabel: 'Aucun incident',
    footerTotalIncidents: 'Total incidents :',
    footerPaginationIncidentsPerPage: 'Incidents par page :',
  } as Partial<GridLocaleText>,
  stepper: {
    comment: 'Commentaire',
    project: 'Projet',
  },
  placeholder: {
    acknowledgeAlarmComment: 'Écrivez un commentaire...',
  },
  label: {
    createProjectFromAlarm: 'Créer un projet à partir de cette alarme',
  },
  tooltip: {
    alarm: 'alarme',
    createAlarm: 'créer une alarme',
    acknowledgeAlarm: 'acquitter une alarme',
    acknowledgedActiveAlarm: 'Alarme active acquittée',
    unacknowledgedActiveAlarm: 'Alarme active non acquittée',
    unacknowledgedInactiveAlarm: 'Alarme non active non acquittée',
  },
};

import { ClearAll } from '@mui/icons-material';
import { List, Stack } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useVncStore } from 'zustand/stores/vnc';

import { GetVarCalcUniqueId } from '../../../../functions/tada/helpers.ts';
import ActionButton from '../../../UI/Buttons/ActionButton/ActionButton';
import { RightPanelBody, RightPanelSectionTitle } from '../../../UI/RightPanel';

import SelectionItem from './SelectionItem';

const Selection: FC = () => {
  const { t } = useTranslation('vnc');

  const selection = useVncStore(state => state.selection);
  const clearSelection = useVncStore(state => state.clearSelection);

  const clearAll = useCallback(() => {
    clearSelection();
  }, [clearSelection]);

  return (
    <Stack sx={{ height: 1, pt: 1 }}>
      <RightPanelSectionTitle
        action={
          <ActionButton startIcon={<ClearAll />} onClick={clearAll}>
            {t('button.clearAllSelection')}
          </ActionButton>
        }
      >
        {t('title.selection')}
      </RightPanelSectionTitle>
      <RightPanelBody>
        <List>
          {selection.map((varCalc, index) => (
            <SelectionItem key={GetVarCalcUniqueId(varCalc)} index={index} isLast={index === selection.length - 1} varCalc={varCalc} />
          ))}
        </List>
      </RightPanelBody>
    </Stack>
  );
};

export default Selection;

import { createSlice } from '@reduxjs/toolkit';

import { GroupMainteanceStatusInfo } from '@dametis/core';

export interface MaintenanceState {
  status: GroupMainteanceStatusInfo | null;
}

const initialState: MaintenanceState = {
  status: null,
};

export const maintenance = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    setMaintenanceStatus: (state, action: { payload: GroupMainteanceStatusInfo }) => {
      state.status = action.payload;
    },
    resetMaintenanceStore: () => initialState,
  },
});

export const { setMaintenanceStatus, resetMaintenanceStore } = maintenance.actions;

export default maintenance.reducer;

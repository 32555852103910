import { CachedOutlined, SettingsOutlined } from '@mui/icons-material';
import { Box, FormLabel, Stack, useTheme } from '@mui/material';
import { DataGridPremium, GridRow, GridRowProps } from '@mui/x-data-grid-premium';
import Fuse from 'fuse.js';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, To } from 'react-router-dom';
import { useResizeObserver } from 'usehooks-ts';

import { ShortDeviceInfo } from '@dametis/core';

import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import LinkButton from 'components/UI/Buttons/LinkButton/LinkButton';
import CalculationMenuContainer from 'components/UI/CalculationMenu/CalculationMenuContainer';
import { RightPanelBody, RightPanelFooter, RightPanelSection, RightPanelSectionContent } from 'components/UI/RightPanel';
import SearchInput from 'components/UI/SearchInput/SearchInput';
import { useDispatch, useSelector } from 'store';
import { fetchVariablesLastPoints } from 'store/actions/variables';
import { setLastPoints } from 'store/slices/variables';
import { ColumnVisibilityModel } from 'types/variables';

import { DeviceVariablesGridColumn, getGridColDef } from './getGridColDef';

export interface RealVariablesPanelProps {
  device: ShortDeviceInfo;
}

const RealVariablesPanel: FC<RealVariablesPanelProps> = ({ device }) => {
  const { t } = useTranslation('devices');
  const dispatch = useDispatch();
  const theme = useTheme();

  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const variablesById = useSelector(state => state.variables.byId);
  const isFetchingVariablesLastPoints = useSelector(state => state.variables.lastPoints.isFetching);
  const lastPoints = useSelector(state => state.variables.lastPoints);

  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<ColumnVisibilityModel>({
    [DeviceVariablesGridColumn.NAME]: true,
    [DeviceVariablesGridColumn.LAST_POINT_VALUE]: true,
    [DeviceVariablesGridColumn.LAST_POINT_DATE]: false,
  });

  const dataGridContainerRef = useRef<HTMLDivElement | null>(null);

  const { height: dataGridContainerHeight = 0 } = useResizeObserver({
    ref: dataGridContainerRef,
  });

  const deviceVariables = useMemo(
    () => Object.values(variablesById).filter(variable => variable.deviceId === device.uuid),
    [variablesById, device.uuid],
  );

  const fuse = useMemo(() => {
    return new Fuse(deviceVariables, {
      keys: ['name'],
      includeScore: true,
      threshold: 0.3,
    });
  }, [deviceVariables]);

  const filteredVariables = useMemo(
    () => (searchInputValue.trim().length > 0 ? fuse.search(searchInputValue).map(result => result.item) : deviceVariables),
    [searchInputValue, fuse, deviceVariables],
  );

  const isRefreshVariablesLastPointsEnabled = useMemo(
    () => filteredVariables.length > 0 && !isFetchingVariablesLastPoints,
    [isFetchingVariablesLastPoints, filteredVariables],
  );

  const linkTo: To = useMemo(() => `/configuration/variables?device=${device.uuid}`, [device.uuid]);

  const columnsDef = useMemo(() => getGridColDef(lastPoints, t), [lastPoints, t]);

  const DataGridRow: FC<GridRowProps> = useCallback(
    props => (
      <CalculationMenuContainer calculation={{ exp: 'var_0', vars: { var_0: { variableUuid: props.row.uuid } } }}>
        <NavLink to={`/configuration/variables?selectedVariables=${props.row.uuid}`}>
          <GridRow {...props} />
        </NavLink>
      </CalculationMenuContainer>
    ),
    [],
  );

  const handleChangeSearchInputValue = useCallback((newSearchInputValue: string) => {
    setSearchInputValue(newSearchInputValue);
  }, []);

  const fetchThisDeviceVariablesLastPoints = useCallback(async () => {
    if (!groupId) {
      return;
    }
    try {
      const fetchedAt = new Date();
      const variablesIds = deviceVariables.map(variable => variable.uuid);
      const data = await dispatch(fetchVariablesLastPoints(groupId, variablesIds));
      dispatch(setLastPoints({ data, fetchedAt }));
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, groupId, deviceVariables]);

  const handleColumnVisibilityModelChange = useCallback(
    (newColumnVisibilityModel: ColumnVisibilityModel) => {
      setColumnVisibilityModel(newColumnVisibilityModel);
    },
    [setColumnVisibilityModel],
  );

  useEffect(() => {
    void fetchThisDeviceVariablesLastPoints();
  }, [fetchThisDeviceVariablesLastPoints]);

  return (
    <>
      <RightPanelBody>
        <RightPanelSection sx={{ height: 1, mb: 0 }}>
          <RightPanelSectionContent sx={{ height: 1 }}>
            <Stack height={1}>
              <Stack alignItems="center" direction="row" justifyContent="space-between" mb={1}>
                <FormLabel sx={{ mr: 1 }}>{t('text.variables')}</FormLabel>
                <Stack alignItems="center" direction="row" gap={1}>
                  <SearchInput withDebounce extendable={false} value={searchInputValue} onChange={handleChangeSearchInputValue} />
                  <ActionButton
                    disabled={!isRefreshVariablesLastPointsEnabled}
                    startIcon={<CachedOutlined />}
                    sx={{ flexShrink: 0 }}
                    onClick={fetchThisDeviceVariablesLastPoints}
                  >
                    {t('button.refresh')}
                  </ActionButton>
                </Stack>
              </Stack>
              <Box ref={dataGridContainerRef} flexGrow={1} overflow="hidden">
                <DataGridPremium
                  disableAggregation
                  disableRowGrouping
                  columns={columnsDef}
                  columnVisibilityModel={columnVisibilityModel}
                  density="compact"
                  getRowId={row => row.uuid}
                  localeText={{
                    ...theme.components?.MuiDataGrid?.defaultProps?.localeText,
                    noRowsLabel: t('dataGrid.noRowsLabel'),
                    footerTotalRows: t('dataGrid.footerTotalRows'),
                  }}
                  rows={filteredVariables}
                  slots={{ row: DataGridRow }}
                  sx={{ border: `1px solid ${theme.palette.divider}`, height: dataGridContainerHeight }}
                  onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                />
              </Box>
            </Stack>
          </RightPanelSectionContent>
        </RightPanelSection>
      </RightPanelBody>
      <RightPanelFooter sx={{ gap: 1 }}>
        <LinkButton startIcon={<SettingsOutlined />} to={linkTo}>
          {t('playground:button.configuration')}
        </LinkButton>
      </RightPanelFooter>
    </>
  );
};

export default RealVariablesPanel;

import { Components, Theme, checkboxClasses } from '@mui/material';
import switchBaseClasses from '@mui/material/internal/switchBaseClasses';

import { LIST_PADDING } from './MuiList';

export default {
  styleOverrides: {
    root: {
      [`& .${checkboxClasses.root}.${switchBaseClasses.edgeStart}`]: {
        marginTop: -9,
        marginBottom: -9,
        marginLeft: -9,
      },
    },
    dense: {
      paddingTop: LIST_PADDING / 2,
      paddingBottom: LIST_PADDING / 2,
    },
  },
} as Components<Theme>['MuiListItem'];

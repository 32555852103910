import { GridValidRowModel } from '@mui/x-data-grid-premium';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MutableRefObject } from 'react';

import { AnyGroupBy, CalculationStats, PlaygroundInfo, ShortGroupInfo, ShortSiteInfo, ShortTagInfo, UUID } from '@dametis/core';

import { DaChart } from 'classes/DaCharts/DaChart';
import HTMLDivRef from 'classes/HTMLDivRef/HTMLDivRef';
import MuiApiRef from 'classes/MuiApiRef/MuiApiRef';

import {
  HasDaChart,
  IBarChart,
  IChart,
  IHistogram,
  IHistogramVariable,
  IHistogramVariableProps,
  ILiveChart,
  ILiveChartProps,
  ILiveVariable,
  ILiveVariableProps,
  IPlayground,
  ITab,
  ITable,
  ITableMacroSettings,
  ITableVariable,
  ITableVariableProps,
  ITyChart,
  ITyChartProps,
  ITyGroup,
  ITyGroupProps,
  ITyVariable,
  ITyVariableProps,
  IXyChart,
  IXyVariable,
  IXyVariableProps,
  IsBarChartTab,
  IsHistogramTab,
  IsLiveChartTab,
  IsTableTab,
  IsTyChartTab,
  IsXyChartTab,
  WithDaChart,
  isXVariableIndex,
} from '../../components/Playground/types';

export interface PlaygroundState extends IPlayground {
  selectedTab: string;
  saving: boolean;
  panelOpen: boolean;
  canEdit: boolean;
  name: string;
  tabs: ITab[];
  template: boolean;
  editingMode: boolean;
  comment: { editComment: boolean; content: any };
  isDirty: boolean;
  site: ShortSiteInfo;
  group: ShortGroupInfo;
  current: {
    playground: Partial<PlaygroundInfo>;
  };
  addingVariable: boolean;
  gb?: AnyGroupBy;
  statistics: Record<string, CalculationStats>;
  drawSegmentButton?: boolean;
  commentDisplayedRef?: MutableRefObject<HTMLDivElement>[];
  isCommentEdit?: boolean;
  withYAxisVisible: boolean;
}

const initialState: PlaygroundState = {
  shared: true,
  uuid: '',
  selectedTab: null,
  saving: false,
  tabs: [],
  name: '',
  panelOpen: true,
  canEdit: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  template: false,
  editingMode: false,
  comment: { editComment: false, content: null },
  isDirty: false,
  group: null,
  site: null,
  sharings: undefined,
  canEditSharings: false,
  tags: [],
  current: {
    playground: null,
  },
  addingVariable: false,
  statistics: {},
  drawSegmentButton: false,
  commentDisplayedRef: [] as MutableRefObject<HTMLDivElement>[],
  isCommentEdit: false,
  lastReadAt: new Date(),
  archivedAt: null,
  ownerId: '',
  withYAxisVisible: true,
};

export const playgroundSlice = createSlice({
  name: 'playground',
  initialState,
  reducers: {
    setPlayground: (state, action: PayloadAction<IPlayground>) => {
      Object.assign(state, action.payload);
      state.isDirty = false;
      return state;
    },
    setPlaygroundName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setPlaygroundTags: (state, action: PayloadAction<ShortTagInfo[]>) => {
      state.tags = action.payload;
    },
    setEdit: (state, action: PayloadAction<boolean>) => {
      state.canEdit = action.payload;
      state.isDirty = true;
      return state;
    },
    setEditingMode: (state, action: PayloadAction<boolean>) => {
      state.editingMode = action.payload;
      state.isDirty = true;
      return state;
    },
    openComment: (state, action: PayloadAction<boolean>) => {
      state.comment.editComment = action.payload;
      state.isDirty = true;
      return state;
    },
    editComment: (state, action: PayloadAction<any>) => {
      state.comment.content = action.payload;
      state.isDirty = true;
      return state;
    },
    clearPlaygroundStore: () => initialState,
    setSelectedTab: (state, action: PayloadAction<ITab<IChart>>) => {
      state.selectedTab = action.payload?.uuid ?? null;
      state.isDirty = true;
      return state;
    },
    updatePlayground: (state, action: PayloadAction<IPlayground>) => {
      Object.assign(state, action.payload);
      state.isDirty = true;
      return state;
    },
    addTab: (state, action: PayloadAction<ITab<IChart>>) => {
      state.tabs.push(action.payload as any);
      state.isDirty = true;
    },
    setTabs: (state, action: PayloadAction<ITab<IChart>[]>) => {
      state.tabs = action.payload as any;
      state.isDirty = true;
    },
    removeTab: (state, action: PayloadAction<UUID>) => {
      state.tabs = state.tabs.filter(tab => tab.uuid !== action.payload);
      if (state.selectedTab === action.payload) {
        state.selectedTab = state.tabs[0]?.uuid ?? null;
      }
      state.isDirty = true;
      return state;
    },
    editTab: (state, action: PayloadAction<{ tabUuid: UUID; options: Partial<ITab> }>) => {
      const { tabUuid, options } = action.payload;
      const tabIndex = state.tabs.findIndex(t => t.uuid === tabUuid);
      if (tabIndex < 0) return state;
      Object.assign(state.tabs[tabIndex], options);
      // state.tabs[tabIndex] = deepmerge<ITab>(state.tabs[tabIndex], options)
      state.isDirty = true;
      return state;
    },
    setDrawSegmentButtonState: (state, action: PayloadAction<boolean>) => {
      state.drawSegmentButton = action.payload;
      state.isDirty = true;
      return state;
    },
    setCommentDisplayedRef: (state, action: PayloadAction<MutableRefObject<any>>) => {
      state.commentDisplayedRef = [...state.commentDisplayedRef, action.payload];
      state.isDirty = true;
      return state;
    },
    setIsCommentEdit: (state, action: PayloadAction<boolean>) => {
      state.isCommentEdit = action.payload;
      state.isDirty = true;
      return state;
    },
    setSaving: (state, action: PayloadAction<boolean>) => {
      state.saving = action.payload;
      state.isDirty = true;
      return state;
    },
    updatePanelOpen: (state, action: PayloadAction<boolean>) => {
      state.panelOpen = action.payload;
      state.isDirty = true;
      return state;
    },
    chartSetDachart: (state, action: PayloadAction<{ chart: WithDaChart<IChart>; daChart: DaChart }>) => {
      const { tabs } = state;
      const { chart, daChart } = action.payload;
      const tabIndex = tabs.findIndex(t => t.chart.uuid === chart.uuid);
      const tab = state.tabs[tabIndex];
      if (HasDaChart(tab)) tab.chart.daChart = daChart;
      state.isDirty = true;
      return state;
    },
    chartSetTable: (state, action: PayloadAction<{ tabUuid: UUID; apiRef: MuiApiRef; ref: HTMLDivRef }>) => {
      const { tabs } = state;
      const { tabUuid, apiRef, ref } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      if (!tabs[tabIndex]?.chart || !IsTableTab(tabs[tabIndex])) return state;
      Object.assign(tabs[tabIndex].chart, { apiRef, ref });
      state.isDirty = true;
      return state;
    },
    setTemplate: (state, action: PayloadAction<boolean>) => {
      state.template = action.payload;
      state.isDirty = true;
      return state;
    },
    tychartAddGroup: (state, action: PayloadAction<{ tabUuid: UUID; group: ITyGroup }>) => {
      const { tabs } = state;
      const { tabUuid, group } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsTyChartTab(tab)) {
        tab.chart.groups.push(group);
        state.isDirty = true;
      }
      return state;
    },
    tychartUpdateChart: (state, action: PayloadAction<{ tabUuid: UUID; options: ITyChartProps }>) => {
      const { tabs } = state;
      const { tabUuid, options } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (!IsTyChartTab(tab)) return state;
      const newState = {
        ...state,
        tabs: [
          ...tabs.slice(0, tabIndex),
          {
            ...tab,
            chart: {
              ...tab.chart,
              ...options,
            } as ITyChart,
          },
          ...tabs.slice(tabIndex + 1),
        ],
      };
      newState.isDirty = true;
      return newState;
    },
    tychartDeleteGroup: (state, action: PayloadAction<{ tabUuid: UUID; group: ITyGroup }>) => {
      const { tabs } = state;
      const { tabUuid, group } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsTyChartTab(tab)) {
        const groupIndex = tab.chart.groups.findIndex(g => g.uuid === group.uuid);
        tab.chart.groups.splice(groupIndex, 1);
      }
      return state;
    },
    tychartUpdateGroup: (state, action: PayloadAction<{ tabUuid: UUID; groupUuid: UUID; options: ITyGroupProps }>) => {
      const { tabs } = state;
      const { tabUuid, groupUuid, options } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsTyChartTab(tab)) {
        const groupIndex = tab.chart.groups.findIndex(g => g.uuid === groupUuid);
        tab.chart.groups[groupIndex] = { ...tab.chart.groups[groupIndex], ...options };
        state.isDirty = true;
      }
      return state;
    },
    tychartUpdateGroups: (state, action: PayloadAction<{ tabUuid: UUID; groups: ITyGroup[] }>) => {
      const { tabs } = state;
      const { tabUuid, groups } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsTyChartTab(tab)) {
        tab.chart.groups = groups;
        state.isDirty = true;
      }
      return state;
    },
    tychartDeleteVariable: (state, action: PayloadAction<{ tabUuid: UUID; group: ITyGroup; variable: ITyVariable }>) => {
      const { tabs } = state;
      const { tabUuid, group: oldGroup, variable } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsTyChartTab(tab)) {
        const groupIndex = tab.chart.groups.findIndex(g => g.uuid === oldGroup.uuid);
        const group: ITyGroup = {
          ...tab.chart.groups[groupIndex],
          variables: tab.chart.groups[groupIndex].variables.filter(v => v.uuid !== variable.uuid),
        };
        const groups: ITyGroup[] = group.variables.length
          ? [...tab.chart.groups.slice(0, groupIndex), group, ...tab.chart.groups.slice(groupIndex + 1)]
          : [...tab.chart.groups.slice(0, groupIndex), ...tab.chart.groups.slice(groupIndex + 1)];
        if (!group.variables.length) {
          group.yAxis.remove();
        }
        tab.chart.groups = groups;
        state.isDirty = true;
      }
      return state;
    },
    tychartUpdateVariable: (
      state,
      action: PayloadAction<{ tabUuid: UUID; groupUuid: UUID; variableUuid: UUID; options: ITyVariableProps }>,
    ) => {
      const { tabs } = state;
      const { tabUuid, groupUuid, variableUuid, options } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = state.tabs[tabIndex];
      if (IsTyChartTab(tab)) {
        const groupIndex = tab.chart.groups.findIndex(g => g.uuid === groupUuid);
        const group = tab.chart.groups[groupIndex];
        if (group) {
          const variableIndex = group.variables.findIndex(v => v.uuid === variableUuid);
          const variable = group.variables[variableIndex];
          if (variable) {
            Object.assign(variable, options);
          }
        }
      }
      state.isDirty = true;
      return state;
    },
    updateVariableStats: (state, action: PayloadAction<{ uuid: UUID; statistics: CalculationStats | null }>) => {
      state.statistics[action.payload.uuid] = action.payload.statistics;
    },
    livechartUpdateChart: (state, action: PayloadAction<{ tabUuid: UUID; options: ILiveChartProps }>) => {
      const { tabs } = state;
      const { tabUuid, options } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsLiveChartTab(tab)) {
        const newState = {
          ...state,
          tabs: [
            ...tabs.slice(0, tabIndex),
            {
              ...tab,
              chart: {
                ...tab.chart,
                ...options,
              } as ILiveChart,
            },
            ...tabs.slice(tabIndex + 1),
          ],
        };
        newState.isDirty = true;
        return newState;
      }
      return state;
    },
    livechartAddVariable: (state, action: PayloadAction<ILiveVariable>) => {
      const { selectedTab, tabs } = state;
      const tabIndex = tabs.findIndex(t => t.uuid === selectedTab);
      const tab = tabs[tabIndex];
      if (IsLiveChartTab(tab)) {
        const newState = {
          ...state,
          tabs: [
            ...tabs.slice(0, tabIndex),
            {
              ...tab,
              chart: {
                ...tab.chart,
                variables: [...tab.chart.variables, action.payload],
              } as ILiveChart,
            },
            ...tabs.slice(tabIndex + 1),
          ],
        };
        newState.isDirty = true;
        return newState;
      }
      return state;
    },
    livechartDeleteVariable: (state, action: PayloadAction<ILiveVariable>) => {
      const { selectedTab, tabs } = state;
      const tabIndex = tabs.findIndex(t => t.uuid === selectedTab);
      const tab = tabs[tabIndex];
      if (IsLiveChartTab(tab)) {
        const variables = tab.chart.variables.filter(v => v.uuid !== action.payload.uuid);
        const newState = {
          ...state,
          tabs: [
            ...tabs.slice(0, tabIndex),
            {
              ...tab,
              chart: {
                ...tab.chart,
                variables,
              } as ILiveChart,
            },
            ...tabs.slice(tabIndex + 1),
          ],
        };
        newState.isDirty = true;
        return newState;
      }
      return state;
    },
    livechartUpdateVariable: (state, action: PayloadAction<{ tabUuid: UUID; variableUuid: UUID; options: ILiveVariableProps }>) => {
      const { tabs } = state;
      const { tabUuid, variableUuid, options } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsLiveChartTab(tab)) {
        const variableIndex = tab.chart.variables.findIndex(v => v.uuid === variableUuid);
        const newState = {
          ...state,
          tabs: [
            ...tabs.slice(0, tabIndex),
            {
              ...tab,
              chart: {
                ...tab.chart,
                variables: [
                  ...tab.chart.variables.slice(0, variableIndex),
                  {
                    ...tab.chart.variables[variableIndex],
                    ...options,
                  },
                  ...tab.chart.variables.slice(variableIndex + 1),
                ],
              } as ILiveChart,
            },
            ...tabs.slice(tabIndex + 1),
          ],
        };
        newState.isDirty = true;
        return newState;
      }
      return state;
    },
    barchartUpdateGroupby: (state, action: PayloadAction<AnyGroupBy>) => {
      const { selectedTab, tabs } = state;
      const tabIndex = tabs.findIndex(t => t.uuid === selectedTab);
      const tab = tabs[tabIndex];
      if (IsBarChartTab(tab)) {
        const newState = {
          ...state,
          tabs: [
            ...tabs.slice(0, tabIndex),
            {
              ...tab,
              chart: {
                ...tab.chart,
                groupBy: action.payload,
              } as IBarChart,
            },
            ...tabs.slice(tabIndex + 1),
          ],
        };
        newState.isDirty = true;
        return newState;
      }
      return state;
    },
    histogramAddVariable: (state, action: PayloadAction<IHistogramVariable>) => {
      const { selectedTab, tabs } = state;
      const tabIndex = tabs.findIndex(t => t.uuid === selectedTab);
      const tab = tabs[tabIndex];
      if (IsHistogramTab(tab)) {
        const newState = {
          ...state,
          tabs: [
            ...tabs.slice(0, tabIndex),
            {
              ...tab,
              chart: {
                ...tab.chart,
                variable: action.payload,
              } as IHistogram,
            },
            ...tabs.slice(tabIndex + 1),
          ],
        };
        newState.isDirty = true;
        return newState;
      }
      return state;
    },
    histogramDeleteVariable: state => {
      const { selectedTab, tabs } = state;
      const tabIndex = tabs.findIndex(t => t.uuid === selectedTab);
      const tab = tabs[tabIndex];
      if (IsHistogramTab(tab)) {
        const newState = {
          ...state,
          tabs: [
            ...tabs.slice(0, tabIndex),
            {
              ...tab,
              chart: {
                ...tab.chart,
                variable: null,
              } as IHistogram,
            },
            ...tabs.slice(tabIndex + 1),
          ],
        };
        newState.isDirty = true;
        return newState;
      }
      return state;
    },
    histogramUpdateVariable: (state, action: PayloadAction<{ tabUuid: UUID; options: IHistogramVariableProps }>) => {
      const { tabs } = state;
      const { options, tabUuid } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = state.tabs[tabIndex];
      if (IsHistogramTab(tab)) {
        tab.chart.variable = {
          ...tab.chart.variable,
          ...options,
        };
        // Object.assign(tab.chart.variable, options);
      }
      state.isDirty = true;
      return state;
    },
    xychartAddYvariable: (state, action: PayloadAction<IXyVariable>) => {
      const { selectedTab, tabs } = state;
      const tabIndex = tabs.findIndex(t => t.uuid === selectedTab);
      const tab = tabs[tabIndex];
      if (IsXyChartTab(tab)) {
        const newState = {
          ...state,
          tabs: [
            ...tabs.slice(0, tabIndex),
            {
              ...tab,
              chart: {
                ...tab.chart,
                yVariables: [...tab.chart.yVariables, action.payload],
              } as IXyChart,
            },
            ...tabs.slice(tabIndex + 1),
          ],
        };
        newState.isDirty = true;
        return newState;
      }
      return state;
    },
    xychartDeleteYvariable: (state, action: PayloadAction<IXyVariable>) => {
      const { selectedTab, tabs } = state;
      const tabIndex = tabs.findIndex(t => t.uuid === selectedTab);
      const tab = tabs[tabIndex];
      if (IsXyChartTab(tab)) {
        const yVariables = tab.chart.yVariables.filter(v => v.uuid !== action.payload.uuid);
        const newState = {
          ...state,
          tabs: [
            ...tabs.slice(0, tabIndex),
            {
              ...tab,
              chart: {
                ...tab.chart,
                yVariables,
              } as IXyChart,
            },
            ...tabs.slice(tabIndex + 1),
          ],
        };
        newState.isDirty = true;
        return newState;
      }
      return state;
    },
    xychartAddXvariable: (state, action: PayloadAction<IXyChart['xVariable']>) => {
      const { selectedTab, tabs } = state;
      const tabIndex = tabs.findIndex(t => t.uuid === selectedTab);
      const tab = tabs[tabIndex];
      if (IsXyChartTab(tab)) {
        const newState = {
          ...state,
          tabs: [
            ...tabs.slice(0, tabIndex),
            {
              ...tab,
              chart: {
                ...tab.chart,
                xVariable: action.payload,
              } as IXyChart,
            },
            ...tabs.slice(tabIndex + 1),
          ],
        };
        newState.isDirty = true;
        return newState;
      }
      return state;
    },
    xychartDeleteXvariable: state => {
      const { selectedTab, tabs } = state;
      const tabIndex = tabs.findIndex(t => t.uuid === selectedTab);
      const tab = tabs[tabIndex];
      if (IsXyChartTab(tab)) {
        const newState = {
          ...state,
          tabs: [
            ...tabs.slice(0, tabIndex),
            {
              ...tab,
              chart: {
                ...tab.chart,
                xVariable: null,
              } as IXyChart,
            },
            ...tabs.slice(tabIndex + 1),
          ],
        };
        newState.isDirty = true;
        return newState;
      }
      return state;
    },
    xychartUpdateGroupby: (state, action: PayloadAction<AnyGroupBy>) => {
      const { selectedTab, tabs } = state;
      const tabIndex = tabs.findIndex(t => t.uuid === selectedTab);
      const tab = tabs[tabIndex];
      if (IsXyChartTab(tab)) {
        const newState = {
          ...state,
          tabs: [
            ...tabs.slice(0, tabIndex),
            {
              ...tab,
              chart: {
                ...tab.chart,
                groupBy: action.payload,
              } as IXyChart,
            },
            ...tabs.slice(tabIndex + 1),
          ],
        };
        newState.isDirty = true;
        return newState;
      }
      return state;
    },
    xychartUpdateYvariable: (state, action: PayloadAction<{ tabUuid: UUID; variableUuid: UUID; options: IXyVariableProps }>) => {
      const { tabs } = state;
      const { tabUuid, variableUuid, options } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsXyChartTab(tab)) {
        const yVariableIndex = tab.chart.yVariables.findIndex(v => v.uuid === variableUuid);
        const yVariable = tab.chart.yVariables[yVariableIndex];
        const newState = {
          ...state,
          tabs: [
            ...tabs.slice(0, tabIndex),
            {
              ...tab,
              chart: {
                ...tab.chart,
                yVariables: [
                  ...tab.chart.yVariables.slice(0, yVariableIndex),
                  {
                    ...yVariable,
                    ...options,
                  },
                  ...tab.chart.yVariables.slice(yVariableIndex + 1),
                ],
              } as IXyChart,
            },
            ...tabs.slice(tabIndex + 1),
          ],
        };
        newState.isDirty = true;
        return newState;
      }
      return state;
    },
    xychartUpdateXvariable: (state, action: PayloadAction<{ tabUuid: UUID; options: IXyVariableProps }>) => {
      const { tabs } = state;
      const { tabUuid, options } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsXyChartTab(tab) && !isXVariableIndex(tab.chart.xVariable)) {
        const newState = {
          ...state,
          tabs: [
            ...tabs.slice(0, tabIndex),
            {
              ...tab,
              chart: {
                ...tab.chart,
                xVariable: {
                  ...tab.chart.xVariable,
                  ...options,
                },
              } as IXyChart,
            },
            ...tabs.slice(tabIndex + 1),
          ],
        };
        newState.isDirty = true;
        return newState;
      }
      return state;
    },
    tableSetLoading: (state, action: PayloadAction<{ tabUuid: UUID; isLoading: boolean }>) => {
      const { tabs } = state;
      const { tabUuid, isLoading } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsTableTab(tab)) {
        tab.chart.isLoading = isLoading;
        state.isDirty = true;
      }
      return state;
    },
    tableSetIsUserOverride: (state, action: PayloadAction<{ tabUuid: UUID; isUserOverride: boolean }>) => {
      const { tabs } = state;
      const { tabUuid, isUserOverride } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsTableTab(tab)) {
        tab.chart.isUserOverride = isUserOverride;
        state.isDirty = true;
      }
      return state;
    },
    tableAddVariable: (state, action: PayloadAction<{ tabUuid: UUID; variable: ITableVariable }>) => {
      const { tabs } = state;
      const { tabUuid, variable } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsTableTab(tab)) {
        tab.chart.variables.push(variable);
        state.isDirty = true;
        return state;
      }
      return state;
    },
    tableDeleteVariable: (state, action: PayloadAction<{ tabUuid: UUID; variableUuid: UUID }>) => {
      const { tabs } = state;
      const { tabUuid, variableUuid } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsTableTab(tab)) {
        tab.chart.variables = tab.chart.variables.filter(v => v.uuid !== variableUuid);
        state.isDirty = true;
        return state;
      }
      return state;
    },
    tableUpdateVariable: (state, action: PayloadAction<{ tabUuid: UUID; variableUuid: UUID; options: ITableVariableProps }>) => {
      const { tabs } = state;
      const { tabUuid, variableUuid, options } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsTableTab(tab)) {
        const variableIndex = tab.chart.variables.findIndex(v => v.uuid === variableUuid);
        const variable = tab.chart.variables[variableIndex];
        Object.assign(variable, options);
        state.isDirty = true;
      }
      return state;
    },
    tableSetMacro: (state, action: PayloadAction<{ tabUuid: UUID; macro: ITableMacroSettings }>) => {
      const { tabs } = state;
      const { tabUuid, macro } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsTableTab(tab)) {
        tab.chart.macro = macro;
        state.isDirty = true;
      }
      return state;
    },
    excelUpdateGroupby: (state, action: PayloadAction<{ tabUuid: UUID; groupBy: AnyGroupBy }>) => {
      state.gb = action.payload.groupBy;
      return state;
    },
    tableUpdateGroupby: (state, action: PayloadAction<{ tabUuid: UUID; groupBy: ITable['groupBy'] }>) => {
      const { tabs } = state;
      const { tabUuid, groupBy } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsTableTab(tab)) {
        tab.chart.groupBy = groupBy;
        state.isDirty = true;
      }
      return state;
    },
    tableUpdateBatchtarget: (state, action: PayloadAction<{ tabUuid: UUID; batchTarget: number }>) => {
      const { tabs } = state;
      const { tabUuid, batchTarget } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsTableTab(tab)) {
        tab.chart.batchTarget = batchTarget;
        state.isDirty = true;
      }
      return state;
    },
    tableUpdateSelectedRows: (state, action: PayloadAction<{ tabUuid: UUID; selectedRows: GridValidRowModel[] }>) => {
      const { tabs } = state;
      const { tabUuid, selectedRows } = action.payload;
      const tabIndex = tabs.findIndex(t => t.uuid === tabUuid);
      const tab = tabs[tabIndex];
      if (IsTableTab(tab)) {
        tab.chart.selectedRows = selectedRows;
        state.isDirty = true;
      }
      return state;
    },
    setAddingVariable: (state, action: PayloadAction<boolean>) => {
      state.addingVariable = action.payload;
    },
    setIsDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },
    toggleYAxisVisible: state => {
      state.withYAxisVisible = !state.withYAxisVisible;
    },
  },
});

export const {
  setPlayground,
  setPlaygroundName,
  setPlaygroundTags,
  setEdit,
  setEditingMode,
  openComment,
  editComment,
  clearPlaygroundStore,
  setSelectedTab,
  updatePlayground,
  addTab,
  removeTab,
  editTab,
  setTabs,
  setDrawSegmentButtonState,
  setCommentDisplayedRef,
  setIsCommentEdit,
  setSaving,
  updatePanelOpen,
  chartSetDachart,
  chartSetTable,
  setTemplate,
  tychartUpdateChart,
  tychartAddGroup,
  tychartDeleteGroup,
  tychartUpdateGroup,
  tychartUpdateGroups,
  tychartDeleteVariable,
  tychartUpdateVariable,
  updateVariableStats,
  livechartUpdateChart,
  livechartAddVariable,
  livechartDeleteVariable,
  livechartUpdateVariable,
  barchartUpdateGroupby,
  histogramAddVariable,
  histogramDeleteVariable,
  histogramUpdateVariable,
  xychartAddYvariable,
  xychartDeleteYvariable,
  xychartAddXvariable,
  xychartDeleteXvariable,
  xychartUpdateGroupby,
  xychartUpdateYvariable,
  xychartUpdateXvariable,
  tableSetLoading,
  tableSetIsUserOverride,
  tableAddVariable,
  tableDeleteVariable,
  tableUpdateVariable,
  tableSetMacro,
  tableUpdateGroupby,
  excelUpdateGroupby,
  tableUpdateBatchtarget,
  tableUpdateSelectedRows,
  setAddingVariable,
  setIsDirty,
  toggleYAxisVisible,
} = playgroundSlice.actions;

export { initialState as playgroundInitialState };

export default playgroundSlice.reducer;

import {
  AuthorizationHeader,
  BasicAuthentication,
  DEFAULT_VARIABLE_DATA_DELAY,
  HttpAuthenticationType,
  HttpDeviceConfig,
  QueryParamAuthentication,
} from '@dametis/core';

import { createPropertyMatcher } from './createPropertyMatcher';

export const createHttpDeviceConfig = ({
  cron = '0 0 * * * ',
  timeout = 50000,
  method = 'GET',
  contentType = 'AUTO',
  endpoints = [],
  jqTransformer = null,
  propertyMatcher = createPropertyMatcher(),
  timeZone = '',
  dateFormat = '',
  authentication = null,
  deviceHeartbeatFrequency = null,
  defaultVariableFrequency = null,
  defaultVariableDataDelay = DEFAULT_VARIABLE_DATA_DELAY,
}: Partial<HttpDeviceConfig> = {}): HttpDeviceConfig => ({
  cron,
  timeout,
  method,
  contentType,
  endpoints,
  jqTransformer,
  propertyMatcher,
  timeZone,
  dateFormat,
  authentication,
  deviceHeartbeatFrequency,
  defaultVariableFrequency,
  defaultVariableDataDelay,
});

export const createBasicAuthentication = ({
  type = HttpAuthenticationType.BASIC,
  username = '',
  password = '',
}: Partial<BasicAuthentication> = {}): BasicAuthentication => ({
  type,
  username,
  password,
});

export const createAuthorizationHeader = ({
  type = HttpAuthenticationType.AUTHORIZATION_HEADER,
  authorizationHeader = '',
}: Partial<AuthorizationHeader> = {}): AuthorizationHeader => ({
  type,
  authorizationHeader,
});

export const createQueryParamAuthentication = ({
  type = HttpAuthenticationType.QUERY_PARAM,
  key = '',
  value = '',
}: Partial<QueryParamAuthentication> = {}): QueryParamAuthentication => ({
  type,
  key,
  value,
});

import { SvgIconComponent } from '@mui/icons-material';

export enum DamIconType {
  FA = 'FontAweseome',
  MUI = 'MaterialUi',
  STRING = 'string',
}

export type DamIconProp = SvgIconComponent | string;

export enum DamIconName {
  DANGER = 'danger',
  HELP = 'help',
  WARNING = 'warning',
  INFO = 'info',
  DONE = 'done',
  SATISFIED = 'satisfied',
  UNSATISFIED = 'unsatisfied',
  EMPTY = 'empty',
  BOLT = 'bolt',
  LIGHTBULB = 'lightbulb',
  INDUSTRY = 'industry',
  PLUG = 'plug',
  POWEROFF = 'power-off',
  TINT = 'tint',
  FIRE = 'fire-alt',
  LEAF = 'leaf',
  SUN = 'sun',
  WIND = 'wind',
  FAN = 'fan',
  THERMOMETER = 'thermometer-half',
  SNOWFLAKE = 'snowflake',
  EURO = 'euro-sign',
  BELL = 'bell',
  PLAYGROUNDS = 'playgrounds',
  REPORTS = 'reports',
  SYNOPTICS = 'synoptics',
  CALENDAR = 'calendar',
  DIRECTIONS = 'directions',
  BALANCE = 'balance',
  DIRECTIONSBUS = 'directions-bus',
  ANALYTICS = 'analytics',
  BATTERY = 'battery',
  DATATHRESHOLD = 'data-threshold',
  EXPAND = 'expand',
  GRADIENT = 'gradient',
  BLOCKTYPE = 'block-type',
  RETURN = 'return',
  FILTER1 = 'filter-1',
  FILTER2 = 'filter-2',
  FILTER3 = 'filter-3',
  FILTER4 = 'filter-4',
  FILTER5 = 'filter-5',
  FILTER6 = 'filter-6',
  FILTER7 = 'filter-7',
  FILTER8 = 'filter-8',
  FILTER9 = 'filter-9',
}

export interface DamIcon {
  type: DamIconType;
  name: DamIconName | string;
  prop: DamIconProp;
}

export const IsDamIcon = (icon?: DamIcon | DamIconName): icon is DamIcon =>
  icon !== undefined &&
  typeof icon !== 'string' &&
  [DamIconType.FA, DamIconType.MUI, DamIconType.STRING].includes(icon.type) &&
  icon.name !== undefined &&
  icon.prop !== undefined;

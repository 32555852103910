import { Clear, CloseFullscreenOutlined, OpenInFullOutlined } from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  IconButtonProps,
  InputAdornment,
  FormControlProps as MuiFormControlProps,
  OutlinedInputProps as MuiOutlinedInputProps,
  OutlinedInput,
  Popover,
  Stack,
  Tooltip,
  Typography,
  drawerClasses,
} from '@mui/material';
import {
  FC,
  MouseEventHandler,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { IsEmptyCalculation } from '@dametis/core';

import { createCalculationVariable } from 'functions/createCalculationVariable';

import CalculationSlate from '../UI/CalculationSlate/CalculationSlate';

import VncDialog from './VncDialog';
import HelperText from './components/VncInput/HelperText';
import { FormLabel } from './components/VncInput/styled';
import { vncDefaultProps } from './props';
import { VncProps } from './types';

interface Props extends VncProps {
  open?: boolean;
  editing?: boolean;
  enableNickname?: boolean;
  label?: MuiOutlinedInputProps['label'];
  helperText?: ReactNode;
  error?: MuiOutlinedInputProps['error'];
  FormControlProps?: Omit<MuiFormControlProps, 'value' | 'onChange' | 'error'>;
  OutlinedInputProps?: Omit<MuiOutlinedInputProps, 'onChange' | 'variant' | 'label' | 'error'>;
}

const defaultFormControlProps: Props['FormControlProps'] = {};
const defaultOutlinedInputProps: Props['OutlinedInputProps'] = {};

const VncInput: FC<Props> = ({
  open = false,
  editing = true,
  enableNickname = false,
  label = undefined,
  helperText = undefined,
  error = false,
  FormControlProps = defaultFormControlProps,
  OutlinedInputProps = defaultOutlinedInputProps,
  ...vncDialogProps
}) => {
  const { value, onChange, unitPicker = vncDefaultProps.unitPicker, disableMaths = vncDefaultProps.disableMaths } = vncDialogProps;

  const { t } = useTranslation('vnc');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [popperOpen, setPopperOpen] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const [horizontal, setHorizontal] = useState<'left' | 'right'>('left');

  const inputRef = useRef<HTMLDivElement>(null);
  const inputId = useId();

  const emptyCalculation = useMemo(() => createCalculationVariable(), []);
  const displayedUnit = useMemo(
    () => (unitPicker && value?.unit !== undefined && value.unit !== null && value.unit.trim().length > 0 ? value.unit.trim() : ''),
    [unitPicker, value?.unit],
  );
  const isEmptyCalculation = useMemo(() => IsEmptyCalculation(value), [value]);

  const handleMouseOver = useCallback(() => {
    setIsMouseOver(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsMouseOver(false);
  }, []);

  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const clearInput = useCallback<MouseEventHandler<HTMLButtonElement>>(
    e => {
      e.stopPropagation();
      onChange(emptyCalculation);
    },
    [onChange, emptyCalculation],
  );

  const closePopper = useCallback(() => {
    setPopperOpen(false);
  }, []);

  const togglePopper = useCallback<NonNullable<IconButtonProps['onClick']>>(
    event => {
      event.stopPropagation();
      setPopperOpen(o => !o);
    },
    [setPopperOpen],
  );

  useEffect(() => {
    setDialogOpen(open);
  }, [open]);

  useLayoutEffect(() => {
    const boundingClientRect = inputRef.current?.getBoundingClientRect();
    const newHorizontal = (boundingClientRect?.left ?? 0) - 1 <= window.innerWidth - (boundingClientRect?.right ?? 0) ? 'left' : 'right';
    setHorizontal(newHorizontal);
  }, []);

  return (
    <>
      <FormControl
        fullWidth
        {...FormControlProps}
        sx={[
          {
            maxWidth: 1,
            '&:hover .unit': { display: 'none' },
            '&:hover .buttons-edit': { display: 'initial' },
          },
          ...(Array.isArray(FormControlProps.sx) ? FormControlProps.sx : [FormControlProps.sx]),
        ]}
      >
        {label !== '' && <FormLabel htmlFor={inputId}>{label ?? t('input.label.data')}</FormLabel>}
        <div ref={inputRef}>
          {editing && (
            <OutlinedInput
              endAdornment={
                Boolean(displayedUnit.length) || !isEmptyCalculation ? (
                  <InputAdornment position="end">
                    {Boolean(displayedUnit.length) && <span className="unit">{displayedUnit}</span>}
                    {!isEmptyCalculation && (
                      <Stack alignItems="center" className="buttons-edit" direction="row" display="none">
                        <Tooltip title={t(popperOpen ? 'tooltip.closePopper' : 'tooltip.openPopper')}>
                          <IconButton size="small" onClick={togglePopper}>
                            {popperOpen ? (
                              <CloseFullscreenOutlined fontSize="small" sx={[horizontal === 'left' && { transform: 'rotate(90deg)' }]} />
                            ) : (
                              <OpenInFullOutlined fontSize="small" sx={[horizontal === 'left' && { transform: 'rotate(90deg)' }]} />
                            )}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t('tooltip.clear')}>
                          <IconButton size="small" onClick={clearInput}>
                            <Clear />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    )}
                  </InputAdornment>
                ) : undefined
              }
              error={error}
              id={inputId}
              inputComponent={
                isEmptyCalculation
                  ? undefined
                  : // eslint-disable-next-line react/no-unstable-nested-components,react/display-name
                    forwardRef((props, ref) => (
                      <Box ref={ref} maxHeight={100} overflow="auto" p={editing ? 1 : undefined} width={1}>
                        <Box sx={{ width: 'max-content' }}>
                          <CalculationSlate calculation={value} />
                        </Box>
                      </Box>
                    ))
              }
              placeholder={
                OutlinedInputProps.placeholder ?? t('input.placeholder.input', { context: disableMaths ? 'disableMaths' : undefined })
              }
              value=""
              onClick={openDialog}
              {...OutlinedInputProps}
              sx={[
                {
                  position: 'relative',
                  width: 1,
                  zIndex: 1,
                  [`.${drawerClasses.paper} &`]: { zIndex: theme => theme.zIndex.drawer + 1 },
                },
                popperOpen && { opacity: 0 },
                ...(Array.isArray(OutlinedInputProps.sx) ? OutlinedInputProps.sx : [OutlinedInputProps.sx]),
              ]}
            />
          )}
          {!editing && !isEmptyCalculation && (
            <Stack
              direction="row"
              maxHeight={100}
              minHeight={30}
              minWidth={150}
              position="relative"
              width={1}
              onMouseEnter={handleMouseOver}
              onMouseLeave={handleMouseLeave}
            >
              <Box flexGrow={1} overflow="auto">
                <Box width="max-content">
                  <CalculationSlate calculation={value} />
                </Box>
              </Box>
              <Tooltip title={t('tooltip.openPopper')}>
                <Box
                  bgcolor="white"
                  borderRadius={1}
                  ml={1}
                  position="absolute"
                  right={0}
                  visibility={!popperOpen && isMouseOver ? 'visible' : 'hidden'}
                >
                  <IconButton size="small" onClick={togglePopper}>
                    <OpenInFullOutlined fontSize="small" sx={[horizontal === 'left' && { transform: 'rotate(90deg)' }]} />
                  </IconButton>
                </Box>
              </Tooltip>
            </Stack>
          )}
          {!editing && isEmptyCalculation && <Typography>{t('global:text.noValue')}</Typography>}
        </div>
        {editing && <HelperText enableNickname={enableNickname} helperText={helperText} value={value} onChange={onChange} />}
      </FormControl>
      <Popover
        disableRestoreFocus
        anchorEl={inputRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal,
        }}
        open={popperOpen}
        slotProps={{ paper: { sx: [{ borderRadius: 1 }, !editing && { m: -1, p: 1 }] } }}
        transformOrigin={{
          vertical: 'top',
          horizontal,
        }}
        TransitionProps={{ timeout: 0 }}
        onClose={closePopper}
      >
        {editing ? (
          <OutlinedInput
            error={error}
            inputComponent={
              isEmptyCalculation
                ? undefined
                : // eslint-disable-next-line react/display-name,react/no-unstable-nested-components
                  forwardRef((props, ref) => (
                    <Box ref={ref} minWidth={inputRef.current?.clientWidth ?? 150} overflow="auto" p={1} width={1}>
                      <Box maxWidth={1000}>
                        <CalculationSlate calculation={value} />
                      </Box>
                    </Box>
                  ))
            }
            placeholder={
              OutlinedInputProps.placeholder ?? t('input.placeholder.input', { context: disableMaths ? 'disableMaths' : undefined })
            }
            value=""
            onClick={openDialog}
            {...OutlinedInputProps}
            sx={[
              {
                position: 'relative',
                width: 1,
              },
            ]}
          />
        ) : (
          <Box minWidth={150} overflow="auto" width={1}>
            <Box maxWidth={1000}>
              <CalculationSlate calculation={value} />
            </Box>
          </Box>
        )}
      </Popover>
      <VncDialog {...vncDialogProps} open={dialogOpen} setOpen={setDialogOpen} />
    </>
  );
};

export default VncInput;

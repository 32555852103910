import deepEqual from 'deep-equal';
import deepmerge from 'deepmerge';
import type { editor } from 'monaco-editor';
import { StoreApi } from 'zustand';

import {
  CalculationVariableProps,
  RawSearchVariablesInGroupBody,
  RawSearchVariablesInSiteBody,
  TinyVariableSearchInfo,
  UUID,
  VarCalc,
} from '@dametis/core';
import { Request } from '@dametis/sdk';

import { InfluentVariablesState } from 'components/VNC/components/Filters/InfluentVariablesFilters';
import { VariableResult } from 'components/VNC/types';
import { getDefaultGroupByForOperator } from 'functions/tada/getGroupBy';
import { sdk } from 'sdk';
import { TypedThunk } from 'store';
import { selectStandardBlocks } from 'store/api/standardBlocks';
import { selectTags } from 'store/api/tags';
import { AllVariableKind, IsStandardKind } from 'types/variables';

import { AreSameVarCalc } from '../../functions/tada/helpers.ts';
import { AvailableVncFilters, FocusedFunction, TextareaMode, VncFilters, VncState, initialVncState } from '../states/vnc';
import { VncStore } from '../stores/vnc';

export interface VncActions {
  clearStore: () => void;
  setCalcVarProp: (props: CalculationVariableProps) => void;
  setCalcVarProps: (props: CalculationVariableProps) => void;
  setTextareaMode: (textareaMode: TextareaMode) => void;
  setMonacoEditor: (monacoEditor: editor.IStandaloneCodeEditor) => void;
  setResultsVariables: (resultsVariables: VariableResult[]) => void;
  setLoadingResults: (loadingResults: boolean) => void;
  setFilters: (filters: Partial<VncFilters>) => void;
  setFiltersValues: (filters: Partial<VncFilters>) => void;
  setFilterValue: (filter: keyof Omit<VncFilters, 'isCorporate'>, key: string, value: boolean) => void;
  setLoadingFilters: (loadingFilters: boolean) => void;
  setSearch: (search: string) => void;
  setSelection: (selection: VarCalc[]) => void;
  addToSelection: (varCalc: VarCalc) => void;
  removeFromSelection: (varCalc: VarCalc) => void;
  removeByUuidFromSelection: (varCalc: VarCalc) => void;
  editSelectionItem: (index: number, varCalc: VarCalc) => void;
  clearSelection: () => void;
  setSelectedStandardBlockUuid: (selectedStandardBlockUuid: UUID | null) => void;
  setSelectedFolderUuid: (selectedFolderUuid: UUID | null) => void;
  setSelectedBlockUuid: (selectedBlockUuid: UUID | null) => void;
  setUnPostedBlockType: (unPostedBlockType: VncState['lego']['unPostedBlockType']) => void;
  setIsCalculationValid: (isCalculationValid: boolean) => void;
  setSelectedModelSimulationUuid: (selectedModelSimulationUuid: UUID | null) => void;
  setSelectedModelUuid: (selectedModelUuid: UUID | null) => void;
  setCovarianceVariable: (covarianceVariable: VncState['covarianceVariable']) => void;
  setFocusedFunction: (focusedFunction: FocusedFunction) => void;
  getResultsVariables: (controller?: AbortController) => TypedThunk<Promise<void>>;
  getResults: (controller?: AbortController) => TypedThunk<Promise<void>>;
  getFilters: (availableFilters: Partial<AvailableVncFilters>, defaultFilters: Partial<VncFilters>) => TypedThunk<void>;
}

const booleansObjectToString = <T extends string | number | symbol>(obj: Partial<Record<T, boolean>>): string =>
  Object.entries(obj)
    .filter(([, val]) => val)
    .map(([key]) => key)
    .join(',');

const arrayToBooleansObject = <T extends string | number | symbol>(
  array: T[],
  defaults: Partial<Record<T, boolean>> | undefined,
): Record<T, boolean> =>
  array.reduce<Record<T, boolean>>(
    (acc, val) => {
      acc[val] = defaults?.[val] ?? false;
      return acc;
    },
    {} as Record<T, boolean>,
  );

export const createVncActions = (set: StoreApi<VncStore>['setState'], get: StoreApi<VncStore>['getState']): VncActions => ({
  clearStore: () => {
    set(state => ({ ...initialVncState, lego: state.lego }));
  },
  setCalcVarProp: props => {
    set(state => ({ calcVarProps: { ...state.calcVarProps, ...props } }));
  },
  setCalcVarProps: calcVarProps => {
    set(() => ({ calcVarProps }));
  },
  setTextareaMode: textareaMode => {
    set({ textareaMode });
  },
  setMonacoEditor: monacoEditor => {
    set({ monacoEditor });
  },
  setResultsVariables: resultsVariables => {
    set(state => ({ results: { ...state.results, variables: resultsVariables } }));
  },
  setLoadingResults: loadingResults => {
    set({ loadingResults });
  },
  setFilters: filters => {
    set(state => ({ filters: { ...state.filters, ...filters } }));
  },
  setFiltersValues: filters => {
    set(state => ({ filters: deepmerge<VncFilters>(state.filters, filters) }));
  },
  setFilterValue: (filter, key, value) => {
    set(state => ({ filters: { ...state.filters, [filter]: { ...state.filters[filter], [key]: value } } }));
  },
  setLoadingFilters: loadingFilters => {
    set({ loadingFilters });
  },
  setSearch: search => {
    set({ search: search.trim().replace(/['"]+/g, '') });
  },
  setSelection: selection => {
    set({ selection });
  },
  addToSelection: varCalc => {
    const groupBy = getDefaultGroupByForOperator(varCalc.operator);
    set(state => ({ selection: [...state.selection, { ...(groupBy && { groupBy }), ...varCalc }] }));
  },
  removeFromSelection: varCalc => {
    set(state => ({ selection: state.selection.filter(selection => !deepEqual(selection, varCalc)) }));
  },
  removeByUuidFromSelection: varCalc => {
    set(state => ({ selection: state.selection.filter(selection => !AreSameVarCalc(varCalc, selection)) }));
  },
  editSelectionItem: (index, varCalc) => {
    set(state => ({ selection: state.selection.map((s, i) => (i === index ? varCalc : s)) }));
  },
  clearSelection: () => {
    set({ selection: [] });
  },
  setSelectedStandardBlockUuid: selectedStandardBlockUuid => {
    set(state => ({ lego: { ...state.lego, selectedStandardBlockUuid } }));
  },
  setSelectedFolderUuid: selectedFolderUuid => {
    set(state => ({ lego: { ...state.lego, selectedFolderUuid } }));
  },
  setSelectedBlockUuid: selectedBlockUuid => {
    set(state => ({ lego: { ...state.lego, selectedBlockUuid } }));
  },
  setUnPostedBlockType: unPostedBlockType => {
    set(state => ({ lego: { ...state.lego, unPostedBlockType } }));
  },
  setIsCalculationValid: isCalculationValid => {
    set({ isCalculationValid });
  },
  setSelectedModelUuid: selectedModelUuid => {
    set(state => ({ models: { ...state.models, selectedModelUuid } }));
  },
  setSelectedModelSimulationUuid: selectedModelSimulationUuid => {
    set(state => ({ models: { ...state.models, selectedModelSimulationUuid } }));
  },
  setCovarianceVariable: covarianceVariable => {
    set({ covarianceVariable });
  },
  getResultsVariables: (controller?: AbortController) => async (dispatch, getState) => {
    const {
      auth: { selectedSite, selectedGroup, user },
      period: {
        present: { period },
      },
      variables: { byId: variables },
    } = getState();
    const {
      search,
      results: { variables: resultsVariables },
      filters: { physicalQuantities, sites, kinds, tags, isCorporate },
      covarianceVariable,
      setLoadingResults,
      setResultsVariables,
    } = get();
    if (!resultsVariables.length) {
      setLoadingResults(true);
    }
    const siteId = selectedSite?.uuid;
    const groupId = selectedGroup!.uuid;
    const physicalQuantitiesString = booleansObjectToString(physicalQuantities);
    const siteIdsString = booleansObjectToString({ ...sites, NULL: isCorporate });
    const kindsString = booleansObjectToString({ ...kinds });
    const tagIdsString = booleansObjectToString(tags);
    const req: Request<RawSearchVariablesInSiteBody | RawSearchVariablesInGroupBody, never> = {
      params: {
        q: search,
        limit: '100',
        physicalQuantities: physicalQuantitiesString,
        siteIds: siteIdsString,
        kinds: kindsString,
        tagIds: tagIdsString,
        variableUuid: covarianceVariable?.variableUuid,
        highlight: user!.preferences.vncHighlight ? 'true' : undefined,
        ...(covarianceVariable?.variableUuid ? period.Raw() : {}),
        ...(kinds.ALARM && { isFromAlarm: 'true', kinds: '' }),
        ...(kinds.BATCH && { isFromBatch: 'true', kinds: '' }),
        ...(kinds.VAPOR_MIX && { isFromOpco: 'true', kinds: '' }),
      },
      signal: controller?.signal,
    };
    try {
      let searchVariables: TinyVariableSearchInfo[] = [];
      if (Object.values(kinds).some(Boolean)) {
        ({ data: searchVariables } = await (siteId
          ? sdk.variable.SearchInSite(siteId, req as Request<RawSearchVariablesInSiteBody, never>)
          : sdk.corporate.SearchVariables(groupId, req as Request<RawSearchVariablesInGroupBody, never>)));
      }
      const variableInfos = searchVariables.reduce<VariableResult[]>((acc, searchVariable) => {
        const variable = variables[searchVariable.uuid];
        if (variable !== undefined) {
          acc.push({
            ...variable,
            score: searchVariable.score,
            dismissed: searchVariable.dismissed,
            sScore: searchVariable.sScore,
            highlight: searchVariable.highlight,
          });
        }
        return acc;
      }, []);
      setResultsVariables(variableInfos);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingResults(false);
    }
  },
  getResults: (controller?: AbortController) => async dispatch => {
    await dispatch(get().getResultsVariables(controller));
  },
  getFilters: (availableFilters: Partial<AvailableVncFilters>, defaultFilters: Partial<VncFilters>) => (_dispatch, getState) => {
    const { setLoadingFilters, setFilters } = get();
    setLoadingFilters(true);
    const {
      variables: { availableVariableFlags: flags },
    } = getState();

    const { data: standardBlocks = [] } = selectStandardBlocks()(getState());
    const { data: tags = [] } = selectTags()(getState());

    try {
      setFilters({
        physicalQuantities: arrayToBooleansObject(flags.physicalQuantities, defaultFilters.physicalQuantities),
        sites: arrayToBooleansObject(flags.sites, defaultFilters.sites),
        kinds: flags.kinds
          .filter(kind => availableFilters.kinds?.includes(kind) ?? false)
          .reduce<Partial<Record<AllVariableKind, boolean>>>((accu, kind) => {
            accu[kind] = defaultFilters.kinds ? Boolean(defaultFilters.kinds[kind]) : IsStandardKind(kind);
            return accu;
          }, {}),
        standardBlocks: arrayToBooleansObject(
          standardBlocks.map(standardBlock => standardBlock.uuid),
          defaultFilters.standardBlocks,
        ),
        tags: arrayToBooleansObject(
          tags.map(siteTag => siteTag.uuid),
          defaultFilters.tags,
        ),
        influentVariables: arrayToBooleansObject(Object.values(InfluentVariablesState), defaultFilters.influentVariables),
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingFilters(false);
    }
  },
  setFocusedFunction: focusedFunction => {
    set({ focusedFunction });
  },
});

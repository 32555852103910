import { AdminPanelSettingsOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

const AdminButton: FC = () => (
  <Button
    component={NavLink}
    size="small"
    startIcon={<AdminPanelSettingsOutlined />}
    sx={{ color: 'text.secondary' }}
    to="/admin/variables"
  >
    Admin
  </Button>
);

export default AdminButton;

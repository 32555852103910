export default {
  login: 'Conexión',
  changePassword: 'Cambiar tu contraseña',
  activeAccount: 'Activación de tu cuenta',
  home: 'Inicio',
  account: 'Cuenta',
  alarms: 'Alarmas',
  batches: 'Batchs',
  batch: 'Batch',
  box: 'Caja',
  groupBoxes: 'Cajas',
  activeAlarms: 'Alarmas activas',
  alarmsHistory: 'Histórico',
  alarmsSettings: 'Ajustes',
  alarm: 'Alarma',
  boxes: 'Estado de los módulos',
  configuration: 'Configuración',
  corporate: 'Corporate',
  dashboards: 'Paneles de control',
  dataHistory: 'Histórico de datos',
  device: 'Aparato',
  group: 'Grupo',
  lego: 'Diagrama de bloques',
  blockTypes: 'Tipos de bloques',
  blocks: 'Bloques',
  playgrounds: 'Playgrounds',
  playground: 'Playground',
  reports: 'Informes',
  report: 'Informe',
  site: 'Sitio',
  synoptics: 'Sinópticos',
  synoptic: 'Sinóptico',
  projects: 'Proyectos',
  project: 'Proyecto',
  energies: 'Energías',
  architecture: 'Arquitectura',
  variables: 'Variables',
  settings: 'Configuración',
  fileImport: 'Importar datos',
  dataImport: 'Importar',
  dataExport: 'Exportar',
  manualEntry: 'Entrada manual',
  advancedEntry: 'Corrección de datos',
  history: 'Histórico',
  models: 'Modelos',
  model: 'Modelo',
  admin: {
    activities: 'Actividades',
    backup: 'Backups',
    boxes: 'Cajas',
    debuggus: 'Debuggus',
    roles: 'Funciones',
    users: 'Usuarios',
    variables: 'Variables',
    groups: 'Grupos',
    sites: 'Sitios',
    authentications: 'Autenticaciones',
    dataHistory: 'Histórico de datos',
    misc: 'Diversos',
    exploration: 'Exploración',
  },
  monitoring: 'Monitoring',
  billing: 'Facturación',
  bills: 'Facturas',
  bill: 'Factura',
  contract: 'Contrato',
  contracts: 'Contratos',
  tags: 'Tags',
  steamMixes: 'Mezclas de vapor',
};

export default {
  login: 'Connexion',
  changePassword: 'Changer votre mot de passe',
  activeAccount: 'Activation de votre compte',
  home: 'Accueil',
  account: 'Compte',
  alarms: 'Alarmes',
  batches: 'Batchs',
  batch: 'Batch',
  box: 'Box',
  groupBoxes: 'Boxes',
  activeAlarms: 'Alarmes actives',
  alarmsHistory: 'Historique',
  alarmsSettings: 'Paramètres',
  alarm: 'Alarme',
  boxes: 'État des modules',
  configuration: 'Configuration',
  corporate: 'Corporate',
  dashboards: 'Dashboards',
  device: 'Appareil',
  dataHistory: 'Historique de données',
  group: 'Groupe',
  lego: 'Schéma-bloc',
  blockTypes: 'Catalogue des types',
  blocks: 'Blocs',
  playgrounds: 'Playgrounds',
  playground: 'Playground',
  reports: 'Rapports',
  report: 'Rapport',
  site: 'Site',
  synoptics: 'Synoptiques',
  synoptic: 'Synoptique',
  projects: 'Projets',
  project: 'Projet',
  energies: 'Énergies',
  devices: 'Équipements',
  variables: 'Variables',
  settings: 'Configuration',
  fileImport: 'Import de données',
  manualEntry: 'Saisie manuelle',
  advancedEntry: 'Correction de données',
  dataExport: 'Exporter',
  history: 'Historique',
  models: 'Modèles',
  model: 'Modèle',
  admin: {
    activities: 'Activités',
    backup: 'Sauvegardes',
    boxes: 'Boxes',
    debuggus: 'Debuggus',
    roles: 'Rôles',
    users: 'Utilisateurs',
    variables: 'Variables',
    groups: 'Groupes',
    sites: 'Sites',
    authentications: 'Authentications',
    dataHistory: 'Historique de données',
    misc: 'Divers',
    maintenance: 'Maintenance',
    exploration: 'Exploration',
  },
  monitoring: 'Monitoring',
  billing: 'Facturation',
  bills: 'Factures',
  bill: 'Facture',
  contract: 'Contrat',
  contracts: 'Contrats',
  tags: 'Tags',
  steamMixes: 'Optimisation',
};

import { VariableKind } from '@dametis/core';

import { FakeVariableKind } from 'types/variables';

export default {
  Error: {
    sameVarSelect: 'Por favor seleccione una variable diferente',
    variableListNameRequired: 'Se requiere un nombre para la lista',
    variableListNameAlreadyExist: 'El nombre de la lista ya está en uso',
    badFileType: 'Tipo de archivo incorrecto',
    dateError: 'Por favor seleccione una columna para la fecha',
    duplicateHeaderError:
      "Su archivo contiene un encabezado duplicado, desactive la opción 'Mi archivo contiene encabezados' o modifique la primera línea del archivo",
  },
  Autocomplete: {
    noOption: 'Sin opciones',
    noUnit: 'Sin unidad',
    noList: 'Sin lista',
    noValue: 'Ninguno',
    list: 'Lista',
    clear: 'Vaciar',
    close: 'Cerrar',
    open: 'Abrir',
    select: 'Seleccionar',
    unknown: 'Unidad desconocida',
    addUnitOption: 'Agregar la unidad "{{name}}"',
    placeholder: 'Unidad',
  },
  Select: {
    placeholder: 'Seleccionar una lista...',
    addList: 'Nueva lista',
  },
  Import: {
    title: 'Importar datos manuales',
    addOrEditList: 'Por favor agregue o seleccione una lista',
    date: 'Fecha',
    dateConfirmation: '¿Está seguro de esta fecha?',
    warningDates: 'Atención, algunas fechas pueden ser incorrectas',
    Action: {
      Button: {
        save: 'Guardar',
        addVar: 'Agregar',
        showMore: 'Mostrar más',
        addVariableList: 'Lista',
        editVariableList: 'Modificar lista',
      },
      ImportFile: {
        importVariables: 'Importar',
        replaceData: 'Reemplazar todo...',
        concatData: 'Agregar al final...',
        cancelImport: 'Cancelar',
        validateImport: 'Validar',
      },
      SelectorFile: {
        title: 'Importar un archivo de variables',
        dragAndDrop: 'Arrastrar y soltar',
        or: 'o',
        selectFile: 'Seleccionar un archivo',
        selectedFile: 'Archivo seleccionado',
      },
    },
    Dialog: {
      titleEditList: 'Editar lista',
      titleAddList: 'Agregar lista',
      Button: {
        addVar: 'Agregar',
        confirm: 'Continuar',
        cancel: 'Cancelar',
        delete: 'Vaciar',
      },
      SelectList: {
        manualVariables: 'Variables manuales',
        name: 'Nombre',
      },
    },
    WelcomeMessage: {
      create: 'Crear una nueva lista',
      or: 'o',
    },
    Field: {
      Timestamp: {
        label: 'Fecha',
        labelTooltip: 'Fecha',
      },
    },
    ImportTable: {
      newList: 'Nueva lista',
    },
    Footer: {
      Button: {
        newEntry: 'Nueva entrada',
      },
    },
    tooltip: {
      createList: 'Crear una nueva lista',
    },
    ImportDataDialog: {
      title: 'Archivo importado',
      checkbox: 'Mi archivo contiene encabezados',
      column_space: 'Columna',
      start_line: 'Mis valores comienzan en la línea:',
      formats: {
        format1: 'DD/MM/AAAA hh:mm:ss',
        format2: 'MM/DD/AAAA hh:mm:ss',
        format3: 'AAAA/MM/DD hh:mm:ss',
        format4: 'AAAA/DD/MM hh:mm:ss',
      },
      noValidDate: 'No se encontró ninguna fecha válida, intente intercambiar la columna asociada o el formato de la fecha',
      parameters: 'Ajustes',
      matching: 'Correspondencia de campos',
      preview: 'Vista previa',
      results: 'Resultados',
      linesWillBeImported: ' líneas serán importadas',
      lineWillBeImported: ' línea será importada',
      table: {
        header1: 'Campos',
        header2: 'Columnas del archivo',
      },
    },
  },
  Export: {
    title: 'Exportar variables',
    Typo: {
      dateInterval: 'Intervalo de fechas',
      periodicity: 'Intervalo de tiempo',
      optionExport: 'Opción de exportación',
      varSelector: 'Variables',
    },
    Button: {
      export: 'Descargar',
    },
    Time: {
      seconds: 'Segundos',
      minutes: 'Minutos',
      hours: 'Horas',
      days: 'Días',
      weeks: 'Semanas',
    },
    Input: {
      Label: {
        to: 'Fin',
        from: 'Inicio',
      },
    },
  },
  MyVariable: {
    title: 'Mis Variables',
    unit: 'Unidad',
    type: 'Tipo',
    variableType: 'Tipo de variable',
    operation: 'Operación',
    Typo: {
      createVar: 'Agregar',
      varManual: 'Manual',
      varManualDesc: 'Importe usted mismo los datos.',

      varCalculate: 'Calculada',
      varCalculateDesc: 'Esta variable se basa en otras variables existentes',
    },
    Button: {
      createVar: 'Agregar',
      advancedSearch: 'Búsqueda avanzada',
      validate: 'Validar',
      cancel: 'Cancelar',
      saveVar: 'Guardar',
    },
    Textfield: {
      name: 'Nombre',
      description: 'Descripción',
      reference: 'Referencia',
      unit: 'Unidad',
    },
    Type: {
      all: 'Todo',
      allType: 'Todos los tipos',
      allUnit: 'Todas las unidades',
      number: 'Número',
      bool: 'Booleano',
    },
    Field: {
      ActionMenu: {
        label: 'Acción',
        labelTooltip: 'Menú de Acción',
        Menu: {
          refresh: 'Actualizar',
          createAlarm: 'Crear una alarma',
          viewAlarm: 'Mostrar alarmas',
          playground: 'Abrir playground',
        },
      },
      TypeVariable: {
        label: 'Tipo Var',
        labelTooltip: 'Tipo de Variable',
        Type: {
          calculated: 'Calculada',
          real: 'Adquirida',
          manual: 'Manual',
          unknown: 'Desconocida',
        },
      },
      Name: {
        label: 'Número',
        labelTooltip: 'Número',
      },
      Ref: {
        label: 'Referencia',
        labelTooltip: 'Referencia',
      },
      Desc: {
        label: 'Descripción',
        labelTooltip: 'Descripción',
      },
      Unit: {
        label: 'Unidad',
        labelTooltip: 'Unidad',
      },
      Type: {
        label: 'Tipo',
        labelTooltip: 'Tipo',
      },
      LastImport: {
        label: 'Última Importación',
        labelTooltip: 'Última Importación',
      },
      LastValue: {
        label: 'Última Valor',
        labelTooltip: 'Última Valor',
      },
      LastAction: {
        label: 'Última Acción',
        labelTooltip: 'Última acción',
      },
    },
  },
  variableKind: {
    [VariableKind.CALCULATED]: 'Calculada',
    [VariableKind.CONSTANT]: 'Constante',
    [VariableKind.MANUAL]: 'Manual',
    [VariableKind.REAL]: 'Adquirida',
    [FakeVariableKind.ALARM]: 'Alarma',
    [FakeVariableKind.BATCH]: 'Lote',
    [FakeVariableKind.VAPOR_MIX]: 'Mezcla de vapor',
  },
};

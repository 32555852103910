import { WarningAmberOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Stack } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UUID } from '@dametis/core';

import { shouldDisplayCalculationValue } from 'functions/tada/shouldDisplay';
import { useSelector } from 'store';

import { getFormattedValue } from '../UnitPicker/functions/getFormattedValue';
import VariableValue, { VariableValueProps } from '../VariableValue/VariableValue';

export interface VariableLastPointProps extends VariableValueProps {
  variableId: UUID;
  unit?: string;
}

const VariableLastPoint: FC<VariableLastPointProps> = ({ variableId, unit = undefined, ...props }) => {
  const { t } = useTranslation('variables');

  const lastPoint = useSelector(state => state.variables.lastPoints.data[variableId]);
  const fetchedAt = useSelector(state => state.variables.lastPoints.fetchedAt);
  const isFetching = useSelector(state => state.variables.lastPoints.isFetching);

  const isValueCurrent = useMemo(
    () => shouldDisplayCalculationValue({ exp: 'var_0', vars: { var_0: { variableUuid: variableId } } }, lastPoint, fetchedAt),
    [variableId, lastPoint, fetchedAt],
  );

  return (
    <VariableValue {...props}>
      <Stack alignItems="center" component="span" direction="row" gap={0.5}>
        {isFetching && <CircularProgress color="secondary" size={12} thickness={4} />}
        {!isFetching && lastPoint && !isValueCurrent && <WarningAmberOutlined color="error" sx={{ fontSize: 12 }} />}
        {(!isFetching || (lastPoint?.value !== null && lastPoint?.value !== undefined)) && (
          <Box component="span">
            {lastPoint?.value !== null && lastPoint?.value !== undefined
              ? getFormattedValue({ value: lastPoint.value, userUnit: unit })
              : t('global:text.noValue')}
          </Box>
        )}
      </Stack>
    </VariableValue>
  );
};

export default VariableLastPoint;

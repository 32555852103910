import { Operator, VariableKind } from '@dametis/core';

import { FakeVariableKind } from 'types/variables';

import { OnlyOptionalKeys } from '../../../types';
import { ListTab, VncProps } from '../types';

export const sortedOperators: Operator[] = [
  Operator.HISTORY,
  Operator.MEAN,
  Operator.MOVING_AVERAGE,
  Operator.MIN,
  Operator.MAX,
  Operator.COUNT,
  Operator.DELTA,
  Operator.STDDEV,
  Operator.SUM,
  Operator.INTEGRAL,
  Operator.DERIVATIVE,
  Operator.FIRST,
  Operator.LAST,
  Operator.INDEX,
  Operator.LINEAR_CADENCE,
  Operator.GROUP_BY_DURATION,
  Operator.TIME,
];

export const compareOperators = (a: Operator, b: Operator) => sortedOperators.indexOf(a) - sortedOperators.indexOf(b);

// Todo FKL: Remove when TS strict
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const vncDefaultProps: OnlyOptionalKeys<VncProps> = {
  sourceUuid: null,
  multiple: false,
  selection: [],
  onSelectionChange: null,
  listTab: ListTab.VARIABLES,
  defaultVariableOperator: null,
  variableOperatorOptions: sortedOperators,
  excludeVariableOperatorOptions: [],
  defaultGlobalOperator: null,
  globalOperatorOptions: sortedOperators,
  excludeGlobalOperatorOptions: [],
  disableMaths: false,
  butKeepVariableMenu: false,
  defaultFilters: {},
  availableFilters: {
    kinds: [
      VariableKind.CALCULATED,
      VariableKind.REAL,
      VariableKind.MANUAL,
      VariableKind.CONSTANT,
      FakeVariableKind.ALARM,
      FakeVariableKind.BATCH,
      FakeVariableKind.VAPOR_MIX,
    ],
  },
  disableFilters: [],
  calculatedVariableMode: false,
  standardBlockMetricMode: false,
  unPostedBlockType: null,
  editingBlockTypeMetricUuid: null,
  disableLego: false,
  disableModels: false,
  covarianceVariable: null,
  unitPicker: true,
  output: 'multipleValues',
  GroupByInputProps: {},
  disableCreateAlias: false,
};

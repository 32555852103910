import { Stack } from '@mui/material';
import { GridColumnHeaderParams, GridColumnHeaderTitleProps, GridValidRowModel } from '@mui/x-data-grid-premium';
import { ReactNode } from 'react';

import TypographyEllipse, { TypographyEllipseProps } from 'components/UI/TypographyEllipse/TypographyEllipse';

export interface CustomGridColumnHeaderProps<T extends GridValidRowModel>
  extends Omit<GridColumnHeaderTitleProps, 'label' | 'columnWidth'> {
  subtitle: ReactNode;
  params: GridColumnHeaderParams<T>;
  label?: GridColumnHeaderTitleProps['label'];
  columnWidth?: GridColumnHeaderTitleProps['columnWidth'];
  labelProps?: TypographyEllipseProps;
  subtitleProps?: TypographyEllipseProps;
}

const CustomGridColumnHeader = <T extends GridValidRowModel>({
  subtitle,
  labelProps = undefined,
  subtitleProps = undefined,
  label = '',
  params,
  columnWidth = 0,
}: CustomGridColumnHeaderProps<T>) => (
  <Stack width={columnWidth}>
    <TypographyEllipse lineHeight={1} variant="caption" {...subtitleProps} align={params.colDef.headerAlign ?? params.colDef.align}>
      {subtitle}
    </TypographyEllipse>
    <TypographyEllipse align={params.colDef.align} color="primary" fontSize={12} fontWeight={500} {...labelProps}>
      {label}
    </TypographyEllipse>
  </Stack>
);

export default CustomGridColumnHeader;

import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'i18next';

import { ListVariableInfo } from '@dametis/core';

import VariableLastPoint from 'components/UI/VariableLastPoint/VariableLastPoint';
import { shouldDisplayCalculationValue } from 'functions/tada/shouldDisplay';
import { localizedFormat } from 'localization/localizedDateFns';
import { VariablesLastPoints } from 'store/slices/variables';

export enum DeviceVariablesGridColumn {
  NAME = 'name',
  LAST_POINT_VALUE = 'lastPointValue',
  UNIT = 'unit',
  LAST_POINT_DATE = 'lastPointDate',
}

export const getGridColDef = (lastPoints: VariablesLastPoints, t: TFunction): GridColDef<ListVariableInfo>[] => [
  {
    field: DeviceVariablesGridColumn.NAME,
    headerName: t('devices:columns.name'),
    flex: 1,
    align: 'left',
    headerAlign: 'left',
    hideable: false,
    resizable: true,
    sortable: true,
  },
  {
    field: DeviceVariablesGridColumn.LAST_POINT_VALUE,
    headerName: t('devices:columns.lastPointValue'),
    resizable: true,
    sortable: true,
    headerAlign: 'right',
    align: 'right',
    hideable: false,
    valueGetter: (_, row) => lastPoints.data[row.uuid]?.value ?? null,
    renderCell: ({ row }) => <VariableLastPoint fontSize={12} variableId={row.uuid} />,
  },
  {
    field: DeviceVariablesGridColumn.UNIT,
    headerName: t('devices:columns.unit'),
    align: 'left',
    headerAlign: 'left',
    hideable: false,
    resizable: true,
    sortable: true,
    width: 70,
  },
  {
    field: DeviceVariablesGridColumn.LAST_POINT_DATE,
    headerName: t('devices:columns.lastPointDate'),
    type: 'dateTime',
    width: 180,
    valueGetter: (_, row) => {
      const lastPoint = lastPoints.data[row.uuid];
      return lastPoint ? new Date(lastPoint.time) : undefined;
    },
    valueFormatter: (_, { id }) => {
      if (!id) {
        return '';
      }
      const lastPoint = lastPoints.data[id];
      return lastPoint ? localizedFormat(new Date(lastPoint?.time), 'Ppp') : '';
    },
    renderCell: ({ row }) => {
      const lastPoint = lastPoints.data[row.uuid];
      const { fetchedAt } = lastPoints;
      const isValueCurrent = shouldDisplayCalculationValue(
        { exp: 'var_0', vars: { var_0: { variableUuid: row.uuid } } },
        lastPoint,
        fetchedAt,
      );
      return (
        <>
          {lastPoint && (
            <Typography color={!isValueCurrent ? 'error' : undefined} fontSize={12} fontStyle="italic">
              {localizedFormat(new Date(lastPoint?.time), 'Ppp')}
            </Typography>
          )}
        </>
      );
    },
  },
];

import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CSVConfig,
  FileImportInfo,
  FileInfo,
  FixedVariable,
  IsSourceColumnRealByHeader,
  IsSourceColumnRealByIndex,
  IsSourceColumnVirtual,
  ListVariableInfo,
  PreviewFileParsingResult,
  PromiseLimit,
  ReferencedBy,
  SourceColumn,
  SourceColumnRealByHeader,
  SourceColumnRealByIndex,
  SourceColumnVirtual,
  UUID,
  UpdateFileImportBody,
  VirtualColumn,
} from '@dametis/core';

import { sdk } from 'sdk';
import { useDispatch, useSelector } from 'store';
import { displaySdkErrorToast } from 'store/actions/toasts';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';
import { IsTimeZone, TimeZone } from 'types/auth';

import BigDropzone from '../../UI/BigDropzone/BigDropzone';
import { createCSVConfig } from '../../helpers/createCSVConfig';
import { getGlobalParseErrors } from '../../helpers/getGlobalParseErrors';
import { getParseErrorsByRow } from '../../helpers/getParseErrorsByRow';

import FilePreview from './FilePreview/FilePreview';
import FilePreviewProvider from './FilePreview/FilePreviewContext';
import GeneralSettings from './GeneralSettings';
import JQTransformer from './JQTransformer';
import MappingSettings from './MappingSettings/MappingSettings';
import MappingSettingsProvider, { FormFixedVariable, IsFixedVariable } from './MappingSettings/MappingSettingsContext';
import PromptBeforeCanceling from './PromptBeforeCanceling';
import SaveFileImportModal from './SaveFileImportModal';
import VirtualColumnsSettings from './VirtualColumnsSettings';
// import test from './test';

const filesDefaultProp: FileInfo[] = [];

export interface CreateFileImportModalProps {
  fileImport: FileImportInfo | null;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  files?: FileInfo[];
  onSaveFileImport: (newFileImport: FileImportInfo) => void;
  forceSaveFileImport?: boolean;
}

const CreateFileImportModal: FC<CreateFileImportModalProps> = ({
  fileImport,
  isOpen,
  setIsOpen,
  files = filesDefaultProp,
  onSaveFileImport,
  forceSaveFileImport = false,
}) => {
  const { t } = useTranslation('fileImport');
  const dispatch = useDispatch();

  const variablesByIds = useSelector(state => state.variables.byId);
  const siteTimeZone = useSelector(state => state.auth.selectedSite?.timeZone);

  const [isPromptBeforeCancelingOpen, setIsPromptBeforeCancelingOpen] = useState<boolean>(false);
  const [isImportingData, setIsImportingData] = useState<boolean>(false);
  const [isSavingFileImport, setIsSavingFileImport] = useState<boolean>(false);
  const [isCanceling, setIsCanceling] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isSaveFileImportModalOpen, setIsSaveFileImportModalOpen] = useState<boolean>(false);

  /**
   * FILES
   */
  const [dropzoneFiles, setDropzoneFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<FileInfo[]>([]);
  const [isUploadingFiles, setIsUploadingFiles] = useState<boolean>(false);

  /**
   * GENERAL SETTINGS
   */
  const [csvConfig, setCSVConfig] = useState<CSVConfig>(createCSVConfig);
  const [referencedBy, setReferencedBy] = useState<ReferencedBy>(ReferencedBy.COLUMNS);
  const [jqValue, setJqValue] = useState<string>('');

  /**
   * MAPPING SETTINGS
   */
  const [virtualColumns, setVirtualColumns] = useState<VirtualColumn[]>([]);
  const [dateTimeSource, setDateTimeSource] = useState<SourceColumn | null>(null);
  const [dateTimeZone, setDateTimeZone] = useState<TimeZone | null>(null);
  const [dateTimeFormat, setDateTimeFormat] = useState<string>('');
  const [referenceSource, setReferenceSource] = useState<SourceColumn | null>(null);
  const [valueSource, setValueSource] = useState<SourceColumn | null>(null);
  const [skippedColumns, setSkippedColumns] = useState<(SourceColumn | null)[]>([]);
  const [fixedVariables, setFixedVariables] = useState<FormFixedVariable[]>([]);

  /**
   * PREVIEWS
   */
  const [preview, setPreview] = useState<PreviewFileParsingResult | null>(null);
  const [isFetchingPreview, setIsFetchingPreview] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<FileInfo | null>(null);

  const fetchPreviewDebounce = useRef<ReturnType<typeof setTimeout> | null>(null);

  const isJq = useMemo(() => jqValue.length > 0, [jqValue]);

  const variablesByReferences = useMemo(
    () =>
      Object.values(variablesByIds).reduce<Record<string, { variable: ListVariableInfo; count: number }>>((result, variable) => {
        if (result[variable.reference]) {
          result[variable.reference].count += 1;
        } else if (variable.reference?.trim().length > 0 && !result[variable.reference]) {
          result[variable.reference] = {
            variable,
            count: 1,
          };
        }
        return result;
      }, {}),
    [variablesByIds],
  );

  const fileImportId: UUID | null = useMemo(() => fileImport?.uuid ?? null, [fileImport?.uuid]);

  const { availableRealByIndexColumns, availableRealByHeaderColumns, availableVirtualColumnSources } = useMemo(
    () =>
      (preview?.columns ?? []).reduce<{
        availableRealByIndexColumns: SourceColumnRealByIndex[];
        availableRealByHeaderColumns: SourceColumnRealByHeader[];
        availableVirtualColumnSources: SourceColumnVirtual[];
      }>(
        (result, column) => {
          if (IsSourceColumnRealByIndex(column)) {
            result.availableRealByIndexColumns.push(column);
          }
          if (IsSourceColumnRealByHeader(column)) {
            result.availableRealByHeaderColumns.push(column);
          }
          if (IsSourceColumnVirtual(column)) {
            result.availableVirtualColumnSources.push(column);
          }
          return result;
        },
        {
          availableRealByIndexColumns: [],
          availableRealByHeaderColumns: [],
          availableVirtualColumnSources: [],
        },
      ),
    [preview?.columns],
  );

  const globalParseErrors = useMemo(() => getGlobalParseErrors(preview?.errors), [preview?.errors]);
  const parseErrorsByRow = useMemo(() => getParseErrorsByRow(preview?.errors), [preview?.errors]);

  // SLE: check to remove this one and use what preview returns (just above)
  const availableVirtualColumnNames = useMemo(() => virtualColumns.map(virtualColumn => virtualColumn.name), [virtualColumns]);

  const updateFileImportBody = useMemo(() => {
    const parsedFixedVariables: FixedVariable[] = fixedVariables.filter(fixedVariable => IsFixedVariable(fixedVariable));
    const body: UpdateFileImportBody = {
      config: csvConfig,
      jq: jqValue.length > 0 ? jqValue : null,
      mapping: {
        virtualColumns,
        format: referencedBy,
        dateTimeSource,
        dateTimeFormat,
        dateTimeZone: dateTimeZone ?? '',
        skippedColumns: skippedColumns.filter(skippedColumn => skippedColumn !== null),
        fixedVariables: parsedFixedVariables,
        valueSource,
        referenceSource,
      },
    };
    return body;
  }, [
    fixedVariables,
    csvConfig,
    jqValue,
    virtualColumns,
    referencedBy,
    dateTimeSource,
    dateTimeFormat,
    dateTimeZone,
    skippedColumns,
    valueSource,
    referenceSource,
  ]);

  const areSettingsValid = useMemo(
    () => dateTimeSource !== null && (referencedBy !== ReferencedBy.ROWS || (referenceSource !== null && valueSource !== null)),
    [dateTimeSource, referencedBy, referenceSource, valueSource],
  );

  const usedSources: SourceColumn[] = useMemo(() => {
    const result: SourceColumn[] = [];
    if (dateTimeSource) {
      result.push(dateTimeSource);
    }
    if (referenceSource) {
      result.push(referenceSource);
    }
    if (valueSource) {
      result.push(valueSource);
    }
    skippedColumns.forEach(skippedColumn => {
      if (skippedColumn) {
        result.push(skippedColumn);
      }
    });
    fixedVariables.forEach(fixedVariable => {
      if (fixedVariable.source) {
        result.push(fixedVariable.source);
      }
    });
    return result;
  }, [dateTimeSource, referenceSource, valueSource, skippedColumns, fixedVariables]);

  const clearSources = useCallback(() => {
    setDateTimeSource(null);
    setReferenceSource(null);
    setValueSource(null);
    setSkippedColumns([]);
    setFixedVariables([]);
  }, []);

  const handleOpenPromptBeforeCanceling = useCallback(() => {
    setIsPromptBeforeCancelingOpen(true);
  }, []);

  const handleCancel = useCallback(async () => {
    if (!fileImportId) {
      return;
    }
    setIsCanceling(true);
    try {
      await sdk.fileImport.Delete(fileImportId);
      setIsPromptBeforeCancelingOpen(false);
      setIsOpen(false);
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    } finally {
      setIsCanceling(false);
    }
  }, [setIsOpen, dispatch, fileImportId]);

  const fetchPreview = useCallback(
    async (fileName: string, controller?: AbortController) => {
      if (!fileImportId) {
        return;
      }
      setIsFetchingPreview(true);
      try {
        await sdk.fileImport.Update(fileImportId, updateFileImportBody);
        const { data: previewData } = await sdk.fileImport.Preview(
          fileImportId,
          { name: fileName },
          controller ? { signal: controller.signal } : undefined,
        );
        setPreview(previewData);
      } catch (err) {
        console.error(err);
        dispatch(displaySdkErrorToast(err));
      } finally {
        setIsFetchingPreview(false);
      }
    },
    [dispatch, fileImportId, updateFileImportBody],
  );

  const handleUploadFiles = useCallback(async () => {
    if (dropzoneFiles.length === 0 || !fileImportId) {
      return;
    }
    setIsUploadingFiles(true);
    try {
      const filesData = await PromiseLimit.do(dropzoneFiles, async dropzoneFile => {
        const formData = new FormData();
        const fileFormData = new File([dropzoneFile], dropzoneFile.name, { type: dropzoneFile.type });
        formData.append('file', fileFormData);
        const { data } = await sdk.fileImport.AddFile(fileImportId, formData);
        return data;
      });
      setUploadedFiles(filesData);
      if (filesData[0]) {
        setSelectedFile(filesData[0]);
        void fetchPreview(filesData[0].name);
      }
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    } finally {
      setIsUploadingFiles(false);
    }
  }, [dropzoneFiles, fileImportId, dispatch, fetchPreview]);

  const handleChangeSelectedFile = useCallback(
    (newSelectedFile: FileInfo | null) => {
      if (newSelectedFile) {
        setSelectedFile(newSelectedFile);
        void fetchPreview(newSelectedFile.name);
      }
    },
    [fetchPreview],
  );

  const handleValidate = useCallback(() => {
    setIsSaveFileImportModalOpen(true);
  }, []);

  const handleSaveFileImport = useCallback(async () => {
    if (!fileImportId) {
      return;
    }
    setIsSavingFileImport(true);
    try {
      const body: UpdateFileImportBody = { name, description, hidden: false };
      const { data } = await sdk.fileImport.Update(fileImportId, body);
      dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: t('toast.fileImportSaved') }));
      setIsOpen(false);
      onSaveFileImport(data);
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    } finally {
      setIsSavingFileImport(false);
    }
  }, [dispatch, setIsOpen, fileImportId, name, description, onSaveFileImport, t]);

  const handleImportData = useCallback(async () => {
    if (!fileImportId) {
      return;
    }
    setIsImportingData(true);
    try {
      await sdk.fileImport.Update(fileImportId, updateFileImportBody);
      await sdk.fileImport.ImportData(fileImportId, { names: uploadedFiles.map(uploadedFile => uploadedFile.name) });
      dispatch(addToast({ severity: ToastSeverity.INFO, message: t('toast.uploadInProgress', { count: uploadedFiles.length }) }));
      setIsOpen(false);
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    } finally {
      setIsImportingData(false);
    }
  }, [dispatch, setIsOpen, fileImportId, uploadedFiles, updateFileImportBody, t]);

  // const handleCloseSaveFileImportModalWithSaving = useCallback(() => {
  //   dispatch(
  //     addToast({
  //       severity: ToastSeverity.INFO,
  //       message: t('toast.uploadInProgressWithRedirect', { count: uploadedFiles.length }),
  //       action: (
  //         <Button color="inherit" component={NavLink} size="small" to={`/data/import/${fileImportId}`}>
  //           {t('button.see')}
  //         </Button>
  //       ),
  //     }),
  //   );
  // }, [dispatch, uploadedFiles, fileImportId, t]);

  useEffect(() => {
    if (isOpen) {
      let parsedDateTimeZone: TimeZone | null = null;
      if (IsTimeZone(fileImport?.mapping?.dateTimeZone)) {
        parsedDateTimeZone = fileImport.mapping.dateTimeZone;
      } else if (IsTimeZone(siteTimeZone)) {
        parsedDateTimeZone = siteTimeZone;
      }
      setName(fileImport?.name ?? '');
      setDescription(fileImport?.description ?? '');
      setCSVConfig(fileImport?.config ?? createCSVConfig());
      setVirtualColumns(fileImport?.mapping?.virtualColumns ?? []);
      setReferencedBy(fileImport?.mapping?.format ?? ReferencedBy.COLUMNS);
      setDateTimeSource(fileImport?.mapping?.dateTimeSource ?? null);
      setDateTimeFormat(fileImport?.mapping?.dateTimeFormat ?? '');
      setDateTimeZone(parsedDateTimeZone);
      setSkippedColumns(fileImport?.mapping?.skippedColumns ?? []);
      setFixedVariables(fileImport?.mapping?.fixedVariables ?? []);
      setValueSource(fileImport?.mapping?.valueSource ?? null);
      setReferenceSource(fileImport?.mapping?.referenceSource ?? null);
      setJqValue(fileImport?.jq ?? '');
    }
  }, [isOpen, fileImport, siteTimeZone]);

  useEffect(() => {
    if (isOpen) {
      setUploadedFiles(files);
      if (files[0]) {
        setSelectedFile(files[0]);
      } else {
        setSelectedFile(null);
      }
    }
  }, [isOpen, files]);

  useEffect(() => {
    const controller = new AbortController();
    fetchPreviewDebounce.current = setTimeout(() => {
      if (selectedFile) {
        void fetchPreview(selectedFile.name, controller);
      }
    }, 500);
    return () => {
      if (fetchPreviewDebounce.current) {
        clearTimeout(fetchPreviewDebounce.current);
      }
      controller.abort();
    };
  }, [fetchPreview, selectedFile]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        open={isOpen}
        PaperProps={{ sx: { width: 1, height: 1 } }}
        onClose={handleOpenPromptBeforeCanceling}
      >
        <DialogTitle>{t('title.createFileImportModal')}</DialogTitle>
        <DialogContent>
          <Stack direction="row" gap={1} height={1}>
            <Box flexShrink={0} pr={1} sx={{ overflowY: 'auto', overflowX: 'hidden' }} width={400}>
              <GeneralSettings
                clearSources={clearSources}
                csvConfig={csvConfig}
                isJq={isJq}
                referencedBy={referencedBy}
                setCSVConfig={setCSVConfig}
                setReferencedBy={setReferencedBy}
              />
              <JQTransformer jqValue={jqValue} setCSVConfig={setCSVConfig} setJqValue={setJqValue} setReferencedBy={setReferencedBy} />
              <MappingSettingsProvider
                dateTimeFormat={dateTimeFormat}
                dateTimeSource={dateTimeSource}
                dateTimeZone={dateTimeZone}
                fixedVariables={fixedVariables}
                referencedBy={referencedBy}
                referenceSource={referenceSource}
                setDateTimeFormat={setDateTimeFormat}
                setDateTimeSource={setDateTimeSource}
                setDateTimeZone={setDateTimeZone}
                setFixedVariables={setFixedVariables}
                setReferenceSource={setReferenceSource}
                setSkippedColumns={setSkippedColumns}
                setValueSource={setValueSource}
                skippedColumns={skippedColumns}
                usedSources={usedSources}
                useHeader={csvConfig.useHeader}
                valueSource={valueSource}
                variablesByReferences={variablesByReferences}
              >
                <MappingSettings
                  availableRealByHeaderColumns={availableRealByHeaderColumns}
                  availableRealByIndexColumns={availableRealByIndexColumns}
                  availableVirtualColumnNames={availableVirtualColumnNames}
                  availableVirtualColumnSources={availableVirtualColumnSources}
                />
              </MappingSettingsProvider>
              <VirtualColumnsSettings
                availableRealByHeaderColumns={availableRealByHeaderColumns}
                availableRealByIndexColumns={availableRealByIndexColumns}
                availableVirtualColumnNames={availableVirtualColumnNames}
                disabled={isJq}
                setVirtualColumns={setVirtualColumns}
                virtualColumns={virtualColumns}
              />
            </Box>
            <Stack direction="column" gap={1} overflow="hidden" width={1}>
              {uploadedFiles.length === 0 && (
                <BigDropzone
                  disabled={isCanceling}
                  files={dropzoneFiles}
                  isUploading={isUploadingFiles || isImportingData}
                  setFiles={setDropzoneFiles}
                  onUpload={handleUploadFiles}
                />
              )}
              {uploadedFiles.length > 0 && selectedFile && (
                <FilePreviewProvider
                  csvConfig={csvConfig}
                  dataInfo={preview?.dataInfo ?? []}
                  dateTimeSource={dateTimeSource}
                  fixedVariables={fixedVariables}
                  globalParseErrors={globalParseErrors}
                  isJq={isJq}
                  parseErrorsByRow={parseErrorsByRow}
                  referencedBy={referencedBy}
                  referenceSource={referenceSource}
                  setDateTimeSource={setDateTimeSource}
                  setFixedVariables={setFixedVariables}
                  setReferenceSource={setReferenceSource}
                  setSkippedColumns={setSkippedColumns}
                  setValueSource={setValueSource}
                  skippedColumns={skippedColumns}
                  usedSources={usedSources}
                  valueSource={valueSource}
                >
                  <FilePreview
                    files={uploadedFiles}
                    isFetching={isFetchingPreview}
                    preview={preview}
                    selectedFile={selectedFile}
                    onChangeSelectedFile={handleChangeSelectedFile}
                  />
                </FilePreviewProvider>
              )}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton disabled={isImportingData || isSavingFileImport} loading={isCanceling} onClick={handleOpenPromptBeforeCanceling}>
            {t('button.close')}
          </LoadingButton>
          {/* <LoadingButton
            color="secondary"
            disabled={!fileImport || files.length === 0 || preview?.errors?.length > 0 || isCanceling}
            loading={isImportingData}
            variant="contained"
            onClick={handleImportData}
          >
            {t('button.importData')}
          </LoadingButton> */}
          <Button
            color="secondary"
            disabled={
              !fileImport ||
              uploadedFiles.length === 0 ||
              (preview?.errors ?? []).length > 0 ||
              isCanceling ||
              !areSettingsValid ||
              isFetchingPreview
            }
            variant="contained"
            onClick={handleValidate}
          >
            {t('button.validate')}
          </Button>
        </DialogActions>
      </Dialog>
      {fileImport && (
        <SaveFileImportModal
          description={description}
          forceSaveFileImport={forceSaveFileImport}
          isOpen={isSaveFileImportModalOpen}
          name={name}
          setDescription={setDescription}
          setIsOpen={setIsSaveFileImportModalOpen}
          setName={setName}
          onImportData={handleImportData}
          onSaveFileImport={handleSaveFileImport}
        />
      )}
      <PromptBeforeCanceling
        isOpen={isPromptBeforeCancelingOpen}
        setIsOpen={setIsPromptBeforeCancelingOpen}
        onConfirmCanceling={handleCancel}
      />
    </>
  );
};

export default CreateFileImportModal;

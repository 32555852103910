import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { Button, Grid, IconButton, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { ChangeEventHandler, FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import AdvancedTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';

export interface DefaultVariablesPickerProps {
  value: Record<string, string>;
  onChange: (newValue: Record<string, string>) => void;
  isEditing?: boolean;
}

const DefaultVariablesPicker: FC<DefaultVariablesPickerProps> = ({ value, onChange, isEditing = true }) => {
  const { t } = useTranslation('devices');

  const [newVariableMenuAnchorEl, setNewVariableMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [newVariableKey, setNewVariableKey] = useState<string>('');
  const [newVariableValue, setNewVariableValue] = useState<string>('');

  const variableKeys = useMemo(() => Object.keys(value), [value]);
  const isNewVariableMenuOpen = useMemo(() => Boolean(newVariableMenuAnchorEl), [newVariableMenuAnchorEl]);

  const isNewVariableValid = useMemo(
    () => newVariableKey.trim().length > 0 && newVariableValue.trim().length > 0 && !variableKeys.includes(newVariableKey),
    [newVariableKey, newVariableValue, variableKeys],
  );

  const handleDeleteVariable = useCallback(
    (variableKey: string) => () => {
      const { [variableKey]: removed, ...newValue } = value;
      onChange(newValue);
    },
    [value, onChange],
  );

  const handleChangeNewVariableKey: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setNewVariableKey(event.target.value);
  }, []);

  const handleChangeNewVariableValue: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setNewVariableValue(event.target.value);
  }, []);

  const handleOpenNewVariableMenu: MouseEventHandler<HTMLElement> = useCallback(event => {
    setNewVariableKey('');
    setNewVariableValue('');
    setNewVariableMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseNewVariableMenu = useCallback(() => {
    setNewVariableMenuAnchorEl(null);
  }, []);

  const handleAddNewVariable = useCallback(() => {
    const newValue = { ...value, [newVariableKey.trim()]: newVariableValue.trim() };
    onChange(newValue);
    setNewVariableMenuAnchorEl(null);
  }, [value, onChange, newVariableKey, newVariableValue]);

  return (
    <>
      <Stack gap={1}>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography variant="h6">{t('label.variables')}</Typography>
          {isEditing && (
            <ActionButton startIcon={<AddOutlined />} onClick={handleOpenNewVariableMenu}>
              {t('button.add')}
            </ActionButton>
          )}
        </Stack>
        {variableKeys.length > 0 && (
          <Stack gap={0.5}>
            {variableKeys.map(variableKey => (
              <Grid key={variableKey} container alignItems="center" spacing={1}>
                <Grid item xs>
                  <Typography>{variableKey}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography>{value[variableKey]}</Typography>
                </Grid>
                {isEditing && (
                  <Grid item xs={false}>
                    <Tooltip placement="top" title={t('tooltip.delete')}>
                      <IconButton size="small" onClick={handleDeleteVariable(variableKey)}>
                        <DeleteOutlined fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            ))}
          </Stack>
        )}
        {variableKeys.length === 0 && (
          <Typography align="center" variant="subtitle2">
            {t('text.noVariable')}
          </Typography>
        )}
      </Stack>
      <Popover
        anchorEl={newVariableMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isNewVariableMenuOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseNewVariableMenu}
      >
        <Stack direction="row" p={2} spacing={1}>
          <AdvancedTextField
            autoFocus
            fullWidth
            placeholder={t('placeholder.key')}
            size="small"
            value={newVariableKey}
            onChange={handleChangeNewVariableKey}
          />
          <AdvancedTextField
            fullWidth
            placeholder={t('placeholder.value')}
            size="small"
            value={newVariableValue}
            onChange={handleChangeNewVariableValue}
          />
          <Button
            color="secondary"
            disabled={!isNewVariableValid}
            sx={{ minWidth: 'unset', padding: '6px' }}
            variant="contained"
            onClick={handleAddNewVariable}
          >
            <AddOutlined />
          </Button>
        </Stack>
      </Popover>
    </>
  );
};

export default DefaultVariablesPicker;

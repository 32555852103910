import { Components, Theme } from '@mui/material';

import { LIST_PADDING } from './MuiList';

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      paddingLeft: LIST_PADDING * 2,
      paddingRight: LIST_PADDING * 2,
    }),
    gutters: {
      marginLeft: LIST_PADDING,
      marginRight: LIST_PADDING,
      width: `calc(100% - 2 * ${LIST_PADDING}px)`,
    },
    dense: {
      paddingTop: LIST_PADDING / 2,
      paddingBottom: LIST_PADDING / 2,
    },
  },
} as Components<Theme>['MuiListItemButton'];

export default {
  text: {
    logIntoDametis: 'Connectez-vous :',
    logRecoverDametis: 'Mot de passe oublié ?',
    logChangePasswordDametis: 'Choisissez votre nouveau mot de passe :',
    logInvalidLostPwdToken: 'Lien pour changer de mot de passe invalide.',
    logInvalidAccountActToken: 'Lien pour activer votre compte invalide.',
    logDeadApi: 'Une erreur est survenue, veuillez réessayez plus tard',
    invalid: 'Email et mot de passe invalides',
    or: 'ou',
    setCookie: 'Création du cookie en cours...',
    connection: 'Connexion',
    invitation: 'Invitation',
    email: 'Email',
    passkey: "une clé d'accès",
  },
  input: {
    accountNotActive: "Votre compte n'est pas activé, contactez support@dametis.com",
    email: {
      label: 'Email',
      error: {
        userNotFound: 'Utilisateur inexistant',
        emailNotFound: 'Email inexistant',
        token: 'Token invalide',
        tokenPassword: "Lien invalide, essayez d'en générer un nouveau",
      },
    },
    password: {
      label: 'Mot de passe',
      error: {
        wrongPassword: 'Mot de passe erroné',
        wrongEmailOrPassword: 'Email ou mot de passe erroné',
        invalidStrength: 'Veuillez choisir un mot de passe plus sûr. Essayez un mélange de lettres, chiffres et symboles.',
      },
    },
    newPassword: {
      label: 'Nouveau mot de passe',
      success: 'Changement de mot de passe réussi, connectez-vous !',
      error: {
        mismatch: 'Les mots de passe sont différents',
        invalidToken: 'Lien invalide',
        noTokenFound: 'Token invalide',
      },
    },
    confirmPassword: {
      label: 'Confirmez votre mot de passe',
    },
  },
  button: {
    logIn: 'Se connecter',
    recover: 'Mot de passe oublié ?',
    reset: 'Valider',
    update: 'Modifier',
    loginWith: 'Se connecter avec {{ auth }}',
    next: 'Suivant',
    login: 'Se connecter',
    retry: 'Réessayer',
  },
  link: {
    alwaysShowGoogle: 'Toujours afficher',
  },
  alertMessage: {
    invalidEmail: 'Email invalide',
    wrongEmail: 'Email inexistant',
    successRecoveryEmail: 'Si un compte existe pour cette adresse e-mail, vous recevrez sous peu un lien de récupération de mot de passe',
    emptyField: 'Veuillez remplir tous les champs',
    successChangePassword: 'Changement de mot de passe réussi, connectez-vous ',
  },
  reconnect: {
    error: 'Une erreur est survenue pendant la connexion.',
  },
  tooltip: {
    showPassword: 'Afficher le mot de passe',
    hidePassword: 'Cacher le mot de passe',
    refresh: 'Rafraîchir',
  },
  twoFA: {
    email: 'Recevoir un code par email',
    phone: 'Recevoir un code par SMS',
    totp: "Application d'authentification",
    passkey: "Utiliser une clé d'accès",
    selectMethod: "Sélectionnez votre méthode d'authentification",
    chooseOtherMethod: 'Précédent',
    changeAccount: 'Précédent',
    errorCode: 'Code invalide',
    enterTOTPCode: "Saisissez le code généré par votre application d'authentification.",
    enrollment: {
      explanation: "Votre organisation vous demande de configurer un second moyen d'authentification",
      selectMethod: "Sélectionnez votre méthode d'authentification",
      email: 'Configurer par email',
      phone: 'Configurer par SMS',
      otp: "Configurer par application d'authentification",
      later: 'Plus tard',
      previous: 'Précédent',
      emailTitle: 'Entrez votre adresse email',
      phoneTitle: 'Entrez votre numéro de téléphone',
      codeTitle: 'Entrez le code reçu par SMS',
      emailError: 'Email invalide',
      phoneError: 'Numéro de téléphone invalide',
      code: 'Code de vérification',
      disconnect: 'Déconnexion',
    },
  },
  sso: {
    notFound: 'Vous n’avez pas été invité à créer un compte. Veuillez contacter {{ admin }} pour recevoir un lien d’invitation.',
    expired: 'Le lien dont vous disposez est expiré, veuillez vous adresser à {{ admin }} pour en avoir un nouveau.',
    invalidId: "Nous n'avons pas pu vérifier votre identité. Veuillez réessayer ou vous rapprocher de {{ admin }}.",
    invitation: 'Vous avez été invité à rejoindre le site {{ site }} - {{ group }} par {{ username }}.',
    askMail: 'Veuillez renseigner votre adresse email professionnelle.',
    yourAdmin: 'votre administrateur',
  },
  webauthn: {
    error: {
      authenticator: "Votre authentificateur n'a pas pu générer de clés d'accès",
    },
  },
};

import {
  Box,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  OutlinedInputProps,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Tooltip,
  Typography,
  selectClasses,
} from '@mui/material';
import { FormikProps, getIn } from 'formik';
import { ChangeEventHandler, FC, KeyboardEventHandler, useCallback, useEffect, useId, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AUTO_GROUPBY, MUTED_GROUPBY, timeGroupBy as TimeGroupBy, TimeUnit, VariableDelay, timeIntervalRegex } from '@dametis/core';
import { UnitName } from '@dametis/unit';

import Info from 'components/UI/Info/Info';
import { getFormattedValueUncomposed } from 'components/UI/UnitPicker/functions/getFormattedValue';
import { getUnitName } from 'components/UI/UnitPicker/functions/getUnitName';
import getVariableDelayLabel from 'functions/getVariableDelayLabel';

enum Mode {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
  MUTED = 'MUTED',
  LIVE = 'LIVE',
}

const selectableUnits = [TimeUnit.SECOND, TimeUnit.MINUTE, TimeUnit.HOUR, TimeUnit.DAY];

const convertTimeUnitToUnitName = (unit: TimeUnit) => (unit === TimeUnit.MINUTE ? UnitName.MINUTE : unit);

const parseTimeGroupBy = (timeGroupBy: `${TimeGroupBy}`): { value: `${number}` | ''; unit: TimeUnit } => {
  const [, value = '', unit] = timeGroupBy.match(/^(\d+)?(ms|s|m|h|d|w|mo|y)$/) as [string, `${number}`, TimeUnit];
  return { value, unit };
};

export const convertDelayToNumber = (delay?: VariableDelay): number | null => {
  if (delay === undefined) {
    return null;
  }
  if (delay === AUTO_GROUPBY || delay === MUTED_GROUPBY) {
    return null;
  }
  const match = (delay as TimeGroupBy).match(timeIntervalRegex) as [string, `${number}`, TimeUnit] | null;
  if (match) {
    const [, newValue, newUnit] = match;
    return (
      getFormattedValueUncomposed({
        value: Number(newValue),
        baseUnit: convertTimeUnitToUnitName(newUnit),
        userUnit: TimeUnit.MILLISECOND,
      })?.value ?? null
    );
  }
  return null;
};

export interface DelayPickerProps extends Omit<OutlinedInputProps, 'value' | 'onChange'> {
  canBeMuted?: boolean;
  canBeLive?: boolean;
  defaultValue?: TimeGroupBy;
  disabled?: boolean;
  editing?: boolean;
  emptyLabel?: string;
  fixedLayout?: boolean;
  fontSize?: string;
  formik?: FormikProps<any>;
  label?: string;
  labelTooltip?: string;
  multiline?: boolean;
  name: string;
  onChange?: (newValue: VariableDelay | `${TimeUnit}` | '') => void;
  required?: boolean;
  value?: VariableDelay | `${TimeUnit}` | '';
}

const DelayPicker: FC<DelayPickerProps> = ({
  canBeLive = false,
  canBeMuted = false,
  defaultValue = undefined,
  disabled = false,
  editing = true,
  emptyLabel = undefined,
  fixedLayout = false,
  fontSize = undefined,
  formik = undefined,
  label = undefined,
  labelTooltip = undefined,
  multiline = false,
  name,
  onChange = undefined,
  required = false,
  sx,
  value: rawValue = undefined,
  ...props
}) => {
  const { t } = useTranslation('delayPicker');

  const [amount, setAmount] = useState<`${number}` | ''>('');
  const [unit, setUnit] = useState<TimeUnit>(TimeUnit.SECOND);
  const [mode, setMode] = useState<Mode>(Mode.DEFAULT);

  const uuid = useId();
  const defaultValueInfo = useMemo(() => (defaultValue ? parseTimeGroupBy(defaultValue) : null), [defaultValue]);
  const value = useMemo(() => {
    const initialValue = formik ? getIn(formik.values, name) : rawValue;
    return initialValue !== AUTO_GROUPBY && initialValue !== MUTED_GROUPBY && !timeIntervalRegex.test(initialValue) ? '' : initialValue;
  }, [formik, name, rawValue]);

  const shownValue = useMemo(() => getVariableDelayLabel(value, defaultValue), [defaultValue, value]);

  const sendValue = useCallback(
    async (newValue: VariableDelay | `${TimeUnit}`) => {
      if (formik) {
        await formik.setFieldValue(name, newValue);
      }
      onChange?.(newValue);
    },
    [formik, name, onChange],
  );

  const changeMode = useCallback(
    async (newMode: Mode) => {
      setMode(newMode);
      switch (newMode) {
        case Mode.MUTED:
          await sendValue(MUTED_GROUPBY);
          break;
        case Mode.LIVE:
          await sendValue('0s');
          break;
        case Mode.CUSTOM: {
          await sendValue(`${((amount || defaultValueInfo?.value) as `${number}`) || ''}${unit}`);
          break;
        }
        case Mode.DEFAULT:
        default:
          await sendValue(AUTO_GROUPBY);
      }
    },
    [amount, defaultValueInfo?.value, sendValue, unit],
  );

  const handleKeyDownAmount = useCallback<KeyboardEventHandler>(event => {
    if (['e', 'E', '+', '-', '.'].includes(event.key)) {
      event.preventDefault();
    }
  }, []);

  const handleChangeAmount: ChangeEventHandler<HTMLInputElement> = useCallback(
    async event => {
      const newValue = event.target.value.replace(/^0+(?!$)/, '') as `${number}` | '';
      setAmount(newValue);
      await sendValue(`${newValue}${unit}`);
    },
    [sendValue, unit],
  );

  const handleChangeUnit = useCallback(
    async (event: SelectChangeEvent) => {
      const newUnit = event.target.value as TimeUnit;
      setUnit(newUnit);
      await sendValue(`${amount}${newUnit}`);
    },
    [amount, sendValue],
  );

  const handleChangeMode = useCallback(
    async (event: SelectChangeEvent) => {
      await changeMode(event.target.value as Mode);
    },
    [changeMode],
  );

  const handleCheckLive = useCallback(async () => {
    await changeMode(mode === Mode.LIVE ? Mode.CUSTOM : Mode.LIVE);
  }, [changeMode, mode]);

  const handleCheckMuted = useCallback(async () => {
    await changeMode(mode === Mode.MUTED ? Mode.CUSTOM : Mode.MUTED);
  }, [changeMode, mode]);

  useEffect(() => {
    switch (value) {
      case MUTED_GROUPBY:
        setMode(Mode.MUTED);
        break;
      case AUTO_GROUPBY:
        setMode(Mode.DEFAULT);
        break;
      case '':
        setAmount('');
        setMode(Mode.CUSTOM);
        break;
      default: {
        const parsedValue = parseTimeGroupBy(value);
        if (Number(parsedValue.value) || !canBeLive) {
          setAmount(parsedValue.value);
          setUnit(parsedValue.unit);
          setMode(Mode.CUSTOM);
        } else {
          setMode(Mode.LIVE);
        }
      }
    }
  }, [canBeLive, value]);

  if (!editing) {
    return (
      <Stack>
        {label && (
          <Stack direction="row" spacing={0.5}>
            <InputLabel required={required} sx={{ fontSize }}>
              {label}
            </InputLabel>
            {labelTooltip && <Info title={labelTooltip} />}
          </Stack>
        )}
        <Typography color={disabled ? 'rgba(0, 0, 0, 0.38)' : undefined} fontSize={fontSize} variant="body1">
          {value === '' ? (emptyLabel ?? t('text.noValue')) : shownValue}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack spacing={0.7} width="100%">
      {label && (
        <Stack direction="row" spacing={0.5}>
          <InputLabel required={required}>{label}</InputLabel>
          {labelTooltip && <Info title={labelTooltip} />}
        </Stack>
      )}
      <Grid container columnSpacing={1} rowSpacing={multiline ? 0.7 : undefined}>
        {defaultValue === undefined && !canBeLive && canBeMuted && (
          <Grid item alignItems="center" display="flex" height={38} xs={multiline ? 12 : 4}>
            <Stack alignItems="center" direction="row" spacing={1} sx={{ cursor: 'pointer' }} onClick={handleCheckMuted}>
              <Switch checked={mode === Mode.MUTED} size="small" />
              <Typography>{t('select.muted')}</Typography>
            </Stack>
          </Grid>
        )}
        {defaultValue === undefined && canBeLive && !canBeMuted && (
          <Grid item alignItems="center" display="flex" height={38} xs={multiline ? 12 : 4}>
            <Stack alignItems="center" direction="row" spacing={1} sx={{ cursor: 'pointer' }} onClick={handleCheckLive}>
              <Switch checked={mode === Mode.LIVE} size="small" />
              <Typography>{t('select.live')}</Typography>
            </Stack>
          </Grid>
        )}
        {(defaultValue !== undefined || (canBeLive && canBeMuted)) && (
          <Grid item alignItems="start" display="flex" xs={multiline ? 12 : 4}>
            <Select fullWidth required={required} sx={{ fontSize }} value={mode} onChange={handleChangeMode}>
              <MenuItem value={Mode.CUSTOM}>
                <Tooltip placement="right" title={t('tooltip.custom')}>
                  <Box height="100%" width="100%">
                    {t('select.custom')}
                  </Box>
                </Tooltip>
              </MenuItem>
              {defaultValue !== undefined && (
                <MenuItem value={Mode.DEFAULT}>
                  <Tooltip placement="right" title={t('tooltip.default')}>
                    <Box height="100%" width="100%">
                      {t('select.default')}
                    </Box>
                  </Tooltip>
                </MenuItem>
              )}
              {canBeLive && (
                <MenuItem value={Mode.LIVE}>
                  <Tooltip placement="right" title={t('tooltip.live')}>
                    <Box height="100%" width="100%">
                      {t('select.live')}
                    </Box>
                  </Tooltip>
                </MenuItem>
              )}
              {canBeMuted && (
                <MenuItem value={Mode.MUTED}>
                  <Tooltip placement="right" title={t('tooltip.muted')}>
                    <Box height="100%" width="100%">
                      {t('select.muted')}
                    </Box>
                  </Tooltip>
                </MenuItem>
              )}
            </Select>
          </Grid>
        )}
        {(mode === Mode.CUSTOM || mode === Mode.DEFAULT) && (
          <Grid
            item
            alignItems="center"
            display="flex"
            justifyContent="flex-start"
            mt={multiline ? 0.7 : undefined}
            xs={multiline || (defaultValue === undefined && !canBeLive && !canBeMuted) ? 12 : 8}
          >
            {(mode === Mode.CUSTOM || (mode === Mode.DEFAULT && defaultValue)) && (
              <Stack width="100%">
                <OutlinedInput
                  fullWidth
                  aria-describedby={`${uuid}-input-helper`}
                  disabled={disabled || mode !== Mode.CUSTOM}
                  endAdornment={
                    <InputAdornment position="end">
                      <Select
                        disabled={disabled || mode !== Mode.CUSTOM}
                        sx={{ fontSize, [`.${selectClasses.select} ~ fieldset`]: { display: 'none' } }}
                        value={mode === Mode.CUSTOM ? unit : defaultValueInfo?.unit}
                        onChange={handleChangeUnit}
                      >
                        {selectableUnits.map(selectableUnit => (
                          <MenuItem key={selectableUnit} value={selectableUnit}>
                            {getUnitName(convertTimeUnitToUnitName(selectableUnit), t)}
                          </MenuItem>
                        ))}
                      </Select>
                    </InputAdornment>
                  }
                  error={formik ? Boolean(getIn(formik.errors, name)) : false}
                  inputProps={{ inputMode: 'numeric', min: canBeLive ? 0 : 1 }}
                  name={name}
                  required={required}
                  sx={{ pr: 0, ...sx }}
                  type="number"
                  value={mode === Mode.CUSTOM ? amount : defaultValueInfo?.value}
                  onChange={handleChangeAmount}
                  onKeyDown={handleKeyDownAmount}
                  {...props}
                />
              </Stack>
            )}
            {mode === Mode.DEFAULT && defaultValue === MUTED_GROUPBY && (
              <Typography color="black" variant="subtitle2">
                {t('select.muted')}
              </Typography>
            )}
          </Grid>
        )}
        {formik && (fixedLayout || getIn(formik.errors, name)) && (
          <>
            {(multiline || Number(defaultValue === undefined) + Number(canBeLive) + Number(canBeMuted) !== 1) && <Grid item xs={4} />}
            <Grid
              item
              mt={0.3}
              xs={multiline || Number(defaultValue === undefined) + Number(canBeLive) + Number(canBeMuted) === 1 ? 12 : 8}
            >
              <FormHelperText error id={`${uuid}-input-helper`} sx={{ minHeight: 13, mt: 0 }}>
                {getIn(formik.errors, name)}
              </FormHelperText>
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
};

export default DelayPicker;

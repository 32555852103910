import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { LexicalEditor, SerializedEditorState, SerializedElementNode } from 'lexical';
import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IsCommentOnAlarmBody, IsCommentOnReportBody, IsCommentOnVariableBody } from '@dametis/core';

import { Hotkeys } from 'components/UI/Hotkeys/Hotkeys';
import getUserName from 'functions/getUserName';
import { localizedFormat, localizedFormatDistanceToNow } from 'localization/localizedDateFns';
import { useSelector } from 'store';
import { EntityType } from 'types/comment';

import PromptBeforeDeleting from '../../PromptBeforeDeleting/PromptBeforeDeleting';
import UserAvatar from '../../UserAvatar/UserAvatar';
import { useCommentsContext } from '../context/CommentsContext';

import { CommentProps } from './Comment';
import CommentOptionsFooter from './CommentOptionsFooter';
import CommentEditor from './Edit/CommentEditor';
import { useCommentContext } from './context/CommentContext';

export const SUBMIT_HOTKEY = `mod+Enter`;

const CommentBase: FC<CommentProps> = ({
  comment,
  stackProps = {},
  onDateClick,
  onPeriodClick,
  onDeleteCb,
  onUpdateCb,
  dateFormat,
  displayActions,
  backgroundColor,
  datePicker,
}) => {
  const { t } = useTranslation('comment');

  const { currentComment, setCurrentComment, entity } = useCommentContext();
  const { editComment, removeComment } = useCommentsContext();

  const user = useSelector(state => state.auth.user!);

  const editing = useMemo(() => Boolean(currentComment), [currentComment]);
  const { owner, createdAt, message: content } = useMemo(() => comment, [comment]);
  const canEdit = useMemo(() => user.uuid === owner?.uuid, [owner, user]);
  const displayFooter = useMemo(
    () => IsCommentOnAlarmBody(comment) || IsCommentOnReportBody(comment) || IsCommentOnVariableBody(comment),
    [comment],
  );
  const joinProject = useMemo(() => entity?.type !== EntityType.TASK && entity?.type !== EntityType.PROJECT, [entity?.type]);

  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const editorRef = useRef<LexicalEditor | null>(null);

  const handleCancel = useCallback(() => {
    if (editorRef.current === null) {
      return;
    }
    const editorState = editorRef.current.parseEditorState(content);
    editorRef.current.setEditorState(editorState);
    setCurrentComment?.(null);
  }, [content, setCurrentComment]);

  const handleUpdate = useCallback(async () => {
    setIsLoading(true);
    if (editorRef.current === null || currentComment === null) {
      return;
    }
    const editorState = editorRef.current.getEditorState();
    try {
      editorRef.current.setEditable(false);
      const newComment = {
        ...currentComment,
        message: editorState.toJSON() as SerializedEditorState<SerializedElementNode>,
      };
      await editComment(newComment, comment.uuid);
      onUpdateCb?.(newComment);
      setCurrentComment(null);
    } catch {
      editorRef.current.setEditable(true);
    }
    setIsLoading(false);
  }, [comment.uuid, currentComment, editComment, setCurrentComment, onUpdateCb]);

  const handleEditComment = useCallback(() => setCurrentComment && setCurrentComment(comment), [comment, setCurrentComment]);

  const handleDeleteComment = useCallback(async () => {
    onDeleteCb?.();
    await removeComment(comment.uuid);
  }, [comment.uuid, removeComment, onDeleteCb]);

  return (
    <Stack
      {...stackProps}
      direction="column"
      spacing={1}
      sx={{ ...stackProps.sx, width: 1, '& .actions': { opacity: 0 }, '&:hover .actions': { opacity: 1 } }}
    >
      {owner !== undefined && createdAt !== undefined && (
        <Stack alignItems="center" direction="row" spacing={1} sx={{ position: 'relative' }}>
          <UserAvatar user={owner} />
          <Stack alignItems="center" direction="row" flexWrap="wrap">
            <Typography noWrap mr={1} variant="h6">
              {getUserName(owner)}
            </Typography>
            <Tooltip placement="right" title={dateFormat === 'relative' ? localizedFormat(new Date(createdAt), 'Pp') : undefined}>
              <Typography noWrap variant="body2">
                {dateFormat === 'relative'
                  ? localizedFormatDistanceToNow(new Date(createdAt), { addSuffix: true })
                  : localizedFormat(new Date(createdAt), 'Ppp')}
              </Typography>
            </Tooltip>
          </Stack>
        </Stack>
      )}
      <Box
        sx={theme => ({
          background: theme.palette.white,
          borderRadius: '6px',
          position: 'relative',
          marginLeft: `${theme.spacing(4)} !important`,
          flexGrow: 1,
        })}
      >
        {displayActions && !editing && (
          <Stack
            className="actions"
            direction="row"
            sx={theme => ({
              position: 'absolute',
              top: 0,
              right: 0,
              backgroundColor: theme.palette.background.default,
              zIndex: 1,
              m: theme.spacing(0.5),
            })}
          >
            {canEdit && (
              <Tooltip placement="top" title={t('tooltip.editComment')}>
                <IconButton size="small" onClick={handleEditComment}>
                  <EditOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {canEdit && (
              <>
                <Tooltip placement="top" title={t('tooltip.deleteComment')}>
                  <IconButton size="small" onClick={() => setDeleteModalOpen(true)}>
                    <DeleteOutlined fontSize="small" />
                  </IconButton>
                </Tooltip>
                <PromptBeforeDeleting open={deleteModalOpen} setOpen={setDeleteModalOpen} onDelete={handleDeleteComment} />
              </>
            )}
          </Stack>
        )}
        <CommentEditor
          backgroundColor={backgroundColor}
          commentFooter={
            displayFooter ? (
              <CommentOptionsFooter
                comment={comment}
                datePicker={datePicker}
                loading={loading}
                onDateClick={onDateClick}
                onPeriodClick={onPeriodClick}
              />
            ) : undefined
          }
          editing={editing}
          editorRef={editorRef}
          editorState={content}
          inlineSubmitButton={
            <Stack alignItems="center" flexDirection="row" justifyContent="end">
              <Button
                color="primary"
                disabled={loading}
                size="small"
                sx={{ mr: theme => theme.spacing(1) }}
                variant="text"
                onClick={handleCancel}
              >
                {t('button.cancel')}
              </Button>
              <Tooltip placement="bottom-end" title={isEmpty || loading ? '' : <Hotkeys hotkeys={SUBMIT_HOTKEY} size="small" />}>
                <div>
                  <LoadingButton
                    color="secondary"
                    disabled={isEmpty || loading}
                    loading={loading}
                    size="small"
                    variant="contained"
                    onClick={handleUpdate}
                  >
                    {t('button.submit')}
                  </LoadingButton>
                </div>
              </Tooltip>
            </Stack>
          }
          joinProject={joinProject}
          setIsEmpty={setIsEmpty}
          onCmdEnter={handleUpdate}
        />
      </Box>
    </Stack>
  );
};

export default memo(CommentBase);

import { DeleteOutlined } from '@mui/icons-material';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IsVirtualColumnColumn,
  IsVirtualColumnSeparator,
  IsVirtualColumnText,
  SourceColumnRealByHeader,
  SourceColumnRealByIndex,
  VirtualColumnElement,
} from '@dametis/core';

import VirtualColumnColumnTile from './VirtualColumnColumnTile';
import VirtualColumnSeparatorTile from './VirtualColumnSeparatorTile';
import VirtualColumnTextTile from './VirtualColumnTextTile';

export interface VirtualColumnElementTileProps {
  element: VirtualColumnElement;
  onChange: (newElement: VirtualColumnElement) => void;
  availableRealByIndexColumns: SourceColumnRealByIndex[];
  availableRealByHeaderColumns: SourceColumnRealByHeader[];
  availableVirtualColumnNames: string[];
  onDelete: () => void;
}

const VirtualColumnElementTile: FC<VirtualColumnElementTileProps> = ({
  element,
  onChange,
  availableRealByIndexColumns,
  availableRealByHeaderColumns,
  availableVirtualColumnNames,
  onDelete,
}) => {
  const { t } = useTranslation('fileImport');

  const handleDeleteElement = useCallback(() => {
    onDelete();
  }, [onDelete]);

  return (
    <>
      <Paper
        component={Stack}
        gap={0.5}
        p={1}
        sx={{ '& .deleteElementButton': { opacity: 0 }, '&:hover .deleteElementButton': { opacity: 1 } }}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography variant="overline">{t(`virtualColumnElementType.${element.type}`)}</Typography>
          <IconButton className="deleteElementButton" sx={{ p: 0 }} onClick={handleDeleteElement}>
            <DeleteOutlined color="icon" sx={{ fontSize: 16 }} />
          </IconButton>
        </Stack>
        {IsVirtualColumnColumn(element) && (
          <VirtualColumnColumnTile
            availableRealByHeaderColumns={availableRealByHeaderColumns}
            availableRealByIndexColumns={availableRealByIndexColumns}
            availableVirtualColumnNames={availableVirtualColumnNames}
            element={element}
            onChange={onChange}
          />
        )}
        {IsVirtualColumnText(element) && <VirtualColumnTextTile element={element} onChange={onChange} />}
        {IsVirtualColumnSeparator(element) && <VirtualColumnSeparatorTile element={element} onChange={onChange} />}
      </Paper>
    </>
  );
};

export default VirtualColumnElementTile;

import { List, ListItem, ListItemButton, ListItemText, ListSubheader, Typography } from '@mui/material';
import { FC, MouseEventHandler, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PropsContext } from 'components/VNC/context';
import { calculationToString } from 'functions/calculationToString';
import { useVncStore } from 'zustand/stores/vnc';

import { useInsertVariable } from '../../../hooks';

const BlockTypeElementList: FC = () => {
  const { t } = useTranslation('vnc');

  const insertVariable = useInsertVariable();
  const { editingBlockTypeMetricUuid, disableMaths, defaultVariableOperator, calculatedVariableMode } = useContext(PropsContext);

  const unPostedBlockType = useVncStore(state => state.lego.unPostedBlockType);

  const handleSelectParameter = useCallback(
    (index: number): MouseEventHandler<HTMLDivElement> =>
      () => {
        const parameter = unPostedBlockType?.parameters?.[index];
        if (parameter) {
          insertVariable({ ...parameter, blockId: undefined }, disableMaths, defaultVariableOperator ?? undefined, calculatedVariableMode);
        }
      },
    [disableMaths, insertVariable, unPostedBlockType?.parameters, defaultVariableOperator, calculatedVariableMode],
  );

  const handleSelectMetric = useCallback(
    (index: number): MouseEventHandler<HTMLDivElement> =>
      () => {
        const metric = unPostedBlockType?.metrics?.[index];
        if (metric) {
          insertVariable(
            { blockKey: metric.blockKey, blockId: undefined },
            disableMaths,
            defaultVariableOperator ?? undefined,
            calculatedVariableMode,
          );
        }
      },
    [disableMaths, insertVariable, unPostedBlockType?.metrics, defaultVariableOperator, calculatedVariableMode],
  );

  return (
    <>
      <List subheader={<li />} sx={{ position: 'relative', maxHeight: '100%', overflow: 'auto', '& ul': { p: 0 } }}>
        <li>
          <ul>
            <ListSubheader disableGutters>{t('subtitle.parameters')}</ListSubheader>
            {unPostedBlockType?.parameters?.map((parameter, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItemButton key={index} disableGutters onClick={handleSelectParameter(index)}>
                <ListItemText
                  primary={
                    parameter.unit?.length !== 0
                      ? t('text.availableParameter', { blockKey: parameter.blockKey, unit: parameter.unit })
                      : parameter.blockKey
                  }
                />
              </ListItemButton>
            ))}
            {!unPostedBlockType?.parameters?.length && (
              <ListItem>
                <Typography variant="subtitle2">{t('text.noParameter')}</Typography>
              </ListItem>
            )}
          </ul>
        </li>
        <li>
          <ul>
            <ListSubheader disableGutters>{t('subtitle.metrics')}</ListSubheader>
            {unPostedBlockType?.metrics?.map((metric, index) => (
              <ListItemButton
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                disableGutters
                disabled={metric.uuid === editingBlockTypeMetricUuid}
                onClick={handleSelectMetric(index)}
              >
                <ListItemText
                  primary={metric.blockKey.trim().length > 0 ? metric.blockKey : t('text.unNamedMetric')}
                  secondary={calculationToString(metric.calculation)}
                  sx={{ fontStyle: metric.blockKey.trim().length > 0 ? 'normal' : 'italic' }}
                />
              </ListItemButton>
            ))}
            {!unPostedBlockType?.metrics?.length && (
              <ListItem>
                <Typography variant="subtitle2">{t('text.noMetric')}</Typography>
              </ListItem>
            )}
          </ul>
        </li>
      </List>
    </>
  );
};

export default BlockTypeElementList;

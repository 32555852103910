import { ParseError, ParseErrorType } from '@dametis/core';

export const LABEL_HEIGHT = 20;
export const ERROR_HEIGHT = 15;
export const GAP_HEIGHT = 4;
export const MB_HEIGHT = 12;

export enum VirtualListItemType {
  GLOBAL_PARSE_ERRORS = 'globalParseErrors',
  ROW_PARSE_ERRORS = 'rowParseErrors',
}

export interface VirtualListItemContents {
  [VirtualListItemType.GLOBAL_PARSE_ERRORS]: ParseError<ParseErrorType.PAPA>[];
  [VirtualListItemType.ROW_PARSE_ERRORS]: ParseError[];
}

export type VirtualListItemContent<T extends VirtualListItemType = VirtualListItemType> = VirtualListItemContents[T];

export interface VirtualListItem<T extends VirtualListItemType = VirtualListItemType> {
  type: T;
  content: VirtualListItemContent<T>;
}

export type VirtualListItemGlobalParseErrors = VirtualListItem<VirtualListItemType.GLOBAL_PARSE_ERRORS>;

export const IsVirtualListItemGlobalParseErrors = (item: VirtualListItem): item is VirtualListItemGlobalParseErrors =>
  item?.type === VirtualListItemType.GLOBAL_PARSE_ERRORS;

export type VirtualListItemRowParseErrors = VirtualListItem<VirtualListItemType.ROW_PARSE_ERRORS>;

export const IsVirtualListItemRowParseErrors = (item: VirtualListItem): item is VirtualListItemRowParseErrors =>
  item?.type === VirtualListItemType.ROW_PARSE_ERRORS;

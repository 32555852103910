import { ShortcutCategory } from '@dametis/core';

export default {
  title: 'Site settings',
  siteMenu: {
    informations: {
      name: 'Information',
    },
    notifications: {
      name: 'Notifications',
    },
    usersManagement: {
      name: 'User management',
    },
    users: {
      name: 'Users',
    },
    invitations: {
      name: 'Invitations',
    },
    teams: {
      name: 'Teams',
    },
    periods: {
      name: 'Periods',
    },
    units: {
      name: 'Units',
    },
    styledRules: {
      name: 'Rules lists',
    },
  },
  informations: {
    form: {
      siteName: 'Site name',
      groupName: 'Group name',
      defaultReport: 'Home default report',
      selectReport: 'Select a report',
      noReport: 'No default report',
      address: 'Address',
      language: 'Site language',
      timeZone: 'Time zone',
      noTimeZoneFound: 'No time zone found',
    },
    presentation: {
      site: 'Site',
      group: 'Group',
      address: 'Address',
    },
  },
  users: {
    button: {
      invite: 'Invite user',
      inviteLink: 'Invite via link',
      create: 'Team',
    },
    searchNotFound: 'No users found with this search',
    userTile: {
      firstName: 'First name',
      lastName: 'Last name',
      phone: 'Phone',
      email: 'Email',
      statusInactive: 'Account not activated',
      statusInvite: 'Invitation sent',
      inviteButton: 'Invite',
      clickToCopy: 'Click to copy',
    },
  },
  periods: {
    button: {
      addPeriod: 'Period',
    },
  },
  toast: {
    successSaveChanges: 'The changes have been saved.',
    errorSaveChanges: 'Unable to save changes',
    infoUserInvitation: 'An invitation email has been sent.',
    errorUserInvitation: 'Unable to send the email invitation',
    userAlreadyExists: 'This email address is already associated with an account.',
    successNewPeriod: 'The new period is recorded.',
    successRemovePeriod: 'The period has been suppressed.',
    updateNotificationsSuccess: 'Notifications updated successfully.',
    updateNotificationsError: 'An error occured while updating the notifications.',
  },
  tooltip: {
    editNotifications: 'edit notications',
    createTeam: 'create a team',
    createPeriod: 'create a period',
    createRuleList: 'create a rule list',
    editSiteInfo: 'edit site informations',
    period: 'a period',
    editSiteInfos: 'edit site settings',
    inviteUser: 'invite a new user',
    editStyleConfiguration: 'Edit',
    deleteStyleConfiguration: 'Delete',
    hasGroupAcl: 'Cannot update rights because they are set upright',
    mustChangeRole: 'You must select an another role to edit sites',
    setMCO: 'Select for monitoring',
    mcoNotifications: 'Selected for monitoring',
    selectReport: 'Select report',
    removeReport: 'Remove report',
  },
  text: {
    newList: 'Create a team',
    users: 'Users',
    editList: 'Edit team: {{name}}',
    chosenUnit_zero: 'No unit defined',
    chosenUnit_one: 'One unit defined',
    chosenUnit_other: '{{count}} units defined',
    noStyledRules: 'No styled rule',
    entity: {
      [ShortcutCategory.PLAYGROUND]: 'Playground : {{name}}',
    },
    csvModel: 'value;label\n1;Example Device 1\n',
  },
  input: {
    userListName: 'Team name',
    search: 'Search...',
  },
  button: {
    add: 'Add',
    close: 'Close',
    delete: 'Delete',
    cancel: 'Cancel',
    create: 'Create',
    update: 'Update',
    edit: 'Edit',
    save: 'Save',
    newStyle: 'List',
    newRule: 'Add rule',
    clear: 'Clear',
    importFrom: 'Import...',
    existingRules: 'Existing rules',
    matchingList: 'Matching list',
    import: 'Import',
    next: 'Next',
    submit: 'Submit',
  },
  modal: {
    title: 'Edit user',
    addTitle: 'Add user',
    userSiteList: 'User sites',
    warningEmptySites: 'User must have at least one site',
    createStyleConfigurationModal: 'New rules list',
    editStyleConfigurationModal: 'Edit a rules list',
    importStyledRulesFrom: 'Import rules from...',
    saveStyleConfigurationModal: 'Save this rules list',
    personalInformation: 'Personal Information',
    permissions: 'Permissions',
    corporate: 'Group access',
  },
  label: {
    name: 'Name',
    rules: 'Rules',
  },
  select: {
    role: 'Role',
    roleSITE_ADMINPrimary: 'Administrator',
    roleSITE_ADMINSecondary: 'Administration, read and write',
    roleCLIENTPrimary: 'Standard user',
    roleCLIENTSecondary: 'Read and write',
    roleReadOnlyPrimary: 'Limited user',
    roleReadOnlySecondary: 'Read only',
    roleCUSTOMPrimary: 'Custom user',
    noGroupRole: 'No group role',
  },
  pings: {
    title: 'Monitoring notifications',
    mails: 'Mails',
    externalMails: 'Customers mails',
    internalMails: 'Dametis mails',
    slack: 'Slack channel',
    addExternal: 'Add an customer mail',
    addInternal: 'Add an Dametis mail',
    changePeriod: 'Change period',
    calendar: 'Calendar',
  },
};

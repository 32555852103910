import { OperationStatus, OperationType } from '@dametis/core';

export default {
  userMenu: {
    logOut: 'Se déconnecter',
    myAccount: 'Mon compte',
    support: 'Aide et assistance',
    stopImpersonation: 'Reprendre votre identité',
  },
  siteSelect: {
    group: 'Groupe',
    groups: 'Groupes',
    sites: 'Sites',
    site: 'Site',
    corporate: 'Corporate',
  },
  statusMenu: {
    status: 'Connexion',
    msg_normal: 'Normale',
    msg_warning: 'Anomalie',
    msg_critical: 'Problème',
    msg_noBox: 'Absence de box',
  },
  operationMenu: {
    title: 'Operations',
    clear: 'Vider',
    tooltip_none: 'Aucune opération en cours',
    tooltip_one: '{{count}} opération en cours',
    tooltip_other: '{{count}} opérations en cours',
    tooltip: {
      [OperationStatus.LOADING]: {
        text_one: '{{count}} opération en cours',
        text_other: '{{count}} opérations en cours',
      },
      [OperationStatus.CANCELLED]: {
        text_one: '{{count}} opération annulée',
        text_other: '{{count}} opérations annulées',
      },
      [OperationStatus.SUCCESS]: {
        text_one: '{{count}} opération réussie',
        text_other: '{{count}} opérations réussies',
      },
      [OperationStatus.WARNING]: {
        text_one: '{{count}} avertissement',
        text_other: '{{count}} avertissements',
      },
      [OperationStatus.ERROR]: {
        text_one: '{{count}} erreur',
        text_other: '{{count}} erreurs',
      },
    },
    buttonLabel: {
      over_one: '{{count}} terminée',
      over_other: '{{count}} terminées',
      warning_one: '{{count}} alerte',
      warning_other: '{{count}} alertes',
      error_one: '{{count}} erreur',
      error_other: '{{count}} erreurs',
    },
    operations: {
      [OperationType.CREATE_CALC_VAR]: {
        name: "Création d'une variable calculée",
        name_withName: 'Création de {{name}}',
        [`description_${OperationStatus.SUCCESS}`]: 'Variable créée avec succès.',
        [`description_${OperationStatus.CANCELLED}`]: 'Variable annulée pendant le calcul.',
        [`description_${OperationStatus.WARNING}`]: "Variable créée mais au moins une dépendance n'a pas de données.",
        [`description_${OperationStatus.ERROR}`]: 'Expression invalide.',
      },
      [OperationType.EDIT_CALC_VAR]: {
        name: "Modification d'une variable calculée",
        name_withName: 'Modification de {{name}}',
        [`description_${OperationStatus.SUCCESS}`]: 'Variable modifiée avec succès.',
        [`description_${OperationStatus.CANCELLED}`]: 'Variable annulée pendant le calcul.',
        [`description_${OperationStatus.WARNING}`]: "Variable modifiée mais au moins une dépendance n'a pas de données.",
        [`description_${OperationStatus.ERROR}`]: 'Expression invalide.',
      },
      [OperationType.CREATE_BATCH]: {
        name: "Création d'un batch",
        name_withName: 'Création de {{name}}',
        [`description_${OperationStatus.SUCCESS}`]: 'Batch créé avec succès.',
        [`description_${OperationStatus.CANCELLED}`]: 'Batch annulé pendant le calcul.',
        [`description_${OperationStatus.WARNING}`]: 'Batch créé mais données manquantes.',
        [`description_${OperationStatus.ERROR}`]: 'Une erreur est survenue.',
      },
      [OperationType.EDIT_BATCH]: {
        name: "Modification d'un batch",
        name_withName: 'Modification de {{name}}',
        [`description_${OperationStatus.SUCCESS}`]: 'Batch modifié avec succès.',
        [`description_${OperationStatus.CANCELLED}`]: 'Batch annulé pendant le calcul.',
        [`description_${OperationStatus.WARNING}`]: 'Batch modifié mais données manquantes.',
        [`description_${OperationStatus.ERROR}`]: 'Une erreur est survenue.',
      },
      [OperationType.EDIT_DATA]: {
        name: 'Edition de données',
        name_withNumber: 'Edition de données de {{number}} variable(s)',
        [`description_${OperationStatus.SUCCESS}`]: 'Données éditées avec succès.',
        [`description_${OperationStatus.ERROR}`]: 'Une erreur est survenue.',
      },
      [OperationType.IMPORT_DATA]: {
        name: 'Importation de données',
        name_withNumber: 'Importation de données de {{number}} variable(s)',
        [`description_${OperationStatus.SUCCESS}`]: 'Données importées avec succès.',
        [`description_${OperationStatus.ERROR}`]: 'Une erreur est survenue.',
      },
      [OperationType.DELETE_DATA]: {
        name: 'Suppression de données',
        name_withNumber: 'Suppression de données de {{number}} variable(s)',
        [`description_${OperationStatus.SUCCESS}`]: 'Données supprimées avec succès.',
        [`description_${OperationStatus.ERROR}`]: 'Une erreur est survenue.',
      },
    },
  },
  helpMenu: {
    tooltip: 'Une question, un problème ou un bug ?',
    button: 'Support',
  },
  maintenance: {
    msg: 'La plateforme Dametis effectue actuellement une mise à jour importante.\nCertaines fonctionnalités peuvent être temporairement indisponibles ou ralenties mais seront de retour sous peu.',
    ovh: 'Notre hébergeur rencontre un incident majeur.\nCertaines fonctionnalités peuvent être temporairement indisponibles ou ralenties mais seront de retour sous peu.',
  },
  impersonate: {
    msg: "Vous utilisez maintenant l'identité de {{ firstName }} {{ lastName }}",
  },
  tooltip: {
    siteConfiguration: 'Configuration du site',
    groupConfiguration: 'Configuration du groupe',
    favorite: 'Ajouter aux favoris',
    unfavorite: 'Retirer des favoris',
  },
  helper: {
    menu: 'pour ouvrir ce menu',
  },
};

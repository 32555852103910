import {
  BlockMetadataBody,
  BlockMetric,
  BlockParameter,
  BlockTypeMetric,
  BlockTypeParameter,
  CreateBlockTypeBody,
  CreateFolderBody,
  CreateStandardBlockBody,
  StandardBlockMetadataBody,
  StandardBlockMetric,
  StandardBlockParameter,
  UpdateBlockTypeBody,
  UpdateFolderBody,
  UpdateStandardBlockBody,
} from '@dametis/core';

export type FolderBody = CreateFolderBody | UpdateFolderBody;

export type BlockTypeBody = CreateBlockTypeBody | UpdateBlockTypeBody;

export type StandardBlockBody = CreateStandardBlockBody | UpdateStandardBlockBody;

export type MetadataBody = StandardBlockMetadataBody | BlockMetadataBody;

export type LegoParameter = BlockTypeParameter | StandardBlockParameter | BlockParameter;

export const IsBlockTypeParameter = (parameter: any): parameter is BlockTypeParameter =>
  (parameter as StandardBlockParameter).isSelected === undefined && (parameter as BlockParameter).calculation === undefined;

export const IsStandardBlockParameter = (parameter: any): parameter is StandardBlockParameter =>
  (parameter as StandardBlockParameter).isSelected !== undefined && (parameter as BlockParameter).calculation === undefined;

export const IsBlockParameter = (parameter: any): parameter is BlockParameter =>
  (parameter as StandardBlockParameter).isSelected !== undefined && (parameter as BlockParameter).calculation !== undefined;

export type LegoMetric = BlockTypeMetric | StandardBlockMetric | BlockMetric;

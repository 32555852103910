import { CreateSynopticBody, DuplicateSynopticBody, SynopticExtendedInfo, UUID, UpdateSynopticBody } from '@dametis/core';

import { removeLastEntity } from 'components/Layouts/Drawer/hooks';
import { sdk } from 'sdk';
import { RootState, useSelector } from 'store';
import { LastEntityType } from 'types/drawer';

import { api } from './index';

const synopticsApi = api.injectEndpoints({
  endpoints: build => ({
    readSynoptics: build.query<SynopticExtendedInfo[], { siteId?: UUID } | void>({
      providesTags: ['Synoptics'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const siteId = (arg && arg.siteId) ?? state.auth.selectedSite!.uuid;
        try {
          const { data } = await sdk.synoptic.List(siteId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    readSynoptic: build.query<SynopticExtendedInfo, UUID>({
      providesTags: ['Synoptics'],
      queryFn: async uuid => {
        try {
          const { data } = await sdk.synoptic.Read(uuid);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    createSynoptic: build.mutation<SynopticExtendedInfo, CreateSynopticBody>({
      invalidatesTags: ['Synoptics', 'TagsDependencies'],
      queryFn: async (newSynoptic, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite!.uuid;
        try {
          const { data } = await sdk.synoptic.Create(siteId, newSynoptic);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    duplicateSynoptic: build.mutation<SynopticExtendedInfo, { uuid: UUID; body: DuplicateSynopticBody }>({
      invalidatesTags: ['Synoptics'],
      queryFn: async ({ uuid, body }) => {
        try {
          const { data } = await sdk.synoptic.Duplicate(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateSynoptic: build.mutation<SynopticExtendedInfo, { uuid: UUID; body: UpdateSynopticBody }>({
      invalidatesTags: ['Synoptics', 'TagsDependencies'],
      queryFn: async ({ uuid, body }) => {
        try {
          const { data } = await sdk.synoptic.Update(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteSynoptic: build.mutation<void, UUID>({
      invalidatesTags: ['Synoptics', 'TagsDependencies'],
      queryFn: async (uuid, { dispatch }) => {
        try {
          const { data } = await sdk.synoptic.Delete(uuid);
          dispatch(removeLastEntity(LastEntityType.SYNOPTICS, uuid));
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useReadSynopticsQuery,
  useReadSynopticQuery,
  useLazyReadSynopticsQuery,
  useCreateSynopticMutation,
  useDuplicateSynopticMutation,
  useUpdateSynopticMutation,
  useDeleteSynopticMutation,
  endpoints: synopticEndpoints,
} = synopticsApi;

export const useSynoptics: typeof useReadSynopticsQuery = (arg, options) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return useReadSynopticsQuery(arg ?? { siteId }, { skip: !siteId, ...options });
};

import { AddOutlined, BlockOutlined, DeleteOutlined, FolderOutlined, InsertDriveFileOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { Dispatch, DragEventHandler, FC, MouseEventHandler, SetStateAction, useCallback, useMemo, useRef } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { MAX_FILE_IMPORT_SIZE } from '@dametis/core';

import addFileImg from 'assets/images/others/add-file.svg';
import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import PaperDropzone from 'components/UI/FileDropzone/PaperDropzone';
import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import { useDispatch } from 'store';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types/toast';

import FileSizeTypography from '../FileSizeTypography/FileSizeTypography';

export interface BigDropzoneProps {
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  onUpload?: () => Promise<void> | void;
  isUploading?: boolean;
  disabled?: boolean;
}

const supportedFileTypes = {
  'text/*': ['.csv', '.txt', '.tsv'],
  'application/vnd.ms-excel': ['.csv'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
};

const BigDropzone: FC<BigDropzoneProps> = ({ files, setFiles, disabled = false, onUpload = undefined, isUploading = false }) => {
  const { t } = useTranslation('fileImport');
  const dispatch = useDispatch();

  const isAddToSelectedMode = useRef<boolean>(false);

  const supportedFileTypesString = useMemo(() => [...new Set(Object.values(supportedFileTypes).flat())].sort().join(', '), []);

  const handleDropAccepted: DropzoneOptions['onDropAccepted'] = useCallback(
    (acceptedFiles: File[]) => {
      if (isAddToSelectedMode.current) {
        setFiles(state => [...state, ...acceptedFiles]);
      } else {
        setFiles(acceptedFiles);
      }
    },
    [setFiles],
  );

  const handleDropRejected = useCallback(
    (rejectedFiles: FileRejection[]) => {
      rejectedFiles.forEach(rejectedFile =>
        rejectedFile.errors.forEach(error => {
          dispatch(addToast({ message: t(`errors.upload.${error.code}`), severity: ToastSeverity.ERROR }));
        }),
      );
    },
    [dispatch, t],
  );

  const handleDragEnter: DragEventHandler = useCallback(() => {
    isAddToSelectedMode.current = false;
  }, []);

  const handleDeleteFile = useCallback(
    (fileIndex: number) => () => {
      setFiles(state => state.filter((_selectedFile, index) => index !== fileIndex));
    },
    [setFiles],
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, open } = useDropzone({
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
    onDragEnter: handleDragEnter,
    noClick: true,
    multiple: true,
    disabled: disabled || isUploading,
    accept: supportedFileTypes,
    maxSize: MAX_FILE_IMPORT_SIZE,
  });

  const handleSelectFile: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    isAddToSelectedMode.current = false;
    open();
  }, [open]);

  const handleAddFileToSelected: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    isAddToSelectedMode.current = true;
    open();
  }, [open]);

  const handleUpload = useCallback(() => {
    if (onUpload) {
      void onUpload();
    }
  }, [onUpload]);

  return (
    <PaperDropzone
      {...getRootProps()}
      disabled={disabled || isUploading}
      isDragAccept={isDragAccept}
      isDragActive={isDragActive}
      sx={{ flexGrow: 1, p: 5, position: 'relative', cursor: 'default' }}
    >
      <input {...getInputProps()} />
      <Stack
        alignItems="center"
        height={1}
        justifyContent="center"
        sx={{
          opacity: disabled || isUploading ? 0.5 : 1,
          filter: `grayscale(${disabled || isUploading ? 1 : 0})`,
          transition: theme => theme.transitions.create(['opacity']),
        }}
      >
        {!isDragActive || disabled ? (
          <>
            <Box component="img" height={150} mb={5} src={addFileImg} />
            <Typography fontSize={18} fontWeight={500} lineHeight={1} mb={2} textAlign="center">
              {t('dropzone.dropFiles')}
            </Typography>
            <Typography fontSize={18} lineHeight={1} mb={2}>
              {t('dropzone.or')}
            </Typography>
            <Button
              color="primary"
              disabled={disabled || isUploading}
              startIcon={<FolderOutlined />}
              variant="contained"
              onClick={handleSelectFile}
            >
              {t('dropzone.browse')}
            </Button>
            <Typography lineHeight={1} mt={3} variant="subtitle2">
              {`${t('dropzone.acceptedFiles')} ${supportedFileTypesString}`}
            </Typography>
            <Typography lineHeight={1} mt={1} variant="subtitle2">
              {t('dropzone.maxSize', { size: MAX_FILE_IMPORT_SIZE / 1000000 })}
            </Typography>
            {files.length > 0 && (
              <>
                <Stack gap={1} maxWidth={500} mt={2} pt={2} sx={{ borderTop: theme => `1px solid ${theme.palette.divider}` }} width={1}>
                  <Stack alignItems="center" direction="row" justifyContent="space-between">
                    <Typography variant="h6">{t('title.selectedFiles')}</Typography>
                    <ActionButton startIcon={<AddOutlined />} onClick={handleAddFileToSelected}>
                      {t('button.add')}
                    </ActionButton>
                  </Stack>
                  <Stack gap={0.5}>
                    {files.map((acceptedFile, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Stack key={index} alignItems="center" direction="row" gap={0.5}>
                        <InsertDriveFileOutlined color="icon" fontSize="small" />
                        <TypographyEllipse>{acceptedFile.name}</TypographyEllipse>
                        <FileSizeTypography flexShrink={0} ml="auto" size={acceptedFile.size} />
                        <IconButton disabled={disabled || isUploading} size="small" onClick={handleDeleteFile(index)}>
                          <DeleteOutlined fontSize="small" />
                        </IconButton>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
                {onUpload && (
                  <LoadingButton color="secondary" loading={isUploading} sx={{ mt: 2 }} variant="contained" onClick={handleUpload}>
                    {t('button.upload')}
                  </LoadingButton>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {isDragAccept && (
              <>
                <Box component="img" height={150} mb={5} src={addFileImg} />
                <Typography fontSize={18} fontWeight={500} lineHeight={1} textAlign="center">
                  {t('dropzone.dropFilesHere')}
                </Typography>
              </>
            )}
            {!isDragAccept && (
              <>
                <BlockOutlined color="error" sx={{ fontSize: 150, mb: 5 }} />
                <Typography fontSize={18} fontWeight={500} lineHeight={1} textAlign="center">
                  {t('dropzone.fileNotAllowed')}
                </Typography>
                <Typography lineHeight={1} mt={3} variant="subtitle2">
                  {t('dropzone.acceptedFiles')}
                </Typography>
              </>
            )}
          </>
        )}
      </Stack>
    </PaperDropzone>
  );
};

export default BigDropzone;

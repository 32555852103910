import { ActivityType, ActivityVerb } from '@dametis/core';

const verbs: Record<ActivityVerb, string> = {
  [ActivityVerb.CREATE]: 'ha creado',
  [ActivityVerb.RESTORE]: 'ha restaurado',
  [ActivityVerb.DUPLICATE]: 'ha duplicado',
  [ActivityVerb.TRANSFER]: 'ha transferido',
  [ActivityVerb.UPDATE]: 'ha modificado',
  [ActivityVerb.DELETE]: 'ha eliminado',
  [ActivityVerb.UPLOAD]: 'ha publicado',
  [ActivityVerb.SIGNIN_PASSWORD]: 'ha iniciado sesión con una contraseña',
  [ActivityVerb.SIGNIN_INVITATION]: 'ha iniciado sesión con una invitación',
  [ActivityVerb.SIGNIN_SSO]: 'ha iniciado sesión con SSO',
  [ActivityVerb.LOGOUT]: 'ha cerrado sesión',
  [ActivityVerb.CREATE_INVITATION]: 'ha creado una invitación',
  [ActivityVerb.FINALIZE_INVITATION]: 'ha finalizado su invitación',
  [ActivityVerb.LOST_PASSWORD]: 'ha perdido su contraseña',
  [ActivityVerb.RESET_PASSWORD]: 'ha restablecido su contraseña',
  [ActivityVerb.ANONYMOUSLY_DUPLICATE]: 'se duplicó de forma anónima',
  [ActivityVerb.SIGNIN_WEBAUTHN]: 'ha iniciado sesión con WebAuthn',
  [ActivityVerb.CALCULUS_ARMAGEDDON]: 'desencadenó un Armagedón de Calculus',
  [ActivityVerb.SCORE_ARMAGEDDON]: 'desencadenó un Armageddon de Scores',
  [ActivityVerb.DAMCO_ARMAGEDDON]: 'desencadena un Armagedón de Damco',
  [ActivityVerb.IMPERSONATE]: 'suplantó a un usuario',
};

const types: Record<ActivityType, string> = {
  [ActivityType.GROUP]: 'el grupo',
  [ActivityType.SITE]: 'el sitio',
  [ActivityType.BOX]: 'la caja',
  [ActivityType.DEVICE]: 'el equipo',
  [ActivityType.PLAYGROUND]: 'el patio de recreo',
  [ActivityType.REPORT]: 'el informe',
  [ActivityType.SYNOPTIC]: 'el sinóptico',
  [ActivityType.VARIABLE]: 'la variable',
  [ActivityType.USER]: 'el usuario',
  [ActivityType.ALARM]: 'la alarma',
  [ActivityType.PROJECT]: 'el proyecto',
  [ActivityType.ASSET]: 'un documento',
  [ActivityType.TASK]: 'una tarea',
  [ActivityType.BATCH]: 'un lote',
  [ActivityType.MODEL]: 'un modelo',
  [ActivityType.COMMENT]: 'un comentario',
  [ActivityType.BLOCK]: 'un bloque',
  [ActivityType.BLOCK_TYPE]: 'un tipo de bloque',
  [ActivityType.STYLE_CONFIGURATION]: 'una configuración de estilo',
  [ActivityType.ALIAS]: 'un alias',
  [ActivityType.IMAGE]: 'una imagen',
  [ActivityType.TAG]: 'una etiqueta',
  [ActivityType.OPCO]: 'una mezcla de vapor',
  [ActivityType.SYNOPTIC_WIDGET]: 'un widget sinóptico',
  [ActivityType.FILEIMPORT]: 'una importación de archivos',
  [ActivityType.STANDARD_BLOCK]: 'un bloque estándar',
  [ActivityType.CALENDAR]: 'un calendario',
  [ActivityType.INVITATION]: 'una invitación',
};

export default {
  title: {
    activityDetails: 'Detalles de la actividad',
  },
  type: verbs,
  object: types,
  text: {
    activities: 'Actividades',
    label: '{{firstName}} {{lastName}} $t(type.{{type}}) $t(object.{{object}})',
    noActivities: 'Ninguna actividad registrada',
    displayOnlyChangedFields: 'Solo mostrar los campos modificados.',
    variable: {
      noUpdatedVariableFields: 'Ninguna modificación',
      updatedVariableFields_zero: ' y $t(configuration:input.{{field}})',
      updatedVariableFields_one: ', $t(configuration:input.{{field}}) y otro campo',
      updatedVariableFields_other: ', $t(configuration:input.{{field}}) y {{count}} otros campos',
    },
  },
  button: {
    close: 'Cerrar',
    restore: 'Restaurar',
    order: {
      newest: 'De más reciente a más antiguo',
      oldest: 'De más antiguo a más reciente',
    },
  },
  toast: {
    restoreVariableSuccess: 'Variable restaurada.',
  },
};

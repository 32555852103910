import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChangeEventHandler, FC, MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeObserver } from 'usehooks-ts';

import {
  IsOpcUaDeviceConnectionEndpoint,
  IsOpcUaDeviceConnectionHostAndPort,
  IsUserIdentityInfoUserName,
  MessageSecurityMode,
  OpcUaConnectionMode,
  OpcUaDeviceConnection,
  SecurityPolicy,
  UserIdentityInfo,
  UserTokenType,
} from '@dametis/core';

import {
  createAnonymousIdentity,
  createOpcUaConnectionUriEndpoint,
  createOpcUaConnectionUriHostAndPort,
  createUserIdentityInfoUserName,
  createUserIdentityInfoX509,
} from 'components/Configuration/Devices/helpers/createOpcUaDeviceConfig';
import AdvancedTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';

import FormPaper, { FormLocation } from '../../FormPaper';

const ENDPOINT_PREFIX = 'opc.tcp://';
const OPC_TCP_PATTERN = /^opc.tcp:\/\/([^:/]+)(?::(\d+))?/;

export interface OpcUaConnectionFormProps {
  connection: OpcUaDeviceConnection;
  setConnection: (newConnection: OpcUaDeviceConnection) => void;
  isEditing?: boolean;
  location?: `${FormLocation}`;
}

export const OpcUaConnectionForm: FC<OpcUaConnectionFormProps> = ({ connection, setConnection, isEditing = true, location = 'modal' }) => {
  const { t } = useTranslation('devices');

  const [connectMethod, setConnectMethod] = useState<OpcUaConnectionMode>(OpcUaConnectionMode.HOST_AND_PORT);
  const [portInputValue, setPortInputValue] = useState<string>('');
  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(true);

  const paperRef = useRef<HTMLDivElement | null>(null);

  const { width: paperWidth = 0 } = useResizeObserver({
    ref: paperRef,
  });

  const handleChangeConnectMethod: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const newConnectMethod = event.target.value as OpcUaConnectionMode;
      setConnectMethod(newConnectMethod);
      let newHost = '';
      let newPort = 4841;
      let newEndpoint = '';
      if (newConnectMethod === OpcUaConnectionMode.ENDPOINT) {
        if (
          IsOpcUaDeviceConnectionHostAndPort(connection) &&
          connection?.connectionUri.host.trim().length > 0 &&
          portInputValue.trim().length > 0
        ) {
          newEndpoint = `${ENDPOINT_PREFIX}${connection.connectionUri.host}:${portInputValue}`;
        }
        setConnection({ ...connection, connectionUri: createOpcUaConnectionUriEndpoint({ endpoint: newEndpoint }) });
      }
      if (newConnectMethod === OpcUaConnectionMode.HOST_AND_PORT) {
        const match = IsOpcUaDeviceConnectionEndpoint(connection) ? connection.connectionUri?.endpoint.match(OPC_TCP_PATTERN) : undefined;
        if (match && match[1]) {
          [, newHost] = match;
        }
        if (match && match[2]) {
          newPort = Number.parseInt(match[2], 10);
        }
        setConnection({ ...connection, connectionUri: createOpcUaConnectionUriHostAndPort({ host: newHost, port: newPort }) });
      }
    },
    [connection, portInputValue, setConnection],
  );

  const handleChangeHost: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (IsOpcUaDeviceConnectionHostAndPort(connection)) {
        setConnection({ ...connection, connectionUri: { ...connection.connectionUri, host: event.target.value } });
      }
    },
    [setConnection, connection],
  );

  const handleChangePort: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setPortInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 254 && IsOpcUaDeviceConnectionHostAndPort(connection)) {
        setConnection({ ...connection, connectionUri: { ...connection.connectionUri, port: parsedValue } });
      }
    },
    [setConnection, connection],
  );

  const handleChangeEndpoint: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (IsOpcUaDeviceConnectionEndpoint(connection)) {
        setConnection({ ...connection, connectionUri: { ...connection.connectionUri, endpoint: event.target.value } });
      }
    },
    [setConnection, connection],
  );

  const handleChangeSecurityPolicy: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const newSecurityPolicy = event.target.value as SecurityPolicy;
      const newSecurityMode = newSecurityPolicy === SecurityPolicy.None ? MessageSecurityMode.None : MessageSecurityMode.Sign;
      setConnection({ ...connection, securityPolicy: newSecurityPolicy, securityMode: newSecurityMode });
    },
    [setConnection, connection],
  );

  const handleChangeSecurityMode: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const newSecurityMode = parseInt(event.target.value, 10) as MessageSecurityMode;
      setConnection({ ...connection, securityMode: newSecurityMode });
    },
    [setConnection, connection],
  );

  const handleChangeUserIdentityInfoType: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const newUserIdentityInfoType = parseInt(event.target.value, 10) as UserTokenType;
      let newUserIdentityInfo: UserIdentityInfo | undefined;
      if (newUserIdentityInfoType === UserTokenType.UserName) {
        newUserIdentityInfo = createUserIdentityInfoUserName();
      }
      if (newUserIdentityInfoType === UserTokenType.Anonymous) {
        newUserIdentityInfo = createAnonymousIdentity();
      }
      if (newUserIdentityInfoType === UserTokenType.Certificate) {
        newUserIdentityInfo = createUserIdentityInfoX509();
      }
      if (newUserIdentityInfo) {
        setConnection({ ...connection, userIdentityInfo: newUserIdentityInfo });
      }
    },
    [setConnection, connection],
  );

  const handleTogglePasswordVisibility: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setIsPasswordHidden(state => !state);
  }, []);

  const handleMouseDownPasswordVisibility: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    event.preventDefault();
  }, []);

  const handleChangeUserIdentityInfoUserName: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (IsUserIdentityInfoUserName(connection.userIdentityInfo)) {
        setConnection({ ...connection, userIdentityInfo: { ...connection.userIdentityInfo, userName: event.target.value } });
      }
    },
    [setConnection, connection],
  );

  const handleChangeUserIdentityInfoPassword: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (IsUserIdentityInfoUserName(connection.userIdentityInfo)) {
        setConnection({ ...connection, userIdentityInfo: { ...connection.userIdentityInfo, password: event.target.value } });
      }
    },
    [setConnection, connection],
  );

  const getSecurityPolicyKeyFromValue = useCallback(
    (value: string) => {
      if (value === SecurityPolicy.Invalid) {
        return t('securityPolicy.Invalid');
      }
      if (value === SecurityPolicy.None) {
        return t('securityPolicy.None');
      }
      return Object.keys(SecurityPolicy)[Object.values(SecurityPolicy).indexOf(value as SecurityPolicy)] ?? t('securityPolicy.Invalid');
    },
    [t],
  );

  useEffect(() => {
    if (IsOpcUaDeviceConnectionHostAndPort(connection)) {
      setPortInputValue(`${connection.connectionUri.port ?? ''}`);
      setConnectMethod(OpcUaConnectionMode.HOST_AND_PORT);
    } else {
      setPortInputValue('');
      setConnectMethod(OpcUaConnectionMode.ENDPOINT);
    }
  }, [connection]);

  return (
    <>
      <FormPaper ref={paperRef} reverse location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.connection')}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>{t('label.connectMethod')}</FormLabel>
              <RadioGroup
                row
                sx={{ pointerEvents: !isEditing ? 'none' : undefined }}
                value={connectMethod}
                onChange={handleChangeConnectMethod}
              >
                <FormControlLabel
                  control={<Radio />}
                  label={t(`opcUaConnectionMode.${OpcUaConnectionMode.HOST_AND_PORT}`)}
                  value={OpcUaConnectionMode.HOST_AND_PORT}
                />
                <FormControlLabel
                  control={<Radio />}
                  label={t(`opcUaConnectionMode.${OpcUaConnectionMode.ENDPOINT}`)}
                  value={OpcUaConnectionMode.ENDPOINT}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {IsOpcUaDeviceConnectionHostAndPort(connection) && (
            <>
              <Grid item xs={8}>
                <AdvancedTextField
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.host')}
                  value={connection.connectionUri.host ?? ''}
                  onChange={handleChangeHost}
                />
              </Grid>
              <Grid item xs={4}>
                <AdvancedTextField
                  fullWidth
                  required
                  editing={isEditing}
                  inputProps={{ step: 1, min: 0 }}
                  label={t('label.port')}
                  value={portInputValue}
                  onChange={handleChangePort}
                />
              </Grid>
            </>
          )}
          {IsOpcUaDeviceConnectionEndpoint(connection) && (
            <Grid item xs={12}>
              <AdvancedTextField
                fullWidth
                required
                editing={isEditing}
                label={t('label.endpoint')}
                placeholder="opc.tcp://localhost:4841"
                value={connection.connectionUri.endpoint ?? ''}
                onChange={handleChangeEndpoint}
              />
            </Grid>
          )}

          <Grid item xs={paperWidth > 400 ? 6 : 12}>
            <AdvancedTextField
              fullWidth
              select
              editing={isEditing}
              label={t('label.securityPolicy')}
              SelectProps={{
                renderValue: value => getSecurityPolicyKeyFromValue(value as string),
              }}
              value={connection.securityPolicy ?? ''}
              onChange={handleChangeSecurityPolicy}
            >
              {connection.securityPolicy === SecurityPolicy.Invalid && (
                <MenuItem disabled value={SecurityPolicy.Invalid}>
                  {t('securityPolicy.Invalid')}
                </MenuItem>
              )}
              <MenuItem value={SecurityPolicy.None}>{t('securityPolicy.None')}</MenuItem>
              {Object.entries(SecurityPolicy)
                .filter(([key]) => key !== 'Invalid' && key !== 'None')
                .map(([key, value]) => (
                  <MenuItem key={key} value={`${value}`}>
                    {key}
                  </MenuItem>
                ))}
            </AdvancedTextField>
          </Grid>
          <Grid item xs={paperWidth > 400 ? 6 : 12}>
            <AdvancedTextField
              fullWidth
              select
              disabled={connection.securityPolicy === SecurityPolicy.Invalid}
              editing={isEditing}
              label={t('label.securityMode')}
              SelectProps={{ renderValue: value => t(`messageSecurityMode.messageSecurityMode_${value}`) }}
              value={connection.securityMode ?? ''}
              onChange={handleChangeSecurityMode}
            >
              <MenuItem disabled={connection.securityPolicy !== SecurityPolicy.None} value={MessageSecurityMode.None}>
                {t(`messageSecurityMode.messageSecurityMode_${MessageSecurityMode.None}`)}
              </MenuItem>
              <MenuItem disabled={connection.securityPolicy === SecurityPolicy.None} value={MessageSecurityMode.Sign}>
                {t(`messageSecurityMode.messageSecurityMode_${MessageSecurityMode.Sign}`)}
              </MenuItem>
              <MenuItem disabled={connection.securityPolicy === SecurityPolicy.None} value={MessageSecurityMode.SignAndEncrypt}>
                {t(`messageSecurityMode.messageSecurityMode_${MessageSecurityMode.SignAndEncrypt}`)}
              </MenuItem>
            </AdvancedTextField>
          </Grid>
        </Grid>
      </FormPaper>
      <FormPaper reverse location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.authentification')}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AdvancedTextField
              fullWidth
              select
              editing={isEditing}
              label={t('label.userTokenType')}
              SelectProps={{ renderValue: value => t(`userTokenType.userTokenType_${value}`) }}
              value={connection.userIdentityInfo.type ?? ''}
              onChange={handleChangeUserIdentityInfoType}
            >
              <MenuItem value={UserTokenType.UserName}>{t(`userTokenType.userTokenType_${UserTokenType.UserName}`)}</MenuItem>
              <MenuItem value={UserTokenType.Anonymous}>{t(`userTokenType.userTokenType_${UserTokenType.Anonymous}`)}</MenuItem>
              {/* <MenuItem value={UserTokenType.Certificate}>{t(`userTokenType.userTokenType_${UserTokenType.Certificate}`)}</MenuItem> */}
            </AdvancedTextField>
          </Grid>
          {IsUserIdentityInfoUserName(connection.userIdentityInfo) && (
            <>
              <Grid item xs={paperWidth > 400 ? 6 : 12}>
                <AdvancedTextField
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.userName')}
                  value={connection.userIdentityInfo?.userName ?? ''}
                  onChange={handleChangeUserIdentityInfoUserName}
                />
              </Grid>
              <Grid item xs={paperWidth > 400 ? 6 : 12}>
                <AdvancedTextField
                  fullWidth
                  required
                  autoComplete="new-password"
                  editing={isEditing}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={isPasswordHidden ? t('tooltip.hidePassword') : t('tooltip.seePassword')}>
                          <IconButton
                            edge="end"
                            size="large"
                            onClick={handleTogglePasswordVisibility}
                            onMouseDown={handleMouseDownPasswordVisibility}
                          >
                            {isPasswordHidden ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  label={t('label.password')}
                  type={isPasswordHidden ? 'password' : 'text'}
                  value={connection.userIdentityInfo?.password ?? ''}
                  onChange={handleChangeUserIdentityInfoPassword}
                />
              </Grid>
            </>
          )}
          {/* {IsUserIdentityInfoX509(opcUa.userIdentityInfo) && (
        <>
          <Grid item xs={4}>
            <AdvancedTextField
              editing={isEditing}
              value={opcUa.userIdentityInfo?.policyId ?? ''}
              onChange={handleChangeUserIdentityInfoPolicyId}
              label={t('label.policyId')}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <AdvancedTextField
              editing={isEditing}
              value={opcUa.userIdentityInfo?.certificateData ?? ''}
              onChange={handleChangeUserIdentityInfoCertificateData}
              label={t('label.certificateData')}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <AdvancedTextField
              editing={isEditing}
              value={opcUa.userIdentityInfo?.privateKey ?? ''}
              onChange={handleChangeUserIdentityInfoPrivateKey}
              label={t('label.privateKey')}
              fullWidth
            />
          </Grid>
        </>
      )} */}
        </Grid>
      </FormPaper>
    </>
  );
};

export default OpcUaConnectionForm;

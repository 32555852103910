import { Typography, TypographyProps } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UnitName } from '@dametis/unit';

import { getFormattedValue } from 'components/UI/UnitPicker/functions/getFormattedValue';

import { getFileSizeMultipleSymbol } from '../../helpers/getFileSizeMultipleSymbol';

export interface FileSizeTypographyProps extends Omit<TypographyProps, 'children' | 'variant'> {
  size?: number;
  variant?: TypographyProps['variant'];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FileSizeTypography: FC<FileSizeTypographyProps> = ({ size = undefined, variant = 'caption', ...props }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('fileImport');

  const selectedUnitName = useMemo(() => (language === 'fr' ? UnitName.BYTE_FR : UnitName.BYTE_EN), [language]);

  return (
    <Typography {...props}>
      {size &&
        getFormattedValue({
          value: size,
          baseUnit: selectedUnitName,
          userUnit: `${getFileSizeMultipleSymbol(size)}${selectedUnitName}`,
        })}
      {!size && t('text.unknownSize')}
    </Typography>
  );
};

export default FileSizeTypography;

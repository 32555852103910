import { Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockInfo, BlockTypeInfo } from '@dametis/core';

import { BlocksOutlined } from 'assets/icons/BlocksOutlined';
import { isBlockConnectionValid } from 'components/Lego/helpers/block/isBlockConnectionValid';
import { isBlockMetadataValid } from 'components/Lego/helpers/block/isBlockMetadataValid';
import { getBlockPath } from 'components/Lego/helpers/getBlockPath';
import { getBlockUrl } from 'components/Lego/helpers/getBlockUrl';
import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import { useFolders } from 'store/api/folders';

import LegoPreviewPaper from '../../LegoPreviewPaper';
import BlockElementLinearProgress from '../BlockTile/BlockElementLinearProgress';

export interface BlockPreviewProps {
  block: BlockInfo;
  blockType?: BlockTypeInfo;
  redirect?: boolean;
}

const BlockPreview: FC<BlockPreviewProps> = ({ block, blockType = undefined, redirect = false }) => {
  const { t } = useTranslation('lego');

  const { data: rootFolder = null } = useFolders();

  const url = useMemo(() => getBlockUrl(rootFolder, block.uuid) ?? '#', [rootFolder, block.uuid]);
  const path = useMemo(() => getBlockPath(rootFolder, block.uuid, t), [rootFolder, block.uuid, t]);

  const elementsProgress = useMemo(() => {
    if (!blockType) {
      return 0;
    }
    const selectedParameters = block.parameters.filter(parameter => parameter.isSelected);
    const validParameters = selectedParameters.filter(parameter => !!parameter.calculation);

    const selectedMetrics = block.metrics.filter(metric => metric.isSelected);
    const validMetrics = selectedMetrics.filter(metric => !!metric.templatedCalculation);

    const validConnections = block.connections.filter(connection => isBlockConnectionValid(connection));

    const validMetadataList = block.blockMetadataList.filter(metadata => isBlockMetadataValid(metadata));

    const totalElements = selectedParameters.length + selectedMetrics.length + block.connections.length + block.blockMetadataList.length;
    if (totalElements === 0) {
      return 0;
    }

    return ((validParameters.length + validMetrics.length + validConnections.length + validMetadataList.length) / totalElements) * 100;
  }, [block, blockType]);

  return (
    <LegoPreviewPaper
      redirect={redirect}
      sx={{ textDecoration: 'inherit', background: theme => theme.palette.background.default, p: 1 }}
      url={url}
    >
      <Stack>
        <Stack alignItems="center" direction="row" gap={1}>
          <BlocksOutlined sx={{ color: block.ui.color }} />
          <Stack>
            <TypographyEllipse variant="caption">{path}</TypographyEllipse>
            <Typography>{block.name}</Typography>
          </Stack>
        </Stack>
        {blockType && <BlockElementLinearProgress sx={{ mt: 0.5 }} value={elementsProgress} variant="determinate" />}
      </Stack>
    </LegoPreviewPaper>
  );
};

export default BlockPreview;

import {
  BacnetDeviceConfig,
  DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  DEFAULT_DEVICE_LOOPTIME,
  DEFAULT_VARIABLE_DATA_DELAY,
  DEFAULT_VARIABLE_FREQUENCY,
} from '@dametis/core';

export const createBacnetDeviceConfig = ({
  host = '',
  port = 47808,
  instance = 0,
  loopTime = DEFAULT_DEVICE_LOOPTIME,
  deviceHeartbeatFrequency = DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  defaultVariableFrequency = DEFAULT_VARIABLE_FREQUENCY,
  defaultVariableDataDelay = DEFAULT_VARIABLE_DATA_DELAY,
}: Partial<BacnetDeviceConfig> = {}): BacnetDeviceConfig => ({
  host,
  port,
  instance,
  loopTime,
  deviceHeartbeatFrequency,
  defaultVariableFrequency,
  defaultVariableDataDelay,
});

import { ListVariableInfo, VariableInfo } from '@dametis/core';

import { FakeVariableKind } from 'types/variables';

export const getVariableKind = (variable: ListVariableInfo | VariableInfo) => {
  if ('alarmUuid' in variable && variable.alarmUuid) {
    return FakeVariableKind.ALARM;
  }
  if ('batchUuid' in variable && variable.batchUuid) {
    return FakeVariableKind.BATCH;
  }
  if ('opcoUuid' in variable && variable.opcoUuid) {
    return FakeVariableKind.VAPOR_MIX;
  }
  return variable.kind;
};

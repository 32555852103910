import { MathFunctionInputName, MathFunctionName, MathFunctionType, Operator } from '@dametis/core';

import { InfluentVariablesState } from 'components/VNC/components/Filters/InfluentVariablesFilters';

import { UserHelperName } from '../../types/userHelpers';

const name: Record<MathFunctionName, string> = {
  AC_Debit: 'FlowRate',
  AC_PointDeRoseeSousPression: 'DewPointUnderPressure',
  AC_ConsommationSpecifique: 'SpecificConsumption',
  EAU_Debit: 'FlowRate',
  EAU_MasseVolumique: 'MassDensity',
  FROID_TemperatureSaturation: 'SaturationTemperature',
  FROID_TemperatureHumide: 'WetBulbTemperature',
  FROID_PuissanceFluideThermique: 'ThermalFluidPower', // ce calcul devrai être masqué
  THERMIQUE_DeltaT: 'DeltaT',
  THERMIQUE_DebitToutLiquide: 'LiquidFlowRate',
  THERMIQUE_DebitToutGaz: 'GasFlowRate',
  VAP_Debit: 'FlowRate',
  VAP_DebitSurchauffee: 'SuperheatedFlowRate',
  VAP_Enthalpie: 'Enthalpy',
  VAP_RendementCombustion: 'CombustionEfficiency',
  BORNESUP: 'UpperBound',
  BORNEINF: 'LowerBound',
  ONOFF: 'OnOff',
  BORNES: 'Bounds',
  INFO_Bit: 'Bit',
  IF: 'If',
  getDate: 'ExtractDate',
  time: 'Time',
  now: 'now',
};

const description: Record<MathFunctionName, string> = {
  AC_Debit: 'Calculates air conditioning flow rate',
  AC_PointDeRoseeSousPression: 'Calculates the dew point under pressure',
  AC_ConsommationSpecifique: 'Calculates specific air conditioning consumption',
  EAU_Debit: 'Calculates water flow rate',
  EAU_MasseVolumique: 'Calculates water density',
  FROID_TemperatureSaturation: 'Calculates saturation temperature of a refrigerant',
  FROID_TemperatureHumide: 'Calculates wet temperature based on dry temperature and relative humidity',
  FROID_PuissanceFluideThermique: 'Calculates thermal power of a refrigerant', // ce calcul devrai être masqué
  THERMIQUE_DeltaT: 'Calculates temperature difference between two fluids',
  THERMIQUE_DebitToutLiquide: 'Calculates liquid flow rate for a diaphragm',
  THERMIQUE_DebitToutGaz: 'Calculates gas flow rate for a diaphragm',
  VAP_Debit: 'Calculates saturated steam flow rate for a diaphragm',
  VAP_DebitSurchauffee: 'Calculates superheated steam flow rate for a diaphragm',
  VAP_Enthalpie: 'Calculates saturated steam enthalpy',
  VAP_RendementCombustion: 'Calculation of combustion efficiency using flue gas losses',
  BORNESUP: 'Returns the value limited to the given lower threshold',
  BORNEINF: 'Returns the value limited to the given upper threshold',
  ONOFF: 'Returns 0 if the value is below the threshold, otherwise returns 1',
  BORNES: 'Returns the value limited between the given lower and upper thresholds',
  INFO_Bit: 'Returns the state of the specified bit in the given value (0 or 1)',
  IF: 'Depending on the condition value, returns the result of the corresponding argument',
  getDate: 'Extracts part of date',
  time: "Returns the date of a variable's points (unix timestamp)",
  now: 'Returns the current date (unix timestamp)',
};

const inputsName: Record<MathFunctionInputName, string> = {
  temperature: 'temperature',
  pressure: 'pressure',
  power: 'power',
  flow: 'flow',
  delta_pressure: 'differential pressure',
  threshold_delta_pressure: 'differential pressure threshold',
  diameter_in: 'orifice diameter',
  diameter_out: 'pipe diameter',
  reynolds: 'Reynolds number',
  altitude: 'altitude',
  atmospheric_pressure: 'atmospheric pressure',
  quantity: 'quantity',
  fluid: 'fluid',
  intake_air_temperature: 'intake air temperature',
  flue_gas_temperature: 'flue gas temperature',
  dry_temperature: 'dry temperature',
  relative_humidity: 'relative humidity',
  volume_flow_rate: 'volume flow rate',
  delta_temperature: 'delta temperature',
  mass_density: 'mass density',
  hot_temperature: 'hot temperature',
  cold_temperature: 'cold temperature',
  viscosity: 'kinematic viscosity',
  value: 'value',
  value_true: 'value if true',
  value_false: 'value if false',
  threshold: 'threshold',
  threshold_min: 'threshold min',
  threshold_max: 'threshold max',
  condition: 'condition',
  bit: 'bit',
  heat_capacity: 'heat capacity',
  fuel_type: 'fuel type',
  oxygen_levels: 'oxygen levels',
  datetime: 'date',
  datetimeKey: 'part of date',
  variable: 'variable',
};

const inputsDescription: Partial<Record<`${MathFunctionName}_${MathFunctionInputName}`, string>> = {
  AC_Debit_temperature: 'Air temperature',
  AC_Debit_pressure: 'Air pressure',
  AC_Debit_delta_pressure: 'Differential pressure measurement',
  AC_Debit_threshold_delta_pressure: 'Pressure difference threshold',
  AC_Debit_diameter_in: 'Diaphragm orifice diameter',
  AC_Debit_diameter_out: 'Internal pipe diameter',
  AC_Debit_reynolds: '0 returns the flow rate, 1 returns Reynolds number',
  AC_Debit_altitude: 'Altitude in meters, default 0',
  AC_PointDeRoseeSousPression_temperature: 'Dew point temperature',
  AC_PointDeRoseeSousPression_pressure: 'Air pressure',
  AC_PointDeRoseeSousPression_atmospheric_pressure: 'Atmospheric pressure',
  AC_ConsommationSpecifique_pressure: 'Power of the power plant or compressor',
  AC_ConsommationSpecifique_quantity: 'Compressed Air flow',
  EAU_Debit_temperature: 'Water temperature',
  EAU_Debit_delta_pressure: 'Differential pressure measurement',
  EAU_Debit_threshold_delta_pressure: 'Pressure difference threshold',
  EAU_Debit_diameter_in: 'Diaphragm orifice diameter',
  EAU_Debit_diameter_out: 'Internal pipe diameter',
  EAU_Debit_reynolds: '0 returns the flow rate, 1 returns Reynolds number',
  EAU_MasseVolumique_temperature: 'Water temperature',
  FROID_TemperatureSaturation_fluid: 'Type of refrigerant fluid (0: Ammonia, 1: R22, 2: R12, 3: R502, 4: R134A)',
  FROID_TemperatureSaturation_pressure: 'Refrigerant fluid pressure',
  FROID_TemperatureHumide_dry_temperature: 'Dry temperature',
  FROID_TemperatureHumide_relative_humidity: 'Relative humidity',
  FROID_TemperatureHumide_atmospheric_pressure: 'Atmospheric pressure',
  FROID_PuissanceFluideThermique_fluid: 'Type of refrigerant fluid (0: Ammonia, 1: R22, 2: R12, 3: R502, 4: R134A)',
  FROID_PuissanceFluideThermique_volume_flow_rate: 'Volumetric flow rate',
  FROID_PuissanceFluideThermique_delta_temperature: 'Temperature difference',
  FROID_PuissanceFluideThermique_mass_density: 'Mass density of refrigerant fluid',
  FROID_PuissanceFluideThermique_heat_capacity: 'Heat capacity of refrigerant fluid',
  THERMIQUE_DeltaT_hot_temperature: 'Temperature of hot fluid',
  THERMIQUE_DeltaT_cold_temperature: 'Temperature of cold fluid',
  THERMIQUE_DebitToutLiquide_temperature: 'Fluid temperature',
  THERMIQUE_DebitToutLiquide_pressure: 'Fluid pressure',
  THERMIQUE_DebitToutLiquide_delta_pressure: 'Differential pressure measurement',
  THERMIQUE_DebitToutLiquide_threshold_delta_pressure: 'Pressure difference threshold',
  THERMIQUE_DebitToutLiquide_diameter_in: 'Diaphragm orifice diameter',
  THERMIQUE_DebitToutLiquide_diameter_out: 'Internal pipe diameter',
  THERMIQUE_DebitToutLiquide_mass_density: 'Mass density of fluid',
  THERMIQUE_DebitToutLiquide_viscosity: 'Fluid viscosity',
  THERMIQUE_DebitToutLiquide_reynolds: '0 returns the flow rate, 1 returns Reynolds number',
  THERMIQUE_DebitToutGaz_temperature: 'Fluid temperature',
  THERMIQUE_DebitToutGaz_pressure: 'Fluid pressure',
  THERMIQUE_DebitToutGaz_delta_pressure: 'Differential pressure measurement',
  THERMIQUE_DebitToutGaz_threshold_delta_pressure: 'Pressure difference threshold',
  THERMIQUE_DebitToutGaz_diameter_in: 'Diaphragm orifice diameter',
  THERMIQUE_DebitToutGaz_diameter_out: 'Internal pipe diameter',
  THERMIQUE_DebitToutGaz_mass_density: 'Mass density of fluid',
  THERMIQUE_DebitToutGaz_viscosity: 'Fluid viscosity',
  THERMIQUE_DebitToutGaz_reynolds: '0 returns the flow rate, 1 returns Reynolds number',
  VAP_Debit_pressure: 'Vapor pressure',
  VAP_Debit_delta_pressure: 'Differential pressure measurement',
  VAP_Debit_threshold_delta_pressure: 'Pressure difference threshold',
  VAP_Debit_diameter_in: 'Diaphragm orifice diameter',
  VAP_Debit_diameter_out: 'Internal pipe diameter',
  VAP_Debit_reynolds: '0 returns the flow rate, 1 returns Reynolds number',
  VAP_DebitSurchauffee_pressure: 'Vapor pressure',
  VAP_DebitSurchauffee_temperature: 'Vapor temperature',
  VAP_DebitSurchauffee_delta_pressure: 'Differential pressure measurement',
  VAP_DebitSurchauffee_threshold_delta_pressure: 'Pressure difference threshold',
  VAP_DebitSurchauffee_diameter_in: 'Diaphragm orifice diameter',
  VAP_DebitSurchauffee_diameter_out: 'Internal pipe diameter',
  VAP_DebitSurchauffee_reynolds: '0 returns the flow rate, 1 returns Reynolds number',
  VAP_Enthalpie_pressure: 'Vapor pressure',
  VAP_RendementCombustion_fuel_type: 'Type of fuel',
  VAP_RendementCombustion_intake_air_temperature: 'Intake air temperature',
  VAP_RendementCombustion_flue_gas_temperature: 'Flue gas temperature',
  VAP_RendementCombustion_oxygen_levels: 'Oxygen level',
  BORNESUP_value: 'Value to limit',
  BORNESUP_threshold: 'Upper threshold',
  BORNEINF_value: 'Value to limit',
  BORNEINF_threshold: 'Lower threshold',
  ONOFF_value: 'Value to compare to threshold',
  ONOFF_threshold: 'Comparison threshold',
  BORNES_value: 'Value to limit',
  BORNES_threshold_min: 'Lower threshold',
  BORNES_threshold_max: 'Upper threshold',
  INFO_Bit_value: 'Value containing bits',
  INFO_Bit_bit: 'Position of the bit to check (0 to 15)',
  IF_condition: 'Condition to check',
  IF_value_true: 'Value to return if condition is true',
  IF_value_false: 'Value to return if condition is false',
  getDate_datetime: 'Date in milliseconds',
  getDate_datetimeKey:
    "Valid values : 'second' / 'minute' / 'hour' / 'day' / 'week' / 'weekDay' / 'daysInMonth' / 'month' / 'dayOfYear' / 'year'",
};

export type Mode = 'GLOBAL' | 'PTP' | 'GROUP_BY';

const operatorDescription: Record<`OP_${Operator}_${Mode}`, string> = {
  OP_MIN_GLOBAL: 'Returns the point with the minimum value. So, a single value.',
  OP_MIN_GROUP_BY: 'Returns, for each sub-period, the point with the minimum value. So, one value for each sub-period.',
  OP_MIN_PTP: '--',
  OP_MAX_GLOBAL: 'Returns the point with the maximum value. So, a single value.',
  OP_MAX_GROUP_BY: 'Returns, for each sub-period, the point with the maximum value. So, one value for each sub-period.',
  OP_MAX_PTP: '--',
  OP_GAP_GLOBAL: 'Returns the difference between the maximum and minimum values. So, a single value.',
  OP_GAP_GROUP_BY:
    'Returns, for each sub-period, the difference between the maximum and minimum values. So, one value for each sub-period.',
  OP_GAP_PTP: '',
  OP_FIRST_GLOBAL: 'Returns the first point. So, a single value.',
  OP_FIRST_GROUP_BY: 'Returns, for each sub-period, the first point. So, one value for each sub-period.',
  OP_FIRST_PTP: '--',
  OP_LAST_GLOBAL: 'Returns the last point. So, a single value.',
  OP_LAST_GROUP_BY: 'Returns, for each sub-period, the last point. So, one value for each sub-period.',
  OP_LAST_PTP: '--',
  OP_INDEX_GLOBAL: 'Returns the point at the given index. So, a single value.',
  OP_INDEX_GROUP_BY: 'Returns, for each sub-period, the point at the given index. So, one value for each sub-period.',
  OP_INDEX_PTP: '--',
  OP_HISTORY_GLOBAL: 'Returns all points.',
  OP_HISTORY_GROUP_BY: '--',
  OP_HISTORY_PTP: '--',
  OP_MEAN_GLOBAL: 'Returns the average value of the points. So, a single value.',
  OP_MEAN_GROUP_BY: 'Returns, for each sub-period, the average value of the points. So, one value for each sub-period.',
  OP_MEAN_PTP: '--',
  OP_ARITHMETIC_MEAN_GLOBAL: 'Returns the arithmetic average value of the points. So, a single value.',
  OP_ARITHMETIC_MEAN_GROUP_BY:
    'Returns, for each sub-period, the arithmetic average value of the points. So, one value for each sub-period.',
  OP_ARITHMETIC_MEAN_PTP: '--',
  OP_TIME_WEIGHTED_MEAN_GLOBAL: '',
  OP_TIME_WEIGHTED_MEAN_GROUP_BY: '',
  OP_TIME_WEIGHTED_MEAN_PTP: '',
  OP_DELTA_GLOBAL: 'Returns the difference between the value of the first and last points. So, a single value.',
  OP_DELTA_GROUP_BY:
    'Returns, for each sub-period, the difference between the value of the first and last points. So, one value for each sub-period.',
  OP_DELTA_PTP:
    'Returns, for each point of the variable, the difference between the current point and its previous one. So, one value for each point of the variable.',
  OP_COUNT_GLOBAL: 'Returns the number of present points. So, a single value.',
  OP_COUNT_GROUP_BY: 'Returns, for each sub-period, the number of present points. So, one value for each sub-period.',
  OP_COUNT_PTP: '',
  OP_INTEGRAL_GLOBAL: 'Returns the integral (time base 1h). So, a single value.',
  OP_INTEGRAL_GROUP_BY: 'Returns, for each sub-period, the integral (time base 1h). So, one value for each sub-period.',
  OP_INTEGRAL_PTP:
    'Returns, for each point of the variable, the integral calculated from the current point and its previous one. So, one value for each point of the variable.',
  OP_TRAPEZOIDAL_INTEGRAL_GLOBAL: '--',
  OP_TRAPEZOIDAL_INTEGRAL_GROUP_BY: '--',
  OP_TRAPEZOIDAL_INTEGRAL_PTP: '--',
  OP_LEFT_RECTANGLE_INTEGRAL_GLOBAL: '--',
  OP_LEFT_RECTANGLE_INTEGRAL_GROUP_BY: '--',
  OP_LEFT_RECTANGLE_INTEGRAL_PTP: '--',
  OP_DERIVATIVE_GLOBAL:
    'Returns the derivative, i.e., the delta divided by the time base (1h). So, a single value. (See also delta operator).',
  OP_DERIVATIVE_GROUP_BY:
    'Returns, for each sub-period, the derivative, i.e., the delta divided by the time base (1h). So, one value for each sub-period.',
  OP_DERIVATIVE_PTP:
    'Returns, for each point of the variable, the derivative of each point, i.e., the delta between the current point and the previous one divided by the time base (1h). So, one value for each point of the variable.',
  OP_STDDEV_GLOBAL: "Returns the standard deviation of the points' values. So, a single value.",
  OP_STDDEV_GROUP_BY: "Returns, for each sub-period, the standard deviation of the points' values. So, one value for each sub-period.",
  OP_STDDEV_PTP: '--',
  OP_MOVING_AVERAGE_GLOBAL: '--',
  OP_MOVING_AVERAGE_GROUP_BY:
    'Returns, for each sub-period, the average value of the points over the specified duration (period of the moving average). So, one value for each sub-period.',
  OP_MOVING_AVERAGE_PTP: '--',
  OP_SUM_GLOBAL: "Returns the sum of the points' values. So, a single value.",
  OP_SUM_GROUP_BY: "Returns, for each sub-period, the sum of the points' values. So, one value for each sub-period.",
  OP_SUM_PTP: '--',
  OP_DISTINCT_GLOBAL: 'Returns the set of points by eliminating duplicate values. So, one point for each value.',
  OP_DISTINCT_GROUP_BY: '--',
  OP_DISTINCT_PTP: '--',
  OP_TIME_GLOBAL: 'Returns the value of the start date of the period (Unix timestamp format in milliseconds). So, a single value.',
  OP_TIME_GROUP_BY:
    'Returns the value of the date for each start of a sub-period (Unix timestamp format in milliseconds). So, one value for each sub-period.',
  OP_TIME_PTP: 'Returns, for each point of the variable, the value of its date. So, one value for each point of the variable.',
  OP_GROUP_BY_DURATION_GLOBAL: 'Returns the duration of the period (in milliseconds). So, a single value.',
  OP_GROUP_BY_DURATION_GROUP_BY: 'Returns the duration of each sub-period (in milliseconds). So, one value for each sub-period.',
  OP_GROUP_BY_DURATION_PTP:
    'Returns, for each point of the variable, the duration between the current point and its previous one (in milliseconds). So, one value for each point of the variable.',
  OP_LINEAR_CADENCE_GLOBAL:
    'Returns the linearized value between the point preceding the period and the first of the period. So, a single value.',
  OP_LINEAR_CADENCE_GROUP_BY:
    'Linearizes the curve by creating an interpolated point for each sub-period. So, one value for each sub-period.',
  OP_LINEAR_CADENCE_PTP: '--',
};

export default {
  alert: {
    ignoreSymbols1: 'Type',
    ignoreSymbols2: 'to ignore mathematical symbols.',
    disableMaths: 'In this context, you can only choose a variable.',
    tadaError: 'An error occurred.',
    tadaOk: 'Ok!',
    tadaWarningSingleValue: 'This calculation outputs {{counter}} results on this period, instead of one in this context.',
    tadaWarningMultipleValues: 'This calculation outputs only one result on this period, instead of several in this context.',
    forbiddenOperators_one: "You can't use {{operators, list}} operator in this context.",
    forbiddenOperators_other: "You can't use {{operators, list}} operators in this context.",
  },
  accordion: {
    physicalQuantities: 'Physical quantities',
    utilities: 'Utilities',
    sites: 'Sites and corporate',
    kinds: 'Types',
    blockTypes: 'Block types',
    standardBlocks: 'Standard blocks',
    tags: 'Tags',
    influentVariables: 'Influent variables',
    advanced: 'Advanced settings',
  },
  button: {
    cancel: 'Cancel',
    ok: 'Ok',
    clearAllSelection: 'Clear',
    deleteHistoryItem: 'Remove from history',
    function: 'Function',
    closeVariableMenu: 'Close',
    saveHistoryItemAsAlias: 'Create an alias from this calculation',
    clearUnit: 'Clear unit',
    deleteAliasItem: 'Delete this alias',
    createAlias: 'Create an alias...',
    updateAliasItem: 'Edit this alias',
    slate: 'Textarea',
    json: 'JSON',
  },
  list: {
    text: {
      variable: 'Variable',
      history: 'Calculation',
      model: 'Calculation',
      isCorporate: 'Corporate',
      physicalQuantitiesAll: 'All physical quantities',
      kindsAll: 'All variable types',
      influentVariablesAll: 'All variables',
      byDefault: '(by default)',
    },
    subheader: {
      operator: 'Operator',
      sameEntity: 'Created from $t(shortcut:name.{{category}}_this, { "count": 1 })',
      sameCategory: 'Created from $t(shortcut:name.{{category}}_a, { "count": 1 })',
      others_one: 'Other',
      others_other: 'Others',
      overModels: 'Other response variables',
    },
  },
  input: {
    label: {
      data: 'Data',
      opArgsPeriod: 'Moving average period',
      aliasName: 'Name',
      aliasSlate: 'Calculation',
      aliasSource: 'Created from',
      aliasOwner: 'By',
      fill: 'Fill missing values with',
      flags: 'Options',
      accumulate: 'Accumulate values',
      timestamp: 'Timestamps of values',
      varCalcKind: 'Kind',
      openIn: 'Open in',
    },
    adornment: {
      nickname: 'a.k.a.',
    },
    helperText: {
      addNickname: 'Too long?',
      removeNickname: 'Remove nickname',
    },
    placeholder: {
      input: 'Variable or math expression',
      input_disableMaths: 'Variable',
      searchTag: 'Search...',
    },
    value: {
      fillPrevious: 'Previous value (by default)',
      fillNull: 'No value',
      fillNull2: 'Warning! This might break your calculation.',
      fillLinear: 'Linear interpolation',
      fillNone: "Don't fill",
      fillZero: 'Zeros',
      timestampUndefined: 'Automatic (by default)',
      timestampUndefined2: 'Depends on the context',
      timestampStart: 'Start of the sub-period',
      timestampEnd: 'End of the sub-period',
      timestampMiddle: 'Middle of the sub-period',
      timestampKeep: 'Keep the original timestamp of the point',
      timestampEx: 'e.g.:',
    },
  },
  subtitle: {
    parameters: 'Parameters',
    metrics: 'Metrics',
    folders: 'Folders',
    blocks: 'Blocks',
    noVariables: 'No variables corresponding to this search',
    noHistory: 'Empty history',
    resultsFor: 'Results for "{{search}}"',
    allVariablesByUpdateDate: 'All variables recently updated',
    allInfluentVariables: 'Influent variables of {{name}}',
    multipleSelection: 'Multiple selection',
    explanatoryVariables: 'Explanatory variables',
    noAliases: 'You can create your first alias from your calculation or from history',
    noAliasesForSearch: 'No aliases for "{{search}}"',
  },
  text: {
    noBlockType: 'No block type',
    noBlock: 'No block',
    noFolder: 'No folder',
    selectedVariables_one: '{{count}} variable sélectionnée',
    selectedVariables_other: '{{count}} variables sélectionnées',
    unNamedMetric: '(Unnamed)',
    unknownId: 'Unknown variable',
    unknownBlock: 'Unknown block',
    noParameter: 'No parameter',
    noMetric: 'No metric',
    noDisplayedUtilities: 'None selected',
    noUtility: 'No result',
    noDisplayedTagFilters: 'None selected',
    noTagFilter: 'No result',
    noExplainedVariable: 'No explained variable',
    noModel: 'No model',
    modelVariable: 'Model',
    modelCalculation: 'Equation',
    yVar: 'Response variable',
    noExplanatoryVariable: 'No explanatory variable',
    modelAbsoluteGap: 'Absolute gap',
    modelAbsoluteGapFormula: 'model - response variable',
    modelRelativeGap: 'Relative gap',
    modelRelativeGapFormula: '(model - response variable) / response variable',
    begin: 'Begin: {{date}}',
    end: 'End: {{date}}',
    groupBy: 'Sub-periods: {{value}}',
    linkedModels_one: '{{count}} associated simulation',
    linkedModels_other: '{{count}} associated simulations',
    selectAll: 'Select all',
    availableParameter: '{{blockKey}} ({{unit}})',
    opOrGroupBy: 'Operator? Sub-periods?',
    unitNa: 'n/a',
    noUnit: 'No unit',
    home: 'Home',
    filteredWith: 'Filtered with :',
    fromSource: ', from',
    createdBy: ', by',
    createdIn: ' in',
  },
  title: {
    selection: 'Selection',
    aliases: 'Aliases',
    history: 'History',
    calculationMenu: 'Calculation parameters',
    variableMenu: '"{{name}}" variable parameters',
    variableDetails: 'Information',
  },
  tooltip: {
    createAlias: 'create an alias',
    addToSelection: 'Add to selection',
    removeFromSelection: 'Remove',
    calculationMenu: 'Calculation parameters',
    variableMenu: 'Variable parameters',
    rightClickToOpenVariableMenu1: 'Right-click',
    rightClickToOpenVariableMenu2: 'on variable to open its parameters',
    calcVarHasHistoryOp: 'Because your calculation has a Value operator, the period was reduced to one week.',
    periodIsFuture: 'Because the period is partially in the future, it was reduced to last 7 days.',
    clear: 'Clear',
    metricsCalcOn_one: 'Show metric details',
    metricsCalcOn_other: 'Show metrics details',
    metricsCalcOff_one: 'Hide metric details',
    metricsCalcOff_other: 'Hide metrics details',
    openPopper: 'Expand this field',
    closePopper: 'Close back',
  },
  tab: {
    history: 'History & aliases',
    variables: 'Variables',
    lego: 'Blocks',
    models: 'Models',
  },
  functions: {
    name,
    description,
    type: {
      number: 'number',
      string: 'string',
      boolean: 'boolean',
    } as Record<MathFunctionType, string>,
    inputs: {
      name: inputsName,
      description: inputsDescription,
    },
  },
  operators: {
    title: '{{groupBy}} + {{operator}} =',
    context: "Dans ce contexte, l'opérateur {{ op }} ",
    operatorDescription,
  },
  helper: {
    [UserHelperName.VNC_VARIABLE_MENU_OPERATOR]: "The available operators depend on the sub-periods' kind you choose.",
  },
  influentVariablesState: {
    [InfluentVariablesState.HIDDEN]: 'Hidden variables',
    [InfluentVariablesState.VISIBLE]: 'Relevant variables',
  },
  dialog: {
    title: {
      updateAlias: 'Edit this alias',
      createAlias: 'Create an alias',
    },
    button: {
      cancel: 'Cancel',
      updateAlias: 'Edit',
      createAlias: 'Create',
    },
  },
  monaco: {
    loading: 'Loading...',
  },
};

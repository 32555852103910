import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  ChangeEventHandler,
  Dispatch,
  FC,
  MouseEvent,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeObserver } from 'usehooks-ts';

import {
  AllFileDeviceSourceTypes,
  FileDeviceConfig,
  FileDeviceSourceType,
  IsFileDeviceFileSystemSource,
  IsFileDeviceMinioSource,
  IsFileDeviceSftpInternalSource,
  IsFileDeviceSftpSource,
  SyncPoint,
} from '@dametis/core';

import AdvancedTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';
import SmallToggleButton from 'components/UI/SmallToggleButton/SmallToggleButton';
import { useSelector } from 'store';

import { SettingsMode } from '../../DeviceSettingsForm';
import FormPaper, { FormLocation } from '../../FormPaper';

import FileImportConfigForm from './FileImportConfigForm';

export interface FileSettingsFormProps {
  file: FileDeviceConfig;
  setFile: Dispatch<SetStateAction<FileDeviceConfig>>;
  isEditing?: boolean;
  syncPoint?: SyncPoint;
  onChangeSyncPoint?: (newSyncPoint: SyncPoint) => void;
  filterPastPoints?: boolean;
  onChangeFilterPastPoints?: (newFilterPastPoints: boolean) => void;
  location?: `${FormLocation}`;
  mode?: `${SettingsMode}`;
}

const FileSettingsForm: FC<FileSettingsFormProps> = ({
  file,
  setFile,
  isEditing = true,
  syncPoint = undefined,
  onChangeSyncPoint = undefined,
  filterPastPoints = undefined,
  onChangeFilterPastPoints = undefined,
  location = 'modal',
  mode = 'create',
}) => {
  const { t } = useTranslation('devices');

  const site = useSelector(state => state.auth.selectedSite);

  const [loopTimeInputValue, setLoopTimeInputValue] = useState<string>('');
  const [portInputValue, setPortInputValue] = useState<string>('');
  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(true);
  const [isSecretKeyHidden, setIsSecretKeyHidden] = useState<boolean>(true);

  const papersContainerRef = useRef<HTMLDivElement | null>(null);

  const { width: papersContainerWidth = 0 } = useResizeObserver({
    ref: papersContainerRef,
  });

  const bucket = useMemo(() => `sftp-${(site?.measurement ?? '').replaceAll('_', '-')}/`, [site?.measurement]);

  const availableSyncPoints: SyncPoint[] = useMemo(() => Object.values(SyncPoint), []);

  const isSyncPointFieldAvailable = useMemo(
    () => syncPoint !== undefined && onChangeSyncPoint !== undefined,
    [syncPoint, onChangeSyncPoint],
  );

  const isFilterPastPointsFieldAvailable = useMemo(
    () => filterPastPoints !== undefined && onChangeFilterPastPoints !== undefined,
    [filterPastPoints, onChangeFilterPastPoints],
  );

  const handleChangeSourceType = useCallback(
    (_event: MouseEvent, sourceType: FileDeviceSourceType | null) => {
      if (sourceType === null) {
        return;
      }

      setFile(state => ({
        ...state,
        source: {
          type: sourceType,
          config: {
            path: '',
            ...(sourceType === FileDeviceSourceType.MINIO && { ssl: false }),
          },
        },
      }));
    },
    [setFile],
  );

  const handleChangeSourceConfigPath: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setFile(state => ({
        ...state,
        source: {
          ...state.source,
          config: {
            ...state.source.config,
            path: event.target.value,
          },
        },
      }));
    },
    [setFile],
  );

  const handleChangeSourceConfigHost: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setFile(state => ({
        ...state,
        source: {
          ...state.source,
          config: {
            ...state.source.config,
            host: event.target.value,
          },
        },
      }));
    },
    [setFile],
  );

  const handleChangeSourceConfigPort: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setPortInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setFile(state => ({
          ...state,
          source: {
            ...state.source,
            config: {
              ...state.source.config,
              port: parsedValue,
            },
          },
        }));
      }
    },
    [setFile],
  );

  const handleChangeSourceConfigSsl: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setFile(state => ({
        ...state,
        source: {
          ...state.source,
          config: {
            ...state.source.config,
            ssl: event.target.checked,
          },
        },
      }));
    },
    [setFile],
  );

  const handleChangeSourceConfigAccessKey: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setFile(state => ({
        ...state,
        source: {
          ...state.source,
          config: {
            ...state.source.config,
            accessKey: event.target.value,
          },
        },
      }));
    },
    [setFile],
  );

  const handleChangeSourceConfigSecretKey: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setFile(state => ({
        ...state,
        source: {
          ...state.source,
          config: {
            ...state.source.config,
            secretKey: event.target.value,
          },
        },
      }));
    },
    [setFile],
  );

  const handleChangeSourceConfigBucket: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setFile(state => ({
        ...state,
        source: {
          ...state.source,
          config: {
            ...state.source.config,
            bucket: event.target.value,
          },
        },
      }));
    },
    [setFile],
  );

  const handleChangeSourceConfigUsername: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setFile(state => ({
        ...state,
        source: {
          ...state.source,
          config: {
            ...state.source.config,
            username: event.target.value,
          },
        },
      }));
    },
    [setFile],
  );

  const handleChangeSourceConfigPassword: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setFile(state => ({
        ...state,
        source: {
          ...state.source,
          config: {
            ...state.source.config,
            password: event.target.value,
          },
        },
      }));
    },
    [setFile],
  );

  const handleChangeLoopTime: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setFile) {
        return;
      }
      setLoopTimeInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setFile(state => ({ ...state, loopTime: parsedValue }));
      }
    },
    [setFile],
  );

  const handleChangeSyncPoint: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (onChangeSyncPoint) {
        onChangeSyncPoint(event.target.value as SyncPoint);
      }
    },
    [onChangeSyncPoint],
  );

  const handleChangeFilterPastPoints: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (onChangeFilterPastPoints) {
        onChangeFilterPastPoints(event.target.checked);
      }
    },
    [onChangeFilterPastPoints],
  );

  const handleTogglePasswordVisibility: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setIsPasswordHidden(state => !state);
  }, []);

  const handleToggleSecretKeyVisibility: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setIsSecretKeyHidden(state => !state);
  }, []);

  useEffect(() => {
    setLoopTimeInputValue(`${file.loopTime ?? ''}`);
  }, [file.loopTime]);

  useEffect(() => {
    if (IsFileDeviceSftpSource(file.source) || IsFileDeviceMinioSource(file.source)) {
      setPortInputValue(`${file.source.config.port ?? ''}`);
    } else {
      setPortInputValue('');
    }
  }, [file.source]);

  return (
    <>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.acquisition')}
        </Typography>
        <Grid ref={papersContainerRef} container spacing={1}>
          <Grid item xs={isSyncPointFieldAvailable && papersContainerWidth > 400 ? 6 : 12}>
            <AdvancedTextField
              fullWidth
              required
              description={t('description.loopTime')}
              editing={isEditing}
              InputProps={{ inputProps: { step: 1000 } }} // SLE: check that // SLE: this step does not count in validator
              label={t('label.loopTime')}
              type="number"
              value={loopTimeInputValue}
              onChange={handleChangeLoopTime}
            />
          </Grid>
          {isSyncPointFieldAvailable && (
            <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
              <AdvancedTextField
                fullWidth
                select
                editing={isEditing}
                label={t('label.syncPoint')}
                value={syncPoint}
                onChange={handleChangeSyncPoint}
              >
                {availableSyncPoints.map(availableSyncPoint => (
                  <MenuItem key={availableSyncPoint} value={availableSyncPoint}>
                    {availableSyncPoint}
                  </MenuItem>
                ))}
              </AdvancedTextField>
            </Grid>
          )}
          {isFilterPastPointsFieldAvailable && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterPastPoints}
                    sx={!isEditing ? { pointerEvents: 'none' } : undefined}
                    onChange={isEditing ? handleChangeFilterPastPoints : undefined}
                  />
                }
                label={t('label.filterPastPoints')}
                labelPlacement="end"
                sx={{ mr: 0, cursor: isEditing ? 'pointer' : 'default' }}
              />
            </Grid>
          )}
        </Grid>
      </FormPaper>

      <FileImportConfigForm file={file} isEditing={isEditing} location={location} setFile={setFile} />

      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Stack alignItems="flex-start" direction="row" justifyContent="space-between" mb={1.5}>
          <Typography variant="h6">{t('label.source')}</Typography>
          <ToggleButtonGroup exclusive color="primary" size="small" value={file.source.type} onChange={handleChangeSourceType}>
            {AllFileDeviceSourceTypes.map(sourceType => (
              <SmallToggleButton
                key={sourceType}
                disabled={
                  !isEditing ||
                  (mode === 'edit' && (IsFileDeviceSftpInternalSource(file.source) || sourceType === FileDeviceSourceType.SFTP_INTERNAL))
                }
                sx={{ p: theme => `${theme.spacing(0.75)}!important`, marginInline: 1 }}
                value={sourceType}
              >
                {t(`button.sourceType.${sourceType}`)}
              </SmallToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>

        {IsFileDeviceFileSystemSource(file.source) && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AdvancedTextField
                fullWidth
                required
                editing={isEditing}
                label={t('label.path')}
                value={file.source.config.path}
                onChange={handleChangeSourceConfigPath}
              />
            </Grid>
          </Grid>
        )}

        {IsFileDeviceMinioSource(file.source) && (
          <Grid container spacing={1}>
            <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
              <AdvancedTextField
                fullWidth
                required
                editing={isEditing}
                label={t('label.host')}
                value={file.source.config.host}
                onChange={handleChangeSourceConfigHost}
              />
            </Grid>
            <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
              <AdvancedTextField
                fullWidth
                required
                editing={isEditing}
                InputProps={{ inputProps: { min: 0, max: 65535 } }}
                label={t('label.port')}
                type="number"
                value={portInputValue}
                onChange={handleChangeSourceConfigPort}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={file.source.config.ssl}
                    sx={!isEditing ? { pointerEvents: 'none' } : undefined}
                    onChange={isEditing ? handleChangeSourceConfigSsl : undefined}
                  />
                }
                label={t('label.useSSL')}
                labelPlacement="end"
                sx={{ mr: 0, cursor: isEditing ? 'pointer' : 'default' }}
              />
            </Grid>
            <Grid item xs={12}>
              <AdvancedTextField
                fullWidth
                required
                editing={isEditing}
                label={t('label.accessKey')}
                value={file.source.config.accessKey}
                onChange={handleChangeSourceConfigAccessKey}
              />
            </Grid>
            <Grid item xs={12}>
              <AdvancedTextField
                fullWidth
                required
                editing={isEditing}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={isSecretKeyHidden ? t('tooltip.hideSecretKey') : t('tooltip.seeSecretKey')}>
                        <IconButton edge="end" size="large" onClick={handleToggleSecretKeyVisibility}>
                          {isSecretKeyHidden ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                label={t('label.secretKey')}
                type={isSecretKeyHidden ? 'password' : 'text'}
                value={file.source.config.secretKey}
                onChange={handleChangeSourceConfigSecretKey}
              />
            </Grid>
            <Grid item xs={12}>
              <AdvancedTextField
                fullWidth
                required
                editing={isEditing}
                label={t('label.bucket')}
                value={file.source.config.bucket}
                onChange={handleChangeSourceConfigBucket}
              />
            </Grid>
            <Grid item xs={12}>
              <AdvancedTextField
                fullWidth
                required
                editing={isEditing}
                label={t('label.path')}
                value={file.source.config.path}
                onChange={handleChangeSourceConfigPath}
              />
            </Grid>
          </Grid>
        )}

        {IsFileDeviceSftpInternalSource(file.source) && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AdvancedTextField
                fullWidth
                required
                editing={isEditing && mode === 'create'}
                InputProps={{ startAdornment: <InputAdornment position="start">{bucket}</InputAdornment> }}
                label={t('label.path')}
                value={file.source.config.path}
                onChange={handleChangeSourceConfigPath}
              />
            </Grid>
          </Grid>
        )}

        {IsFileDeviceSftpSource(file.source) && (
          <Grid container spacing={1}>
            <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
              <AdvancedTextField
                fullWidth
                required
                editing={isEditing}
                label={t('label.host')}
                value={file.source.config.host}
                onChange={handleChangeSourceConfigHost}
              />
            </Grid>
            <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
              <AdvancedTextField
                fullWidth
                required
                editing={isEditing}
                InputProps={{ inputProps: { min: 0, max: 65535 } }}
                label={t('label.port')}
                type="number"
                value={portInputValue}
                onChange={handleChangeSourceConfigPort}
              />
            </Grid>
            <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
              <AdvancedTextField
                fullWidth
                required
                editing={isEditing}
                label={t('label.username')}
                value={file.source.config.username}
                onChange={handleChangeSourceConfigUsername}
              />
            </Grid>
            <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
              <AdvancedTextField
                fullWidth
                required
                autoComplete="new-password"
                editing={isEditing}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={isPasswordHidden ? t('tooltip.hidePassword') : t('tooltip.seePassword')}>
                        <IconButton edge="end" size="large" onClick={handleTogglePasswordVisibility}>
                          {isPasswordHidden ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                label={t('label.password')}
                type={isPasswordHidden ? 'password' : 'text'}
                value={file.source.config.password}
                onChange={handleChangeSourceConfigPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <AdvancedTextField
                fullWidth
                required
                editing={isEditing}
                label={t('label.path')}
                value={file.source.config.path}
                onChange={handleChangeSourceConfigPath}
              />
            </Grid>
          </Grid>
        )}
      </FormPaper>
    </>
  );
};

export default FileSettingsForm;

import { CreateBlockTypeBody, UpdateBlockTypeBody } from '@dametis/core';

export const getIsConnectionsStepValid = (body: CreateBlockTypeBody | UpdateBlockTypeBody): boolean => {
  const blockKeys: string[] = [];
  return (body.connections ?? []).every(connection => {
    const blockKey = connection.blockKey.trim();
    const result =
      blockKey.length > 0 && connection.blockTypeId !== null && connection.blockTypeId.trim().length > 0 && !blockKeys.includes(blockKey);
    blockKeys.push(blockKey);
    return result;
  });
};

import { BookmarkAddOutlined, DeleteOutlined, History } from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  capitalize,
  listItemSecondaryActionClasses,
} from '@mui/material';
import { cloneDeep } from 'lodash-es';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateAliasBody, GetCalculationVariableStats } from '@dametis/core';

import { PropsContext } from 'components/VNC/context';
import { slateToTada } from 'components/VNC/slate/tada';
import makePermissionLabel from 'functions/makePermissionLabel';
import { openReplayEvent } from 'functions/openReplay';
import { usePermission } from 'hooks/usePermission';
import { localizedFormatDistance } from 'localization/localizedDateFns';
import { useSelector } from 'store';

import CalculationSlate from '../../../../UI/CalculationSlate/CalculationSlate';
import { useInsertCalculation } from '../../../hooks';
import { HistoryItem, OpenReplayEvent } from '../../../types';
import { TypographyNoBreak } from '../TypographyNoBreak';

import AliasDialog from './AliasDialog';
import { db } from './db';

export interface HistoryListItemProps {
  item: HistoryItem;
  isLast: boolean;
}

const HistoryListItem: FC<HistoryListItemProps> = ({ item, isLast }) => {
  const { t } = useTranslation('vnc');

  const { disableLego, sourceUuid, sourceCategory } = useContext(PropsContext);
  const isCorpo = useSelector(state => !state.auth.selectedSite);

  const insertCalculation = useInsertCalculation();

  const canEditAlias = usePermission('canEditAlias');

  const [open, setOpen] = useState(false);

  const hasBlocks = useMemo(
    () => GetCalculationVariableStats(slateToTada(item.slate, { withSlate: false })).blockVariables.length > 0,
    [item.slate],
  );
  const alias = useMemo<CreateAliasBody>(
    () => ({ name: '', calculation: slateToTada(item.slate), source: { uuid: sourceUuid ?? undefined, category: sourceCategory } }),
    [item.slate, sourceCategory, sourceUuid],
  );

  const addItem = useCallback(() => {
    openReplayEvent(OpenReplayEvent.VNC_HISTORY_INSERTING_ELEMENT);
    insertCalculation(cloneDeep(item.slate));
  }, [insertCalculation, item.slate]);

  const deleteItem = useCallback(async () => {
    await db.history.delete(item.id);
  }, [item.id]);

  const openAliasDialog = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <ListItem
      divider={!isLast}
      secondaryAction={
        <Stack direction="row">
          {!isCorpo && (
            <Tooltip title={makePermissionLabel(t('button.saveHistoryItemAsAlias'), canEditAlias, true)}>
              <div>
                <IconButton disabled={!canEditAlias} onClick={openAliasDialog}>
                  <BookmarkAddOutlined />
                </IconButton>
              </div>
            </Tooltip>
          )}
          <AliasDialog alias={alias} open={open} setOpen={setOpen} />
          <Tooltip title={t('button.deleteHistoryItem')}>
            <IconButton onClick={deleteItem}>
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
        </Stack>
      }
      sx={{
        px: 0,
        py: 0.5,
        [`& .${listItemSecondaryActionClasses.root}`]: { display: 'none' },
        [`&:hover .${listItemSecondaryActionClasses.root}`]: { display: 'block' },
      }}
    >
      <ListItemButton disableGutters disabled={hasBlocks && disableLego} sx={{ pr: '96px !important' }} onClick={addItem}>
        <ListItemAvatar>
          <Stack alignItems="center" spacing={0.5} sx={{ width: 40 }}>
            <Avatar>
              <History />
            </Avatar>
            <TypographyNoBreak variant="caption">{t('list.text.history')}</TypographyNoBreak>
          </Stack>
        </ListItemAvatar>
        <ListItemText
          primary={<CalculationSlate calculation={item.slate} />}
          primaryTypographyProps={{ component: 'div' }}
          secondary={item.createdAt ? capitalize(localizedFormatDistance(item.createdAt, new Date(), { addSuffix: true })) : undefined}
          sx={{ height: 1 }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default HistoryListItem;

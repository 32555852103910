import { ArrowForwardIosOutlined, ArrowRight, NavigateNext, SettingsOutlined, Star, StarOutline } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  breadcrumbsClasses,
} from '@mui/material';
import {
  FC,
  KeyboardEventHandler,
  MouseEventHandler,
  RefObject,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { GroupInfo, SiteInfo, UUID } from '@dametis/core';

import GroupSiteMenuHelper from 'components/Home/GroupSiteMenuHelper';
import SiteTile from 'components/Home/SiteTile';
import { BorderBox } from 'components/UI/BorderBox/BorderBox';
import LinkButton, { LinkButtonOnClick } from 'components/UI/Buttons/LinkButton/LinkButton';
import PopperPanel from 'components/UI/PopperPanel/PopperPanel';
import SearchInput from 'components/UI/SearchInput/SearchInput';
import { OpenReplayEvent } from 'components/VNC/types';
import getHighlightedText from 'functions/getHighlightedText';
import { getLocalStorageItem, setLocalStorageItem } from 'functions/localStorage';
import { openReplayEvent } from 'functions/openReplay';
import { stopPropagation } from 'functions/stopPropagation';
import useFuse from 'hooks/useFuse';
import { usePermission } from 'hooks/usePermission';
import { sdk } from 'sdk';
import { useDispatch, useSelector } from 'store';
import { updateSiteAndGroup } from 'store/actions/auth';
import { displaySdkErrorToast } from 'store/actions/toasts';
import { setFavoriteGroups } from 'store/slices/auth';

const filterOptions = { keys: ['name', 'normalizedName', 'sites.name', 'sites.location.city'] };

const GroupSiteMenu: FC = () => {
  const { t } = useTranslation('header');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const canAccessConfiguration = usePermission('canAccessConfiguration');

  const selectedGroup = useSelector(state => state.auth.selectedGroup!);
  const selectedGroupId = useMemo(() => selectedGroup.uuid, [selectedGroup.uuid]);
  const selectedSite = useSelector(state => state.auth.selectedSite);
  const selectedSiteId = useMemo(() => selectedSite?.uuid, [selectedSite?.uuid]);
  const groups = useSelector(state => state.auth.groups);
  const acl = useSelector(state => state.auth.user!.acl);
  const userId = useSelector(state => state.auth.user!.uuid);
  const favoriteGroups = useSelector(state => state.auth.user!.favorites.groups);

  const [targetGroup, setTargetGroup] = useState<GroupInfo>(selectedGroup);
  const [targetSite, setTargetSite] = useState<SiteInfo | null>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const anchorRef = useRef<HTMLButtonElement>(null);
  const inputSearchRef = useRef<HTMLInputElement>();
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<Record<UUID, RefObject<any>>>({});

  const isCorporate = useMemo(() => !selectedSiteId, [selectedSiteId]);
  const sortedGroups = useMemo(
    () =>
      groups.toSorted((groupA, groupB) => {
        if (!favoriteGroups || favoriteGroups.includes(groupA.uuid) === favoriteGroups.includes(groupB.uuid)) {
          return groupA.name.localeCompare(groupB.name);
        }
        return favoriteGroups.includes(groupA.uuid) ? -1 : 1;
      }),
    [groups, favoriteGroups],
  );
  const filteredGroups = useFuse(sortedGroups, search, filterOptions);

  const navToSettings = useCallback<MouseEventHandler>(event => {
    stopPropagation(event);
    openReplayEvent(OpenReplayEvent.OPEN_GROUP_SITE_MENU);
  }, []);

  const open = useCallback(() => {
    setSearch('');
    setIsMenuOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const goToGroup = useCallback(
    (newTargetGroup: GroupInfo): LinkButtonOnClick =>
      async (_, event) => {
        event?.preventDefault();
        event?.stopPropagation();
        navigate('/', { state: { goToGroupAndSite: true } });
        close();
        await dispatch(updateSiteAndGroup({ group: newTargetGroup, site: null }));
      },
    [close, dispatch, navigate],
  );

  const goToGroupAndSite = useCallback(
    (newTargetGroup: GroupInfo, newTargetSite: SiteInfo) => async () => {
      navigate('/', { state: { goToGroupAndSite: true } });
      close();
      await dispatch(updateSiteAndGroup({ group: newTargetGroup, site: newTargetSite }));
    },
    [close, dispatch, navigate],
  );

  const makeAddFavoriteGroup = useCallback(
    (groupId: UUID): MouseEventHandler<HTMLButtonElement> =>
      async () => {
        const newFavoriteGroups = favoriteGroups?.includes(groupId)
          ? favoriteGroups.filter(group => group !== groupId)
          : [...(favoriteGroups ?? []), groupId];
        try {
          await sdk.user.Update(userId, { favorites: { groups: newFavoriteGroups } });
          dispatch(setFavoriteGroups(newFavoriteGroups));
        } catch (error) {
          console.error(error);
          dispatch(displaySdkErrorToast(error));
        }
      },
    [favoriteGroups, userId, dispatch],
  );

  const changeTarget = useCallback(
    ({ group: newTargetGroup = targetGroup, site: newTargetSite = targetSite }) => {
      setTargetGroup(newTargetGroup);
      setTargetSite(newTargetSite);
      if (newTargetSite) {
        buttonsRef.current[newTargetSite.uuid].current?.focus();
      } else {
        buttonsRef.current[newTargetGroup.uuid].current?.focus();
      }
    },
    [targetGroup, targetSite],
  );

  const toggleIsMenuOpen = useCallback(() => {
    if (isMenuOpen) {
      close();
    } else {
      open();
    }
  }, [close, isMenuOpen, open]);

  useHotkeys('mod+k', toggleIsMenuOpen, { enableOnFormTags: ['INPUT', 'TEXTAREA', 'SELECT'], preventDefault: true }, [toggleIsMenuOpen]);

  const handleMenuKeyDownArrowDown = useCallback(() => {
    if (targetSite) {
      const oldTargetSiteIndex = targetGroup.sites.findIndex(site => site.uuid === targetSite.uuid);
      if (oldTargetSiteIndex < targetGroup.sites.length - 1) {
        changeTarget({ site: targetGroup.sites[oldTargetSiteIndex + 1] });
      }
    } else {
      const oldTargetGroupIndex = filteredGroups.findIndex(group => group.uuid === targetGroup.uuid);
      if (oldTargetGroupIndex < filteredGroups.length - 1) {
        changeTarget({ group: filteredGroups[oldTargetGroupIndex + 1] });
      }
    }
  }, [changeTarget, filteredGroups, targetGroup.sites, targetGroup.uuid, targetSite]);

  useHotkeys('ArrowDown', handleMenuKeyDownArrowDown, { enabled: isMenuOpen, preventDefault: true }, [handleMenuKeyDownArrowDown]);

  const handleMenuKeyDownArrowUp = useCallback(() => {
    if (targetSite) {
      const oldTargetSiteIndex = targetGroup.sites.findIndex(site => site.uuid === targetSite.uuid);
      if (oldTargetSiteIndex > 0) {
        changeTarget({ site: targetGroup.sites[oldTargetSiteIndex - 1] });
      }
    } else {
      const oldTargetGroupIndex = filteredGroups.findIndex(group => group.uuid === targetGroup.uuid);
      if (oldTargetGroupIndex === undefined) {
        changeTarget({ group: filteredGroups[0] });
      }
      if (oldTargetGroupIndex === 0) {
        inputSearchRef.current?.focus();
      }
      if (oldTargetGroupIndex > 0) {
        changeTarget({ group: filteredGroups[oldTargetGroupIndex - 1] });
      }
    }
  }, [changeTarget, filteredGroups, targetGroup.sites, targetGroup.uuid, targetSite]);

  useHotkeys('ArrowUp', handleMenuKeyDownArrowUp, { enabled: isMenuOpen, preventDefault: true }, [handleMenuKeyDownArrowUp]);

  const handleMenuKeyDownArrowLeft = useCallback(() => {
    if (targetSite) {
      changeTarget({ site: null });
    }
  }, [changeTarget, targetSite]);

  useHotkeys('ArrowLeft', handleMenuKeyDownArrowLeft, { enabled: isMenuOpen, preventDefault: true }, [handleMenuKeyDownArrowLeft]);

  const handleMenuKeyDownArrowRight = useCallback(() => {
    if (!targetSite) {
      changeTarget({ site: targetGroup.sites[0] });
    }
  }, [changeTarget, targetGroup.sites, targetSite]);

  useHotkeys('ArrowRight', handleMenuKeyDownArrowRight, { enabled: isMenuOpen, preventDefault: true }, [handleMenuKeyDownArrowRight]);

  const handleSearchKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    event => {
      if (event.key === 'ArrowDown' || event.key === 'Enter') {
        changeTarget({ group: filteredGroups[0] });
      }
    },
    [changeTarget, filteredGroups],
  );

  useEffect(() => {
    if (isMenuOpen) {
      setTargetGroup(selectedGroup);
      setTargetSite(null);
    }
  }, [isMenuOpen, selectedGroup]);

  useEffect(() => {
    groups.forEach(group => {
      buttonsRef.current[group.uuid] = createRef();
      group.sites.forEach(site => {
        buttonsRef.current[site.uuid] = createRef();
      });
    }, []);
  }, [groups]);

  useEffect(() => {
    void (async () => {
      const localStorageFavoriteGroups: UUID[] = getLocalStorageItem('favoriteGroups', { userId }) ?? [];
      if (Array.isArray(localStorageFavoriteGroups) && localStorageFavoriteGroups.length > 0) {
        try {
          await sdk.user.Update(userId, { favorites: { groups: localStorageFavoriteGroups } });
          dispatch(setFavoriteGroups(localStorageFavoriteGroups));
          setLocalStorageItem('favoriteGroups', [], { userId });
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [dispatch, userId]);

  return (
    <>
      <Button
        ref={anchorRef}
        endIcon={
          canAccessConfiguration ? (
            <Tooltip title={isCorporate ? t('tooltip.groupConfiguration') : t('tooltip.siteConfiguration')}>
              <IconButton
                component={NavLink}
                size="small"
                to={isCorporate ? '/group' : '/site'}
                onClick={navToSettings}
                onMouseDown={stopPropagation}
              >
                <SettingsOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : undefined
        }
        sx={{ whiteSpace: 'nowrap' }}
        onClick={open}
        onKeyDown={event => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          sx={{
            [`& .${breadcrumbsClasses.ol}`]: {
              flexWrap: 'nowrap',
            },
          }}
        >
          <Typography color={!isCorporate ? 'textSecondary' : undefined} sx={{ fontWeight: 600 }} variant="h6">
            {selectedGroup.name}
          </Typography>
          {!isCorporate && (
            <Typography sx={{ fontWeight: 600 }} variant="h6">
              {selectedSite?.name}
            </Typography>
          )}
        </Breadcrumbs>
      </Button>
      <PopperPanel anchorEl={anchorRef.current ?? undefined} open={isMenuOpen} onClickAway={close}>
        <Stack ref={menuRef} direction="row" height="80vh" minWidth={600}>
          {groups.length > 1 && (
            <>
              <Stack height={1} py={2} spacing={1.5} width={300}>
                <GroupSiteMenuHelper />
                <Stack height={1} px={1.5} spacing={1.5}>
                  <Typography variant="overline">{t('siteSelect.groups')}</Typography>
                  {sortedGroups.length > 1 && (
                    <SearchInput
                      autoFocus
                      extendable={false}
                      inputRef={inputSearchRef}
                      value={search}
                      onChange={setSearch}
                      onKeyDown={handleSearchKeyDown}
                    />
                  )}
                  <Box flexGrow={1} height={0} overflow="auto">
                    <List>
                      {filteredGroups.map(group => (
                        <ListItemButton
                          key={group.uuid}
                          ref={buttonsRef.current[group.uuid]}
                          disableRipple
                          selected={group.uuid === targetGroup.uuid}
                          sx={{ position: 'relative' }}
                          onClick={() => {
                            changeTarget({ group, site: group.sites[0] });
                          }}
                          onFocus={() => {
                            setTargetGroup(group);
                            setTargetSite(null);
                          }}
                        >
                          <ListItemText
                            primary={
                              <>
                                {favoriteGroups?.includes(group.uuid) && <Star sx={{ color: 'picker.yellow', fontSize: 12 }} />}
                                {getHighlightedText(group.name, search)}
                              </>
                            }
                            secondary={getHighlightedText(group.sites.map(s => s.name).join(', '), search)}
                            secondaryTypographyProps={{ noWrap: true }}
                          />
                          {group.uuid === targetGroup.uuid && <ArrowRight color="action" />}
                          <BorderBox active={group.uuid === selectedGroupId} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Box>
                </Stack>
              </Stack>
              <Divider orientation="vertical" />
            </>
          )}
          <Stack my={2} overflow="auto" px={1.5} spacing={1.5} width={300}>
            <Stack height={1} spacing={1.5}>
              <Typography variant="overline">{t('siteSelect.group')}</Typography>
              <Typography mb={0.5} variant="h3">
                {targetGroup?.name}
              </Typography>
              <Stack direction="row" justifyContent="space-between">
                {groups.length > 1 && (
                  <Tooltip
                    title={favoriteGroups?.some(groupId => groupId === targetGroup.uuid) ? t('tooltip.unfavorite') : t('tooltip.favorite')}
                  >
                    <IconButton size="small" onClick={makeAddFavoriteGroup(targetGroup.uuid)}>
                      {favoriteGroups?.some(groupId => groupId === targetGroup.uuid) ? (
                        <Star sx={{ color: 'picker.yellow' }} />
                      ) : (
                        <StarOutline sx={{ color: 'icon.main' }} />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
                {targetGroup.uuid && acl?.HasPermissionOnGroup('canAccessCorporate', targetGroup.uuid) && (
                  <LinkButton
                    disabled={isCorporate && targetGroup.uuid === selectedGroupId}
                    endIcon={<ArrowForwardIosOutlined fontSize="small" />}
                    size="small"
                    sx={{ display: 'flex', ml: 'auto' }}
                    onClick={goToGroup(targetGroup)}
                  >
                    {t('siteSelect.corporate')}
                  </LinkButton>
                )}
              </Stack>
              <Divider />
              <Typography variant="overline">{t('siteSelect.sites')}</Typography>
              <Stack flexGrow={1} spacing={2}>
                {targetGroup?.sites.map(site => (
                  <BorderBox key={site.uuid} active={site.uuid === selectedSiteId} sx={{ borderRadius: 1 }}>
                    <SiteTile
                      refIndex={buttonsRef.current[site.uuid]}
                      selected={site.uuid === targetSite?.uuid}
                      site={site}
                      onSelect={goToGroupAndSite(targetGroup, site)}
                    />
                  </BorderBox>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </PopperPanel>
    </>
  );
};

export default GroupSiteMenu;

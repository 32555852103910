import { ContentCopyOutlined } from '@mui/icons-material';
import { FormLabel, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeObserver } from 'usehooks-ts';

import { FileDeviceSourceConfig, FileDeviceSourceType } from '@dametis/core';

import FormPaper from 'components/Configuration/Devices/CreateDeviceModal/FormPaper';
import { RightPanelSection, RightPanelSectionContent } from 'components/UI/RightPanel';
import { useDispatch } from 'store';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

const ICON_BUTTON_CLASSNAME = 'iconButton';

export interface SftpInternalSourceCredentialsProps {
  source: FileDeviceSourceConfig<FileDeviceSourceType.SFTP_INTERNAL>;
}

const SftpInternalSourceCredentials: FC<SftpInternalSourceCredentialsProps> = ({ source }) => {
  const { t } = useTranslation('devices');
  const dispatch = useDispatch();

  const gridRef = useRef<HTMLDivElement | null>(null);

  const { width: gridWidth = 0 } = useResizeObserver({
    ref: gridRef,
  });

  const copyToClipboard = useCallback(
    (text: string) => async () => {
      try {
        await navigator.clipboard.writeText(text);
        dispatch(
          addToast({
            message: t('toast.copyToClipboard'),
            severity: ToastSeverity.INFO,
          }),
        );
      } catch (err) {
        console.error(err);
      }
    },
    [t, dispatch],
  );

  return (
    <RightPanelSection>
      <RightPanelSectionContent>
        <FormPaper location="panel" sx={{ p: 1.5 }}>
          <Typography mb={1.5} variant="h6">
            {t(`button.sourceType.${FileDeviceSourceType.SFTP_INTERNAL}`)}
          </Typography>
          <Grid ref={gridRef} container spacing={1}>
            <Grid item xs={gridWidth > 400 ? 6 : 12}>
              <Stack
                alignItems="center"
                direction="row"
                sx={{ [`& .${ICON_BUTTON_CLASSNAME}`]: { opacity: 0 }, [`&:hover .${ICON_BUTTON_CLASSNAME}`]: { opacity: 1 } }}
              >
                <Stack>
                  <FormLabel>{t('label.host')}</FormLabel>
                  <Typography>{source.config.host ?? t('global:text.noValue')}</Typography>
                </Stack>
                <Tooltip title={t('tooltip.copyToClipboard')}>
                  <IconButton className={ICON_BUTTON_CLASSNAME} onClick={copyToClipboard(source.config.host ?? '')}>
                    <ContentCopyOutlined fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
            <Grid item xs={gridWidth > 400 ? 6 : 12}>
              <Stack
                alignItems="center"
                direction="row"
                sx={{ [`& .${ICON_BUTTON_CLASSNAME}`]: { opacity: 0 }, [`&:hover .${ICON_BUTTON_CLASSNAME}`]: { opacity: 1 } }}
              >
                <Stack>
                  <FormLabel>{t('label.port')}</FormLabel>
                  <Typography>{source.config.port ?? t('global:text.noValue')}</Typography>
                </Stack>
                <Tooltip title={t('tooltip.copyToClipboard')}>
                  <IconButton className={ICON_BUTTON_CLASSNAME} onClick={copyToClipboard(`${source.config.port ?? ''}`)}>
                    <ContentCopyOutlined fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
            <Grid item xs={gridWidth > 400 ? 6 : 12}>
              <Stack
                alignItems="center"
                direction="row"
                sx={{ [`& .${ICON_BUTTON_CLASSNAME}`]: { opacity: 0 }, [`&:hover .${ICON_BUTTON_CLASSNAME}`]: { opacity: 1 } }}
              >
                <Stack>
                  <FormLabel>{t('label.username')}</FormLabel>
                  <Typography>{source.config.username ?? t('global:text.noValue')}</Typography>
                </Stack>
                <Tooltip title={t('tooltip.copyToClipboard')}>
                  <IconButton className={ICON_BUTTON_CLASSNAME} onClick={copyToClipboard(source.config.username ?? '')}>
                    <ContentCopyOutlined fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
            <Grid item xs={gridWidth > 400 ? 6 : 12}>
              <Stack
                alignItems="center"
                direction="row"
                sx={{ [`& .${ICON_BUTTON_CLASSNAME}`]: { opacity: 0 }, [`&:hover .${ICON_BUTTON_CLASSNAME}`]: { opacity: 1 } }}
              >
                <Stack>
                  <FormLabel>{t('label.password')}</FormLabel>
                  <Typography>{source.config.password ?? t('global:text.noValue')}</Typography>
                </Stack>
                <Tooltip title={t('tooltip.copyToClipboard')}>
                  <IconButton className={ICON_BUTTON_CLASSNAME} onClick={copyToClipboard(source.config.password ?? '')}>
                    <ContentCopyOutlined fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                alignItems="center"
                direction="row"
                sx={{ [`& .${ICON_BUTTON_CLASSNAME}`]: { opacity: 0 }, [`&:hover .${ICON_BUTTON_CLASSNAME}`]: { opacity: 1 } }}
              >
                <Stack>
                  <FormLabel>{t('label.path')}</FormLabel>
                  <Typography>{source.config.path ?? t('global:text.noValue')}</Typography>
                </Stack>
                <Tooltip title={t('tooltip.copyToClipboard')}>
                  <IconButton className={ICON_BUTTON_CLASSNAME} onClick={copyToClipboard(source.config.path ?? '')}>
                    <ContentCopyOutlined fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>
        </FormPaper>
      </RightPanelSectionContent>
    </RightPanelSection>
  );
};

export default SftpInternalSourceCredentials;

import { t } from 'i18next';

import { AUTO_GROUPBY, MUTED_GROUPBY, TimeUnit, VariableDelay, timeIntervalRegex } from '@dametis/core';

const getVariableDelayLabel = (delay: VariableDelay, inheritedDelay?: VariableDelay): string => {
  if (delay === AUTO_GROUPBY) {
    return inheritedDelay !== undefined
      ? `${t('delayPicker:label.default')} (${getVariableDelayLabel(inheritedDelay)})`
      : t('delayPicker:label.default');
  }
  if (delay === MUTED_GROUPBY) {
    return t('delayPicker:label.muted');
  }
  // ? Must not happened but checked
  if (!timeIntervalRegex.test(delay)) {
    return t('delayPicker:label.invalid');
  }
  const [, rawValue, unit] = delay.match(timeIntervalRegex) as [string, `${number}`, TimeUnit];
  const value = Number(rawValue);
  if (!value) {
    return t('delayPicker:label.live');
  }
  switch (unit) {
    case TimeUnit.YEAR:
      return `${value} ${t('global:unit.time_year', { count: value })}`;
    case TimeUnit.MONTH:
      return `${value} ${t('global:unit.time_month', { count: value })}`;
    case TimeUnit.DAY:
      return `${value} ${t('global:unit.time_day', { count: value })}`;
    case TimeUnit.HOUR:
      return `${value} ${t('global:unit.time_hour', { count: value })}`;
    case TimeUnit.MINUTE:
      return `${value} ${t('global:unit.time_minute', { count: value })}`;
    case TimeUnit.SECOND:
      return `${value} ${t('global:unit.time_second', { count: value })}`;
    case TimeUnit.MILLISECOND:
    default:
      return `${value} ${t('global:unit.time_millisecond', { count: value })}`;
  }
};

export default getVariableDelayLabel;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BatchInfo, Operator } from '@dametis/core';

export type BatchFilterQuery = { type: string; content: BatchInfo | string };

export interface BatchState {
  currentBatch: BatchInfo | null;
  fetching: boolean;
  allBatches: BatchInfo[];
  flattenBatches: BatchInfo[];
  filters: {
    query: BatchFilterQuery[] | null;
    operator: Operator | undefined;
  };
}

const initialState: BatchState = {
  currentBatch: null,
  fetching: false,
  allBatches: [],
  flattenBatches: [],
  filters: {
    query: null,
    operator: undefined,
  },
};

export const batchSlice = createSlice({
  name: 'batch',
  initialState,
  reducers: {
    setBatches: (state, action: PayloadAction<{ allBatches: BatchInfo[]; flattenBatches: BatchInfo[] }>) => {
      state.allBatches = action.payload.allBatches;
      state.flattenBatches = action.payload.flattenBatches;
    },
    setCurrentBatch: (state, action: PayloadAction<BatchInfo | null>) => {
      state.currentBatch = action.payload;
    },
    setFilterQuery: (state, action: PayloadAction<BatchFilterQuery[]>) => {
      state.filters.query = action.payload;
    },
    setFilterOperator: (state, action: PayloadAction<Operator | undefined>) => {
      state.filters.operator = action.payload;
    },
    setBatchFetching: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
    clearQuery: state => {
      state.currentBatch = null;
      state.filters = initialState.filters;
    },
    clearExceptCurrentBatch: state => {
      return { ...initialState, currentBatch: state.currentBatch };
    },
    clearBatchStore: () => initialState,
  },
});

export const { setBatches, setCurrentBatch, setFilterQuery, setFilterOperator, setBatchFetching, clearQuery, clearBatchStore } =
  batchSlice.actions;

export default batchSlice.reducer;

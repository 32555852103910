import { Box, ButtonBase, Paper, Stack, StackProps, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BrandDeviceInfo } from '@dametis/core';

import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import getProtocols from 'config/protocols';

export interface BrandDevicePickerProps extends Omit<StackProps, 'children'> {
  brandDevice: BrandDeviceInfo | null;
}

const BrandDevicePicker: FC<BrandDevicePickerProps> = ({ brandDevice, onClick, ...props }) => {
  const { t } = useTranslation('devices');

  const protocols = useMemo(() => getProtocols(t), [t]);

  return (
    <Paper sx={{ overflow: 'hidden' }}>
      <Stack
        alignItems="center"
        component={onClick ? ButtonBase : undefined}
        direction="row"
        gap={1}
        height={37}
        justifyContent="flex-start"
        p={1}
        width={1}
        onClick={onClick}
        {...props}
      >
        {!brandDevice && <Typography variant="h6">{t('text.noBrandDevice')}</Typography>}
        {brandDevice && (
          <>
            <Box
              alt={brandDevice.name}
              component="img"
              src={brandDevice.previewPath || protocols[brandDevice.protocol].preview}
              sx={{
                height: 1,
                aspectRatio: '1/1',
                borderRadius: theme => `${theme.shape.borderRadius}px`,
                objectFit: 'contain',
              }}
            />
            <TypographyEllipse variant="h6">{brandDevice.name}</TypographyEllipse>
          </>
        )}
      </Stack>
    </Paper>
  );
};

export default BrandDevicePicker;

import { Feed, FeedOutlined } from '@mui/icons-material';
import { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockTypeInfo, ShortStandardBlockInfo } from '@dametis/core';

import { Blocks } from 'assets/icons/Blocks';
import { BlocksOutlined } from 'assets/icons/BlocksOutlined';
import IconTab from 'components/UI/IconTabs/IconTab';
import IconTabs from 'components/UI/IconTabs/IconTabs';
import { useBlockTypes } from 'store/api/blockTypes';

import BlocksTab from './BlocksTab';
import InformationsTab from './InformationsTab';

export enum StandardBlockPanelTab {
  INFORMATIONS = 'informations',
  BLOCKS = 'blocks',
}

const blockTypesEmptyArray: BlockTypeInfo[] = [];

export interface StandardBlockRightPanelProps {
  standardBlock: ShortStandardBlockInfo;
}

const StandardBlockRightPanel: FC<StandardBlockRightPanelProps> = ({ standardBlock }) => {
  const { t } = useTranslation('lego');

  const { data: blockTypes = blockTypesEmptyArray } = useBlockTypes();

  const [selectedPanelTab, setSelectedPanelTab] = useState<StandardBlockPanelTab>(StandardBlockPanelTab.INFORMATIONS);

  const currentBlockType = useMemo(
    () => blockTypes.find(blockType => blockType.uuid === standardBlock.blockTypeId),
    [standardBlock.blockTypeId, blockTypes],
  );

  const handleChangePanelTab = useCallback((_event: SyntheticEvent, newPanelTab: StandardBlockPanelTab) => {
    setSelectedPanelTab(newPanelTab);
  }, []);

  return (
    <>
      <IconTabs centered value={selectedPanelTab} variant="fullWidth" onChange={handleChangePanelTab}>
        <IconTab
          activeIcon={<Feed />}
          icon={<FeedOutlined />}
          isActive={StandardBlockPanelTab.INFORMATIONS === selectedPanelTab}
          label={t(`panelTab.${StandardBlockPanelTab.INFORMATIONS}`)}
          value={StandardBlockPanelTab.INFORMATIONS}
        />
        <IconTab
          activeIcon={<Blocks />}
          icon={<BlocksOutlined />}
          isActive={StandardBlockPanelTab.BLOCKS === selectedPanelTab}
          label={t(`panelTab.${StandardBlockPanelTab.BLOCKS}`)}
          value={StandardBlockPanelTab.BLOCKS}
        />
      </IconTabs>
      {selectedPanelTab === StandardBlockPanelTab.INFORMATIONS && (
        <InformationsTab blockType={currentBlockType} standardBlock={standardBlock} />
      )}
      {selectedPanelTab === StandardBlockPanelTab.BLOCKS && <BlocksTab blockType={currentBlockType} standardBlock={standardBlock} />}
    </>
  );
};

export default StandardBlockRightPanel;

import { LoadingButton, LoadingButtonProps, loadingButtonClasses } from '@mui/lab';
import { Tooltip, TooltipProps, buttonClasses, styled } from '@mui/material';
import { FC, forwardRef } from 'react';

type EmptyTooltipProps = Omit<TooltipProps, 'children'>;

export interface ActionButtonProps extends LoadingButtonProps {
  tooltipProps?: EmptyTooltipProps;
  loadingPosition?: LoadingButtonProps['loadingPosition'];
}

const Wrapper: FC<TooltipProps> = ({ children = undefined, title, ...tooltipProps }) =>
  title ? (
    <Tooltip title={title} {...tooltipProps}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  );

const StyledButton = styled(LoadingButton)(({ children, theme }) => ({
  textTransform: 'unset',
  minWidth: 0,
  border: 'none !important',
  lineHeight: '20px',
  paddingTop: theme.spacing(0.375),
  paddingBottom: theme.spacing(0.375),
  ...(!children && {
    [`& .${buttonClasses.startIcon}`]: {
      margin: 0,
    },
    [`& .${loadingButtonClasses.loadingIndicator}`]: {
      left: 10,
    },
  }),
  [`&.${buttonClasses.colorPrimary}`]: {
    color: theme.palette.primary.main,
  },
  [`&.${buttonClasses.colorSecondary}`]: {
    color: theme.palette.secondary.main,
  },
  [`&.${buttonClasses.contained}.${buttonClasses.colorPrimary}`]: {
    backgroundColor: `${theme.palette.primary.light}11`,
  },
  [`&.${buttonClasses.contained}.${buttonClasses.colorPrimary}:hover`]: {
    backgroundColor: `${theme.palette.primary.light}22`,
  },
  [`&.${buttonClasses.contained}.${buttonClasses.colorSecondary}`]: {
    backgroundColor: `${theme.palette.secondary.light}11`,
  },
  [`&.${buttonClasses.contained}.${buttonClasses.colorSecondary}:hover`]: {
    backgroundColor: `${theme.palette.secondary.light}22`,
  },
  [`&.${buttonClasses.disabled}`]: {
    opacity: 0.5,
  },
}));

const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ tooltipProps = { title: '' }, children, className, loadingPosition = 'start', ...props }, ref) => (
    <Wrapper {...tooltipProps}>
      <StyledButton ref={ref} loadingPosition={loadingPosition} variant="contained" {...props}>
        {children}
      </StyledButton>
    </Wrapper>
  ),
);

ActionButton.defaultProps = {
  tooltipProps: { title: '' },
  loadingPosition: 'start',
};

ActionButton.displayName = 'ActionButton';

export default ActionButton;

import { Box, FormLabel, Stack, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createEditor } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';

import { CalculationVariable } from '@dametis/core';

import { createParagraph } from 'components/VNC/slate/paragraph';
import { tadaToSlate } from 'components/VNC/slate/tada';
import { useDispatch } from 'store';
import { fetchBatches, setBatchFilters } from 'store/actions/batch';
import { setFilterOperator } from 'store/slices/batch';

import BatchFilterModal from './BatchFilterModal';
import { RenderElement } from './slate/RenderElement';
import { withCustomElements } from './slate/withCustomElements';

const initialSlate = [createParagraph()];

export interface BatchFilterInputProps {
  batch: CalculationVariable | null;
  setBatch: (batch: CalculationVariable | null) => void;
  disabled?: boolean;
  noOperator?: boolean;
  label?: string;
  editing?: boolean;
}

const BatchFilterInput: FC<BatchFilterInputProps> = ({
  batch,
  setBatch,
  disabled = false,
  noOperator = false,
  label = undefined,
  editing = true,
}) => {
  const { t } = useTranslation('batch');
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const editor = useMemo(() => withCustomElements(withReact(createEditor()), false), []);
  const slate = useMemo(() => (batch !== null && tadaToSlate(batch) ? tadaToSlate(batch) : initialSlate), [batch]);

  const openModal = useCallback(() => {
    setOpen(true);
    if (batch?.exp) {
      dispatch(setBatchFilters(batch));
    } else {
      dispatch(setBatchFilters(undefined));
    }
  }, [batch, dispatch]);

  useEffect(() => {
    editor.children = slate;
    editor.onChange();
    return () => {
      dispatch(setFilterOperator(undefined));
    };
  }, [dispatch, editor, slate]);

  useEffect(() => {
    void dispatch(fetchBatches());
  }, [dispatch]);

  const label2 = label ?? t('batch:title.batchFilters');
  return (
    <>
      <Stack>
        <FormLabel disabled={disabled} sx={[label2.length > 0 && { marginBottom: '4px' }]}>
          {label2}
        </FormLabel>
        <Box
          sx={[
            editing &&
              (theme => ({
                p: 0.5,
                border: `solid 2px ${theme.palette.grey[300]}`,
                borderRadius: 1,
                bgcolor: 'background.default',
                '&:hover': { borderColor: `${theme.palette.grey[500]}`, cursor: 'text' },
              })),
          ]}
          onClick={disabled || !editing ? undefined : openModal}
        >
          {editing || (batch?.exp && batch.exp.length > 0) ? (
            <Slate editor={editor} initialValue={slate}>
              <Editable readOnly placeholder={t('input.placeholder')} renderElement={RenderElement} />
            </Slate>
          ) : (
            <Typography>{t('input.noBatchSelected')}</Typography>
          )}
        </Box>
      </Stack>
      <BatchFilterModal batch={batch} noOperator={noOperator} open={open} setBatch={setBatch} setOpen={setOpen} />
    </>
  );
};

export default BatchFilterInput;

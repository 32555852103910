import { RelativeToNowPeriod } from 'components/UI/RelativeToNowPeriodPicker/RelativeToNowPeriodPicker';
import { CustomRangeType } from 'types/date';

const relativeToNowPeriods: Record<RelativeToNowPeriod, string> = {
  [RelativeToNowPeriod.ONE_DAY]: '1 día',
  [RelativeToNowPeriod.THREE_DAYS]: '3 días',
  [RelativeToNowPeriod.ONE_WEEK]: '1 semana',
  [RelativeToNowPeriod.TWO_WEEKS]: '2 semanas',
  [RelativeToNowPeriod.ONE_MONTH]: '1 mes',
};

export default {
  title: {
    absoluteTimeRange: 'Absoluto',
    relativeTimeRanges: 'Relativo',
    refreshRate: 'Tasa de refresco',
    liveMode: 'En vivo',
    history: 'Histórico',
  },
  input: {
    from: {
      label: 'Inicio',
    },
    to: {
      label: 'Fin',
    },
  },
  button: {
    [`range_${CustomRangeType.YESTERDAY}`]: 'Ayer',
    [`range_${CustomRangeType.TODAY}`]: 'Hoy',
    [`range_${CustomRangeType.THIS_WEEK}`]: 'Esta semana',
    [`range_${CustomRangeType.THIS_MONTH}`]: 'Este mes',
    lastXUnitOfTime: 'Desde {{value}} $t(global:unit.time_{{unit}}, { "count": {{value}} })',
    refreshRate: '{{count}} $t(global:unit.time_{{unit}}, { "count": {{count}} })',
    refreshRate_off: 'Desactivado',
    submit: 'Validar',
    nextQuarterPeriod: 'Avanzar un cuarto de período',
    previousQuarterPeriod: 'Retroceder un cuarto de período',
    nextPeriod: 'Avanzar un período',
    previousPeriod: 'Retroceder un período',
    clear: 'Suprimir',
  },
  tabs: {
    calendar: 'Calendario',
    batches: 'Batchs',
    label: 'Pestañas',
  },
  text: {
    empty: 'Ningún elemento',
    noTimeRange: 'Ningún período en el historial',
    fixedPeriodDuration: 'Cada período personalizado tiene la misma duración que el primero, es decir {{duration}}.',
    duration: 'Duración: ',
  },
  tooltip: {
    previousPeriod: 'Período anterior',
    nextPeriod: 'Período siguiente',
  },
  periods: {
    year: 'Año',
    month: 'Mes',
  },
  relativeToNowPeriods,
  sinceRelative: 'Desde $t(dateTimePicker:relativeToNowPeriods.{{value}})',
  now: 'Ahora',
};

import { ErrorName } from '@dametis/core';

export const DEFAULT_ERROR = 'Une erreur est survenue. Veuillez réessayer plus tard.';

const alerts: Record<ErrorName, string> = {
  USER_NOT_FOUND: 'Utilisateur inexistant',
  USER_BAD_SIGNIN: 'Mot de passe invalide',
  // Common
  UNKNOWN: DEFAULT_ERROR,
  BAD_JSON: DEFAULT_ERROR,
  ROUTE_NOT_FOUND: DEFAULT_ERROR,
  VALIDATION: DEFAULT_ERROR,
  TOO_MANY_REQUESTS: 'Trop de requêtes',
  NOT_IMPLEMENTED: DEFAULT_ERROR,
  // Session
  SESSION_ID_MISSING: DEFAULT_ERROR,
  SESSION_UNAUTHORIZED: DEFAULT_ERROR,
  SESSION_EXPIRED: 'La session a expiré, veuillez vous connectez à nouveau',
  // PreSession
  PRESESSION_EXPIRED: 'La session a expiré, veuillez vous connectez à nouveau',
  PRESESSION_ID_MISSING: DEFAULT_ERROR,
  PRESESSION_UNAUTHORIZED: DEFAULT_ERROR,
  // Token
  TOKEN_MISSING: DEFAULT_ERROR,
  TOKEN_UNAUTHORIZED: DEFAULT_ERROR,
  TOKEN_INVALID: DEFAULT_ERROR,
  TOKEN_ALREADY_EXISTS: DEFAULT_ERROR,

  CANNOT_SELF_IMPERSONATE: DEFAULT_ERROR,

  // 2FA
  USER_HAS_NO_TWOFA: DEFAULT_ERROR,
  TWOFA_INVALID_TYPE: DEFAULT_ERROR,
  TWOFA_ALREADY_ACTIVATED: 'Cette méthode 2FA est déjà activée avec le même email/téléphone',
  WRONG_ENROLL_TWOFA_CODE: 'Code incorrect',
  EXPIRED_ENROLL_TWOFA_CODE: 'Le code a expiré, veuillez réessayer avec un nouveau',
  TWOFA_CHANGE_UNAUTHORIZED:
    "Modification 2FA impossible, l'utilisateur doit avoir au moins 2 méthodes 2FA actives pour pouvoir en modifier une",
  USER_HAS_TWOFA: "L'utilisateur doit se connecter via 2FA",
  TWOFA_INVALID_PHONENUMBER: 'Numéro de téléphone incorrect',
  USER_TWO_FA_NOT_ACTIVATED: "Cette méthode 2FA n'est pas activée, il n'est donc pas possible de la désactiver",
  // CORS
  UNAUTHORIZED_CORS_ORIGIN: DEFAULT_ERROR,
  // Browser
  UNSUPPORTED_BROWSER: "Le navigateur n'est pas pris en charge",
  // User
  USER_INVALID_PASSWORD: 'Veuillez choisir un mot de passe plus sûr. Essayez un mélange de lettres, chiffres et symboles.',
  USER_INVALID_EMAIL: "L'adresse email de l'utilisateur semble invalide",
  USER_ALREADY_EXISTS: 'Cet utilisateur existe déjà',
  USER_ACCOUNT_NOT_ACTIVE: "Le compte utilisateur n'est pas actif",
  USER_ACCOUNT_NOT_INVITING: "L'utilisateur n'est pas invité",
  USER_BAD_PASSWORD: 'Identifiants incorrects',
  USER_HAS_NO_PASSWORD: "L'utilisateur n'a pas de mot de passe",
  USER_HAS_NO_PASSWORD_POLICY: DEFAULT_ERROR,
  USER_HAS_NOT_FINALIZED_INVITATION: "L'utilisateur n'a pas finalisé son invitation",
  USER_ALREADY_HAS_GROUP: "L'utilisateur a déjà un groupe",
  USER_MISMATCH_GROUP_SITE: DEFAULT_ERROR,
  USER_CANNOT_DELETE_CURRENT_SESSION: DEFAULT_ERROR,
  INVALID_ID: DEFAULT_ERROR,
  USER_HAS_NO_GROUP: DEFAULT_ERROR,
  USER_MISMATCH_HEIR_GROUP: DEFAULT_ERROR,
  USER_STILL_HAS_ENTITIES: "L'utilisateur a encore des entités associées",
  // Permission
  USER_ACCESS_DENIED: "Vous n'avez pas accès à la ressource demandée",
  INVALID_ACL: DEFAULT_ERROR,
  // Group
  GROUP_NOT_FOUND: "Le groupe demandé n'a pas été trouvé",
  // Site
  SITE_NOT_FOUND: "Le site demandé n'a pas été trouvé",
  SITE_MISMTACH: DEFAULT_ERROR,
  SITE_HAS_NO_GROUP: DEFAULT_ERROR,
  // Site invitation
  SITE_INVITATION_NOT_FOUND: "L'invitation n'a pas été trouvée",
  SITE_NO_DEFAULT_ROLE: "Le site n'a pas de rôle par défaut",
  SITE_INVITATION_BAD_EXPIRES_AT: "L'invitation doit avoir une date d'expiration inférieur à un mois",
  SITE_INVITATION_EXPIRED: "L'invitation a expirée",
  // Variable list
  VARIABLE_LIST_NOT_FOUND: "La liste de variables demandée n'a pas été trouvée",
  // Box
  BOX_NOT_FOUND: "La box demandée n'a pas été trouvée",
  BOX_HAS_NO_SITE: DEFAULT_ERROR,
  BOX_FAILED_TO_SYNCHRONIZE: 'La synchronisation de la box a échoué',

  // Ansible Box
  ANSIBLEBOX_NOT_FOUND: "L'ansible box demandée n'a pas été trouvée",
  ANSIBLEBOX_LOCKED: "L'ansible box demandée est déjà en cours de configuration ou la précédente tentative de configuration a échoué",
  ANSIBLEBOX_NOT_READY: "L'ansible box n'est pas prête à être setup, réessayez dans quelque temps.",

  // Awx
  UNKNOWN_AWX_INVENTORY: DEFAULT_ERROR,
  AWX_HOST_INSERTION_FAILURE: DEFAULT_ERROR,
  AWX_HOST_DELETION_FAILURE: DEFAULT_ERROR,
  UNKNOWN_AWX_JOB: DEFAULT_ERROR,
  AWX_JOB_NOT_CREATED: DEFAULT_ERROR,

  // Device
  DEVICE_NOT_FOUND: "L'équipement demandé n'a pas été trouvé",
  DEVICE_INVALID_PROTOCOL: DEFAULT_ERROR,
  DEVICE_HAS_VARIABLES: "L'équipement a des variables associées",
  DEVICE_AND_VARIABLE_INCOMPATIBLE: DEFAULT_ERROR,
  CANNOT_CHANGE_QUERYSTRATEGY: "Il n'est pas possible de changer le mode d'un équipement déjà créé",
  // Brand device
  BRAND_DEVICE_NOT_FOUND: "L'équipement de marque demandé n'a pas été trouvé",
  BRAND_DEVICE_HAS_DEVICES: "L'équipement de marque a encore des équipements",
  // Variable
  VARIABLE_NOT_FOUND: "La variable demandée n'a pas été trouvée",
  VARIABLE_UNKNOWN_KIND: 'Type de variable inconnu',
  VARIABLE_MEASUREMENT_NOT_FOUND: DEFAULT_ERROR,
  VARIABLE_BAD_REQUEST: DEFAULT_ERROR,
  VARIABLE_HAS_DEPENDANTS: 'La variable demandée a des dépendants et ne peut pas être supprimée',
  VARIABLE_HAS_CIRCUIT: 'La variable calculée forme un circuit',
  VARIABLE_MCO_OF_SITE: DEFAULT_ERROR,
  VARIABLE_MCO_INVALID_RANGE: DEFAULT_ERROR,
  VARIABLE_HAS_NO_REAL_NOR_GROUP_BY:
    'Échec de la création, les variables calculées doivent avoir au moins une variable acquise ou une sous-période comme dépendance',
  VARIABLE_IMPORT_INVALID_COLUMNS: 'Les colonnes de la variable importée sont invalides',
  VARIABLE_MISSING_VERSION: "Variable manque d'une version",
  VARIABLE_IS_NOT_CALCULATED_KIND: "La variable n'est pas une variable calculée",
  VARIABLE_INTERMEDIATE_NOT_EDITABLE: "Il n'est pas possible de supprimer une variable intermédiaire.",
  // Variable set
  VARIABLE_SET_INVALID_ARGS: DEFAULT_ERROR,
  PLAYGROUND_SET_INVALID_ARGS: DEFAULT_ERROR,
  REPORT_SET_INVALID_ARGS: DEFAULT_ERROR,
  SYNOPTIC_SET_INVALID_ARGS: DEFAULT_ERROR,
  // Data
  DATA_NOT_FOUND: DEFAULT_ERROR,
  MISSING_MEASUREMENT: DEFAULT_ERROR,
  PARSING_VARCALC: DEFAULT_ERROR,
  UNKNOWN_NODE_TYPE: DEFAULT_ERROR,
  UNKNOWN_FUNCTION: 'Fonction inconnue',
  UNKNOWN_SYMBOL: 'Symbole inconnu',
  GROUPBY_FORBIDDEN: 'Sous-période interdite',
  BAD_GROUPBY_CALCULATION: 'Sous-période interdite',
  MISSING_MOVING_AVERAGE_ARGS: DEFAULT_ERROR,
  MISSING_INDEX_ARGS: DEFAULT_ERROR,
  FILL_SETTING_UNKNOWN: 'Le paramètre de remplissage est inconnu',
  FORBIDDEN_FILL_SETTING: 'Le paramètre de remplissage demandé est interdit',
  FORBIDDEN_OPERATOR: "L'opérateur demandé est interdit",
  MISSING_TADA_ARGUMENT: DEFAULT_ERROR,
  UNDEFINED_TIME: DEFAULT_ERROR,
  TOO_MANY_VARS: 'Trop de variables sont demandées',
  INVALID_GROUPBY_PERIODS: DEFAULT_ERROR,
  INVALID_FLAG: "Le drapeau n'est pas valide",
  INVALID_OPERATOR_ARGS: DEFAULT_ERROR,
  INVALID_VARIABLE: DEFAULT_ERROR,
  UNIT_INVALID: DEFAULT_ERROR,
  UNIT_INVALID_CONVERSION: DEFAULT_ERROR,
  // Alarm
  ALARM_NOT_FOUND: "L'alarme demandée n'a pas été trouvée",
  ALARM_MCO_NOT_WRITABLE: "Cette alarme n'est pas éditable",
  CONDITION_NOT_FOUND: DEFAULT_ERROR,
  INCIDENT_NOT_FOUND: "L'incident demandé n'a pas été trouvé",
  INCIDENT_ALREADY_ACKED: "L'incident a déjà été pris en compte",
  INCIDENT_INVALID: DEFAULT_ERROR,
  INVALID_EXTENDED_CONDITION: DEFAULT_ERROR,
  EVENT_NOT_FOUND: "L'événement demandé n'a pas été trouvé",
  EVENT_INVALID: DEFAULT_ERROR,
  // Synoptic
  SYNOPTIC_NOT_FOUND: "Le synoptique demandé n'a pas été trouvé",
  // Synoptic widget
  SYNOPTIC_WIDGET_NOT_FOUND: "Le widget synoptique demandé n'a pas été trouvé",
  // Playground
  PLAYGROUND_NOT_FOUND: 'Playground introuvable',
  PLAYGROUND_NOT_WRITABLE: "Ce playground n'est pas modifiable",
  // Report
  REPORT_NOT_FOUND: 'Rapport introuvable',
  REPORT_NOT_WRITABLE: 'Rapport non modifiable',
  REPORT_VARIABLE_NOT_FOUND: 'Variable dans le rapport introuvable',
  REPORT_NOT_IN_GROUP_SCOPE: "Le rapport n'appartient pas à ce groupe",
  REPORT_NOT_IN_SITE_SCOPE: "Le rapport n'appartient pas à ce site",
  // Data
  INVALID_MATH_EXPRESSION: 'Expression mathématique non valide',
  MATH_EVALUATION: "Erreur lors de l'évaluation mathématique",
  INCOMPLETE_DATA: 'Données fournies incomplètes',
  MATH_MISSING_DATA: 'Données manquantes sur la variable expliquée ou les variables explicatives',
  INVALID_GROUP_BY: DEFAULT_ERROR,
  NO_VARS_PROVIDED: DEFAULT_ERROR,
  DIFFERENT_DATA_SIZE: DEFAULT_ERROR,
  INVALID_PERIOD: DEFAULT_ERROR,
  PERIOD_REQUIRED: DEFAULT_ERROR,
  AN_ERROR_OCCURRED: DEFAULT_ERROR,
  UNKNOWN_OPERATOR: 'Opérateur inconnu',
  // Math Data
  UNKNOWN_MATH_DATA_OPERATOR: DEFAULT_ERROR,
  REQUIRED_GROUPBY: DEFAULT_ERROR,
  GROUPBY_MUST_BE_EQUALS: DEFAULT_ERROR,
  MISSING_RETRO_CALCULATED_SINCE: DEFAULT_ERROR,
  MISSING_GLOBAL_GROUPBY_CALC: DEFAULT_ERROR,
  // Clickouse
  INVALID_QUERY: DEFAULT_ERROR,
  CREDENTIALS_NOT_FOUND: DEFAULT_ERROR,
  NO_POINTS_RETURNED: DEFAULT_ERROR,
  MAX_BUCKET_COUNT_EXCEEDED: "Trop de données à traiter, veuillez réduire l'intervalle de temps et/ou augmenter la sous-période",
  MAX_PERIODS_COUNT_EXCEEDED: 'Nombre maximal de périodes temporelles dépassé',
  MOVING_AVERAGE_EXPECTS_ARGS: DEFAULT_ERROR,
  FORBIDDEN_HISTORY_WITH_GROUP_BY: DEFAULT_ERROR,
  CLICKHOUSE_UNAVAILABLE: DEFAULT_ERROR,
  CLICKHOUSE_NETWORK_ERROR: DEFAULT_ERROR,
  // Rabbit
  RABBIT_UNAVAILABLE: DEFAULT_ERROR,
  // Minio
  MINIO_UNAVAILABLE: DEFAULT_ERROR,
  BAD_PREVIEW_TYPE: 'Type de preview non valide',
  CORRUPTED_PREVIEW: 'Image corrompue',
  NO_PREVIEW_DEFINED: 'Pas de preview définie',
  HEAVY_PREVIEW: 'Image trop lourde',
  // Colossus
  COLOSSUS_UNAVAILABLE: DEFAULT_ERROR,
  // Email
  EMAIL_FAILURE: DEFAULT_ERROR,
  EMAIL_MESSAGE_NOT_FOUND: DEFAULT_ERROR,
  // Streaming
  STREAMING_INVALID_BODY: DEFAULT_ERROR,
  STREAMING_HANDLER_ALREAD_USED: DEFAULT_ERROR,
  STREAMING_AUTHENTICATION_FAILURE: DEFAULT_ERROR,
  STREAMING_REQUEST_LIMIT_REACHED: DEFAULT_ERROR,
  STREAMING_HANDLER_NOT_FOUND: DEFAULT_ERROR,
  // Project
  PROJECT_NOT_FOUND: 'Projet introuvable',
  PROJECT_INVALID: 'Projet invalide',
  PROJECT_TEMPLATE_NOT_FOUND: 'Template de Project introuvable',
  PROJECT_TEMPLATE_HAS_PROJECTS: 'Template de Project a des Project',
  ASSET_NOT_FOUND: 'Pièce jointe introuvable',
  TASK_NOT_FOUND: 'Tâche introuvable',
  IMAGE_NOT_FOUND: 'Image introuvable',
  // Calendar
  CALENDAR_NOT_FOUND: 'Calendrier introuvable',
  // Role
  ROLE_NOT_FOUND: DEFAULT_ERROR,
  ROLE_FORBIDDEN: DEFAULT_ERROR,
  // Batch
  BATCH_NOT_FOUND: 'Batch introuvable',
  BATCH_HEIGHT_TOO_HIGH: 'Batch trop grand',
  // Comment
  COMMENT_NOT_FOUND: 'Commentaire introuvable',
  COMMENT_INVALID: 'Comment invalide',
  // Users List
  USER_LIST_NOT_FOUND: "Liste d'utilisateurs introuvable",
  // API
  API_UNAVAILABLE: DEFAULT_ERROR,
  // BULL
  BULL_UNAVAILABLE: DEFAULT_ERROR,
  // MONGO
  MONGO_UNAVAILABLE: DEFAULT_ERROR,
  // Model
  MODEL_NOT_FOUND: "Le modèle demandé n'a pas été trouvé",
  // Activity
  ACTIVITY_NOT_FOUND: "L'activité demandée n'a pas été trouvée",
  ACTIVITY_NOT_RESTORABLE: "L'activité demandée ne peut pas être restaurée",
  // Tag
  TAG_NOT_FOUND: "Le tag demandé n'a pas été trouvé",
  TAG_ALREADY_EXISTS: 'Un tag avec ce nom existe déjà',
  TAG_EDITOR_FAILURE: DEFAULT_ERROR,
  // Macro
  MACRO_NOT_FOUND: "La macro demandée n'a pas été trouvée",
  MACRO_JOB_NOT_FOUND: "Le travail de macro demandé n'a pas été trouvé",
  MACRO_JOB_NOT_CREATED: "Le travail de macro n'a pas pu être créé",
  // Macro File
  MACRO_FILE_NOT_FOUND: "Le fichier macro demandé n'a pas été trouvé",
  // Macro Library
  MACROLIB_NOT_FOUND: "La bibliothèque macro demandée n'a pas été trouvée",
  // Streaming
  SOCKET_IO: DEFAULT_ERROR,
  // Facturation
  CONTRACT_NOT_FOUND: "Le contrat demandé n'a pas été trouvé",
  CONTRACT_HAS_BILLS: 'Le contrat demandé a des factures associées et ne peut pas être supprimé',
  BILL_NOT_FOUND: "La facture demandée n'a pas été trouvée",
  BILL_INVALID_DATES: 'Les dates de la facture ne sont pas comprises entre les dates du contrat',
  // AlarmConnector
  ALARM_CONNECTOR_NOT_FOUND: "Le connecteur d'alarme demandé n'a pas été trouvé",
  ALARM_CONNECTOR_HAS_DEPENDANTS: "Le connecteur d'alarme demandé a des dépendances et ne peut pas être supprimé",
  // Block
  BLOCK_NOT_FOUND: "Le bloc demandé n'a pas été trouvé",
  BLOCK_METRIC_TEMPLATING_FAILURE: DEFAULT_ERROR,
  METRIC_NOT_FOUND: DEFAULT_ERROR,
  METRIC_NOT_VIRTUAL: DEFAULT_ERROR,
  // Block Type
  BLOCK_TYPE_NOT_FOUND: "Le type de bloc demandé n'a pas été trouvé",
  BLOCK_TYPE_DELETE_USED_PARAMETER: 'Le paramètre de type de bloc demandé est utilisé et ne peut pas être supprimé',
  BLOCK_TYPE_DELETE_USED_METRIC: 'La métrique de type de bloc demandée est utilisée et ne peut pas être supprimée',
  BLOCK_TYPE_PARAMETERS_METRICS_NON_UNIQUE: 'Les paramètres et les métriques de type de bloc doivent être uniques',
  // Stantard block
  STANDARD_BLOCK_NOT_FOUND: "Le bloc standard demandé n'a pas été trouvé",
  TOO_MANY_POINTS: DEFAULT_ERROR,
  UNKNOWN_VAR_CALC: DEFAULT_ERROR,
  UNSUPPORTED_CALCULATION: DEFAULT_ERROR,
  MISSING_VERSION: DEFAULT_ERROR,
  MODEL_KEY_NOT_FOUND: DEFAULT_ERROR,
  ALARM_SET_INVALID_ARGS: DEFAULT_ERROR,
  // Consul
  CONSUL_UPDATE_CONFIG: DEFAULT_ERROR,
  // Folder
  FOLDER_NOT_FOUND: "Le dossier n'a pas été trouvé",
  FOLDER_ROOT_ALREADY_EXISTS: 'Dossier racine déjà existant',
  FOLDER_INVALID_OPERATION: 'Dossier opération invalide',
  // Authentication
  AUTHENTICATION_ALREADY_EXISTS: "Ce nom d'authentification est déjà utilisé",
  AUTHENTICATION_NOT_FOUND: "L'authentification demandée n'a pas été trouvée",
  AUTHENTICATION_USED_AS_FALLBACK: 'Vous ne pouvez pas supprimer une authentification utilisée en tant que fallback',
  AUTHENTICATION_USES_ITSELF_AS_FALLBACK: "Une authentification ne peut pas s'utiliser elle même en tant que fallback",
  CANNOT_DELETE_DEFAULT_PASSWORD: "Vous ne pouvez pas supprimer l'authentification par défaut",
  // Twilio
  LOCALE_NOT_FOUND: DEFAULT_ERROR,
  TWILIO_MESSAGE_NOT_FOUND: DEFAULT_ERROR,
  TWILIO_MESSAGE_NOT_SEND: DEFAULT_ERROR,
  // StyleConfiguration
  STYLE_CONFIGURATION_NOT_FOUND: "La Configuration de Style demandé n'a pas été trouvé.",
  // Alias
  ALIAS_NOT_FOUND: "L'Alias demandé n'a pas été trouvé.",
  // DataOperation
  DATAOPERATION_NOT_FOUND: "L'opération sur les données n'a pas été trouvée.",
  CANNOT_RETRY_DATA_OPERATION: "Impossible de réessayer l'opération",
  CANNOT_REIMPORT_DATA_OPERATION: "Impossible de réessayer l'opération d'Import",
  CANNOT_REVERT_DATA_OPERATION: "Impossible de restaurer l'opération",
  // EditVariablesData
  EDIT_VARIABLES_DATA_BAD_REQUEST: DEFAULT_ERROR,
  // FileImport
  FILEIMPORT_NOT_FOUND: "La configuration d'import n'a pas été trouvée",
  FILE_ALREADY_EXISTS: 'Le fichier existe déjà',
  FILE_MAX_SIZE_EXCEEDED: 'Le fichier dépasse la taille maximale autorisée',
  FILE_NOT_CREATED: "Le fichier n'a pas été créé",
  FILE_DOES_NOT_EXISTS: "Le fichier n'existe pas",
  FILE_PARSE_ERROR: 'Impossible de lire le fichier',
  // Webauthn
  WEBAUTHN_ERROR: DEFAULT_ERROR,
  WEBAUTHN_EXPIRED_CHALLENGE: 'Le challenge est expiré. Veuillez réessayer',
  WEBAUTHN_WRONG_PASSKEY: "La clé fournie n'est pas connue du serveur",
  WEBAUTHN_INVALID_SIGNATURE: "La signature de la clé n'a pas pu être vérifiée par le serveur",
  WEBAUTHN_PASSKEY_REGISTRATION: "La clé fournie n'a pas pu être enregistrée par le serveur",

  BLOCK_TYPE_HAS_STANDARD_BLOCKS: 'Le Block Type a des Blocks Standards',
  STANDARD_BLOCK_HAS_BLOCKS: 'Le Block Standard a des Blocks',

  // Opco
  OPCO_NOT_FOUND: "Le mix vapeur n'a pas été trouvé",
  OPCO_UNAVAILABLE_ON_SITE: "Mix vapeur n'est pas disponible sur votre site",
  OPCO_INPUT_FILE_ALREADY_UPLOADED: "Le fichier d'entrée a déjà été téléchargé",
  OPCO_INPUT_FILE_WRONG_MIMETYPE: "L'extension du fichier d'entrée est invalide",
  OPCO_DELETE_RUNNING_OPCO: 'Impossible de supprimer un mix vapeur en cours',
  OPCO_FILE_NOT_FOUND: 'Le fichier est introuvable',
  OPCO_DOWNLOAD_TEMPLATE_ERROR: 'Une erreur est survenue lors du téléchargement du template',
  CREATE_REPORT_OPCO_STATUS_NOT_SUCCESS: 'La création du rapport a échoué',
  OPCO_REPORT_TEMPLATE_NOT_FOUND: "La template du rapport n'a pas été trouvé",
  OPCO_REPORT_VARIABLE_NOT_FOUND: "Une variable n'a pas été trouvé",

  TADA_TEMPLATING_VARIABLE_NOT_FOUND: DEFAULT_ERROR,
};

export default {
  pageTitle: {
    403: 'Accès interdit - Dametis',
    404: 'Page introuvable - Dametis',
  },
  title: {
    crash: DEFAULT_ERROR,
    notFound: 'Page introuvable',
    accessDenied: 'Accès interdit',
    requestAccessTo: "Demander l'accès à",
  },
  text: {
    crash1: "Veuillez vérifier que votre navigateur est à jour. Si l'erreur subsiste, vous pouvez",
    crash2: 'contacter notre support',
    notFound: "La page que vous cherchez n'existe pas",
    accessDenied: "Vous n'avez pas accès à",
    askAccess: "Vous pouvez demander l'accès par mail à",
    notFoundOrAccessDenied: "La page que vous cherchez n'existe pas, ou vous n'y avez pas accès",
    requestAccessTo: "Vous allez demander l'accès par mail à",
  },
  button: {
    back: "Retour à l'accueil",
    learnMore: 'En savoir plus',
    requestAccess: "Demander l'accès",
    cancel: 'Annuler',
    submit: 'Envoyer',
  },
  label: {
    comment: 'Commentaire',
  },
  toast: {
    accessRequested: 'Accès demandé',
  },
  alerts,
};

import { AddOutlined } from '@mui/icons-material';
import { Stack, Typography, TypographyProps } from '@mui/material';
import { DataGridPremium, GridRowModel, gridClasses } from '@mui/x-data-grid-premium';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';

import { EndpointRow, getColumnsDefObj } from '../EndpointsGridColDef';

const TypographyPropsDefaultProp: TypographyProps = {};

export interface EndpointsPickerProps {
  value: string[];
  onChange: (newValue: string[]) => void;
  isEditing?: boolean;
  label?: string;
  labelProps?: TypographyProps;
  prefix?: string;
}

const EndpointsPicker: FC<EndpointsPickerProps> = ({
  value,
  onChange,
  isEditing = true,
  label = undefined,
  labelProps = TypographyPropsDefaultProp,
  prefix = undefined,
}) => {
  const { t } = useTranslation('devices');

  const rows = useMemo(() => value.map((endpoint, index) => ({ id: index, endpoint })), [value]);

  const handleAddEndpoint = useCallback(() => {
    const newValue = [...value, prefix ?? ''];
    onChange(newValue);
  }, [value, onChange, prefix]);

  const handleChangeEndpoint = useCallback(
    (newRow: GridRowModel<EndpointRow>): GridRowModel<EndpointRow> => {
      const newValue = value.map((endpoint, index) => (index === newRow.id ? newRow.endpoint : endpoint));
      onChange(newValue);
      return newRow;
    },
    [value, onChange],
  );

  const handleDeleteEndpoint = useCallback(
    (endpointIndex: number) => () => {
      const newValue = value.filter((_endpoint, index) => index !== endpointIndex);
      onChange(newValue);
    },
    [value, onChange],
  );

  const columns = useMemo(() => Object.values(getColumnsDefObj(isEditing, handleDeleteEndpoint)), [isEditing, handleDeleteEndpoint]);

  return (
    <>
      <Stack gap={1}>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography variant="h6" {...labelProps}>
            {`${label ?? t('label.endpoints')}${isEditing ? ' *' : ''}`}
          </Typography>
          {isEditing && (
            <ActionButton startIcon={<AddOutlined />} onClick={handleAddEndpoint}>
              {t('button.add')}
            </ActionButton>
          )}
        </Stack>
        <Stack height={250} overflow="hidden">
          <DataGridPremium
            columns={columns}
            density="compact"
            getRowHeight={() => 'auto'}
            processRowUpdate={handleChangeEndpoint}
            rows={rows}
            rowSelection={false}
            slots={{ columnHeaders: () => null }}
            sx={{
              [`& .${gridClasses.cell}:focus`]: { outline: 'none' },
              '& > div': { background: 'transparent' },
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default EndpointsPicker;

import { Paper, PaperProps } from '@mui/material';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

export interface LegoPreviewPaperProps extends PaperProps {
  redirect: boolean;
  url: string;
}

const LegoPreviewPaper: FC<LegoPreviewPaperProps> = ({ redirect, url, sx, ...props }) => (
  <>
    {!redirect && <Paper {...props} />}
    {redirect && (
      <Paper component={NavLink} sx={{ '&:hover': { backgroundColor: theme => theme.palette.link.light }, ...sx }} to={url} {...props} />
    )}
  </>
);

export default LegoPreviewPaper;

import { AddOutlined } from '@mui/icons-material';
import { Link, List, ListItem, ListItemButton, ListItemText, Skeleton, Stack, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { CreateFileImportBody, FileDeviceConfig, FileImportInfo, ShortFileImportInfo, UUID } from '@dametis/core';

import CreateFileImportModal from 'components/Data/FileImport/Home/CreateFileImportModal/CreateFileImportModal';
import { createCSVConfig } from 'components/Data/FileImport/helpers/createCSVConfig';
import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import { sdk } from 'sdk';
import { useDispatch, useSelector } from 'store';
import { displaySdkErrorToast } from 'store/actions/toasts';

import FormPaper, { FormLocation } from '../../FormPaper';

export interface FileImportConfigFormProps {
  file: FileDeviceConfig;
  setFile: Dispatch<SetStateAction<FileDeviceConfig>>;
  isEditing?: boolean;
  location?: `${FormLocation}`;
}

const FileImportConfigForm: FC<FileImportConfigFormProps> = ({ file, setFile, isEditing = true, location = 'modal' }) => {
  const { t } = useTranslation('devices');
  const dispatch = useDispatch();

  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  const [isCreateFileImportModalOpen, setIsCreateFileImportModalOpen] = useState<boolean>(false);
  const [isCreatingFileImport, setIsCreatingFileImport] = useState<boolean>(false);
  const [createdFileImport, setCreatedFileImport] = useState<FileImportInfo | null>(null);
  const [availableFileImports, setAvailableFileImports] = useState<ShortFileImportInfo[]>([]);
  const [isFetchingAvailableFileImports, setIsFetchingAvailableFileImports] = useState<boolean>(false);
  const [fileImportsData, setFileImportsData] = useState<Record<UUID, FileImportInfo>>({});

  const fetchAvailableFileImports = useCallback(
    async (controller: AbortController) => {
      if (!siteId) {
        return;
      }
      setIsFetchingAvailableFileImports(true);
      try {
        const { data } = await sdk.fileImport.List(siteId, { signal: controller.signal });
        setAvailableFileImports(data.filter(availableFileImport => !availableFileImport.hidden));
      } catch (error) {
        console.error(error);
        dispatch(displaySdkErrorToast(error));
      } finally {
        setIsFetchingAvailableFileImports(false);
      }
    },
    [siteId, dispatch],
  );

  const handleChangeFileImport = useCallback(
    (fileImportId: string) => async () => {
      try {
        let fileImport: FileImportInfo | undefined;
        if (fileImportsData[fileImportId]) {
          fileImport = fileImportsData[fileImportId];
        } else {
          const { data } = await sdk.fileImport.Read(fileImportId);
          fileImport = data;
          setFileImportsData(state => ({ ...state, [fileImportId]: data }));
        }
        if (fileImport) {
          setFile(state => ({
            ...state,
            fileImport: {
              uuid: fileImport.uuid,
              name: fileImport.name,
              config: fileImport.config,
              mapping: fileImport.mapping,
              jq: fileImport.jq,
            },
          }));
        }
      } catch (error) {
        console.error(error);
        dispatch(displaySdkErrorToast(error));
      }
    },
    [dispatch, setFile, fileImportsData],
  );

  const createFileImport = useCallback(async (): Promise<FileImportInfo | null> => {
    if (!siteId) {
      return null;
    }
    setIsCreatingFileImport(true);
    try {
      const body: CreateFileImportBody = {
        name: '',
        description: '',
        config: createCSVConfig(),
        mapping: null,
        jq: null,
        hidden: true,
      };
      const { data } = await sdk.fileImport.Create(siteId, body);
      return data;
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
      return null;
    } finally {
      setIsCreatingFileImport(false);
    }
  }, [siteId, dispatch]);

  const handleCreateFileImport = useCallback(async () => {
    const fileImport = await createFileImport();
    if (fileImport) {
      setIsCreateFileImportModalOpen(true);
      setCreatedFileImport(fileImport);
    }
  }, [createFileImport]);

  const handleSaveFileImport = useCallback(
    (newFileImport: FileImportInfo) => {
      setAvailableFileImports(state => [...state, newFileImport]);
      setFileImportsData(state => ({ ...state, [newFileImport.uuid]: newFileImport }));
      setFile(state => ({
        ...state,
        fileImport: {
          uuid: newFileImport.uuid,
          name: newFileImport.name,
          config: newFileImport.config,
          mapping: newFileImport.mapping,
          jq: newFileImport.jq,
        },
      }));
    },
    [setFile],
  );

  useEffect(() => {
    const controller = new AbortController();
    void fetchAvailableFileImports(controller);

    return () => {
      controller.abort();
    };
  }, [fetchAvailableFileImports]);

  return (
    <>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Stack alignItems="flex-start" direction="row" justifyContent="space-between" mb={1.5}>
          <Typography variant="h6">{`${t('label.fileImport')}${isEditing ? ' *' : ''}`}</Typography>
          {isEditing && (
            <ActionButton disabled={isCreatingFileImport} startIcon={<AddOutlined />} onClick={handleCreateFileImport}>
              {t('button.add')}
            </ActionButton>
          )}
        </Stack>
        {isEditing && (
          <>
            {!isFetchingAvailableFileImports && availableFileImports.length > 0 && (
              <List sx={{ maxHeight: 400, overflow: 'auto' }}>
                {availableFileImports.map(availableFileImport => (
                  <ListItem key={availableFileImport.uuid} disablePadding>
                    <ListItemButton
                      disabled={!isEditing}
                      selected={availableFileImport.uuid === file.fileImport?.uuid}
                      onClick={handleChangeFileImport(availableFileImport.uuid)}
                    >
                      <ListItemText primary={availableFileImport.name} secondary={availableFileImport.description} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
            {availableFileImports.length === 0 && <Typography textAlign="center">{t('text.noFileImportsAvailable')}</Typography>}
            {isFetchingAvailableFileImports && (
              <Stack gap={1} overflow="auto" pb={2} pr={1}>
                <Skeleton height={45} sx={{ flexShrink: 0 }} variant="rounded" />
              </Stack>
            )}
          </>
        )}
        {!isEditing && (
          <>
            {file.fileImport && (
              <Link component={NavLink} to={`/data/import/${file.fileImport.uuid}`}>
                {file.fileImport.name}
              </Link>
            )}
            {!file.fileImport && <Typography>{t('text.noFileImport')}</Typography>}
          </>
        )}
      </FormPaper>
      <CreateFileImportModal
        forceSaveFileImport
        fileImport={createdFileImport}
        isOpen={isCreateFileImportModalOpen}
        setIsOpen={setIsCreateFileImportModalOpen}
        onSaveFileImport={handleSaveFileImport}
      />
    </>
  );
};

export default FileImportConfigForm;

import { CheckCircleOutlineOutlined, DeleteOutlined, EditOutlined, HideSourceOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Tooltip } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BacnetDeviceConfig,
  BoxInfo,
  BrandDeviceInfo,
  CreateCommonDeviceBody,
  FileDeviceConfig,
  HttpDeviceConfig,
  IsBacnetDevice,
  IsFileDevice,
  IsHttpDevice,
  IsMQTTDevice,
  IsModbusTcpDevice,
  IsOpcUaDevice,
  IsS7Device,
  IsSQLBigQDeviceConfig,
  IsSQLDefaultDeviceConfig,
  IsSQLDevice,
  IsUserIdentityInfoUserName,
  MQTTDeviceConfig,
  ModbusTcpDeviceConfig,
  OpcUaDeviceConfig,
  ProtocolKind,
  S7DeviceConfig,
  SQLDeviceConfig,
  ShortDeviceInfo,
  SyncPoint,
  UpdateDeviceBody,
} from '@dametis/core';

import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import PromptBeforeDisabling from 'components/UI/PromptBeforeDisabling/PromptBeforeDisabling';
import PromptBeforeLeaving from 'components/UI/PromptBeforeLeaving/PromptBeforeLeaving';
import { RightPanelActions, RightPanelBody, RightPanelFooter, RightPanelSection, RightPanelSectionContent } from 'components/UI/RightPanel';
import makePermissionLabel from 'functions/makePermissionLabel';
import { usePermission } from 'hooks/usePermission';
import { useDispatch, useSelector } from 'store';
import { getCurrentBox } from 'store/actions/currentBox';
import { displaySdkErrorToast } from 'store/actions/toasts';
import { useBoxes, useSynchronizeBoxMutation } from 'store/api/boxes';
import { useReadBrandDevicesQuery } from 'store/api/brandDevices';
import { useDeleteDeviceMutation, useUpdateDeviceMutation } from 'store/api/devices';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import BrandDevicePreview from '../CreateDeviceModal/BrandDevicePreview';
import DeviceCommonForm from '../CreateDeviceModal/DeviceCommonForm';
import DeviceSettingsForm from '../CreateDeviceModal/DeviceSettingsForm';
import { createBacnetDeviceConfig } from '../helpers/createBacnetDeviceConfig';
import { createCreateCommonDeviceBody } from '../helpers/createCreateCommonDeviceBody';
import { createFileDeviceConfig } from '../helpers/createFileDeviceConfig';
import { createHttpDeviceConfig } from '../helpers/createHttpDeviceConfig';
import { createMQTTDeviceConfig } from '../helpers/createMQTTDeviceConfig';
import { createModbusTcpDeviceConfig } from '../helpers/createModbusTcpDeviceConfig';
import { createOpcUaDeviceConfig } from '../helpers/createOpcUaDeviceConfig';
import { createS7DeviceConfig } from '../helpers/createS7DeviceConfig';
import { createSQLBigQDeviceConfig, createSQLDefaultDeviceConfig } from '../helpers/createSQLDeviceConfig';

import PromptBeforeDeletingDevice from './PromptBeforeDeletingDevice';

const brandDevicesEmptyArray: BrandDeviceInfo[] = [];
const boxesEmptyArray: BoxInfo[] = [];

export interface InformationsPanelProps {
  device: ShortDeviceInfo;
}

const InformationsPanel: FC<InformationsPanelProps> = ({ device }) => {
  const { t } = useTranslation('devices');
  const dispatch = useDispatch();

  const canEditConfiguration = usePermission('canEditConfiguration');

  const { data: brandDevices = brandDevicesEmptyArray } = useReadBrandDevicesQuery();
  const { data: boxes = boxesEmptyArray } = useBoxes();

  const [updateDevice, { isLoading: isUpdatingDevice }] = useUpdateDeviceMutation();
  const [deleteDevice, { isLoading: isDeletingDevice }] = useDeleteDeviceMutation();
  const [synchronizeBox] = useSynchronizeBoxMutation();

  const boxId = useSelector(state => state.currentBox?.infos?.uuid);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isPromptBeforeDisableOpen, setIsPromptBeforeDisableOpen] = useState<boolean>(false);
  const [isPromptBeforeDeleteOpen, setIsPromptBeforeDeleteOpen] = useState<boolean>(false);

  const brandDevice = useMemo(
    () => brandDevices.find(storeBrandDevice => storeBrandDevice.uuid === device.brandDeviceId),
    [brandDevices, device],
  );

  /**
   * Common
   */
  const [commonDeviceBody, setCommonDeviceBody] = useState<CreateCommonDeviceBody>(createCreateCommonDeviceBody);

  /**
   * Protocols
   */
  const [modbusTcp, setModbusTcp] = useState<ModbusTcpDeviceConfig>(createModbusTcpDeviceConfig);
  const [opcUa, setOpcUa] = useState<OpcUaDeviceConfig>(createOpcUaDeviceConfig);
  const [sql, setSQL] = useState<SQLDeviceConfig>(createSQLDefaultDeviceConfig);
  const [file, setFile] = useState<FileDeviceConfig>(createFileDeviceConfig);
  const [s7, setS7] = useState<S7DeviceConfig>(createS7DeviceConfig);
  const [http, setHttp] = useState<HttpDeviceConfig>(createHttpDeviceConfig);
  const [bacnet, setBacnet] = useState<BacnetDeviceConfig>(createBacnetDeviceConfig);
  const [mqtt, setMQTT] = useState<MQTTDeviceConfig>(createMQTTDeviceConfig);

  const areActionsDisabled = useMemo(
    () => isEditing || isUpdatingDevice || isDeletingDevice,
    [isEditing, isUpdatingDevice, isDeletingDevice],
  );

  const handleEditDevice = useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const handleChangeSyncPoint = useCallback((newSyncPoint: SyncPoint) => {
    setCommonDeviceBody(state => ({ ...state, syncPoint: newSyncPoint }));
  }, []);

  const handleChangeFilterPastPoints = useCallback((newFilterPastPoints: boolean) => {
    setCommonDeviceBody(state => ({ ...state, filterPastPoints: newFilterPastPoints }));
  }, []);

  const handleOpenPromptBeforeDisable = useCallback(() => {
    setIsPromptBeforeDisableOpen(true);
  }, []);

  const handleSyncronizeBox = useCallback(async () => {
    if (!boxId) {
      return;
    }
    dispatch(addToast({ severity: ToastSeverity.INFO, message: t('toast:waitingSyncConfigBox') }));
    const { error } = await synchronizeBox({ uuid: boxId });
    await dispatch(getCurrentBox(boxId, boxes));
    if (!error) {
      dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: t('toast:successSyncConfigBox') }));
    }
  }, [dispatch, synchronizeBox, boxId, boxes, t]);

  const handleDisableDevice = useCallback(async () => {
    try {
      await updateDevice({ uuid: device.uuid, body: { enabled: false } });
      dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: t('toast:successDisableDevice') }));
      void handleSyncronizeBox();
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
    setIsPromptBeforeDisableOpen(false);
  }, [dispatch, device.uuid, t, updateDevice, handleSyncronizeBox]);

  const handleEnableDevice = useCallback(async () => {
    try {
      await updateDevice({ uuid: device.uuid, body: { enabled: true } });
      dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: t('toast:successEnableDevice') }));
      void handleSyncronizeBox();
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  }, [dispatch, device.uuid, t, updateDevice, handleSyncronizeBox]);

  const handleOpenPromptBeforeDelete = useCallback(() => {
    setIsPromptBeforeDeleteOpen(true);
  }, []);

  const handleDeleteDevice = useCallback(async () => {
    try {
      await deleteDevice(device.uuid);
      dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: t('toast:successDeleteDevice') }));
      void handleSyncronizeBox();
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
    setIsPromptBeforeDeleteOpen(false);
  }, [dispatch, device.uuid, t, deleteDevice, handleSyncronizeBox]);

  const getProtocolBody = useCallback(
    (protocol: ProtocolKind | undefined) => {
      if (protocol === ProtocolKind.MODBUS_TCP) {
        return modbusTcp;
      }
      if (protocol === ProtocolKind.OPC_UA) {
        const parsedConnections = opcUa.connections.map(connection =>
          IsUserIdentityInfoUserName(connection.userIdentityInfo)
            ? {
                ...connection,
                userIdentityInfo: {
                  ...connection.userIdentityInfo,
                  userName: connection.userIdentityInfo.userName.trim().length === 0 ? undefined : connection.userIdentityInfo.userName,
                  password: connection.userIdentityInfo.password.trim().length === 0 ? undefined : connection.userIdentityInfo.password,
                },
              }
            : connection,
        );
        return { ...opcUa, connections: parsedConnections };
      }
      if (protocol === ProtocolKind.SQL) {
        return sql;
      }
      if (protocol === ProtocolKind.FILE) {
        return file;
      }
      if (protocol === ProtocolKind.S7) {
        return s7;
      }
      if (protocol === ProtocolKind.HTTP) {
        return http;
      }
      if (protocol === ProtocolKind.BACNET) {
        return bacnet;
      }
      if (protocol === ProtocolKind.MQTT) {
        return mqtt;
      }
      if (protocol === ProtocolKind.CUSTOM) {
        return {};
      }
      return undefined;
    },
    [modbusTcp, opcUa, sql, file, s7, http, bacnet, mqtt],
  );

  const handleSaveDevice = useCallback(async () => {
    if (!boxId) {
      return;
    }
    const protocolBody = getProtocolBody(commonDeviceBody.protocol);
    if (!protocolBody || !commonDeviceBody.protocol) {
      return;
    }

    const updateDeviceBody: UpdateDeviceBody = {
      ...commonDeviceBody,
      [commonDeviceBody.protocol]: protocolBody,
    };

    try {
      await updateDevice({ uuid: device.uuid, body: updateDeviceBody });
      dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: t('toast:successEditDevice') }));
      void handleSyncronizeBox();
      setIsEditing(false);
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  }, [boxId, device.uuid, commonDeviceBody, dispatch, getProtocolBody, t, updateDevice, handleSyncronizeBox]);

  const initData = useCallback(() => {
    setCommonDeviceBody(createCreateCommonDeviceBody({ ...device, brandDeviceId: device.brandDeviceId ?? undefined }));
    if (IsModbusTcpDevice(device)) {
      setModbusTcp(createModbusTcpDeviceConfig(device.modbusTcp));
    }
    if (IsOpcUaDevice(device)) {
      setOpcUa(createOpcUaDeviceConfig(device.opcUa));
    }
    if (IsSQLDevice(device)) {
      if (IsSQLBigQDeviceConfig(device.sql)) {
        setSQL(createSQLBigQDeviceConfig(device.sql));
      }
      if (IsSQLDefaultDeviceConfig(device.sql)) {
        setSQL(createSQLDefaultDeviceConfig(device.sql));
      }
    }
    if (IsFileDevice(device)) {
      setFile(createFileDeviceConfig(device.file));
    }
    if (IsS7Device(device)) {
      setS7(createS7DeviceConfig(device.s7));
    }
    if (IsHttpDevice(device)) {
      setHttp(createHttpDeviceConfig(device.http));
    }
    if (IsBacnetDevice(device)) {
      setBacnet(createBacnetDeviceConfig(device.bacnet));
    }
    if (IsMQTTDevice(device)) {
      setMQTT(createMQTTDeviceConfig(device.mqtt));
    }
  }, [device]);

  useEffect(() => {
    if (!isEditing) {
      initData();
    }
  }, [isEditing, initData]);

  useEffect(() => {
    if (device.uuid) {
      setIsEditing(false);
    }
  }, [device.uuid]);

  return (
    <>
      <RightPanelActions>
        <Tooltip title={makePermissionLabel(t('tooltip.editDevice'), canEditConfiguration)}>
          <span>
            <ActionButton disabled={areActionsDisabled || !canEditConfiguration} startIcon={<EditOutlined />} onClick={handleEditDevice}>
              {t('button.edit')}
            </ActionButton>
          </span>
        </Tooltip>
        {device.enabled && (
          <Tooltip title={makePermissionLabel(t('tooltip.disableDevice'), canEditConfiguration)}>
            <span>
              <ActionButton
                disabled={areActionsDisabled || !canEditConfiguration}
                startIcon={<HideSourceOutlined />}
                sx={{ letterSpacing: '-0.1px' }} // SLE: quick win because missing only one pixel
                onClick={handleOpenPromptBeforeDisable}
              >
                {t('button.disable')}
              </ActionButton>
            </span>
          </Tooltip>
        )}
        {!device.enabled && (
          <Tooltip title={makePermissionLabel(t('tooltip.enableDevice'), canEditConfiguration)}>
            <span>
              <ActionButton
                disabled={areActionsDisabled || !canEditConfiguration}
                startIcon={<CheckCircleOutlineOutlined />}
                onClick={handleEnableDevice}
              >
                {t('button.enable')}
              </ActionButton>
            </span>
          </Tooltip>
        )}
        <Tooltip title={makePermissionLabel(t('tooltip.deleteDevice'), canEditConfiguration)}>
          <span>
            <ActionButton
              disabled={areActionsDisabled || !canEditConfiguration}
              startIcon={<DeleteOutlined />}
              onClick={handleOpenPromptBeforeDelete}
            >
              {t('button.delete')}
            </ActionButton>
          </span>
        </Tooltip>
      </RightPanelActions>
      <RightPanelBody>
        <RightPanelSection>
          <RightPanelSectionContent>
            {!device.enabled && (
              <Alert severity="warning" sx={{ mb: 1.5 }}>
                {t('text.deviceDisabled')}
              </Alert>
            )}
            {brandDevice && (
              <BrandDevicePreview
                brandDevice={brandDevice}
                sx={{ background: theme => theme.palette.background.default, mb: 1.5, width: 'fit-content' }}
              />
            )}
            <Box mb={1.5}>
              <DeviceCommonForm
                body={commonDeviceBody}
                canChangeProtocol={false}
                displayBrandDevicePicker={false}
                isEditing={isEditing}
                setBody={setCommonDeviceBody}
              />
            </Box>
            <DeviceSettingsForm
              bacnet={bacnet}
              file={file}
              filterPastPoints={commonDeviceBody.filterPastPoints}
              http={http}
              isEditing={isEditing}
              location="panel"
              modbusTcp={modbusTcp}
              mode="edit"
              mqtt={mqtt}
              opcUa={opcUa}
              protocol={commonDeviceBody.protocol}
              s7={s7}
              setBacnet={setBacnet}
              setFile={setFile}
              setHttp={setHttp}
              setModbusTcp={setModbusTcp}
              setMQTT={setMQTT}
              setOpcUa={setOpcUa}
              setS7={setS7}
              setSQL={setSQL}
              sql={sql}
              syncPoint={commonDeviceBody.syncPoint}
              onChangeFilterPastPoints={handleChangeFilterPastPoints}
              onChangeSyncPoint={handleChangeSyncPoint}
            />
          </RightPanelSectionContent>
        </RightPanelSection>
      </RightPanelBody>
      {isEditing && (
        <RightPanelFooter sx={{ gap: 1 }}>
          <Button disabled={isUpdatingDevice} onClick={handleCancelEdit}>
            {t('button.cancel')}
          </Button>
          <LoadingButton
            color="secondary"
            // disabled={!isCommonValid || !isSettingsValid}
            loading={isUpdatingDevice}
            variant="contained"
            onClick={handleSaveDevice}
          >
            {t('button.save')}
          </LoadingButton>
        </RightPanelFooter>
      )}
      <PromptBeforeDisabling open={isPromptBeforeDisableOpen} setOpen={setIsPromptBeforeDisableOpen} onDisable={handleDisableDevice} />
      <PromptBeforeDeletingDevice
        device={device}
        open={isPromptBeforeDeleteOpen}
        setOpen={setIsPromptBeforeDeleteOpen}
        onDelete={handleDeleteDevice}
      />
      <PromptBeforeLeaving when={isEditing} />
    </>
  );
};

export default InformationsPanel;

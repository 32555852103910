import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import { ChangeEventHandler, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface SaveFileImportModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  description: string;
  setDescription: Dispatch<SetStateAction<string>>;
  onImportData: () => Promise<void>;
  onSaveFileImport: () => Promise<void>;
  forceSaveFileImport?: boolean;
}

const SaveFileImportModal: FC<SaveFileImportModalProps> = ({
  isOpen,
  setIsOpen,
  name,
  setName,
  description,
  setDescription,
  onImportData,
  onSaveFileImport,
  forceSaveFileImport = false,
}) => {
  const { t } = useTranslation('fileImport');

  const [doImportData, setDoImportData] = useState<boolean>(false);
  const [doSaveFileImport, setDoSaveFileImport] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const isValid = useMemo(
    () => (name.trim().length > 0 || !doSaveFileImport) && (doImportData || doSaveFileImport),
    [name, doSaveFileImport, doImportData],
  );

  const handleChangeDoImportData: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setDoImportData(event.target.checked);
  }, []);

  const handleChangeDoSaveFileImport: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setDoSaveFileImport(event.target.checked);
  }, []);

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setName(event.target.value);
    },
    [setName],
  );

  const handleChangeDescription: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setDescription(event.target.value);
    },
    [setDescription],
  );

  const handleCloseModal = useCallback(() => {
    if (!isSaving) {
      setIsOpen(false);
    }
  }, [setIsOpen, isSaving]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    if (doSaveFileImport) {
      await onSaveFileImport();
    }
    if (doImportData) {
      await onImportData();
    }
    setIsSaving(false);
    setIsOpen(false);
  }, [onImportData, onSaveFileImport, doSaveFileImport, doImportData, setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      setDoImportData(false);
      setDoSaveFileImport(forceSaveFileImport);
    }
  }, [isOpen, forceSaveFileImport]);

  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={handleCloseModal}>
      <DialogTitle>{t('title.saveFileImportModal')}</DialogTitle>
      <DialogContent>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={doImportData} onChange={handleChangeDoImportData} />}
            label={t('label.doImportData')}
          />
          <FormControlLabel
            control={<Checkbox checked={doSaveFileImport} disabled={forceSaveFileImport} onChange={handleChangeDoSaveFileImport} />}
            label={t('label.doSaveFileImport')}
          />
        </FormGroup>
        <Collapse in={doSaveFileImport}>
          <Paper sx={{ p: 2 }}>
            <Stack gap={1.5}>
              <TextField
                autoFocus
                fullWidth
                required
                disabled={isSaving}
                label={t('label.name')}
                value={name}
                onChange={handleChangeName}
              />
              <TextField
                fullWidth
                multiline
                disabled={isSaving}
                label={t('label.description')}
                minRows={2}
                size="small"
                value={description}
                onChange={handleChangeDescription}
              />
            </Stack>
          </Paper>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSaving} onClick={handleCloseModal}>
          {t('button.close')}
        </Button>
        <LoadingButton color="secondary" disabled={!isValid} loading={isSaving} variant="contained" onClick={handleSave}>
          {t('button.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SaveFileImportModal;

import { BrandDeviceInfo } from '@dametis/core';

import { sdk } from '../../sdk';

import { api } from './index';

export const brandDevicesApi = api.injectEndpoints({
  endpoints: build => ({
    readBrandDevices: build.query<BrandDeviceInfo[] | undefined, void>({
      providesTags: ['BrandDevices'],
      queryFn: async () => {
        try {
          const { data } = await sdk.brandDevice.List();
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const { useReadBrandDevicesQuery } = brandDevicesApi;

export const selectBrandDevices = brandDevicesApi.endpoints.readBrandDevices.select();

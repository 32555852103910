import { CacheVariableKind, RawPoint, UUID, VariableKind } from '@dametis/core';

import { sdk } from 'sdk';
import { TypedThunk } from 'store';
import {
  setFilterDevice,
  setFilterKinds,
  setFilterPhysicalQuantities,
  setFilterTags,
  setIsFetchingLastPoints,
  setIsFetchingLastProgresses,
  setLastPoints,
  setLastProgresses,
  setSelectedVariables,
} from 'store/slices/variables';

import { displaySdkErrorToast } from './toasts';

export enum SearchParamKey {
  KINDS = 'kinds',
  DEVICE = 'device',
  PHYSICAL_QUANTITIES = 'physicalQuantities',
  TAGS = 'tags',
  SELECTED_VARIABLES = 'selectedVariables',
}

export const setFilterKindsWithUrl =
  (
    payload: Parameters<typeof setFilterKinds>[0],
    searchParams: URLSearchParams,
    setSearchParams: (newSearchParams: URLSearchParams) => void,
  ): TypedThunk<void> =>
  dispatch => {
    dispatch(setFilterKinds(payload));
    searchParams.delete(SearchParamKey.KINDS);
    payload.forEach(changesOption => {
      searchParams.append(SearchParamKey.KINDS, changesOption);
    });
    setSearchParams(searchParams);
  };

export const setFilterDeviceWithUrl =
  (
    payload: Parameters<typeof setFilterDevice>[0],
    searchParams: URLSearchParams,
    setSearchParams: (newSearchParams: URLSearchParams) => void,
  ): TypedThunk<void> =>
  dispatch => {
    dispatch(setFilterDevice(payload));
    searchParams.set(SearchParamKey.DEVICE, payload);
    setSearchParams(searchParams);
  };

export const setFilterPhysicalQuantitiesWithUrl =
  (
    payload: Parameters<typeof setFilterPhysicalQuantities>[0],
    searchParams: URLSearchParams,
    setSearchParams: (newSearchParams: URLSearchParams) => void,
  ): TypedThunk<void> =>
  dispatch => {
    dispatch(setFilterPhysicalQuantities(payload));
    searchParams.delete(SearchParamKey.PHYSICAL_QUANTITIES);
    payload.forEach(physicalQuantity => {
      searchParams.append(SearchParamKey.PHYSICAL_QUANTITIES, physicalQuantity);
    });
    setSearchParams(searchParams);
  };

export const setFilterTagsWithUrl =
  (
    payload: Parameters<typeof setFilterTags>[0],
    searchParams: URLSearchParams,
    setSearchParams: (newSearchParams: URLSearchParams) => void,
  ): TypedThunk<void> =>
  dispatch => {
    dispatch(setFilterTags(payload));
    searchParams.delete(SearchParamKey.TAGS);
    payload.forEach(tag => {
      searchParams.append(SearchParamKey.TAGS, tag);
    });
    setSearchParams(searchParams);
  };

export const setSelectedVariablesWithUrl =
  (
    payload: Parameters<typeof setSelectedVariables>[0],
    searchParams: URLSearchParams,
    setSearchParams: (newSearchParams: URLSearchParams) => void,
  ): TypedThunk<void> =>
  dispatch => {
    dispatch(setSelectedVariables(payload));
    searchParams.delete(SearchParamKey.SELECTED_VARIABLES);
    payload.forEach(selectedVariable => {
      searchParams.append(SearchParamKey.SELECTED_VARIABLES, selectedVariable);
    });
    setSearchParams(searchParams);
  };

export const fetchVariablesLastPoints =
  (groupId: string, variablesIds: UUID[]): TypedThunk<Promise<Record<UUID, RawPoint | null>>> =>
  async dispatch => {
    dispatch(setIsFetchingLastPoints(true));
    try {
      const { data } = await sdk.tada.Last(
        groupId,
        variablesIds.map(variableId => ({ kind: CacheVariableKind.VARIABLES, uuid: variableId })),
      );
      return variablesIds.reduce<Record<UUID, RawPoint | null>>((result, variableId, index) => {
        result[variableId] = data[index];
        return result;
      }, {});
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
      return {};
    } finally {
      dispatch(setIsFetchingLastPoints(false));
    }
  };

export const fetchStoreVariablesLastPoints = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  const {
    auth: { selectedGroup },
    variables: { byId: variablesById },
  } = getState();
  if (!selectedGroup?.uuid) {
    return;
  }
  try {
    const fetchedAt = new Date();
    const variablesIds = Object.keys(variablesById);
    const data = await dispatch(fetchVariablesLastPoints(selectedGroup.uuid, variablesIds));
    dispatch(setLastPoints({ data, fetchedAt }));
  } catch (err) {
    console.error(err);
  }
};

export const fetchVariablesLastProgresses =
  (groupId: string, variablesIds: UUID[]): TypedThunk<Promise<Record<UUID, number | null>>> =>
  async dispatch => {
    dispatch(setIsFetchingLastProgresses(true));
    try {
      const { data } = await sdk.tada.Progress(
        groupId,
        variablesIds.map(variableId => ({ uuid: variableId })),
      );
      return variablesIds.reduce<Record<UUID, number | null>>((result, variableId, index) => {
        result[variableId] = data[index];
        return result;
      }, {});
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
      return {};
    } finally {
      dispatch(setIsFetchingLastProgresses(false));
    }
  };

export const fetchStoreCalculatedVariablesLastProgresses = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  const {
    auth: { selectedGroup },
    variables: { byId: variablesById },
  } = getState();
  if (!selectedGroup?.uuid) {
    return;
  }
  try {
    const fetchedAt = new Date();
    const variablesIds = Object.values(variablesById).reduce<UUID[]>((result, variable) => {
      if (variable.kind === VariableKind.CALCULATED) {
        result.push(variable.uuid);
      }
      return result;
    }, []);
    const data = await dispatch(fetchVariablesLastProgresses(selectedGroup.uuid, variablesIds));
    dispatch(setLastProgresses({ data, fetchedAt }));
  } catch (err) {
    console.error(err);
  }
};

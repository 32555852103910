import { Paper, PaperProps, styled } from '@mui/material';

export interface PaperDropzoneProps extends PaperProps {
  disabled?: boolean;
  isDragActive?: boolean;
  isDragAccept?: boolean;
}

const PaperDropzone = styled(Paper, {
  shouldForwardProp: prop => prop !== 'disabled' && prop !== 'isDragActive' && prop !== 'isDragAccept',
})<PaperDropzoneProps>(({ theme, disabled = false, isDragActive = false, isDragAccept = false }) => ({
  borderWidth: 2,
  borderStyle: 'dashed',
  transition: theme.transitions.create(['border-color']),
  ...(isDragActive && !disabled
    ? {
        borderColor: !isDragAccept ? `${theme.palette.error.light}!important` : theme.palette.success.light,
      }
    : {
        borderColor: theme.palette.divider,
      }),
  ...(!disabled && {
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.secondary.light,
    },
  }),
}));

export default PaperDropzone;

import { ArrowDropDown, ErrorOutlineOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import {
  GridRenderCellParams,
  gridDetailPanelExpandedRowIdsSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import { FC, isValidElement, useMemo } from 'react';

import { useFilePreviewContext } from './FilePreviewContext';
import { ImportGridData } from './FilePreviewGridColDef';

export interface IndexCellProps {
  id: GridRenderCellParams['id'];
  row: ImportGridData;
}

const IndexCell: FC<IndexCellProps> = ({ id, row }) => {
  const apiRef = useGridApiContext();
  const { csvConfig, isJq } = useFilePreviewContext();

  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);
  const expandedRowsIds = useGridSelector(apiRef, gridDetailPanelExpandedRowIdsSelector);

  const hasDetails = useMemo(() => isValidElement(contentCache[id]), [contentCache, id]);
  const isExpanded = useMemo(() => expandedRowsIds.includes(id), [expandedRowsIds, id]);
  const parsedRowIndex = useMemo(
    () => (isJq ? row.index : row.index + (csvConfig?.startLine ?? 0) + (csvConfig?.useHeader ? 1 : 0)) + 1,
    [isJq, row.index, csvConfig],
  );

  return (
    <Stack
      alignItems="center"
      direction="row-reverse"
      gap={0.5}
      height={1}
      justifyContent="space-between"
      sx={{ cursor: 'pointer', '& .ArrowDropDown': { opacity: 0 }, '&:hover .ArrowDropDown': { opacity: 1 } }}
      width={1}
    >
      <Typography variant="subtitle2">{parsedRowIndex}</Typography>
      {hasDetails && (
        <Stack alignItems="center" direction="row">
          <ErrorOutlineOutlined color="error" fontSize="small" />
          <ArrowDropDown className="ArrowDropDown" fontSize="small" sx={{ transform: `rotateZ(${isExpanded ? 180 : 0}deg)` }} />
        </Stack>
      )}
    </Stack>
  );
};

export default IndexCell;

import { Grid, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockInfo, BlockTypeInfo, ShortStandardBlockInfo } from '@dametis/core';

import { BlocksOutlined } from 'assets/icons/BlocksOutlined';
import { isBlockConnectionValid } from 'components/Lego/helpers/block/isBlockConnectionValid';
import { isBlockMetadataValid } from 'components/Lego/helpers/block/isBlockMetadataValid';
import { isBlockMetricValid } from 'components/Lego/helpers/block/isBlockMetricValid';
import { isBlockParameterValid } from 'components/Lego/helpers/block/isBlockParameterValid';

import BlockTileContainer, { BlockTileContainerProps } from './BlockTileContainer';
import BlockTileSubtitle from './BlockTileSubtitle';
import ElementProgress from './ElementProgress';

export interface BlockTileProps extends BlockTileContainerProps {
  block: BlockInfo;
  standardBlock?: ShortStandardBlockInfo;
  blockType?: BlockTypeInfo;
}

const BlockTile: FC<BlockTileProps> = ({ block, standardBlock = undefined, blockType = undefined, ...props }) => {
  const { t } = useTranslation('lego');

  const selectedParameters = useMemo(() => block.parameters.filter(parameter => parameter.isSelected), [block.parameters]);
  const validParameters = useMemo(() => selectedParameters.filter(parameter => isBlockParameterValid(parameter)), [selectedParameters]);

  const selectedMetrics = useMemo(() => block.metrics.filter(metric => metric.isSelected), [block.metrics]);
  const validMetrics = useMemo(() => selectedMetrics.filter(metric => isBlockMetricValid(metric)), [selectedMetrics]);

  const validMetadataList = useMemo(
    () => block.blockMetadataList.filter(metadata => isBlockMetadataValid(metadata)),
    [block.blockMetadataList],
  );

  const validConnections = useMemo(() => block.connections.filter(connection => isBlockConnectionValid(connection)), [block.connections]);

  return (
    <BlockTileContainer {...props}>
      <Stack gap={1}>
        <Stack alignItems="center" direction="row" gap={1}>
          <BlocksOutlined sx={{ color: block.ui.color }} />
          <Stack gap={0.5}>
            <BlockTileSubtitle blockType={blockType} standardBlock={standardBlock} />
            <Typography lineHeight={1} variant="h6">
              {block.name}
            </Typography>
          </Stack>
        </Stack>
        <Grid container alignItems="flex-end" columnSpacing={2} rowSpacing={1.5}>
          <Grid item xs={6}>
            <ElementProgress available={validParameters.length} label={t('label.parameters')} total={selectedParameters.length} />
          </Grid>
          <Grid item xs={6}>
            <ElementProgress available={validMetrics.length} label={t('label.metrics')} total={selectedMetrics.length} />
          </Grid>
          <Grid item xs={6}>
            <ElementProgress available={validMetadataList.length} label={t('label.metadata')} total={block.blockMetadataList.length} />
          </Grid>
          <Grid item xs={6}>
            <ElementProgress available={validConnections.length} label={t('label.connections')} total={block.connections.length} />
          </Grid>
        </Grid>
      </Stack>
    </BlockTileContainer>
  );
};

export default BlockTile;

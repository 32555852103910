import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useContext, useMemo } from 'react';

import { CSVConfig, DataInfo, ParseError, ParseErrorType, ReferencedBy, SourceColumn } from '@dametis/core';

import { FormFixedVariable } from '../MappingSettings/MappingSettingsContext';

export type FilePreviewContextState = FilePreviewProviderProps;
// export interface FilePreviewContextState extends FilePreviewProviderProps {}

export interface FilePreviewProviderProps {
  dataInfo: DataInfo[][];
  csvConfig: CSVConfig;
  usedSources: SourceColumn[];
  referencedBy: ReferencedBy;
  dateTimeSource: SourceColumn | null;
  setDateTimeSource: Dispatch<SetStateAction<SourceColumn | null>>;
  referenceSource: SourceColumn | null;
  setReferenceSource: Dispatch<SetStateAction<SourceColumn | null>>;
  valueSource: SourceColumn | null;
  setValueSource: Dispatch<SetStateAction<SourceColumn | null>>;
  skippedColumns: (SourceColumn | null)[];
  setSkippedColumns: Dispatch<SetStateAction<(SourceColumn | null)[]>>;
  fixedVariables: FormFixedVariable[];
  setFixedVariables: Dispatch<SetStateAction<FormFixedVariable[]>>;
  globalParseErrors: ParseError<ParseErrorType.PAPA | ParseErrorType.JQ>[];
  parseErrorsByRow: Record<number, ParseError[]>;
  isJq: boolean;
}

export const FilePreviewContext = createContext<FilePreviewContextState | undefined>(undefined);

const FilePreviewProvider: FC<PropsWithChildren<FilePreviewProviderProps>> = ({
  dataInfo,
  csvConfig,
  usedSources,
  referencedBy,
  dateTimeSource,
  setDateTimeSource,
  referenceSource,
  setReferenceSource,
  valueSource,
  setValueSource,
  skippedColumns,
  setSkippedColumns,
  fixedVariables,
  setFixedVariables,
  globalParseErrors,
  parseErrorsByRow,
  isJq,
  children = undefined,
}) => {
  const contextValues = useMemo(
    () => ({
      dataInfo,
      csvConfig,
      referencedBy,
      usedSources,
      dateTimeSource,
      setDateTimeSource,
      referenceSource,
      setReferenceSource,
      valueSource,
      setValueSource,
      skippedColumns,
      setSkippedColumns,
      fixedVariables,
      setFixedVariables,
      globalParseErrors,
      parseErrorsByRow,
      isJq,
    }),
    [
      dataInfo,
      csvConfig,
      referencedBy,
      usedSources,
      dateTimeSource,
      setDateTimeSource,
      referenceSource,
      setReferenceSource,
      valueSource,
      setValueSource,
      skippedColumns,
      setSkippedColumns,
      fixedVariables,
      setFixedVariables,
      globalParseErrors,
      parseErrorsByRow,
      isJq,
    ],
  );

  return <FilePreviewContext.Provider value={contextValues}>{children}</FilePreviewContext.Provider>;
};

export const useFilePreviewContext = () => {
  const context = useContext(FilePreviewContext);

  if (!context) {
    throw Error('useFilePreviewContext must be used inside an FilePreviewProvider');
  }

  return context;
};

export default FilePreviewProvider;

import { FC, useCallback, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { ParseError, ParseErrorType } from '@dametis/core';

import useGridComponents from 'hooks/useGridComponents';

import ErrorsListRow from './ErrorsListRow';
import { ERROR_HEIGHT, GAP_HEIGHT, LABEL_HEIGHT, MB_HEIGHT, VirtualListItem, VirtualListItemType } from './types';

const globalParseErrorsDefaultProp: ParseError<ParseErrorType.PAPA>[] = [];

const parseErrorsByRowDefaultProp: Record<number, ParseError[]> = {};

export interface ErrorsListProps {
  globalParseErrors?: ParseError<ParseErrorType.PAPA | ParseErrorType.JQ>[];
  parseErrorsByRow?: Record<number, ParseError[]>;
}

export const getItemSizeFromNbErrors = (nbErrors: number) => LABEL_HEIGHT + nbErrors * (ERROR_HEIGHT + GAP_HEIGHT) + MB_HEIGHT;

const ErrorsList: FC<ErrorsListProps> = ({
  globalParseErrors = globalParseErrorsDefaultProp,
  parseErrorsByRow = parseErrorsByRowDefaultProp,
}) => {
  const rows: VirtualListItem[] = useMemo(
    () => [
      ...(globalParseErrors.length > 0 ? [{ type: VirtualListItemType.GLOBAL_PARSE_ERRORS, content: globalParseErrors }] : []),
      ...Object.values(parseErrorsByRow).map(rowParseErrors => ({ type: VirtualListItemType.ROW_PARSE_ERRORS, content: rowParseErrors })),
    ],
    [globalParseErrors, parseErrorsByRow],
  );

  const itemContent = useCallback((_: number, row: VirtualListItem) => <ErrorsListRow item={row} />, []);

  const gridComponents = useGridComponents('100%', 2);

  return <Virtuoso components={gridComponents} data={rows} itemContent={itemContent} style={{ scrollBehavior: 'smooth' }} />;
};

export default ErrorsList;

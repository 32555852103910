import { MaskedInputProps } from 'react-text-mask';

const MaskFormat: Record<string, MaskedInputProps> = {
  ip: {
    guide: false,
    mask: (value: string) => {
      const mask = Array(value.length).fill(/[\d.]/);
      const chunks = value.split('.');

      for (let i = 0; i < 4; i += 1) {
        const chunk = chunks[i] || '';

        if (255 % +chunk === 255) {
          mask[value.length - 1] = '.';
          mask[value.length] = /[\d.]/;
        }
      }

      return mask;
    },
    pipe: (value: string): string | false => {
      if (value === '.' || value.endsWith('..')) return false;

      const parts = value.split('.');

      if (
        parts.length > 4 ||
        parts.some(part => (part.length > 1 && part[0] === '0') || parseInt(part, 10) < 0 || parseInt(part, 10) > 255)
      ) {
        return false;
      }

      return value;
    },
  },
  time: {
    guide: true,
    mask: () => {
      let result: (string | RegExp)[] = [];
      for (let i = 0; i < 3; i += 1) {
        result.push(/\d/, /\d/, ':');
      }
      result = result.slice(0, -1);
      return result;
    },
    pipe: (value: string): string | false => {
      if (value === ':' || value.endsWith('::')) return false;
      const parts = value.split(':');

      if (parts.length > 3 || parts.some((part, i) => parseInt(part, 10) < 0 || (i > 0 && parseInt(part, 10) > 59))) {
        return false;
      }
      return value;
    },
  },
};

export default MaskFormat;

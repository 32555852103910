import { TFunction } from 'i18next';

export interface S7SlotInfo {
  key: string;
  value: string;
}

export const getSlotInfos = (t: TFunction): S7SlotInfo[] => [
  { key: 'S7 300 CPU', value: '2' },
  { key: 'S7 1200 CPU', value: t('slotInfo.S71200CPU') },
  { key: 'S7 1500 CPU', value: t('slotInfo.S71500CPU') },
  { key: 'CP 343', value: t('slotInfo.CP343') },
];

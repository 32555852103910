import { makeStyles } from '@mui/styles';

const useDateTimeRangePanelStyles = makeStyles(theme => ({
  openBtn: {
    whiteSpace: 'nowrap',
  },
  popper__element: {
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  popper__element__top: {
    height: '22rem',
  },
  popper__element__bottom: {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
  popper__element__header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'inherit',
  },
  popper__element__title: {
    color: theme.palette.primary.main,
  },
  popper__element__fixedPeriod: {
    height: 'auto',
  },
  dateRange: {
    display: 'flex',
    width: 260,
    flexDirection: 'column',
    '& > div, & > button': {
      marginTop: 10,
    },
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  tabs__indicator: {
    width: '50%!important',
  },
  tabs__indicator__calendar: {
    left: '0%!important',
  },
  tabs__indicator__batch: {
    left: '50%!important',
  },
  batch: {
    // padding: `0 ${theme.spacing(1)}`,
  },
  batchDate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: `0 ${theme.spacing(1)}`,
  },
  batchDate__picker: {
    margin: `0 ${theme.spacing(1)}`,
  },
  batchInput: {
    margin: `0 ${theme.spacing(1)}`,
    '& > div': {
      marginTop: '0.5rem !important',
      marginBottom: '0.5rem !important',
    },
    '& > div:first-child > div:first-child': {
      display: 'none!important',
    },
  },
  batch__runs: {
    maxHeight: '250px',
    overflow: 'auto',
    padding: theme.spacing(1),
  },
  batch__runs__loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing(1)} 0`,
  },
  batchDate__badge: {
    '& > span': {
      transform: 'scale(0.8) translate(50%, -50%)',
      top: '6px!important',
      right: '6px!important',
    },
  },
  batch__runs__item: {
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  batch__runs__item__dates: {},
  batch__runs__item__duration: {
    marginLeft: theme.spacing(5),
    fontStyle: 'italic',
  },
  dateRange__iconButton: {
    padding: 0,
  },
}));

export default useDateTimeRangePanelStyles;

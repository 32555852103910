import { HelpOutlineOutlined, SvgIconComponent } from '@mui/icons-material';
import { Box, Stack, SvgIcon, Tooltip, TooltipProps } from '@mui/material';
import { FC } from 'react';

import { AdvancedTextFieldProps } from './AdvancedTextField';

const tooltipPropsDefaultProp: Omit<TooltipProps, 'children' | 'title'> = {};

export interface LabelWithDescriptionProps {
  label: AdvancedTextFieldProps['label'];
  description: TooltipProps['title'];
  icon?: SvgIconComponent;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
  required?: boolean;
}

const LabelWithDescription: FC<LabelWithDescriptionProps> = ({
  label,
  description,
  icon = HelpOutlineOutlined,
  tooltipProps = tooltipPropsDefaultProp,
  required = false,
}) => (
  <Stack alignItems="center" direction="row" gap={0.5}>
    <Box>{`${label}${required === true ? ' *' : ''}`}</Box>
    <Tooltip placement="top" title={description} {...tooltipProps}>
      <SvgIcon component={icon} fontSize="small" />
    </Tooltip>
  </Stack>
);

export default LabelWithDescription;

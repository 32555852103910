import { Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';

import { VariableResult } from 'components/VNC/types';
import { useVncStore } from 'zustand/stores/vnc';

import ListItemSkeleton from './ListItemSkeleton';
import ListItemVariable from './ListItemVariable';

const skeletonItems = Array.from({ length: 10 }, (v, k) => k);

const VariablesList: FC = () => {
  const { t } = useTranslation('vnc');

  const variables = useVncStore(state => state.results.variables);
  const loadingResults = useVncStore(state => state.loadingResults);

  const itemContent = useCallback(
    (index: number) =>
      loadingResults ? (
        <ListItemSkeleton isLast={skeletonItems.length - 1 === index} />
      ) : (
        <ListItemVariable index={index} isLast={skeletonItems.length - 1 === index} item={variables[index]} />
      ),
    [loadingResults, variables],
  );

  if (!variables.length && !loadingResults)
    return (
      <Typography align="center" sx={{ mt: 4 }} variant="subtitle2">
        {t('subtitle.noVariables')}
      </Typography>
    );
  return <Virtuoso<VariableResult | number> data={loadingResults ? skeletonItems : variables} itemContent={itemContent} />;
};

export default VariablesList;

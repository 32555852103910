export default {
  button: {
    reset: 'Réinitialiser',
  },
  label: {
    noValue: 'n/a',
  },
  option: {
    all: 'Tout sélectionner',
  },
  text: {
    noTagSelected: 'Aucun tag sélectionné',
    noUserSelected: 'Aucun utilisateur sélectionné',
  },
  toast: {
    tooMuchActiveFilters: 'Trop de filtres actifs',
  },
  tooltip: {
    unassigned: 'Non assigné',
  },
};

import { Divider, Grid2, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ShortAliasInfo, ShortCorporateAliasInfo } from '@dametis/core';

import AliasesList from './AliasesList';
import HistoryList from './HistoryList';

interface HistoryAndAliasesListProps {
  filteredAliases: (ShortAliasInfo | ShortCorporateAliasInfo)[];
}

const HistoryAndAliasesList: FC<HistoryAndAliasesListProps> = ({ filteredAliases }) => {
  const { t } = useTranslation('vnc');

  return (
    <Grid2 container height={1} spacing={0.5}>
      <Grid2 height={1} size="grow">
        <Stack height={1}>
          <Typography px={1.5} py={1.5} variant="h5">
            {t('title.history')}
          </Typography>
          <HistoryList />
        </Stack>
      </Grid2>
      <Grid2>
        <Divider orientation="vertical" />
      </Grid2>
      <Grid2 height={1} size="grow">
        <Stack height={1}>
          <Typography px={1.5} py={1.5} variant="h5">
            {t('title.aliases')}
          </Typography>
          <AliasesList filteredAliases={filteredAliases} />
        </Stack>
      </Grid2>
    </Grid2>
  );
};

export default HistoryAndAliasesList;

import { ErrorOutlineOutlined, UnfoldLessOutlined, UnfoldMoreOutlined } from '@mui/icons-material';
import { Box, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Resizable, ResizeCallbackData } from 'react-resizable';

import { ParseError, ParseErrorType } from '@dametis/core';

import AlertPaper from 'components/Data/FileImport/UI/AlertPaper/AlertPaper';
import ErrorsList from 'components/Data/FileImport/UI/ErrorsList/ErrorsList';
import ResizeHandler from 'components/UI/ResizeHandler/ResizeHandler';

export interface ErrorsAlertProps {
  globalParseErrors: ParseError<ParseErrorType.PAPA | ParseErrorType.JQ>[];
  parseErrorsByRow: Record<number, ParseError[]>;
  containerHeight: number | undefined;
}

const ALERT_MIN_HEIGHT = 100;

const ErrorsAlert: FC<ErrorsAlertProps> = ({ globalParseErrors, parseErrorsByRow, containerHeight }) => {
  const { t } = useTranslation('fileImport');

  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(ALERT_MIN_HEIGHT);

  const alertMaxHeight = useMemo(() => (containerHeight ?? 9999) - 200, [containerHeight]);

  const resize = useCallback((_event: SyntheticEvent, { size }: ResizeCallbackData) => setHeight(size.height), []);

  const handleToggleExpand = useCallback(() => {
    if (height === alertMaxHeight) {
      setHeight(ALERT_MIN_HEIGHT);
    } else {
      setHeight(alertMaxHeight);
    }
  }, [height, alertMaxHeight]);

  const handleResizeStart = useCallback(() => {
    setIsResizing(true);
  }, []);

  const handleResizeStop = useCallback(() => {
    setIsResizing(false);
  }, []);

  return (
    <Resizable
      handle={<ResizeHandler isResizing={isResizing} sx={{ position: 'absolute', top: '-6px', borderRadius: 0.5, zIndex: 1 }} />}
      height={height}
      maxConstraints={[9999, alertMaxHeight]}
      minConstraints={[9999, ALERT_MIN_HEIGHT]}
      resizeHandles={['n']}
      width={9999}
      onResize={resize}
      onResizeStart={handleResizeStart}
      onResizeStop={handleResizeStop}
    >
      <Box>
        <AlertPaper severity="error" sx={{ height, transition: !isResizing ? theme => theme.transitions.create(['height']) : undefined }}>
          <Stack height={1}>
            <Stack alignItems="center" direction="row" gap={1} px={2} py={1}>
              <ErrorOutlineOutlined color="error" />
              <Typography lineHeight={1} variant="h6">
                {t('text.documentContainsErrors')}
              </Typography>
              <IconButton size="small" sx={{ ml: 'auto' }} onClick={handleToggleExpand}>
                <SvgIcon component={height === alertMaxHeight ? UnfoldLessOutlined : UnfoldMoreOutlined} fontSize="small" />
              </IconButton>
            </Stack>
            <Box flexGrow={1} pb={2} px={2}>
              <ErrorsList globalParseErrors={globalParseErrors} parseErrorsByRow={parseErrorsByRow} />
            </Box>
          </Stack>
        </AlertPaper>
      </Box>
    </Resizable>
  );
};

export default ErrorsAlert;

import { MultipleSymbol, MultiplesBySymbol } from '@dametis/unit';

export const getFileSizeMultipleSymbol = (value: number): MultipleSymbol => {
  if (value > MultiplesBySymbol.G.coefficient) {
    return MultipleSymbol.G;
  }
  if (value > MultiplesBySymbol.M.coefficient) {
    return MultipleSymbol.M;
  }
  if (value > MultiplesBySymbol.k.coefficient) {
    return MultipleSymbol.k;
  }
  return MultipleSymbol[''];
};

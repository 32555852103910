import { BookmarkBorder, DeleteOutlined, EditOutlined } from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  capitalize,
  listItemSecondaryActionClasses,
} from '@mui/material';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GetCalculationVariableStats, IsShortCorporateAlias, ShortAliasInfo, ShortCorporateAliasInfo } from '@dametis/core';

import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import { PropsContext } from 'components/VNC/context';
import getUserName from 'functions/getUserName';
import { localizedFormatDistance } from 'localization/localizedDateFns';
import { useSelector } from 'store';
import { useDeleteAliasMutation } from 'store/api/aliases';

import CalculationSlate from '../../../../UI/CalculationSlate/CalculationSlate';
import { useInsertVariable } from '../../../hooks';
import { TypographyNoBreak } from '../TypographyNoBreak';

import AliasChip from './AliasChip';
import AliasDialog from './AliasDialog';

interface Props {
  item: ShortAliasInfo | ShortCorporateAliasInfo;
  isLast: boolean;
}

const AliasesListItem: FC<Props> = ({ item, isLast }) => {
  const { t } = useTranslation('vnc');

  const [deleteAlias, { isLoading: isDeleting }] = useDeleteAliasMutation();
  const [dialogOpen, setDialogOpen] = useState(false);

  const isCorpo = useSelector(state => !state.auth.selectedSite);
  const sites = useSelector(state => state.auth.selectedGroup!.sites);

  const insertVariable = useInsertVariable();

  const { disableLego, disableMaths, defaultVariableOperator, calculatedVariableMode } = useContext(PropsContext);

  const hasBlocks = useMemo(() => GetCalculationVariableStats(item.calculation).blockVariables.length > 0, [item.calculation]);
  const siteName = useMemo(
    () => (IsShortCorporateAlias(item) ? `${t('text.createdIn')} ${sites.find(site => site.uuid === item?.siteId)?.name}` : null),
    [item, t, sites],
  );

  const addItem = useCallback(() => {
    insertVariable({ aliasUuid: item.uuid }, disableMaths, defaultVariableOperator ?? undefined, calculatedVariableMode);
  }, [calculatedVariableMode, defaultVariableOperator, disableMaths, insertVariable, item.uuid]);

  const deleteItem = useCallback(async () => {
    await deleteAlias(item.uuid);
  }, [deleteAlias, item.uuid]);

  const updateItem = useCallback(() => {
    setDialogOpen(true);
  }, []);

  return (
    <ListItem
      divider={!isLast}
      secondaryAction={
        isCorpo || item.canEdit === false ? undefined : (
          <>
            <Tooltip title={t('button.updateAliasItem')}>
              <IconButton onClick={updateItem}>
                <EditOutlined />
              </IconButton>
            </Tooltip>
            <AliasDialog edit alias={item} open={dialogOpen} setOpen={setDialogOpen} />
            <Tooltip title={t('button.deleteAliasItem')}>
              <IconButton disabled={isDeleting} onClick={deleteItem}>
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          </>
        )
      }
      sx={{
        px: 0,
        py: 0.5,
        [`& .${listItemSecondaryActionClasses.root}`]: { display: 'none' },
        [`&:hover .${listItemSecondaryActionClasses.root}`]: { display: 'block' },
      }}
    >
      <ListItemButton disableGutters disabled={hasBlocks && disableLego} sx={{ pr: '96px !important' }} onClick={addItem}>
        <ListItemAvatar>
          <Stack alignItems="center" spacing={0.5} sx={{ width: 40 }}>
            <Avatar>
              <BookmarkBorder />
            </Avatar>
            <TypographyNoBreak variant="caption">{t('list.text.variable')}</TypographyNoBreak>
          </Stack>
        </ListItemAvatar>
        <ListItemText
          primary={<TypographyEllipse>{item.name}</TypographyEllipse>}
          primaryTypographyProps={{ variant: 'h6' }}
          secondary={
            <>
              <CalculationSlate calculation={item.calculation} />
              {item.createdAt ? capitalize(localizedFormatDistance(new Date(item.createdAt), new Date(), { addSuffix: true })) : undefined}
              {t('text.fromSource')} <AliasChip size="small" source={item.source} sx={{ fontSize: 'inherit' }} />
              {siteName !== null && siteName}
              {item.owner && `${t('text.createdBy')} ${getUserName(item.owner)}`}
            </>
          }
          secondaryTypographyProps={{ component: 'div' }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default AliasesListItem;

import { BlockTypeInfo, CreateStandardBlockBody, MetadataScope, StandardBlockUI } from '@dametis/core';

import { createStandardBlockConnectionBody } from './standardBlock/createStandardBlockConnection';
import { createStandardBlockMetadataBody, createStandardBlockMetadataContent } from './standardBlock/createStandardBlockMetadata';
import { createStandardBlockMetricBody } from './standardBlock/createStandardBlockMetric';
import { createStandardBlockParameterBody } from './standardBlock/createStandardBlockParameter';

export const getStandardBlockBodyDataFromBlockType = (blockType: BlockTypeInfo): Partial<CreateStandardBlockBody> => {
  const ui: StandardBlockUI = { color: blockType.content.color };
  const parameters = (blockType.parameters ?? []).map(parameter => createStandardBlockParameterBody({ ...parameter, isSelected: true }));
  const metrics = (blockType.metrics ?? []).map(metric => createStandardBlockMetricBody({ ...metric, isSelected: true }));
  const connections = (blockType.connections ?? []).map(connection => createStandardBlockConnectionBody(connection));
  const standardMetadataList = (blockType.standardMetadataList ?? []).map(standardMetadata =>
    createStandardBlockMetadataBody({
      ...standardMetadata,
      scope: MetadataScope.STANDARD_BLOCK,
      content: createStandardBlockMetadataContent(standardMetadata.type, standardMetadata.content),
    }),
  );
  return {
    ui,
    parameters,
    metrics,
    connections,
    standardMetadataList,
  };
};

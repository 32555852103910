import { GRID_DETAIL_PANEL_TOGGLE_FIELD, GridColDef } from '@mui/x-data-grid-premium';

import { SourceColumn, UUID } from '@dametis/core';

import ColumnPickerHeaderCell from './ColumnPickerHeaderCell';
import IndexCell from './IndexCell';
import ValueCell from './ValueCell';

export type RowIndex = string;

export interface ImportGridData {
  uuid: UUID;
  index: number;
  [x: RowIndex]: string | number;
}

export const getColumnDef = (source: SourceColumn, index: number): GridColDef<ImportGridData> => ({
  field: `${index}`,
  align: 'left',
  headerAlign: 'left',
  resizable: true,
  sortable: true,
  width: 200,
  // editable: true,
  renderHeader: params => <ColumnPickerHeaderCell params={params} source={source} />,
  renderCell: params => <ValueCell params={params} source={source} />,
});

export const indexColDef: GridColDef<ImportGridData> = {
  field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
  headerName: '#',
  headerAlign: 'right',
  align: 'right',
  resizable: false,
  sortable: true,
  width: 100,
  editable: false,
  valueGetter: (_, row) => row.index,
  renderCell: ({ id, row }) => <IndexCell id={id} row={row} />,
};

import { SettingsOutlined } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ListVariableInfo, ShortDeviceInfo } from '@dametis/core';

import LinkButton from 'components/UI/Buttons/LinkButton/LinkButton';
import PromptBeforeDeleting, { PromptBeforeDeletingProps } from 'components/UI/PromptBeforeDeleting/PromptBeforeDeleting';
import { useSelector } from 'store';
import { SearchParamKey } from 'store/actions/variables';

export interface PromptBeforeDeletingDeviceProps extends PromptBeforeDeletingProps {
  device: ShortDeviceInfo;
}

const PromptBeforeDeletingDevice: FC<PromptBeforeDeletingDeviceProps> = ({ device, open, setOpen, ...props }) => {
  const { t } = useTranslation('devices');

  const variablesById = useSelector(state => state.variables.byId);

  const deviceVariables: ListVariableInfo[] = useMemo(
    () => Object.values(variablesById).filter(variable => variable.deviceId === device.uuid),
    [variablesById, device.uuid],
  );

  const handleCloseNoDeleteModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      {deviceVariables.length === 0 && <PromptBeforeDeleting open={open} setOpen={setOpen} {...props} />}
      {deviceVariables.length > 0 && (
        <Dialog open={open} onClose={handleCloseNoDeleteModal}>
          <DialogTitle>{t('title.noDelete')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('text.noDelete', { count: deviceVariables.length })}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleCloseNoDeleteModal}>
              {t('button.close')}
            </Button>
            <LinkButton startIcon={<SettingsOutlined />} to={`/configuration/variables?${SearchParamKey.DEVICE}=${device.uuid}`}>
              {t('button.configuration')}
            </LinkButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default PromptBeforeDeletingDevice;

import { Checkbox, FormControlLabel, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { ChangeEventHandler, Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CSVConfig, CSVDelimiter, ReferencedBy } from '@dametis/core';

import CharsetPicker from './CharsetPicker';
import DelimiterPicker from './DelimiterPicker';
import ReferencedByPicker from './ReferencedByPicker/ReferencedByPicker';
import { SectionAccordion, SectionAccordionDetails, SectionAccordionSummary } from './SectionAccordion';

export interface GeneralSettingsProps {
  csvConfig: CSVConfig;
  setCSVConfig: Dispatch<SetStateAction<CSVConfig>>;
  referencedBy: ReferencedBy;
  setReferencedBy: Dispatch<SetStateAction<ReferencedBy>>;
  clearSources: () => void;
  isJq: boolean;
}

const GeneralSettings: FC<GeneralSettingsProps> = ({ csvConfig, setCSVConfig, referencedBy, setReferencedBy, clearSources, isJq }) => {
  const { t } = useTranslation('fileImport');

  const [startLineInputValue, setStartLineInputValue] = useState<string>('');
  const [stopLineInputValue, setStopLineInputValue] = useState<string>('');

  const handleChangeStartLine: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setStartLineInputValue(event.target.value);
      if (event.target.value.length === 0) {
        setCSVConfig(state => ({ ...state, startLine: null }));
      } else {
        const parsedValue = parseInt(event.target.value, 10);
        if (!Number.isNaN(parsedValue) && parsedValue >= 1) {
          setCSVConfig(state => ({ ...state, startLine: parsedValue - 1 }));
        }
      }
    },
    [setCSVConfig],
  );

  const handleChangeStopLine: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setStopLineInputValue(event.target.value);
      if (event.target.value.length === 0) {
        setCSVConfig(state => ({ ...state, stopLine: null }));
      } else {
        const parsedValue = parseInt(event.target.value, 10);
        if (!Number.isNaN(parsedValue) && parsedValue >= 1) {
          setCSVConfig(state => ({ ...state, stopLine: parsedValue - 1 }));
        }
      }
    },
    [setCSVConfig],
  );

  const handleChangeDelimiter = useCallback(
    (newDelimiter: CSVDelimiter) => {
      setCSVConfig(state => ({ ...state, delimiter: newDelimiter }));
    },
    [setCSVConfig],
  );

  const handleChangeCharset = useCallback(
    (newCharset: NodeJS.BufferEncoding | null) => {
      setCSVConfig(state => ({ ...state, charset: newCharset }));
    },
    [setCSVConfig],
  );

  const handleChangeReferencedBy = useCallback(
    (newReferencedBy: ReferencedBy) => {
      clearSources();
      setReferencedBy(newReferencedBy);
    },
    [setReferencedBy, clearSources],
  );

  const handleChangeUseHeader = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      clearSources();
      setCSVConfig(state => ({ ...state, useHeader: event.target.checked }));
    },
    [setCSVConfig, clearSources],
  );

  const handleChangeIgnoreEmptyLines = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCSVConfig(state => ({ ...state, ignoreEmptyLines: event.target.checked }));
    },
    [setCSVConfig],
  );

  useEffect(() => {
    setStartLineInputValue(csvConfig?.startLine !== null ? `${(csvConfig?.startLine ?? 0) + 1}` : '');
  }, [csvConfig?.startLine]);

  useEffect(() => {
    setStopLineInputValue(csvConfig?.stopLine !== null ? `${(csvConfig?.stopLine ?? 0) + 1}` : '');
  }, [csvConfig?.stopLine]);

  return (
    <SectionAccordion>
      <SectionAccordionSummary>
        <Typography variant="h6">{t('title.settings')}</Typography>
      </SectionAccordionSummary>
      <SectionAccordionDetails>
        <Stack gap={1.5}>
          <ReferencedByPicker disabled={isJq} value={referencedBy} onChange={handleChangeReferencedBy} />
          <FormControlLabel
            control={<Checkbox checked={csvConfig.useHeader} onChange={handleChangeUseHeader} />}
            disabled={isJq}
            label={t('label.useHeader', { context: referencedBy })}
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">{t('text.lineNumber')}</InputAdornment>,
                }}
                inputProps={{ min: 1 }}
                label={t('label.startLine')}
                type="number"
                value={startLineInputValue}
                onChange={handleChangeStartLine}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">{t('text.lineNumber')}</InputAdornment>,
                }}
                inputProps={{ min: 1 }}
                label={t('label.stopLine')}
                type="number"
                value={stopLineInputValue}
                onChange={handleChangeStopLine}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={csvConfig.ignoreEmptyLines} onChange={handleChangeIgnoreEmptyLines} />}
                label={t('label.ignoreEmptyLines')}
              />
            </Grid>
            <Grid item xs={6}>
              <DelimiterPicker value={csvConfig.delimiter} onChange={handleChangeDelimiter} />
            </Grid>
            <Grid item xs={6}>
              <CharsetPicker value={csvConfig.charset} onChange={handleChangeCharset} />
            </Grid>
          </Grid>
        </Stack>
      </SectionAccordionDetails>
    </SectionAccordion>
  );
};

export default GeneralSettings;

import { Grid, Typography } from '@mui/material';
import { ChangeEventHandler, Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BacnetDeviceConfig } from '@dametis/core';

import AdvancedTextFieldWithMask from 'components/UI/AdvancedTextFieldWithMask/AdvancedTextFieldWithMask';
import MaskFormat from 'components/UI/InputMask/MaskFormat';
import AdvancedTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';

import FormPaper, { FormLocation } from '../FormPaper';

export interface BacnetSettingsFormProps {
  bacnet: BacnetDeviceConfig;
  setBacnet: Dispatch<SetStateAction<BacnetDeviceConfig>>;
  isEditing?: boolean;
  location?: `${FormLocation}`;
}

const BacnetSettingsForm: FC<BacnetSettingsFormProps> = ({ bacnet, setBacnet, isEditing = true, location = 'modal' }) => {
  const { t } = useTranslation('devices');

  const [portInputValue, setPortInputValue] = useState<string>('');
  const [instanceInputValue, setInstanceInputValue] = useState<string>('');
  const [loopTimeInputValue, setLoopTimeInputValue] = useState<string>('');

  const handleChangeHost: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setBacnet) {
        return;
      }
      setBacnet(state => ({ ...state, host: event.target.value }));
    },
    [setBacnet],
  );

  const handleChangePort: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setBacnet) {
        return;
      }
      setPortInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setBacnet(state => ({ ...state, port: parsedValue }));
      }
    },
    [setBacnet],
  );

  const handleChangeInstance: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setBacnet) {
        return;
      }
      setInstanceInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setBacnet(state => ({ ...state, instance: parsedValue }));
      }
    },
    [setBacnet],
  );

  const handleChangeLoopTime: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setBacnet) {
        return;
      }
      setLoopTimeInputValue(event.target.value);
      const parsedValue = parseInt(event.target.value, 10);
      if (!Number.isNaN(parsedValue)) {
        setBacnet(state => ({ ...state, loopTime: parsedValue }));
      }
    },
    [setBacnet],
  );

  useEffect(() => {
    setPortInputValue(`${bacnet.port ?? ''}`);
  }, [bacnet.port]);

  useEffect(() => {
    setInstanceInputValue(`${bacnet.instance ?? ''}`);
  }, [bacnet.instance]);

  useEffect(() => {
    setLoopTimeInputValue(`${bacnet.loopTime ?? ''}`);
  }, [bacnet.loopTime]);

  return (
    <FormPaper location={location} sx={{ p: 1.5 }}>
      <Typography mb={1.5} variant="h6">
        {t('title.acquisition')}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <AdvancedTextFieldWithMask
            fullWidth
            required
            editing={isEditing}
            label={t('label.ip')}
            maskedInputProps={MaskFormat.ip}
            value={bacnet.host ?? ''}
            onChange={handleChangeHost}
          />
        </Grid>
        <Grid item xs={3}>
          <AdvancedTextField
            fullWidth
            required
            editing={isEditing}
            InputProps={{ inputProps: { min: 0 } }} // SLE: check that
            label={t('label.port')}
            type="number"
            value={portInputValue}
            onChange={handleChangePort}
          />
        </Grid>
        <Grid item xs={6}>
          <AdvancedTextField
            fullWidth
            required
            editing={isEditing}
            InputProps={{ inputProps: { step: 1 } }} // SLE: check that
            label={t('label.instance')}
            type="number"
            value={instanceInputValue}
            onChange={handleChangeInstance}
          />
        </Grid>
        <Grid item xs={6}>
          <AdvancedTextField
            fullWidth
            required
            editing={isEditing}
            InputProps={{ inputProps: { step: 100, min: 1000 } }} // SLE: check that // SLE: this step does not count in validator
            label={t('label.loopTime')}
            type="number"
            value={loopTimeInputValue}
            onChange={handleChangeLoopTime}
          />
        </Grid>
      </Grid>
    </FormPaper>
  );
};

export default BacnetSettingsForm;

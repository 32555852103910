import { TFunction } from 'i18next';

import { IsFileParseError, IsJqParseError, IsPapaParseError, ParseError } from '@dametis/core';

export const getParseErrorLabel = (parseError: ParseError, t: TFunction): string => {
  if (IsFileParseError(parseError)) {
    return t(`errors.fileParse.${parseError.error.code}`);
  }
  if (IsPapaParseError(parseError)) {
    return t(`errors.papaParse.${parseError.error.code}`);
  }
  if (IsJqParseError(parseError)) {
    return t('errors.jqError');
  }
  return t('text.unknownParseError');
};

import { ArrowBackIos } from '@mui/icons-material';
import { Box, Button, Stack, StackProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import StandardPage from 'components/UI/StandardPage/StandardPage';

export interface ErrorPageTemplateProps extends StackProps {
  action?: ReactNode;
  children?: ReactNode;
  image: string;
  text: ReactNode;
  title: string;
}

const ErrorPageTemplate: FC<ErrorPageTemplateProps> = ({ action = undefined, children = undefined, image, text, title, ...props }) => {
  const { t } = useTranslation('errors');

  return (
    <StandardPage alignItems="center" maxWidth="800px" minWidth="480px" py={2} width="100%" {...props}>
      <Stack spacing={4} width="100%">
        <Box alt="error" component="img" height="250px" src={image} />
        <Stack spacing={2}>
          <Typography variant="h1">{title}</Typography>
          <Typography variant="subtitle2">{text}</Typography>
        </Stack>
        {children && <Box sx={{ wordBreak: 'break-word' }}>{children}</Box>}
        <Stack direction="row" flexWrap="wrap" justifyContent="space-between" spacing={2} width={1}>
          <Button href="/" startIcon={<ArrowBackIos />} variant="contained">
            {t('button.back')}
          </Button>
          {action}
        </Stack>
      </Stack>
    </StandardPage>
  );
};

export default ErrorPageTemplate;
